import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { GenericButtonComponent } from 'src/app/dce-shared/generic-button/generic-button.component';
interface btn_loader{
	is_loading: boolean
	btn_loading_text: string
}
@Component({
  selector: 'app-cancel-edit',
  templateUrl: './cancel-edit.component.html',
  styleUrls: ['./cancel-edit.component.scss']
})
export class CancelEditComponent implements OnInit {
	
	@ViewChild(GenericButtonComponent ,{static: false}) genericbuttoncomponent: GenericButtonComponent;
	@Input() payload_dict?:any = {};
	@Output() edit_event: EventEmitter<any> = new EventEmitter<any>();
	previous_value :any = {};
	@Input() show_edit_button:boolean;
	@Input() class_="";
	@Input() update_button_data = {
		'update_button_id' : '',
		'update_service_url' : '',
	}
	disable_cancel_edit:boolean = false;
	@Input() generic_button:boolean = false;
	@Input() is_btn_loader ?:boolean = false; 
	@Input() btn_loader_config:btn_loader = {
		is_loading: false,
		btn_loading_text:'Saving'
	}

	constructor() { }

	ngOnInit() {
	}
	click_event(case_){
		let data = {
			'case': case_,
			'payload': {},
			'editing': this.show_edit_button
		}
		switch (case_) {
			case "edit":
				this.show_edit_button = true
				this.disable_cancel_edit = false
				for (var i in this.payload_dict) {
					if(this.payload_dict[i]){
						if(typeof(this.payload_dict[i].getRawValue) === 'function'){
							this.previous_value[i] = JSON.parse(JSON.stringify(this.payload_dict[i].getRawValue())); 
						}else{
							this.previous_value[i] = JSON.parse(JSON.stringify(this.payload_dict[i]))
						}
					}else{
						this.previous_value[i] = this.payload_dict[i]
					}
				}
				break;
		case "cancel-edit":
				this.show_edit_button = false
				data['payload'] = this.previous_value
			break;
		case "update":
			this.show_edit_button = false;
			break;
		}
	this.edit_event.emit(data);

	}

	update_events(emitted_event){
		this.edit_event.emit(emitted_event)
	}

	save_update_data_on_service(parent_payload = null){
		this.disable_cancel_edit = true;
		this.genericbuttoncomponent.save_data_on_service(parent_payload)
	}
}
