import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/dce-service/common.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { CustomValidators } from 'src/app/dce-service/custom-validators.component';
import { ToastrService } from 'ngx-toastr';
import {  ToastrUtilityService } from '../../dce-service/toastr-utility.service';

declare var $: any;

@Component({
  selector: 'app-bus-org-details-payload',
  templateUrl: './bus-org-details-payload.component.html',
  styleUrls: ['./bus-org-details-payload.component.scss']
})
export class BusOrgDetailsPayloadComponent implements OnInit, AfterViewInit {

  @Input() unique_id?:string = '';
  busorg_payload: FormGroup;
  submitted = false;
  employer_category = []
  industry_list = []
  highlight_fields = {};
  customPatterns = {}

  constructor(
    private _fb: FormBuilder,
    private _env: EnvService,
    private _commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { }

  ngOnInit() {
    this.get_classification_data();
    this.initialize_payload();
    this.customPatterns = this._commonService.phone_number_pattern();
  }

  ngAfterViewInit() {
    // $('#add_bus_phone_number1'+this.unique_id).inputmask("999 999 9999");
    // $('#add_bus_phone_number2'+this.unique_id).inputmask("999 999 9999");
  }

  initialize_payload() {
    this.busorg_payload = this._fb.group({
      'company_name': this._fb.control('', Validators.required),
      'registration_number': this._fb.control('', Validators.required),
      'trading_name': this._fb.control(''),
      'registration_date': this._fb.control(''),
      'type': this._fb.control({}),
      'category': this._fb.control(''),
      'holding_company': this._fb.control(''),
      'industry': this._fb.control(''),
      'uifrefno': this._fb.control(''),
      'cfcustomerid': this._fb.control(''),
      'ministerial_status': this._fb.control(false),
      'biz_data': this._fb.group({
        'employees_count': this._fb.control(''),
        'bbbee_data': this._fb.control(''),
        'imp_info': this._fb.control('')
      }),
      'email_address1': this._fb.control(''),
      'email_address2': this._fb.control(''),
      'phone_number1': this._fb.control('',[Validators.required, CustomValidators.contact_number]),
      'phone_number2': this._fb.control('',[Validators.minLength(10)]),
    });
  }

  get_classification_data() {
    const payload = {
      'classification_type_id': [this._env.dropdown_list.employer_category, this._env.dropdown_list.industry] ,
      'classification_value_id': [-1, -1]
    };
    this._commonService.get_particular_classification_data(payload).subscribe( res => {
      if (res.errCode == 0) {
        for (let i = 0; i < res.msg.length; i++) {
          if (res.msg[i].classification_type == this._env.dropdown_list.employer_category) {
            this.employer_category = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.industry) {
            this.industry_list = res.msg[i].data;
          }
        }
      }
    });
  }

  validate() {
    this.submitted = true;
    if (this.busorg_payload.status == 'VALID' || this.busorg_payload.status == 'DISABLED') {
      return true;
    }
    return false;
  }

  onChangeEmail(_case) {
    this.validate_email(_case)
  }

  validate_email(_case) {
    switch(_case) {
      case 'email_address1': 
        const email_address1 = this.busorg_payload.get('email_address1');
        if(email_address1.value.length >0 && email_address1.valid) {
          this.busorg_payload.get('email_address1').setValidators([Validators.email]);
        } else if (email_address1.value.length <=0) {
          this.busorg_payload.get('email_address1').clearValidators()
        } else {
          // do nothing
        }
        this.busorg_payload.get('email_address1').updateValueAndValidity();
      break;
      case 'email_address2':
        const email_address2 = this.busorg_payload.get('email_address2');
        if(email_address2.value.length >0 && email_address2.valid) {
          this.busorg_payload.get('email_address2').setValidators([Validators.email]);
        } else if (email_address2.value.length <=0) {
          this.busorg_payload.get('email_address2').clearValidators()
        } else {
          // do nothing
        }
        this.busorg_payload.get('email_address2').updateValueAndValidity();
      break;
    }
  }

  // validate_phone_no() {
  //   var phone1 = $('#add_bus_phone_number1'+this.unique_id).val();
  //   var phone2 = $('#add_bus_phone_number2'+this.unique_id).val();

  //   // phone1
  //   if(phone1.length == 12 && !phone1.includes("_")) {
  //     this.busorg_payload.controls.phone_number1.setValue(phone1)
  //   } else {
  //     this._toastr.error('', 'Please enter a valid phone number', this._toastrUtility.basic_configuration);
  //     return false;
  //   }

  //   // phone2
  //   if (phone2.length == 0) {
  //     this.busorg_payload.controls.phone_number2.setValue("")
  //   }else if(phone2.length == 12 && !phone2.includes("_")) {
  //     this.busorg_payload.controls.phone_number2.setValue(phone2)
  //   } else {
  //     this._toastr.error('', 'Please enter a valid alternate phone number', this._toastrUtility.basic_configuration);
  //     return false;
  //   }

  //   // console.log(this.ind_cus_payload.controls.phone_number1, this.ind_cus_payload.controls.phone_number2);

  //   return true;
  // }

  toggle_form(bool) {
    if (bool) {
      this.busorg_payload.enable();
    } else {
      this.busorg_payload.disable();
    }
  }



  clear_form() {
    this.submitted = false;
    this.busorg_payload.patchValue({
      'company_name': '',
      'registration_number': '',
      'trading_name': '',
      'category': '',
      'holding_company': '',
      'industry': '',
      'registration_date':'',
      'type': {},
      'uifrefno': '',
      'cfcustomerid':'',
      'ministerial_status': false,
      'biz_data': {
        'employees_count': '',
        'bbbee_data': '',
        'imp_info': ''
      },
      'email_address1':'',
      'email_address2':'',
      'phone_number1':'',
      'phone_number2':'',
    });
  }


  fill_busorg_details(data, auto_populate = false) {
    console.log(data);
    
    if(data['ministerial_status'] == 1){
      data['ministerial_status'] = true
    }else if(data['ministerial_status'] == 0 || data['ministerial_status'] == null){
      data['ministerial_status'] = false
    }
    
    this.busorg_payload.patchValue(data);
    if (auto_populate && Object.keys(data).length) {
      this.mark_fields_to_highlight(data);
    }
  }

  mark_fields_to_highlight(data) {
    for (let i in data) {
      this.highlight_fields[i] = true;
    };
  }


  get_payload_data() {
    const payload = this.busorg_payload.getRawValue()
    payload['ministerial_status'] = payload['ministerial_status']==false?0 :1;
    return payload
  }

  disable_registration_num(){
    this.busorg_payload.get('registration_number').disable();
  }

  check_reg_num_exist(){
    if(this.busorg_payload.get('registration_number').value != ''){
      let payload = {
        "registration_number":this.busorg_payload.get('registration_number').value
      }
       this._commonService.check_reg_num_exist(payload).subscribe(res => {
         if(res.errCode == 0){
           if(res.exist){
             this.busorg_payload.get('registration_number').setValue('')
             this._toastr.error('', 'Organisation Already exist.', this._toastrUtility.basic_configuration);
           }
         }
       })
    }
  }
}
