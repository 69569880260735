import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-clear-search',
  templateUrl: './clear-search.component.html',
  styleUrls: ['./clear-search.component.scss']
})
export class ClearSearchComponent implements OnInit {

  @Output() clear_form_search: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  table_clear_data(){
    this.clear_form_search.emit(true);
  }

}
