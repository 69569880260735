import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BusinessObjectWorkflowService {

  constructor(
		protected http: HttpClient,
		protected env: EnvService,
		) {  }
    
  // Schema flow servcie calls
  private create_schema_flow_url = `${this.env.service_url}/businessworkflowobject/schemaflow/create`
  create_schema_flow(payload){
    return this.http.post<any>( this.create_schema_flow_url, payload);
  }

  private update_schema_flow_url = `${this.env.service_url}/businessworkflowobject/schemaflow/update`
  update_schema_flow(payload){
    return this.http.post<any>( this.update_schema_flow_url, payload);
  }

  private get_attached_conditions_url = `${this.env.service_url}/businessworkflowobject/schemaflow/get_attached_conditions`
  get_attached_conditions(payload){
    return this.http.post<any>( this.get_attached_conditions_url, payload);
  } 

  private remove_schema_flow_url = `${this.env.service_url}/businessworkflowobject/schemaflow/remove`
  remove_schema_flow(payload){
    return this.http.post<any>( this.remove_schema_flow_url, payload);
  } 

  private get_schema_flow_url = `${this.env.service_url}/businessworkflowobject/schemaflow/get`
  get_schema_flow(payload){
    return this.http.post<any>( this.get_schema_flow_url, payload);
  }

  private get_all_schema_flow_url = `${this.env.service_url}/businessworkflowobject/schemaflow/get_all`
  get_all_schema_flow(payload){
    return this.http.post<any>( this.get_all_schema_flow_url, payload);
  }

  private activate_schema_flow_url = `${this.env.service_url}/businessworkflowobject/schemaflow/activate`
  activate_schema_flow(payload){
    return this.http.post<any>( this.activate_schema_flow_url, payload);
  }

  private change_status_schema_flow_url =  `${this.env.service_url}/businessworkflowobject/schemaflow/set_status`
  change_status_schema_flow(payload){
    return this.http.post<any>( this.change_status_schema_flow_url, payload);
  }
  private configure_schema_flow_url = `${this.env.service_url}/businessworkflowobject/schemaflow/configuration`
  configure_schema_flow(payload){
    return this.http.post<any>( this.configure_schema_flow_url, payload);
  }

  // Schema Instances
  private get_all_stage_of_schema_instance_url = `${this.env.service_url}/businessworkflowobject/instance/stage/getall`
  get_all_stage_of_schema_instance(payload){
    return this.http.post<any>( this.get_all_stage_of_schema_instance_url, payload);
  }

  private get_all_stage_task_of_schema_instance_url = `${this.env.service_url}/businessworkflowobject/instance/stage/getall_tasks`
  get_all_stage_task_of_schema_instance(payload){
    return this.http.post<any>( this.get_all_stage_task_of_schema_instance_url, payload);
  }

  private unlock_stage_override_url = `${this.env.service_url}/businessworkflowobject/instance/stage/unlock_stage_override`
  unlock_stage_override(payload){
    return this.http.post<any>( this.unlock_stage_override_url, payload);
  }

  // Task flow/ Stage flow service calls
  private create_stage_flow_url = `${this.env.service_url}/businessworkflowobject/stageflow/create`
  create_stage_flow(payload){
    return this.http.post<any>( this.create_stage_flow_url, payload);
  }

  private update_stage_flow_url = `${this.env.service_url}/businessworkflowobject/stageflow/update`
  update_stage_flow(payload){
    return this.http.post<any>( this.update_stage_flow_url, payload);
  }
  
  private remove_stage_flow_url = `${this.env.service_url}/businessworkflowobject/stageflow/remove`
  remove_stage_flow(payload){
    return this.http.post<any>( this.remove_stage_flow_url, payload);
  }
  
  private get_stage_flow_url = `${this.env.service_url}/businessworkflowobject/stageflow/get`
  get_stage_flow(payload){
    return this.http.post<any>( this.get_stage_flow_url, payload);
  }

  private get_stage_flow_all_url = `${this.env.service_url}/businessworkflowobject/stageflow/get_all`
  get_all_stage_flow(payload){
    return this.http.post<any>( this.get_stage_flow_all_url, payload);
  }

  // schema 2 classification link
  private get_all_active_schema_url = `${this.env.service_url}/businessworkflowobject/action/getall`
  get_all_active_schema(payload) {
    return this.http.post<any>(this.get_all_active_schema_url, payload);
  }

  private get_all_active_schema_case_url = `${this.env.service_url}/businessworkflowobject/action/case/get`
  get_all_active_schema_case(payload) {
    return this.http.post<any>(this.get_all_active_schema_case_url, payload);
  }

  private get_all_active_schema_lead_url = `${this.env.service_url}/businessworkflowobject/action/lead/get`
  get_all_active_schema_lead(payload) {
    return this.http.post<any>(this.get_all_active_schema_lead_url, payload);
  }

  private add_case_class_to_schema_url = `${this.env.service_url}/businessworkflowobject/action/case/add`
  add_case_class_to_schema(payload) {
    return this.http.post<any>(this.add_case_class_to_schema_url, payload);
  }

  private update_case_class_to_schema_url = `${this.env.service_url}/businessworkflowobject/action/case/update`
  update_case_class_to_schema(payload) {
    return this.http.post<any>(this.update_case_class_to_schema_url, payload);
  }

  private remove_class_to_schema_url = `${this.env.service_url}/businessworkflowobject/action/remove`
  remove_class_to_schema(payload) {
    return this.http.post<any>(this.remove_class_to_schema_url, payload);
  }

  private add_lead_class_to_schema_url = `${this.env.service_url}/businessworkflowobject/action/lead/add`
  add_lead_class_to_schema(payload) {
    return this.http.post<any>(this.add_lead_class_to_schema_url, payload);
  }

  private update_lead_class_to_schema_url = `${this.env.service_url}/businessworkflowobject/action/lead/update`
  update_lead_class_to_schema(payload) {
    return this.http.post<any>(this.update_lead_class_to_schema_url, payload);
  }

  // dynamic-forms services

  private get_workflow_xfields_url = `${this.env.service_url}/businessworkflowobject/instance/get_workflow_xfields`
  get_workflow_xfields(payload) {
    return this.http.post<any>(this.get_workflow_xfields_url, payload);
  }

  private get_dynamic_form_url = `${this.env.service_url}/dynamic_forms/workflow/get`
  get_dynamic_form(payload) {
    return this.http.post<any>(this.get_dynamic_form_url, payload);
  }

  private get_all_dynamic_form_url = `${this.env.service_url}/dynamic_forms/workflow/get_all`
  get_all_dynamic_form(payload) {
    return this.http.post<any>(this.get_all_dynamic_form_url, payload);
  }

  private create_dynamic_form_url = `${this.env.service_url}/dynamic_forms/workflow/create`
  create_dynamic_form(payload) {
    return this.http.post<any>(this.create_dynamic_form_url, payload);
  }

  private update_dynamic_form_url = `${this.env.service_url}/dynamic_forms/workflow/update`
  update_dynamic_form(payload) {
    return this.http.post<any>(this.update_dynamic_form_url, payload);
  }

  private activate_dynamic_form_url = `${this.env.service_url}/dynamic_forms/workflow/activate`
  activate_dynamic_form(payload) {
    return this.http.post<any>(this.activate_dynamic_form_url, payload);
  }

  private deactivate_dynamic_form_url = `${this.env.service_url}/dynamic_forms/workflow/deactivate`
  deactivate_dynamic_form(payload) {
    return this.http.post<any>(this.deactivate_dynamic_form_url, payload);
  }

  private disable_dynamic_form_url = `${this.env.service_url}/dynamic_forms/workflow/disable`
  disable_dynamic_form(payload) {
    return this.http.post<any>(this.disable_dynamic_form_url, payload);
  }

  // task-form services
  private attached_forms_url = `${this.env.service_url}/businessworkflowobject/schemaflow/dynamic_forms/attached_forms`
  get_attached_forms(payload) {
    return this.http.post<any>(this.attached_forms_url, payload);
  }

  private get_all_forms_url = `${this.env.service_url}/dynamic_forms/workflow/get_all`
  get_all_forms(payload) {
    return this.http.post<any>(this.get_all_forms_url, payload);
  }



}
