import { Component, OnInit, Input, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { isNumeric } from "rxjs/util/isNumeric"

@Injectable()
export class CustomValidators implements OnInit {

    static birthYear(c: FormControl): ValidationErrors  {
        const user_dob = new Date(c.value);
        let currentDate = new Date();
        let lastDate = new Date();
        currentDate =  new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
        lastDate = new Date(lastDate.getFullYear() - 90, lastDate.getMonth(), lastDate.getDate());
        const isValid = (currentDate >= user_dob && lastDate <= user_dob);
        const message = {
          'birthYear': {
            'message': 'Minimum age should be 18 years'
          }
        };
        return isValid ? null : message;
      }


     static mobileNo(c: FormControl): ValidationErrors {
     // let isValid = false;
     const mobile_number = c.value;
     const isValid = (mobile_number.toString().length == 9 && mobile_number != null);
     const message = {
       'message': 'Enter 9-digit number'
     };
     return isValid ? null : message;
   }
   static mobileNo_10(c: FormControl): ValidationErrors {
    // let isValid = false;
      const mobile_number = c.value;
      const mobileNumberStr = mobile_number.toString().trim();
      const isOnlyDigits = /^\d+$/.test(mobileNumberStr);
      const isValid = (mobile_number != null && isNumeric(mobile_number) && mobile_number.toString().length == 10 && isOnlyDigits);
      const message = {
        'mobileNo_10': {
          'message':'Enter 10-digit number'
        }
      };

      return isValid ? null : message;
    }

   static startDate(c: FormControl): ValidationErrors {
     const date = new Date(c.value);
     const today = new Date();
     const isValid =  (date.getTime() < today.getTime()) ? true : false;
     const message = {
      'startDate':{
        'message': ' Start date should be a date after today'
        }
      };
      return isValid ? null : message;
   }

  static date_for_accident(c: FormControl): ValidationErrors {
    if(!c.value || c.value == ''){
      return null;
    }
    let dateObject: any
    const dateParts = c.value.split('/');
    if(dateParts[0].length >= 4){
      dateObject = new Date(c.value);
    }else{
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);
      dateObject = new Date(year, month - 1, day);
    }
    const today = new Date();
    const isValid = (dateObject <= today) ? true : false;
    const message = {
      'date_for_accident': {
        'message': 'Future dates are not allowed. Please enter a valid date.'
      }
    };
    return isValid ? null : message;
  }

    static ID_no_format(c: FormControl): ValidationErrors {
      var cc = c.value;
      var isValid = true;
      var message = {};
      if(cc != ''){
        const d = new Date('19'+cc.substring(0,2)+'-'+cc.substring(2,4)+'-'+cc.substring(4,6));
        const x = (d.toString() == 'Invalid Date');
        // YYMMDDSSSSCAZ
        let nSum: number = 0;
        let isSecond = false;
        for (let i = cc.length - 1; i >= 0; i--) {
          let d = Number(cc[i]);
          if (isSecond == true) {
            d = d * 2;
          }
          nSum += Math.floor(d / 10);
          nSum += Math.floor(d % 10);
          isSecond = !isSecond;
        }

        if(cc.length != 13 || isNaN(cc) || x || (cc.substring(10,11) != '0' && cc.substring(10,11) != '1' && cc.substring(10,11) != '2') || Math.floor(nSum % 10 )!= 0 ){
            isValid = false;
            message = {
              'idNumber': {
                'message': 'ID Number format Incorrect. YYMMDDSSSSCAZ'
              }
            };
        }
      }
      return isValid ? null : message;
    }

    static Ticket_no_format(c: FormControl): ValidationErrors {
      var cc = c.value;
      if(cc == ''){
        return null;
      }
      var isValid = true;
      var message = {};
      // let ticket_format = /(?:[C]-((?:19|20)\d\d(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])-\d{6})|(C\d{8}\d{6}))/;
      let ticket_format = /^C-(?:19|20)\d\d(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])-\d{6}$/;
      if(!ticket_format.test(cc)){
        isValid = false;
        message = {
          'Ticket_no_format': {
            'message': 'Ticket Number format Incorrect. C-XXXXXXXX-XXXXXX or CXXXXXXXXXXXXXX'
          }
        };
      }
      return isValid ? null : message;
    }

    static Link_no_format(c: FormControl): ValidationErrors {
      var cc = c.value;
      if(cc == ''){
        return null;
      }
      var isValid = true;
      var message = {};
      // let ticket_format = /(?:[C]-((?:19|20)\d\d(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])-\d{6})|(C\d{8}\d{6}))/;
      let ticket_format =/^\d{7}$/;
      if(!ticket_format.test(cc)){
        isValid = false;
        message = {
          'Link_no_format': {
            'message': 'Link Number format Incorrect. XXXXXXX'
          }
        };
      }
      return isValid ? null : message;
    }

    static text_include_only_alphabets(c: FormControl): ValidationErrors {
      var cc = c.value;
      if(cc == ''){
        return null;
      }
      var isValid = true;
      var message = {};
      let ticket_format =/^[A-Za-z ]+$/;
      if(!ticket_format.test(cc)){
        isValid = false;
        message = {
          'text_include_only_alphabets': {
            'message': 'This field should include only alphabets'
          }
        };
      }
      return isValid ? null : message;
    }

    static rating_format(c: FormControl): ValidationErrors {
      var rating = c.value;
      if(rating == ''){
        return null;
      }
      var isValid = true;
      var message = {};
      if(rating<0 || rating>10){
        isValid = false;
        message = {
          'rating_format': {
            'message': 'Invalid Ratings'
          }
        };
      }
      return isValid ? null : message;
    }

    static isNotExemptionDependentDate(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = Math.abs(ageDate.getUTCFullYear() - 1970);
      const isValid =  (age_value > 21) ? false : true;
      const message = {
       "isNotExemptionDependentDate":{
          'message': ' Age cannot be greater than 21'
        }
       };
       return isValid ? null : message;
    }

    static isExemptionDependentDate(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = Math.abs(ageDate.getUTCFullYear() - 1970);

      const isValid =  (age_value > 26) ? false : true;
      const message = {
       "isExemptionDependentDate":{
          'message': ' Age cannot be greater than 26'
        }
       };
       return isValid ? null : message;
    }

    static adultAge(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = Math.abs(ageDate.getUTCFullYear() - 1970);

      const isValid =  (age_value < 21) ? false : true;
      const message = {
       "adultAge":{
          'message': ' Age cannot be less than 21'
        }
       };
       return isValid ? null : message;
    }

    static matchText(c: FormControl): ValidationErrors {
      const isValid =  (c.value == 'DUMMY') ? true : false;
      const message = {
       "matchText":{
          'message': ' Text should be DUMMY'
        }
       };
       return isValid ? null : message;
    }

    static passport_id_format(c: FormControl): ValidationErrors {
      var cc = c.value;
      var isValid = true;
      var message = {};
      const isOnlyDigits = /^\d+$/.test(cc);
      if(cc != '' && cc.length==13 && isOnlyDigits){
        const d = new Date('19'+cc.substring(0,2)+'-'+cc.substring(2,4)+'-'+cc.substring(4,6));
        const x = (d.toString() == 'Invalid Date');
        // YYMMDDSSSSCAZ
        let nSum: number = 0;
        let isSecond = false;
        for (let i = cc.length - 1; i >= 0; i--) {
          let d = Number(cc[i]);
          if (isSecond == true) {
            d = d * 2;
          }
          nSum += Math.floor(d / 10);
          nSum += Math.floor(d % 10);
          isSecond = !isSecond;
        }
        
        if(isNaN(cc) || x || (cc.substring(10,11) != '0' && cc.substring(10,11) != '1' && cc.substring(10,11) != '2') || Math.floor(nSum % 10 )!= 0 ){
            isValid = false;
            message = {
              'passport_id_format': {
                'message': 'ID Number format Incorrect. YYMMDDSSSSCAZ'
              }
            };
        }
      }
      return isValid ? null : message;
    }


    ngOnInit() {

    }
}
