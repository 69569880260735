import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Md5 } from 'md5-typescript';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() show_create_user = true;
  @Input() show_fields:boolean = false;
  @Output() checkbox_value?: EventEmitter<any> = new EventEmitter<any>();

  login_details_payload: FormGroup;
  is_enabled = true;
  submitted = false;
  show_box = false;
  isIEOrEdge: boolean;


  constructor(
    private _fb: FormBuilder,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService

  ) { }



  ngOnInit() {
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    this.initialize_payload();
    this.show_box = this.show_fields;
  }

  initialize_payload(){
  	this.login_details_payload = this._fb.group({
      'user_name' : this._fb.control('', [Validators.required,Validators.email]),
      'password' : this._fb.control('' , [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]),
      'cpassword' : this._fb.control('', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)])
   });
  }
  login_service_call_payload() {
    return {
      'user_name':this.login_details_payload.value.user_name.toLowerCase(),
      'password': Md5.init(Md5.init(this.login_details_payload.value.password))
    };
  }

    toggle_form() {
    this.show_box = !this.show_box
    this.checkbox_value.emit(this.show_box);
  }

  clear_form() {
    this.login_details_payload.patchValue({
	    'user_name' :'',
      'password' : '',
      'cpassword' : ''
    })
  } 


  validate(): boolean {
    this.submitted = true;
    let valid = false;
    if(this.is_enabled){
      if (this.login_details_payload.valid) {
        if(this.login_details_payload.value.password == this.login_details_payload.value.cpassword){
          valid = true;
        } else {
          valid = false;
          this._toastr.error('', 'Passwords did not match', this._toastrUtility.basic_configuration);
        }
      }
    } 
    return valid;
  }

}
