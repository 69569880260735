import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GenericSurveyjsRoutingModule } from './generic-surveyjs-routing.module';
import { GenericSurveyjsComponent } from './generic-surveyjs/generic-surveyjs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [GenericSurveyjsComponent],
  imports: [
    CommonModule,
    GenericSurveyjsRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[
    GenericSurveyjsComponent
  ]
})
export class GenericSurveyjsModule { }
