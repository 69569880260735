import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;
import { TreeService } from '../tree/tree.service';
import { SharedService } from '../../dce-service/shared.service';
import { Config } from './config';
@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class TreeComponent implements OnInit, AfterViewInit, OnChanges {

	//	Variables Initialised

  	@Input() case: string;
  	@Input() tree_id: string;
  	@Input() id: string;
    @Input() search_placeholder: string = "Search within Hierarchy";
    @Input() plugins?: any = []
    @Input() extra_payload_keys?: any = {};

    @Output() tree_event: EventEmitter<any> = new EventEmitter<any>();
    @Output() move_node_event: EventEmitter<any> = new EventEmitter<any>();
    // @ViewChild('mainScreen') elementView: ElementRef;

  	menu_items = {};
  	service = '';

    tree_data = [];
    tree_nodes_json = {};
    tree_height = '';
    role_map = {};

	constructor(
		private _sharedService: SharedService,
		private _tree_service: TreeService,
    private config: Config
		) {

  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.id && !changes.id.firstChange) {
      this._update_tree_data(this.case, this.tree_id, changes.id.currentValue);
    }
    if(changes.plugins) {
      this.config.menu_map[this.case]['plugins'] = changes.plugins.currentValue;
    }
  }

	ngOnInit() {
		var self = this;
    this.role_map = this._sharedService.get_roles_map();
    this._get_tree_data();
  }

  ngAfterViewInit() {
    this.onResize('');
  }

  // @HostListener('window:scroll', [])
  // onScroll(event): void {
  //   localStorage.setItem(this.tree_id, event.srcElement.scrollTop);
  // }

  onResize(event) {
    // 20 value for the margin bottom 
    if ($('.top-position').offset() != undefined) {
      const w = window.innerHeight - $('.top-position').offset().top - 20;
      const divHeight = w.toString() + 'px' ;
      document.getElementById(this.tree_id).setAttribute('style', `height:${divHeight}`);
    }
  }

	  jsTree(tree_id, tree_data){

    var to: any = false;

    $('#search_'+tree_id).keyup(function() {
      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(function() {
        var v = $('#search_'+tree_id).val();
        $('#'+tree_id).jstree(true).search(v);
      }, 250);
    });

    tree_id = tree_id;
    // tree_id = 'js_tree_id';
		var self = this;
		// alert(self);
		// console.log(tree_data);
		var main_tree = $('#'+tree_id).jstree({
			'core' : {
				'data' : tree_data,
        "check_callback": function (op, node, par, pos, more) {
          if(more && more.dnd && (op === 'move_node') && node.parent != par.id) {
             return false;
           }
           return true;
         },
				'themes': {
          'name': 'proton',
          'responsive': true,
					'icons': true
				}
			},
      "plugins": this.config.menu_map[this.case]['plugins']? this.config.menu_map[this.case]['plugins']:["types", "contextmenu", "search"],
      "search": {
        "show_only_matches": true,
        "show_only_matches_children": true,
      },
			// "plugins": ["contextmenu"],
      "types" : this.config.icon_types,
			"contextmenu": {
		    "items": function(node) {
          console.log(node)
          return self.context_menu_options(self, node);
        }
	    }

		}).bind("select_node.jstree", function (evt, data){
			self.left_click_options(self, data);
    }).bind("move_node.jstree", function(e, data) {
      //console.log(data.old_position + '->' + data.position);
      //console.log(data.node.id + '->' + data.position);
      //console.log(index_difference);
      // console.log(children);
      // const index_difference = data.position -data.old_position
      // if(index_difference >0) {
      //   console.log("uper se niche")
      // } else {
      //   console.log("niche se uper")
      // }
      const children = $('#' + self.tree_id).jstree().get_node(data.parent).children;
      const old_index_node = $('#' + self.tree_id).jstree().get_node(children[data.position +1])
      self.move_node_event.emit({drop_node: old_index_node, selected_node: data.node, selected_node_index:data.old_position});
    }).bind("refresh.jstree", function(e,data){
      self.restore_tree_state();
    });

    this.tree_event.emit({'case': 'tree_loaded', 'data': {} });

  }

  //	FUNCTIONS

  context_menu_options(self, node){
  	// var tree = $("#"+tree_id).jstree(true);	//	IMPORTANT

    var final_items = {};

    var items = {};
    for(var item in this.config.context_menu_items_map){
      items[item] = {
        label: this.config.context_menu_items_map[item],
        value: item,
        action: function (data) {
          self.tree_to_parent({'case': data.item.value, 'data': node.original});
        }
      };
    }

    for(var i=0; i<node.data.context_items.length; i++) {
    	final_items[node.data.context_items[i]] = items[node.data.context_items[i]];
    }

    return final_items;

  }

  left_click_options(self, data){
    console.log(data);
    var internal_case = data.node.original.type;
    self.tree_to_parent({'case': self.case + '_left_' + internal_case , 'data': data.node.original, 'level':data.node.parents.length});

  }

  tree_to_parent(data: any): void {
      this.tree_event.emit(data);
  }

  _get_tree_data() {
  	this.update_menu_items();

    this._tree_service.get_tree_data(this.service, this.id, this.menu_items, this.extra_payload_keys).subscribe(
      res => {
        this.tree_data = res
        // this.tree_data = [
        //   {"id": 127096, "parent": "#", "text": "Moses App Case Classification V1", "state": {"opened": true}, "data": {"context_items": []}, "type": "no_leaf", "mapped_data": null},
        //   {"id": 127097, "parent": 127096, "text": "Healthcare", "state": {"opened": true}, "data": {"context_items": []}, "type": "no_leaf", "mapped_data": null},
        //   {"id": 127120, "parent": 127097, "text": "Membership Termination", "state": {"opened": true}, "data": {"context_items": []}, "type": "mapped_leaf", "mapped_data": {"mobile_classification_id": 127120, "case_classification_id": 128243, "app_username": "sys_admin@alteram.com"}}]
        this.jsTree(this.tree_id, this.tree_data);
      },
      err => {
        console.log(err)
      }
    );

  }
  _get_tree_length(){
    this.tree_to_parent({'case':'tree_length','data':this.tree_data.length});   
  }
  _update_tree_data(case_type, tree_id, id) {
  	this.case = case_type;
  	this.tree_id = tree_id;
  	this.id = id;
    this.tree_nodes_json = {};

  	this.update_menu_items();

	    this._tree_service.get_tree_data(this.service, this.id, this.menu_items, this.extra_payload_keys).subscribe(
	      res => {
	        this.tree_data = res;
	        // console.log(this.tree_data);
	        // console.log($('#'+ this.tree_id).jstree(true));
          this.tree_nodes_json = this.get_tree_node_state_map();
	        $('#'+ this.tree_id).jstree(true).settings.core.data = this.tree_data;
					$('#'+ this.tree_id).jstree(true).refresh();
	      },
	      err => {
	        console.log(err)
	      }
	    );

  }

  get_tree_node_state_map() {
    var jsonNodes = $('#' + this.tree_id).jstree(true).get_json('#', { flat: true });
    const nodes_state_map = {};
    for (let i = 0; i < jsonNodes.length; i++) {
      nodes_state_map[jsonNodes[i].id] = jsonNodes[i].state; // {node_id: state obj}
    }
    return nodes_state_map;
  }

  restore_tree_state() {
    // setTimeout(() => { // time required for tree to rerender
      for (let i = 0; i < this.tree_data.length; i++) {
        if (this.tree_nodes_json[this.tree_data[i].id]) { // if node_id is present in updated tree data
          const node_state_object = this.tree_nodes_json[this.tree_data[i].id];
          if (node_state_object.opened) {
            $('#' + this.tree_id).jstree("open_node", $('#' + this.tree_data[i].id));
          } else {
            $('#' + this.tree_id).jstree("close_node", $('#' + this.tree_data[i].id));
          }
        } else {
          // do nothin ( node is deleted )
        }
      };
    // }, 1000);
  }

  update_menu_items(){
  	this.menu_items = this.filter_menu_by_role(this.config.menu_map[this.case]['menu']);
  	this.service = this.config.menu_map[this.case]['service'];

  }

  filter_menu_by_role(menu_items){
    // return menu_items;
    var return_menu_obj = {};
    for (var type in menu_items) {
      return_menu_obj[type] = [];
      for (var role_key in menu_items[type]) {
        if(this.fetch_role(role_key) == 1){
          return_menu_obj[type].push(menu_items[type][role_key]);
        } else {
          // skip adding to menu
        }
      }
    }

    return return_menu_obj;
  }

  fetch_role(key){
    var param = key.split("__");
    if(this.role_map[param[0]] != undefined && this.role_map[param[0]][param[1]] != undefined){
      return this.role_map[param[0]][param[1]];
    } else {
      return 1;
    }
  }

  update_tree_data_local() {
    this.tree_nodes_json = this.get_tree_node_state_map();
    $('#'+ this.tree_id).jstree(true).settings.core.data = this.tree_data;
    $('#'+ this.tree_id).jstree(true).refresh();
  }
  
  tree_state_close() {
    $('#'+ this.tree_id).jstree("close_all");
  }

  tree_state_open() {
    $('#'+ this.tree_id).jstree("open_all");
  }

  add_new_node_local(object){
    // add new node to this.tree_data
    object['data'] = {"context_items": this.config.menu_map[this.case]['menu'][object.type]};
    object['state'] = {"opened": true};
    this.tree_data.push(object);
    this.update_tree_data_local();
  }

  // update_node_local(id, object){
  //   // update node in this.tree_data
  //   for(var i=0; i<this.tree_data.length; i++){
  //     if(this.tree_data[i]['id'] == id){
  //       object['data'] = {"context_items": this.menu_map[this.case]['menu'][object.type]};
  //       this.tree_data[i] = object;
  //     }
  //   }
  //   this.update_tree_data_local();
  // }
  
  update_node_text_local(id, text){
    // update node in this.tree_data
    for(var i=0; i<this.tree_data.length; i++){
      if(this.tree_data[i]['id'] == id){
        this.tree_data[i]['text'] = text;
        break;
      }
    }
    this.update_tree_data_local();
  }

  remove_node_local(id){
    this.remove_nodes_recursive(id);
    this.update_tree_data_local();
  }

  remove_nodes_recursive(id){
    var children = [];
    var index_to_remove = -1;
    for(var i=0; i<this.tree_data.length; i++){
      if(this.tree_data[i]['id'] == id){
        index_to_remove = i;
      }
      if(this.tree_data[i]['parent'] == id){
        children.push(this.tree_data[i]['id']);
      }
    }
    if(children.length){
      for(var j=0; j<children.length; j++){
        this.remove_nodes_recursive(children[j]);
      }
    }

    this.tree_data.splice(index_to_remove, 1);

  }

  select_node(id) {
    setTimeout( () => { 
      $('#' + this.tree_id).jstree('select_node', id);
      // $('#' + this.tree_id).animate( {scrollTop : localStorage.getItem(this.tree_id) }, 600 )
    }, 500);
  }

  get_node_name(id,_case) {
    setTimeout( () => {
      var node = $('#' + this.tree_id).jstree().get_node(id).text; 
      this.tree_to_parent({'case':_case,'data':node});   
    },500);
  }

  reset_tree_node() {
    this.tree_nodes_json = this.get_tree_node_state_map();
    $('#'+ this.tree_id).jstree().refresh();
  }

  update_tree_node_local(node_id, node_text, type='default') {
    for (let i = 0; i < this.tree_data.length; i++) {
      if (this.tree_data[i].id === node_id) {
        switch(type){
          case 'priority':
            console.log(node_text)
            this.tree_data[i].priority = node_text.priority;
            this.tree_data[i].severity = node_text.severity;
            this.tree_data[i].text = `${this.tree_data[i].display_value} (${this.tree_data[i].priority}) (${this.tree_data[i].severity})`;
            break;
          case 'status':
            this.tree_data[i].case_statuses = node_text;
            break;
          case 'resolution':
            this.tree_data[i].case_resolutions = node_text;
            break;
          case 'custom_mandatory_fields':
            this.tree_data[i].checkbox_extensions = node_text;
            break;
          case 'task_record':
            this.tree_data[i].task_record_status = node_text;
            break;
          case 'task_closure':
            this.tree_data[i].closure_status = node_text;
            break;
          case 'task_status':
            this.tree_data[i].task_status = node_text;
            break;
          case 'uif_case_outcome':
            this.tree_data[i].uif_case_outcomes = node_text;
            break;
          case 'default':
            this.tree_data[i].text = node_text;
            break;
        }
      }
    };
    this.update_tree_data_local();
  }

}
