import { Injectable } from '@angular/core';
@Injectable({
providedIn: 'root'
})
export class EnvService {

  public service_host = 'http://102.130.125.28';
        public service_port = 7000;
        public service_url  = this.service_host+':'+this.service_port;
        public raptor_db_host = '102.130.123.192';
        public raptor_db_port = 5088;
        public raptor_wh_host = '102.130.123.192';
        public raptor_wh_port = 9088;
        public raptor_db_service_url = 'http://' + this.raptor_db_host + ':' + this.raptor_db_port;
        public raptor_wh_service_url = 'http://' + this.raptor_wh_host + ':' + this.raptor_wh_port;  public blacklist_file = ['exe','sh']
  public build_version = '1.2.27';
  public show_file_in_browser = ['pdf','txt','png'];
  public job_grade_exceptions = ['Manager','Senior Manager'];
  //  public case_paginated_search = true;
  //  public number_of_records= 50;
  public notification_interval = 3600000;
  public get_services_with_token =['/data/classifications','/campaign/version/target/get_counts','/interaction/individual/get_all','/interaction/biz_org/get_all','/interaction/documents/get_all','/campaign/version/get','/campaign/version/target/download_invalid','/campaign/version/get_status','/is_repeat_caller','/communication/registry/types/get_all','/smm/data/accounts/get_all','/meta/chatqueue/user/get_all','/meta/chatqueue/get_all','/meta/chatqueue/get','/meta/chatqueue/timings/get_all'];
  //public sip_protocol = 'wss://';
  public cvt_number_of_records = 10; // cvt = campaign_version_target
  //public sip_server = 'sip1.fibrephone.telviva.com';
  //public sip_port = '4433';
  //public sip_service_url = this.sip_protocol + this.sip_server + ':' + this.sip_port;
  //public show_sip_login_modal = true;
  //public sip_call_autopick = true;
  public net_ping_timer = 10000;
  public check_net_connectivity = false;
  public skip_spinner_for_services = ['/get_all_bizorg','/get_contact_persons_from_biz_org', '/get_contact_details_for_employee',
    '/get_employee_products_and_houses', '/moses/v1/search_employee_fm', '/get_case_classification_dropdowns',
    '/get_all_workflow_actions','/get_classification_value_data_dropdown','/get_signature_from_user_id', '/get_product_house_map',
    '/get_user_workgroups', '/get_workflow_object_data_for_user','/get_workflow_objects_for_wrkgrp', '/get_tree_biz_with_levels',
    '/get_employee_details_from_national_id', '/get_employee_details_from_passport_number', '/get_tree_biz_site_with_levels',
    '/get_tree_biz_site', '/get_contact_details_from_national_id', '/get_contact_person_data',
    '/get_all_users','/get_feedback', '/get_closed_workflow_objects_dynamic_filter', '/get_contact_details_for_contact_person',
    '/get_workgroup_member_list', '/get_all_workgroups','/dispatch_case_by_rules', '/get_workgroup_data', '/check_user_default_workgroup',
    '/get_workgroup_role_audit', '/get_inactive_workgroup_members', '/get_all_notification_definition', '/get_notification_definition',
    '/get_all_email_configuration', '/get_notification_view_cols', '/get_notification_decision_data', '/get_all_api_definitions',
    '/get_communication_details_for_division', '/get_bizorg_contracts', '/get_biz_prod_tree', '/bread_crumb_service', '/get_all_divisions_data',
    '/get_employee_master_data', '/get_employee_details_from_employee_id', '/get_employee_products_from_employee_id',
    '/get_employee_details_from_bizorg_divorg', '/get_tree_biz', '/get_all_sites_data', '/get_unread_push_notifications',
    '/get_classification_value_data', '/get_classification_tree', '/get_internal_org_employee', '/get_internal_org_employee_tree',
    '/get_internal_org_div_employees','/get_terminated_employees', '/get_internal_org_employee_contacts',
    '/get_internal_org_employee_reportees','/get_internal_org_employee_managers', '/get_user_workgroups_with_id', '/get_internal_org_employee_certs',
    '/get_internal_org_employee_div_history', '/get_employee_managers_audit', '/get_internal_employee_audit', '/get_eligible_managers_multiple_employees',
    '/get_all_priority_data', '/get_internal_class_priority_severity', '/get_bizorg_employee_list', '/get_task_group_list', '/get_all_items',
    '/get_task_group_instances', '/get_task_list_for_instance', '/get_gannt_chart', '/get_task_classification_dropdown', '/get_lead_classification_tree',
    '/get_roles_list', '/get_all_user_roles', '/get_component_configuration', '/get_sidebar_configuration', '/get_all_dispatch_definition',
    '/get_dispatch_decision_data', '/get_dispatch_definition', '/moses/v1/get_chronic_conditions_list', '/moses/v1/get_contact_details_from_employee_id',
    '/moses/v1/get_filters_from_employee_id', '/moses/v1/filter_schemes', '/moses/v1/export_advice', '/moses/v1/get_employee_dependents',
    '/moses/v1/get_all_advice_documents', '/moses/v1/get_potential_member_dependents', '/moses/v1/get_filters_from_employee_id_frontend_req',
    '/moses/v1/export_gap', '/moses/v1/get_product_houses', '/moses/v1/get_products_from_product_house_id',
    '/moses/v1/get_scheme_details_from_product_id', '/portfolio/get_all_portfolio', '/portfolio/get_unallocated_clients_portfolio',
    '/portfolio/get_all_clients_from_portfolio', '/portfolio/get_portfolio_employees', '/portfolio/get_employee_portfolio', '/get_employee_account_bizorg_list',
    '/get_all_biz_orgs_with_unallocated', '/moses/v1/get_schemes_for_gems_fna', '/get_lead_level_change_log', '/get_vat','/whapi/job/data'
  ];
  public note_reply_level = 4;
  public user_signature_type="profile"  // template/profile


  public dropdown_list = {
    'communication_type': 160,
    'communication_subtype': 161,
    'division_type': 132,
    'job_title': 158,
    'job_grade': 159,
    'workgroup_type': 162,
    'workgroup_status': 169,
    'business_object_old': 170,
    'trigger': 171,
    'select_notifications': 172,
    'notification_type': 173,
    'certificates': 163,
    'gender': 49,
    'priority': 164,
    'severity': 165,
    'member_role': 166,
    'contact_role': 167,
    'country': 50,
    'server_type': 181,
    'case_status': 182,
    'case_resolution_status': 183,
    'task_status': 184,
    'reporting_role': 187,
    'dispatch_business_object': 202,
    'dispatch_trigger': 203,
    'title': 204,
    'task_dispatch_decision': 206,
    'case_dispatch_decision': 207,
    'workflow_rules_case': 220,
    'workflow_action_object': 221,
    'workflow_rules_task': 222,
    'workflow_rules_advice': 223,
    'case_channel': 224,
    'query_initiator': 225,
    'id_type': 226,
    'employer_category': 230,
    'all_cases_statuses': 231,
    'caller_mood': 236,
    'case_classification_type': 237,
    'wfobj_status': 238,
    'advice_status': 239,
    'case_view_cols': 208,
    'task_view_cols': 209,
    'notification_headers': 243,
    'industry': 245,
    'level': 246,
    'lead_resolution_status': 247,
    'potential_category': 249,
    'product_document_identifier': 252,
    'product_house_document_identifier': 253,
    'schema_wfobj_types': 261,
    'stages_action': 262,
    'individual_type': 263,
    'nationality': 267,
    'martial_status': 264,
    'content_type': 265,
    'knowledge_category_list': 266,
    'case_status_change': 270,
    'business_object': 272,
    'poller_object_action_map': 273,
    'poller_status': 274,
    'provinces': 275,
    'country_list': 276,
    'case_status_for_pagination': 277,
    'email_type': 278,
    'case_classification_checkbox': 279,
    'workgroup_telephony_keys': 280,
    'male_titles': 281,
    'female_titles': 282,
    'extension_types': 283,
    'business_object_status': 286,
    'request_approval_status': 287,
    'dispatch_priority': 288,
    'meeting_type': 314,
    'channel': 290,
    'case_rejection_choices': 291,
    'document_attach_method': 292,
    'task_deadline':294,
    'campaign_business_objects': 298,
    'campaign_type': 299,
    'campaign_call_statuses':302,
    'workgroup_comprehensive_case_status': 303,
    'sms_record_statuses': 305,
    'sms_delivery_statuses': 306,
    'survey_form_type':308,
    'comm_registry_types':315,
    'notification_meta_type':316,
    'announcement_service_type':317,
    'socialmedia_channel_list' : 322,
    'meeting_status':324,
    'live_chat_agent_pause_statuses':317,
    'live_chat_outcomes':318,
    "complaint_reason":319,
    "ministerial_source":320,
    'workgroup_category': 321,
    'micro_film_status': 322,
    'task_type': 323,
    'workspace_member_role': 312
  };
  
  public instance_type = "DEMO INSTANCE";   
  public use_local_storage = true;
  public email_conf_api_port = 9090;
  public email_service_host = 'https://localhost';
  public email_service_url  = this.service_host+':'+this.email_conf_api_port;
  public date_format = 'dd MMM yyyy HH:mm';
  public poller_company_name_for_case = 'Unidentified-Customer'
  public services_to_skip = ['/login', '/register','/send_forgot_password_email','/forgot_password','/get_feedback_form_details','/save_feedback','/check_password_already_used','/lg_query/get_query_by_query_id','/lg_composite_widget/get_widget_by_id','/lg_report/get_report_by_id','/lg_query/get_query_by_query_id','/lg_composite_widget/get_widget_by_id','/lg_report/get_report_by_id','/lg_vinsights/get_vinsights_by_id','/queuemetrics','/queuemetrics/QmAgentQueues/qm_jsonsvc_do_pbxactions.do','/queuemetrics/QmAgentPCodes/jsonStatsApi.do','/queuemetrics/qm_jsonsvc_do_pbxactions.do','/check_case_feedback_submitted','/check_feedback_submitted'];
  public cache_service = ['/get_case_classification_dropdowns','/get_task_classification_dropdown','/get_all_workflow_actions','/get_classification_value_data_dropdown','/workflow/user/workgroups', '/get_signature_from_user_id','/get_all_users_for_filters','/get_all_case_status','/email_content/get_all','/workflow/user/workgroups/object_status']

  public update_cache_services = ['/create_mobile_classification_mapping','/update_mobile_classification_mapped_data','/remove_classification','/create_classification','/add_classification_value','/update_classification_value','/add_priority_classification','/remove_priority_classification','/update_priority_classification','/update_classification_extension','/update_priority_severity_deadline','/classification_extension/checkbox','/move_classification_value',]
  public force_cache_purge_services = ['/data/classifications']
  public lg_wh_services = ['/whapi/getCompWidget','/whapi/getPivotReport','/whapi/getDrillReport','/whapi/queryTest','/whapi/processQueryFilters','/api/lg_dashboards','/whapi/create_report','/whapi/download_file','/whapi/create_chart_report','/api/lg_reports','/api/lg_query','/lg_query/get_query_by_query_id','/api/lg_report_dashboard','/api/lg_vinsights','/lg_composite_widget/get_widget_by_id','/lg_report/get_report_by_id','/lg_vinsights/get_vinsights_by_id','/whapi/getVIParam','/whapi/filterFromQuery','/whapi/send_mail_from_vi','/api/lg_dashboards','/api/lg_composite_widgets','/whapi/queryTestById']
  
  public GET_services_to_skip = [];
  public max_file_count = 5; 
  public max_file_size = 1048576*5;

  public project_variables = {
    'org_name': 'Organisation',
    'org_name_s': 'Organisations',
    'show_certification': true,
    'stock_code_label': '',
    'wg_user_name': 'User',
    'wg_user_name_s': 'Users',
    'cost_price': 'Cost',
    'emp_name': 'Customer' 
  };

  public is_prod = true;
  public default_time = {
    'start_time': '09:00',
    'end_time' : '18:00'
  };

  public paginated_search = {
    'case' : {
      search: true,
      number_of_records: 100,
      percent_color_map: null
    },
    'task' : {
      search: true,
      number_of_records: 100,
      percent_color_map : {
        '0-50': "#000000de",
        '51-80': "#088ff1",
        '81-99': "#F27E17",
        '100':"#ff0000"
      }
    }
  };
   
  public send_doc_with_notification = {
    'case':true,
    'task':false
  };

  public project_dropdown_list = {
    'hospitals': 214,
    'coverage': 215,
    'day_to_day_cover': 216,
    'day_to_day_extent': 217,
    'adult_dependent': 218,
    'child_dependent': 219,
  };

  public gems_dropdown_list = {
    'gems_hospital_cover': 232,
    'gems_chronic_conditions_cover': 233,
    'gems_in_hospital_benefit': 234,
    'gems_out_hospital_benefit': 235
  };
 
  public docchecklist_config = {
    single_file_size: 1048576*10,
    max_file_size:1048576*25,
  };

  public load_batch_size = 1000;

  public telephony_cmp_conf = {
    ver_time_check_interval: 30000 // 1 minute
  }

  public telephony_conf = {
    conn_retry_limit: 300000, // in ms (5 min)
    conn_retry_attempt_interval: 15000, // in ms (15sec)
    err_log_enabled: true,
    codec_log_enabled: true,
    get_qm_stats_fire_after: 5000
  };

  public task_percent_color_map = {
    '0-50': "#000000de",
    '51-80': "#F27E17",
    '81-100': "#ff0000"
  };
  
  public completed_status_for_voice = ['Completed']; 

  public mobile_case_classification_level = 3
  public case_classification_level = 4
  public case_classification_config = {
    l1: { 'name': 'Service', 'class': 'col-6' },
    l2: { 'name': 'Category', 'class': 'col-6' },
    l3: { 'name': 'Sub-category', 'class': 'col-6' },
    l4: { 'name': 'Issues', 'class': 'col-6' }
  };

  public case_classification_default_values = {
    'classification_l1':'',
    'classification_l2':'',
    'classification_l3':'',
    'classification_l4':''
  };
  
  public task_classification_default_values = {
  	l1: 'NA'
  };
	
  public queue_ext_ent_type_name = 'Chat Queue Classification';

  public social_media_host = 'https://localhost'
  public social_media_port = 4000;
  public social_media_url = this.social_media_host+':'+this.social_media_port;
 
  public tele_service_host = 'https://dohatestbo.dcecrm.alteram.co.za'; // Same as del BO host
  public tele_service_port = 8000;
  public tele_service_url = this.tele_service_host + ':' + this.tele_service_port;

  public qengine_service_host = 'https://chatqueue';
  public qengine_service_port = 4000;
  public qengine_service_url  = this.qengine_service_host+':'+this.qengine_service_port;

  public io_host = 'shock';
  public io_port = '3001';
  public io_namespace = 'test';
  public io_url = 'https://'+this.io_host+'/'+this.io_namespace;

  public live_chat = {
  'customer_inactivity_timer': 300, // in seconds
  'chat_outcome_timer': 60, // in seconds
  'max_character_limit':1000,
  'max_visible_rows':3  //range can be from 1 to 9
  }

   public live_chat_outcome = {
     default_live_chat_outcome:"Agent did not select",
     live_chat_outcome_timer:30
   }

   public dispostion_minutes = 30 // Keep it in sync with leggero config >  TELEPHONE_CONFIG > Disposition_Minutes

   public live_chat_agent_name = 'Customer Service Executive';
  
   public lookup_dashgroup_id = 67;

   public poller_individual_name_for_case = 'Unidentified-Individual'

   public   raptor_switch = true;

  public task_classification_config = {
    default_values : {
      l1: 'NA',
      l2: 'NA',
      l3: 'NA'
    },
    labels : {
      l1:{'name':'Business Function', 'class':'col-3'}, // Not in used
      l2:{'name':'Function Area', 'class':'col-3'}, // Not in used
      l3:{'name':'Area', 'class':'col-3'},// Not in used
      l4:{'name':'Task Function Type', 'class':'col-3'},
      l5:{'name':'Task Function SubType', 'class':'col-3'},
    },
    allowed_classfication : {
      start: 4, end: 5
    }
  }

   public task_classification_level = 6;

  public current_year = '2024';
  
  public digital_assistant_io_host = '43.205.159.89';
  public digital_assistant_io_port = '3002';
  public digital_assistant_io_namespace = 'digital-assistant';
  public digital_assistant_io_url = 'http://'+this.digital_assistant_io_host+':'+this.digital_assistant_io_port+'/'+this.digital_assistant_io_namespace;

  public socket_server_conf ={
    digital_assistant: {
      "enable": false,
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 2000,
      reconnectionDelayMax : 5000, // not used
      reconnectionAttempts: 20, // not used
      },
    }

  public feature_switch = {
      shortcut_keys: true
  }
  
  public chat_service_host = 'https://raftest.chatbotbe.alteram.co.za';
  public chat_service_port = '5000';
  public chat_service_url  = this.chat_service_host + ':' + this.chat_service_port;
  public chatbot_conf = {
    chat_process_id: 1,
    bo_server_error_msg: 'Something went wrong! We are currently unable to process your request',
    max_visible_rows: 3,
    input_always_enabled: false,
    default_km_summary: 'Click here to view FAQ'
  }



  
  constructor() { }
  }

 export const web_page_title='Leggero DCE';
 export const homepage_title='Leggero DCE Customer Relationship Management Portal';

