import { Component, OnInit } from '@angular/core';
import { EnvService } from './dce-service/env.service';
import { PlatformLocation } from '@angular/common';
import { AuthService } from './shared/services/auth.service';
import { Router,NavigationEnd } from '@angular/router';
import { SharedService } from './dce-service/shared.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bootDash';

  constructor(private _env: EnvService, private location: PlatformLocation, 
    private auth: AuthService,public router: Router, private _shared:SharedService) {
    location.onPopState((event) => {
      $('.modal').modal('hide');
      $( ".modal-backdrop" ).remove();
      $( ".in" ).remove();
    });
    router.events.subscribe((e) => {
      if(e instanceof NavigationEnd){
        $(".modal").on('shown.bs.modal', function () {
          $(this).find("input:visible:first").focus();
        });
        // $('input[type=text],textarea,select').filter(':visible:enabled:first').focus();
      }
    });
  }

  ngOnInit(){
  	if(this._env.is_prod) {
      // localStorage.removeItem('token');	//	added to log user out on refresh
      let tkn = this._shared.getToken()
      if (tkn != undefined) {
        this.logout();
      }
      $(document).on("wheel", "input[type=number]", function (e) {
          $(this).blur();
      });
    }
    const current_version = localStorage.getItem('current_version');
    if(current_version == undefined || current_version == null) {
      localStorage.setItem('current_version', this._env.build_version);
    } else if(current_version != undefined && current_version != this._env.build_version) {
      localStorage.setItem('current_version', this._env.build_version);
      location.reload(true);
    }
  }

  logout() {
    const payload = {}; // will be set in future based on functionality during logging out.
    this.auth.logout(payload).subscribe(res => {
      if (res.errCode === 0) {
        // do nothing for now
        this.auth.signout();
      }
    });
  }

}
