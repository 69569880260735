import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';

type session_actions = 'login' | 'logout' | 'dndon' | 'dndoff' | 'get_dnd_duration' | 'in' | 'out';
type log_call_actions = 'start' | 'end' | 'answer' | 'hold' | 'unhold' | 'add_disposition_code' | 'add_disposition_code_user' | 'startevent' | 'endevent';

@Injectable({
  providedIn: 'root'
})
export class SipService {

  // sip server url settings
  public sip_service_url = this._shared.sip_storage.config_data.sip_protocol
    + this._shared.sip_storage.config_data.sip_server
    + ':'
    + this._shared.sip_storage.config_data.sip_port;


  // queuemetrics_url = "https://testqm-qm.telviva.com/queuemetrics";

  constructor(
    protected http: HttpClient,
    protected env: EnvService,
    protected _shared: SharedService
  ) {

  }

  telephone_token = localStorage.getItem('telephony_token');

  // queuemetrics services

  setReqOptions() {
    let headers = new HttpHeaders()
    headers = headers.set('Authorization', 'Basic ' + this.telephone_token);
    const reqOptions = {
      headers: headers
    }
    return reqOptions;
  }

  retrieve_qm_agent_queues(payload) {
    const route = `${this.env.tele_service_url}${this._shared.sip_storage.config_data.retrieve_qm_agent_queues}${payload}`;
    return this.http.get<any>(route, (this.setReqOptions()));
  }

  login_agent_to_queues(payload) {
    const route = `${this.env.tele_service_url}${this._shared.sip_storage.config_data.login_logoff_queues}`;
    return this.http.post<any>(route, payload, (this.setReqOptions()));
  }

  logoff_agent_from_queues(payload) {
    const route = `${this.env.tele_service_url}${this._shared.sip_storage.config_data.login_logoff_queues}`;
    return this.http.post<any>(route, payload, (this.setReqOptions()));
  }

  retrieve_available_pause_codes(payload) {
    const route = `${this.env.tele_service_url}${this._shared.sip_storage.config_data.retrieve_available_pause_codes}${payload}`;
    return this.http.get<any>(route, (this.setReqOptions()));
  }

  pause_agent(payload) {
    const route = `${this.env.tele_service_url}${this._shared.sip_storage.config_data.pause_unpause_agent}`;
    return this.http.post<any>(route, payload, (this.setReqOptions()));
  }

  unpause_agent(payload) {
    const route = `${this.env.tele_service_url}${this._shared.sip_storage.config_data.pause_unpause_agent}`;
    return this.http.post<any>(route, payload, (this.setReqOptions()));
  }

  get_qm_stats(payload) {
    const route = `${this.env.service_url}/queue-metrics/get_stats`;
    return this.http.post<any>(route, payload);
  }
  
  retrieve_realtime_qm_stats(payload) {
    const q_metrics_url = `${this.env.tele_service_url}${this._shared.sip_storage.config_data.retrieve_realtime_qm_stats}${payload}`;
    return this.http.get<any>(q_metrics_url, (this.setReqOptions()));
  }

  is_repeat_caller(payload) {
    const route = `${this.env.service_url}/is_repeat_caller`;
    return this.http.get<any>(this.process_get_req(route, payload));
  }
  
  check_dispo_allowed(payload){
    const route = `${this.env.service_url}/telephone/log/call/check_outcome_allowed`;
    return this.http.get<any>(this.process_get_req(route, payload));
  }

  private log_error_url = `${this.env.service_url}/log/error`;
  log_error(payload) {
    return this.http.post<any>(this.log_error_url, payload);
  }


  // generic service methods

  telephony_session(action: session_actions, payload) {
    const route = `${this.env.service_url}/user/telephony/${action}`;
    return this.http.post<any>(route, payload);
  }

  telephony_log_call(action: log_call_actions, payload) {
    const route = `${this.env.service_url}/telephone/log/call/${action}`;
    return this.http.post<any>(route, payload);
  }

  process_get_req(route, payload) {
    return `${route}?${this.object_to_query_param(payload)}`;
  }

  object_to_query_param(obj) {
    let query_param = "";
    for (let key in obj) {
      query_param += key + "=" + obj[key] + '&'
    }
    return query_param.slice(0, -1)
  }

}