import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appButtonClick]'
})
export class ButtonClickDirective {

  @Input('appButtonClick') enableBack?: boolean = true;
  // @Output()  appButtonClickChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('click', ['$event'])
  onClick(btn) {
    // console.log(btn);

    setTimeout(() => {
      btn.target.disabled = true;
    }, 2);
    if (this.enableBack || 1) {
      setTimeout(() => {
        btn.target.disabled = false;
      }, 4000);
    }
  }

  constructor(
    private el: ElementRef
  ) {
    // this.el.nativeElement.style.backgroundColor = 'red';
  }

  // @HostListener('mouseenter') onMouseEnter() {
  //   this.highlight('yellow');
  // }

  // @HostListener('mouseleave') onMouseLeave() {
  //   this.highlight(null);
  // }

  // private highlight(color: string) {
  //   this.el.nativeElement.style.backgroundColor = color;
  // }

}
