import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import { EnvService } from './env.service';
import { LiveChatService } from './live-chat.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from './toastr-utility.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

	public io: any;
  
  public digital_assistant_io: any;
  events = {
    socket_events: [
                    'connect',
                    'disconnect',
                    'error',
                    'connect_error',
                    'connect_timeout',
                    'reconnect',
                    'reconnect_attempt',
                    'reconnecting',
                    'reconnect_error',
                    'reconnect_failed',
                    'ping',
                    'pong',
                  ],
    custom_events: ['dataOut'],
    manager_events: ['reconnect_failed', 'reconnect', 'reconnect_error']
  }
  constructor(
    private env: EnvService,
    private chat_store: LiveChatService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) {}

  initiate_connection(params) {
  	this.io = io(this.env.io_url, {autoConnect: false,query: params});
    // const data = this.io.connect();
  	// data.on('connect',() => {
    //   this.chat_store.live_chat_store.agent_socket_session_id = data.id;
      
    // })

    // this.io.on('error', function(){
    //   console.log("error")
    // });
    this.io.on("connect_error", function(error) {
      console.log(error )
      this.io.close();
    });
    return this.io.connect();
  }

  socket_event(event_type, room, data=null) {
  	try {
      this.io.emit(event_type, {"event_type": event_type, "room": room, "data": data});
    } catch (error) {
      alert("Not connected to Chat server");
      console.log(error)
    }
  }

  get_data_all() {
  	return Observable.create((observer) => {
      this.io.on('data_out_all', (data) => {
        observer.next(data);
      });
    });
  }

  /****************************D.A.(Digital Assistant)*****************************/
  initiate_da_socket_connection(params) {
    if(!this.env.socket_server_conf.digital_assistant.enable) {
      return;
    }
    console.log("this.env.digital_assistant_io_url = ", this.env.digital_assistant_io_url)
    this.digital_assistant_io = io(this.env.digital_assistant_io_url, {
      autoConnect: this.env.socket_server_conf.digital_assistant.autoConnect,
      reconnection: this.env.socket_server_conf.digital_assistant.reconnection,
      reconnectionDelay: this.env.socket_server_conf.digital_assistant.reconnectionDelay,
      // reconnectionDelayMax : this.env.socket_server_conf.digital_assistant.reconnectionDelay,
      // reconnectionAttempts: this.env.socket_server_conf.digital_assistant.reconnectionAttempts,
      query: params,
    });
    this.da_socket_event_handler();
    return this.digital_assistant_io.connect();
  }

  da_socket_emit(event_type,room, data=null) {
    if(!this.env.socket_server_conf.digital_assistant.enable) {
      return;
    }
    if(this.digital_assistant_io.connected){ // fail-safe only for connected socket.
      try {
        this.digital_assistant_io.emit(event_type, {"event_type": event_type, "room": room, "data": data});
      } catch (error) {
        alert("Not connected to Digital Assitant server");
        console.log(error)
      }
    }
  }

  da_socket_event_handler() {
    for (let evt of this.events.socket_events) {
      this.da_socket_event_listener(evt);
    }
  }

  da_socket_event_listener(eventName) {
    this.digital_assistant_io.on(eventName, (data, callback) => {
      // console.log("Event occurred: " + eventName);
      if(this.da_socket_event_actions[eventName]) {
        this.da_socket_event_actions[eventName](data);
      };
    });
  }

  get_da_data_out_all_events() {
  	return Observable.create((observer) => {
      this.digital_assistant_io.on('data_out_all', (data) => {
        console.log("data_out_all is called")
        observer.next(data);
      });
    });
  }

  // All the events that are being monitored and the actions that need to be performed when they occur
  da_socket_event_actions = {
    connect: (data) => {
      console.log("DCE D.A.(Digital Assistant) Socket Connected to server", data);
    },
    connect_error: (data) => {
      console.log("DCE D.A.(Digital Assistant) Socket Socket Connect Error", data)
    },
    disconnect: (data) => {
      console.log("DCE D.A.(Digital Assistant) Socket Disconnected from server: ", data);
    },
    error: (err) => {
      console.log("DCE D.A.(Digital Assistant) Socket Error encountered:", err);
    },
    reconnecting: (data) => {
      console.log("DCE D.A.(Digital Assistant) Socket Reconnecting:", data);
    },
    reconnect: (data) => {
      console.log("DCE D.A.(Digital Assistant) Socket Reconnect encountered:", data);
    },
    reconnect_failed: (data) => {
      console.log("DCE D.A.(Digital Assistant) Socket Reconnection failure encountered:", data);
      // this.tele_io.connect();
    }
  };

  da_socket_subscribe(){
    this.get_da_data_out_all_events().subscribe(res => {
      this.socket_event_handler(res);
    });
  }
  
  da_socket_session_close() {
    if(!this.env.socket_server_conf.digital_assistant.enable) {
      return;
    }
    console.log("called");
    // this disconnects from the server
    this.digital_assistant_io.close();
  }

    public socket_event_handler(res):void {
      console.log(res)
      switch (res.type) {
        case 'join_room':
          console.log("join room")
        break;
        case 'disconnect':
          console.log("disconnect")
        break;
        case 'wf_obj_actions':  // broadcast
          console.log(res);
          console.log("broadcast*****************************************************************")
          this._toastr.success("",res.data, this._toastrUtility.basic_configuration);
    
          // this._common_service.modify_wf_obj(res.data).subscribe(res => {
          //   if(res.errCode == 0){
          //     this._toastr.success("",res.data, this._toastrUtility.basic_configuration);
          //   }else{
          //     this._toastr.error('',res.msg, this._toastrUtility.basic_configuration);
          //   }
          // })
          break;
        default:
          break;
      }
      // this.socket_events.emit(res);
    }
    
}
