import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
declare var window: any;
declare var $: any;
@Component({
  selector: 'app-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss']
})
export class PinInputComponent implements OnInit, AfterViewInit {

  @Input() pinLength?: number = 4;
  @Input() unique_id?: string = '';

  mpin_value =  {};
  show_mpin_bool = true;
  prev_value = {}
  attempt = 0

  constructor() {}

  ngOnInit() {
    for(let  i= 0;i<this.pinLength;i++) {
      this.mpin_value['mpin' + i] = '';
      this.prev_value['mpin' + i + '_empty'] = true;
    }
  }

  ngAfterViewInit() {
    this.hide_mpin();
  }

  clear_mpin() {
    for(let  i= 0;i<this.pinLength;i++) {
      this.mpin_value['mpin' + i] = '';
    }
  }

  validate_mpin() {
    for(let  i= 0;i<this.pinLength;i++) {
      if(this.mpin_value['mpin' + i] = '') {
        return false;
      }
    }
    return true;
  }

  get_mpin(): string {
    let combined_value = '';
    for(let  i= 0;i<this.pinLength;i++) {
      combined_value += this.mpin_value['mpin' + i]
    }
    return combined_value;
  }

  _keyPress(event: any) {
    // $('#'+event.target.id).attr('type', 'password');
    // $('#' + event.target.id).css('-webkit-text-security', 'disc')
    const pattern = /[0-9]/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    } else {
      const value = event.target.id.split('');
      const current_mpin_int = parseInt(value[value.length - 1]);
      const previous_variable_id = 'mpin' + current_mpin_int + '_empty';
      this.prev_value[previous_variable_id] = false;
      const mpin_int = parseInt(value[value.length - 1]) + 1;
      if (mpin_int > 1 && mpin_int < this.pinLength+1) {
        setTimeout(() => {
          $('#mpin' + this.unique_id + mpin_int).focus();
        }, 10)
      }
    }
  }

  _keyUp(event) {
    if ((event.keyCode == 8 || event.keyCode == 46)) {
      const value = event.target.id.split('');
      const mpin_int = parseInt(value[value.length - 1]) - 1;
      const previous_variable_id = 'mpin' + mpin_int + '_empty';
      const current_variable_id = event.target.id + '_empty';
      const previous_id = 'mpin' + this.unique_id + mpin_int;
      const current_id = event.target.id;

      if (current_id != 'mpin' + this.unique_id + '1' && this.prev_value[current_variable_id]) {
        this.delete_values(previous_id);
        this.prev_value[previous_variable_id] = true;
      } else {
        this.prev_value[current_variable_id] = true;
      }
    }
  }

  _focus(event) {
    const new_case = event.target.id + '_empty';
    if (event.target.value != '') {
      this.prev_value[new_case] = false;
    } else {
      this.prev_value[new_case] = true;
    }
  }

  delete_values(id) {
    $('#' + id).focus();
    $('#' + id).val('');
  }

  show_mpin() {
    for(let  i= 0;i<this.pinLength;i++) {
      $('#mpin' + this.unique_id + (i+1)).css('-webkit-text-security', 'none');
    }
    this.show_mpin_bool = false;
  }

  hide_mpin() {
    for(let  i= 0;i<this.pinLength;i++) {
      $('#mpin' + this.unique_id + (i+1)).css('-webkit-text-security', 'disc');
    }
    this.show_mpin_bool = true;
  }


}
