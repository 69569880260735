import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { EnvService } from 'src/app/dce-service/env.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear = this._env.current_year;

  constructor(
    public navService: NavigationService,
    private _env: EnvService
  ) { }

  ngOnInit() {
  }

}
