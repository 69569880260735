import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chatbot-chat-bubble',
  templateUrl: './chatbot-chat-bubble.component.html',
  styleUrls: ['./chatbot-chat-bubble.component.scss']
})
export class ChatbotChatBubbleComponent implements OnInit {
  message_type = {
    "OPTION": "option",
    "TEXT": "text",
    'LINK':"link",
    "NOTIF":"notification"
  }
  @Input() message:any;

  constructor() { }

  ngOnInit() {
  }

}
