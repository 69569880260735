import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from "@angular/forms";
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from "ngx-toastr";
import { ToastrUtilityService } from "../../../dce-service/toastr-utility.service";
import { SideContainerComponent } from 'src/app/dce-shared/side-container/side-container.component';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';
import { HistoryCaseLinkComponent } from '../history-case-link/history-case-link.component';
import { HistoryCustomerLinkComponent } from '../history-customer-link/history-customer-link.component';
declare var $:any;
@Component({
  selector: 'app-live-chat-history',
  templateUrl: './live-chat-history.component.html',
  styleUrls: ['./live-chat-history.component.scss']
})
export class LiveChatHistoryComponent implements OnInit {
  @ViewChild(SideContainerComponent ,{static: false}) sideContainer: SideContainerComponent;
  @ViewChild(HistoryCaseLinkComponent ,{static: false}) link_case: HistoryCaseLinkComponent;
  @ViewChild(HistoryCustomerLinkComponent ,{static: false}) link_customer: HistoryCustomerLinkComponent;
  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().substring(0,10);
  }
  filter_payload = {
    agent_id : 0,
    agent_name : '',
    start_date: new Date().toISOString().substring(0,10),
    end_date: new Date().toISOString().substring(0,10),
    max_date: new Date()
  }
  selected_filter_payload = {
    agent_id: 0,
    start_date : '',
    end_date : '',
  }
  agent_list = [];
  agent_keyword = 'full_name'
  table_message = {emptyMessage: 'There are no records'};
  selected_session_id=null;
  user_data={};
  rows:any = [];
  selected_row_data={};
  selected_date:any;

  columns = [
    {prop: "side_icon", name: "", width: 50 },
    {prop: "end_status", name:"Chat Outcome", width: 185},
    {prop: "chatsession_guid", name:"Session ID"},
    {prop: "start_time", name:"Start Time"},
    {prop: "end_time", name:"End Time"},
    {prop: "queue_name", name:"Queue Name"},
    {prop: "customer_name", name:"Customer Name"},
    {prop: "case_id", name:"Case ID"},
    {prop: "interaction_id", name:"Interaction ID"}
  ];
  page_size=10;
  total_pages:any
  page_num_list:any; 
  page_no=0;
  total_recs=0;
  chat_outcome_list=[];
  constructor(
    private _commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private _liveChatService: LiveChatService,
    private _env: EnvService,
    public _shared:SharedService,
  ) {
    this.initialize_filter()
   }

  ngOnInit() {
    this.get_chat_outcome_list();
    this.get_agent_list();
    this.footer_text();
  }

  initialize_filter(){
    this.filter_payload.agent_name = this._shared.getValue('user_details')['full_name'];
    this.filter_payload.agent_id = this._shared.getValue('user_id');
    this.apply_filter('filter');
  }

  get_chat_outcome_list() {
    const payload = {
      'classification_type_id': [this._env.dropdown_list.live_chat_outcomes] ,
      'classification_value_id': [-1]
    };
    this._commonService.get_particular_classification_data(payload).subscribe(res =>{
      if (res.errCode == 0) {
        this.chat_outcome_list = res.msg[0].data;
      }
    })
  }
  

  footer_text(){
    this.total_pages= Math.ceil(this.total_recs/ this.page_size);
    this.page_num_list = Array(this.total_pages).fill(0).map((x,i)=>i);
  }

  set_prev_page(e){
    if(e){
      this.page_no--;
      this.apply_filter('pagination');
    }
    else{
      this.page_no=0;
      this.apply_filter('pagination');
    }
  }
  set_next_page(e){
    if(e){
      this.page_no++;
      this.apply_filter('pagination');
    }
    else{
      this.page_no=this.total_pages - 1;
      this.apply_filter('pagination');
    }
  }
  onChangePage(e){
    e=Number(e);
    this.page_no=e;
    this.apply_filter('pagination');
  }

  get_agent_list(){
    this._commonService.get_users_list({}).subscribe(res => {
      if (res.errCode == 0) {
        this.agent_list = res.msg;
      }
    });
  }

  completer_select(event){
    if(event != null){
      this.filter_payload.agent_name = event.full_name;
      this.filter_payload.agent_id = event.user_id;
    }else{
      this.filter_payload.agent_name = '';
      this.filter_payload.agent_id = 0;
    }
  }

  validate_payload(type){
    switch(type){
      case 'pagination':
        return true;
      case 'filter':
        if(this.filter_payload.agent_name!='' && this.filter_payload.start_date!='' && this.filter_payload.end_date!=''){
          const sdate = new Date(this.filter_payload.start_date);
          const edate = new Date(this.filter_payload.end_date);
          const today = new Date();
          if(sdate <= today && edate <= today){
            if (this._commonService.date_validation_eq(this.filter_payload.start_date, this.filter_payload.end_date)) {
              this.selected_filter_payload = {
                "start_date": this.filter_payload.start_date,
                "end_date": this.filter_payload.end_date,
                "agent_id": this.filter_payload.agent_id,
              }
              return true;
            }
            else{
              this._toastr.error(
                "",
                "End Date should be greater than or equal to Start Date",
                this._toastrUtility.basic_configuration
              );
              return false;
            } 
          }
          else{
            this._toastr.error(
              "",
              "Start Date and End Date should be less than or equal to current date",
              this._toastrUtility.basic_configuration
            );
            return false;
          }
        }
        else{
          this._toastr.error(
            "",
            "Please fill all mandatory fields",
            this._toastrUtility.basic_configuration
          );
          return false;
        }
    }
  }

  apply_filter(type:any){
    let payload:any;
    switch(type){
      case 'filter':
        payload={
          "from": this.filter_payload.start_date,
	        "to": this.filter_payload.end_date,
	        "agent_id": this.filter_payload.agent_id,
	        "page": 0,
	        "per_page": 10
        }
        this.page_no = 0;
        break;
      case 'pagination':
        payload={
          "from": this.selected_filter_payload.start_date,
	        "to": this.selected_filter_payload.end_date,
	        "agent_id": this.selected_filter_payload.agent_id,
	        "page": this.page_no,
	        "per_page": 10
        }
    }
    if(this.validate_payload(type)){
      this._commonService.get_livechat_filter(payload).subscribe(res =>{
        if(res.errCode == 0){
          this.total_recs=res.total_recs;
          this.rows=res.data;
          this.rows = res.data.map(v => ({...v, outcome_changed: false}));
          this.change_end_status();
          if(type=='filter'){
            this.footer_text();
            if(res.data.length==0){
              this._toastr.success(
                "",
                "No records available",
                this._toastrUtility.basic_configuration
              );
            }
          }
        }
      })
    }
  }

  change_end_status(){
    for(let i=0;i<this.rows.length;i++){
      if(this.rows[i].end_status!=null){
        for(let j=0;j<this._liveChatService.live_chat_store.chat_outcomes_list.length;j++){
          if(this.rows[i].end_status == this._liveChatService.live_chat_store.chat_outcomes_list[j].value_value){
            this.rows[i].end_status = this._liveChatService.live_chat_store.chat_outcomes_list[j].value_name;
          }
        }
      }
    }
  }

  open_side_container(index) {
    this.selected_session_id=this.rows[index].chatsession_guid;
    this.user_data ={
      user_name: this._env.live_chat_agent_name,
      user_details: this.rows[index].livechatinfolog?this.rows[index].livechatinfolog.user_details:null,
      queue_name: this.rows[index].queue_name
    }
    this.selected_date=this.rows[index].start_time;
    this.sideContainer.openContainer();
  }

  side_div_events(event){
  }

  set_chat_outcome(index){
    let payload = {
      "chatsession_guid": this.rows[index].chatsession_guid,
      "end_status": $('#'+this.rows[index]['chatsession_guid']).val(),
	    "cancel_code": ""
    }
    this._commonService.set_livechat_status(payload).subscribe(res =>{
      if(res.errCode == 0){
        this.rows[index].end_status=$('#'+ this.rows[index]['chatsession_guid'] +' :selected').text();
        this.rows=[...this.rows];
        this._toastr.success(
          "",
          "Chat Outcome Updated Successfully",
          this._toastrUtility.basic_configuration
        );
      }
      else if(res.errCode == 1){
        this.rows[index].end_status=this._env.live_chat_outcome.default_live_chat_outcome;
        this.rows[index].is_outcome_allowed = false;
        this.rows=[...this.rows];
      }
      else{
        this._toastr.error(
          "",
          "Failed to set Chat Outcome",
          this._toastrUtility.basic_configuration
        );
      }
    })
  }

  change_chat_outcome(index){
    if($('#'+this.rows[index]['chatsession_guid']).val()!=this._env.live_chat_outcome.default_live_chat_outcome)
      this.rows[index].outcome_changed = true;
    else{
      this.rows[index].outcome_changed = false;
    }
  }

  open_modal(type, index){
    this.selected_row_data=this.rows[index];
    switch(type){
      case 'case':
        $('#case-modal').modal({backdrop: 'static', keyword: false});
        break;
      case 'customer':
        $('#customer-modal').modal({backdrop: 'static', keyword: false});
        break;
    }
  }

  case_emitter(event){
    switch(event.type){
      case 'cancel':
        $('#case-modal').modal('hide');
        this.link_case.empty_modal();
        break;
      case 'link':
        let payload = {
          "chatsession_guid": this.selected_row_data['chatsession_guid'],
          "individual_id": null,
          "bizorg_id": null,
          "case_table_id":event.case_details['case_table_id'],
          "case_id": event.case_details['case_id']
        }
        if(event.case_details['party1_role_type'] == 'Individual' || event.case_details['party1_role_type'] == 'Organisation' ) {
          this._commonService.link_customer(payload).subscribe(res => {
            if(res.errCode == 0) {
              $('#case-modal').modal('hide');
              this.apply_filter('pagination');
              this._toastr.success('', 'Customer Linked successfully', this._toastrUtility.basic_configuration);
              this.link_case.empty_modal();
            }
          })
        } else {
          this._toastr.error('', 'Please add customer details for this case.', this._toastrUtility.basic_configuration);
        }
      break;
    }
  }

  customer_emitter(event){
    switch(event.type){
      case 'cancel':
        $('#customer-modal').modal('hide');
        this.link_customer.empty_modal();
        break;
      case 'link':
        this.customer_link(event);
        break;
    }
  }

  customer_link(event) {
    let payload = {}
    if(event.search_type == 'Organisation') {
      if(Object.keys(event.selected_organsation).length) {
        payload = this.link_organisation_customer_payload(event)
      } else {
        this._toastr.error('', 'Please select a customer', this._toastrUtility.basic_configuration);
        return
      }
    } else if (event.search_type == 'Individual') {
      if(Object.keys(event.selected_individual).length) {
        payload = this.link_individual_customer_payload(event)
      } else {
        this._toastr.error('', 'Please select a customer', this._toastrUtility.basic_configuration);
        return
      }
    }
    this._commonService.link_customer(payload).subscribe(res => {
      if(res.errCode == 0) {
        this._toastr.success('', 'Customer Linked successfully', this._toastrUtility.basic_configuration);
        this.apply_filter('pagination');
        $('#customer-modal').modal('hide');
        this.link_customer.empty_modal();
      }
    })
  }

  link_individual_customer_payload(event) {
    const payload = {
      "individual_id": event.selected_individual['individual_id'],
      "bizorg_id": null,
      "chatsession_guid": this.selected_row_data['chatsession_guid'],
      "case_table_id": null,
      "case_id": null
    }
    return payload;
  }

  link_organisation_customer_payload(event) {
    const payload = {
      "individual_id": null,
      "bizorg_id": event.selected_organsation['bizorg_id'],
      "chatsession_guid": this.selected_row_data['chatsession_guid'],
      "case_table_id": null,
      "case_id": null
    }
    return payload;
  }
}
 