  import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
declare var $:any;

@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent implements OnInit {

  private static readonly errorMessages = {
    'required': () => 'This field is required',
    'years': (params) => params.message,
    'uniqueEmail': (params) => params.message,
    'email': () => 'Enter a valid email address',
    'minlength': (params) => 'The min number of characters is ' + params.requiredLength,
    'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength,
    'pattern': (params) => 'Password must be of 8 or more characters. Must include atleast one upper case letter, one lower case letter, one number and one speacial character.',
    'mobileNo_10':(params) => params.message,
    'idNumber': (params) => params.message,
    'isNotExemptionDependentDate': (params) => params.message,
    'isExemptionDependentDate': (params) => params.message,
    'adultAge': (params) => params.message,
    'invalidEmail': (params) => params.message,
    'start_date_org': (params) => params.message,
    'startDate': (params) => params.message,
    'endDate': (params) => params.message,
    'min':(params) => 'Value cannot be less than ' + params.min,
    'max':(params) => 'Value cannot be greater than ' + params.max,
    'multipleCheckbox': (params) => params.message,
    'valid_sip_ext': (params) => params.message,
    'sip_authorization_user': (params) => params.message
  };

  @Input() private control: AbstractControlDirective | AbstractControl;

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {

  }
  
  // should_show_errors(): boolean {
  //   return this.control && this.control.errors && (this.control.dirty || this.control.touched);
  // }

  list_of_errors() {
    if (this.control.errors != null || this.control.errors != undefined) {
      $(this.el.nativeElement).prev().addClass('is-invalid');
      return Object.keys(this.control.errors).map(field => this.get_message(field, this.control.errors[field]));
    } else {
      $(this.el.nativeElement).prev().removeClass('is-invalid');
    }
  }

  private get_message(type: string, params: any) {

    return ShowErrorsComponent.errorMessages[type](params);
  }

}
