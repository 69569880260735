import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/dce-service/custom-validators.component';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { AltProjectService } from 'src/app/dce-service/alt-project.service';

// import * as stringSimilarity from 'string-similarity';
declare var $: any;

@Component({
  selector: 'app-individual-customers-payload',
  templateUrl: './individual-customers-payload.component.html',
  styleUrls: ['./individual-customers-payload.component.scss']
})
export class IndividualCustomersPayloadComponent implements OnInit, AfterViewInit {

  @Input() unique_id?:string = "";
  ind_cus_payload: FormGroup;
  submitted = false;
  gender_list = []
  martial_status_list = []
  province_list = []
  individual_type_list = []
  title_list = []
  male_title_list = []
  female_title_list = []
  nationality_list = [];
  country_list = [];
  all_title_list = [];
  highlight_fields = {};
  email_domain_registry = {
    gnial: 'gmail',
    gnail: 'gmail',
    gmaiil: 'gmail',
    gmial: 'gmail',
    giaml: 'gmail',
  }

  customPatterns = {}
  national_id_pattern = {
    // (\d{1,2})[0|1](\d{1})[0|1|2|3](\d{1})(\d{1,4})[0|1|2](\d{1,2})
    // [patterns]="national_id_pattern"  mask="00Z0D00000C00" [showMaskTyped]="true"
    'Z': {
      pattern: new RegExp('\(0|1)')
    },
    'D': {
      pattern: new RegExp('\[0|1|2|3]')
    },
    'C': {
      pattern: new RegExp('\[0|1|2]')
    },
    '0': {
      pattern: new RegExp('\[0-9]')
    },
    
    
  }
  constructor(
    private _fb: FormBuilder,
    private _commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private _env: EnvService,
    private _altServices: AltProjectService,
  ) { }

  ngOnInit() {
    this.initialize_payload();
    this.get_classification_data()
    this.customPatterns = this._commonService.phone_number_pattern();
  }

  ngAfterViewInit() {
    // $('#add_ind_cust_phone_number1'+this.unique_id).inputmask("999 999 9999");
    // $('#add_ind_cust_phone_number2'+this.unique_id).inputmask("999 999 9999");
  }

  initialize_payload() {
    this.ind_cus_payload = this._fb.group({
      'title': this._fb.control('', Validators.required),
      'first_name': this._fb.control('', Validators.required),
      'last_name': this._fb.control('', Validators.required),
      'initials': this._fb.control(''),
      'maiden_name': this._fb.control(''),
      'dob':this._fb.control(null),
      'dod':this._fb.control(null),
      'gender': this._fb.control('', Validators.required),
      'martial_status': this._fb.control(''),
      "individual_type": this._fb.control('', Validators.required),
      'uifrefno': this._fb.control(''),
      'cfcustomerid': this._fb.control(''),
      'idtype': this._fb.control('National ID', Validators.required),
      'idnumber': this._fb.control(null, [Validators.required, CustomValidators.ID_no_format]),
      'passport_number': this._fb.control(''),
      'asylum_seeker_no': this._fb.control(null),
      'bpnumber': this._fb.control(''),
      'nationality': this._fb.control('', Validators.required),
      'email_address1': this._fb.control(''),
      'phone_number1': this._fb.control('',[Validators.required, CustomValidators.contact_number]),
      'phone_number2': this._fb.control('',[Validators.minLength(10)]),
      'email_address2': this._fb.control(''),
      'address_1': this._fb.control(''),
      'address_2': this._fb.control(''),
      'address_3': this._fb.control(''),
      'city': this._fb.control(''),
      'province': this._fb.control('',Validators.required),
      'country': this._fb.control('South Africa'),
      'zipcode': this._fb.control(''),
      'ministerial_status': this._fb.control(false)
    });
    this.onSelectIdentificationType(null);
  }

  get_classification_data() {
    const payload = {
      'classification_type_id': [ this._env.dropdown_list.gender, this._env.dropdown_list.individual_type, this._env.dropdown_list.nationality, this._env.dropdown_list.country, 
                                  this._env.dropdown_list.martial_status, this._env.dropdown_list.provinces, this._env.dropdown_list.male_titles, this._env.dropdown_list.female_titles] ,
      'classification_value_id': [-1,-1,-1,-1,-1,-1,-1,-1]
    };
    this._commonService.get_particular_classification_data(payload).subscribe(res => {
      if (res.errCode == 0) {
        for (let i = 0; i < res.msg.length; i++) {
          if (res.msg[i].classification_type == this._env.dropdown_list.gender) {
            this.gender_list = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.individual_type) {
            this.individual_type_list = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.nationality) {
            this.nationality_list = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.country) {
            this.country_list = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.martial_status) {
            this.martial_status_list = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.provinces) {
            this.province_list = res.msg[i].data;
          // } else if (res.msg[i].classification_type == this._env.dropdown_list.title) {
          //   this.title_list = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.male_titles) {
            this.male_title_list = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.female_titles) {
            this.female_title_list = res.msg[i].data;
          }
          var temp_list = [...this.male_title_list, ...this.female_title_list]
          const unique_values = this.find_unique_value(temp_list, 'value_value')
          this.title_list = unique_values;
          this.all_title_list = unique_values;
        }
      }
    });
  }

  validate_payload() {
    this.submitted = true;
    if (this.ind_cus_payload.status == 'VALID' || this.ind_cus_payload.status == 'DISABLED') {
      return true;
    } else {
      // this._toastr.error('','Please fill all mandatory fields', this._toastrUtility.basic_configuration);
    }
    return false;
  }

  checkEmailSanity(primary_mail = true) {
    const email = primary_mail ? this.ind_cus_payload.controls.email_address1.value :
      this.ind_cus_payload.controls.email_address2.value;

    if (email) {
      const extracted_domain = email.match(/(?<=@)[^.]+(?=\.)/g);
      const mail_domain = extracted_domain != null ? extracted_domain[0] : null;
      console.log(mail_domain);
      if (mail_domain in this.email_domain_registry) {
        this._toastr.warning('', `It appears email address has spellcheck, do you mean ${this.email_domain_registry[mail_domain]} ?`, this._toastrUtility.basic_configuration);
      }
    }
  }

  onChangeEmail(_case) {
    this.validate_email(_case)
  }

  validate_email(_case) {
    switch(_case) {
      case 'email_address1': 
        const email_address1 = this.ind_cus_payload.get('email_address1');
        if(email_address1.value.length >0 && email_address1.valid) {
          this.ind_cus_payload.get('email_address1').setValidators([Validators.email]);
        } else if (email_address1.value.length <=0) {
          this.ind_cus_payload.get('email_address1').clearValidators()
        } else {
          // do nothing
        }
        this.ind_cus_payload.get('email_address1').updateValueAndValidity();
      break;
      case 'email_address2':
        const email_address2 = this.ind_cus_payload.get('email_address2');
        if(email_address2.value.length >0 && email_address2.valid) {
          this.ind_cus_payload.get('email_address2').setValidators([Validators.email]);
        } else if (email_address2.value.length <=0) {
          this.ind_cus_payload.get('email_address2').clearValidators()
        } else {
          // do nothing
        }
        this.ind_cus_payload.get('email_address2').updateValueAndValidity();
      break;
    }
  }

  // validate_phone_no() {
  //   const primary_email = this.ind_cus_payload.controls.email_address1.value;
    
  //   var phone1 = $('#add_ind_cust_phone_number1'+this.unique_id).val();
  //   var phone2 = $('#add_ind_cust_phone_number2'+this.unique_id).val();
    
  //   if (!(primary_email == "" || primary_email == null)) {
  //     if (!phone1.length) {
  //       this.ind_cus_payload.controls.phone_number1.setValue("");
  //       return true;
  //     }
  //   }
  //   // phone1
  //   if(phone1.length == 12 && !phone1.includes("_")) {
  //     this.ind_cus_payload.controls.phone_number1.setValue(phone1)
  //   } else {
  //     this._toastr.error('', 'Please enter a valid phone number', this._toastrUtility.basic_configuration);
  //     return false;
  //   }

  //   // phone2
  //   if (phone2.length == 0) {
  //     this.ind_cus_payload.controls.phone_number2.setValue("")
  //   }else if(phone2.length == 12 && !phone2.includes("_")) {
  //     this.ind_cus_payload.controls.phone_number2.setValue(phone2)
  //   } else {
  //     this._toastr.error('', 'Please enter a valid alternate phone number', this._toastrUtility.basic_configuration);
  //     return false;
  //   }

  //   // console.log(this.ind_cus_payload.controls.phone_number1, this.ind_cus_payload.controls.phone_number2);

  //   return true;
  // }

  id_type_disable() {
    this.ind_cus_payload.get('idnumber').disable();
    this.ind_cus_payload.get('passport_number').disable();
    this.ind_cus_payload.get('asylum_seeker_no').disable();
    this.ind_cus_payload.get('idtype').disable();
    const id_type = this.ind_cus_payload.controls['idtype'].value;
    if(id_type == 'National ID' || id_type == 'Asylum Seeker ID'){
      this.ind_cus_payload.get('country').disable();
      this.ind_cus_payload.get('nationality').disable();
    }
  }

  onSelectIdentificationType(value) {
    const id_type = this.ind_cus_payload.controls['idtype'].value;
    if (id_type == 'Passport') {
      this.ind_cus_payload.controls.idnumber.setValue(null);
      this.ind_cus_payload.get('passport_number').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(15)]);
      this.ind_cus_payload.get('idnumber').setValidators([]);
      this.ind_cus_payload.controls.asylum_seeker_no.setValue(null);
      this.ind_cus_payload.get('asylum_seeker_no').setValidators([]);
      this.clearSouthAfrica();
    } else if (id_type == 'National ID') {
      this.ind_cus_payload.controls.passport_number.setValue('');
      this.ind_cus_payload.get('idnumber').setValidators([Validators.required, CustomValidators.ID_no_format]);
      this.ind_cus_payload.get('passport_number').setValidators([]);
      this.ind_cus_payload.controls.asylum_seeker_no.setValue(null);
      this.ind_cus_payload.get('asylum_seeker_no').setValidators([]);
      this.patchSouthAfrica();
    } else if (id_type == 'Asylum Seeker ID') {
      this.onAslyumSeekerIdSelect();
    }
    this.ind_cus_payload.controls.gender.setValue('');
    this.ind_cus_payload.get('idnumber').updateValueAndValidity();
    this.ind_cus_payload.get('passport_number').updateValueAndValidity();
    this.ind_cus_payload.get('asylum_seeker_no').updateValueAndValidity();
  }

  onAslyumSeekerIdSelect() {
    this.ind_cus_payload.get('asylum_seeker_no').setValidators([Validators.required]);
    this.ind_cus_payload.controls.idnumber.setValue(null);
    this.ind_cus_payload.get('idnumber').setValidators([]);
    this.ind_cus_payload.controls.passport_number.setValue('');
    this.ind_cus_payload.get('passport_number').setValidators([]);
    this.clearSouthAfrica();
  }

  patchSouthAfrica() {
    this.ind_cus_payload.controls.nationality.setValue('South African');
    this.ind_cus_payload.controls.country.setValue('South Africa');
    this.ind_cus_payload.get('nationality').disable();
    this.ind_cus_payload.get('country').disable();
  }

  clearSouthAfrica() {
    this.ind_cus_payload.get('nationality').enable();
    this.ind_cus_payload.get('country').enable();
    this.ind_cus_payload.controls.nationality.setValue('');
    this.ind_cus_payload.controls.country.setValue('');
  }

  create_new_pot_mem_btn() {
    this.clear_ind_cus_details();
    this.toggle_ind_cus_details(true);
  }

  clear_ind_cus_details(){
    this.ind_cus_payload.patchValue({
      'title':'',
      'first_name': '',
      'last_name': '',
      'initials':'',  
      'dob': null,
      'dod': null,
      'gender': '',
      'idtype': 'National ID',
      'idnumber': null,
      'passport_number': '',
      'asylum_seeker_no': null,
      "martial_status":'',
      "individual_type":'',
      'maiden_name': '',
      "uifrefno":"",
      "cfcustomerid":"",
      'bpnumber':'',
      'nationality':'',
      'email_address1':'',
      'phone_number1':'',
      'phone_number2':'',
      'email_address2':'',
      'address_1':'',
      'address_2':'',
      'address_3':'',
      'city':'',
      'province':'',
      'country':'South Africa',
      'zipcode':'',
      'ministerial_status': false
    })
    this.onSelectIdentificationType(null);
    // setTimeout(()=>{
    //   $('#add_ind_cust_idnumber'+ this.unique_id).focus();
    // },100)   
  }

  toggle_ind_cus_details(bool) {
    if (bool){
      this.submitted = false;
      this.ind_cus_payload.enable();
    } else {
      this.ind_cus_payload.disable();
    }
  }

  fill_ind_cus_details(data, fill_val_from_id = false) {
    this.onSelectIdentificationType(null);
    // Fill presidential key
    data['ministerial_status'] = data['ministerial_status'] == 1? true: false;
    this.ind_cus_payload.patchValue(data);
    if (fill_val_from_id && Object.keys(data).length) {
      this.mark_fields_to_highlight(data);
      this.check_id_type_exist(true);
    }
  }

  mark_fields_to_highlight(data) {
    for (let i in data) {
      this.highlight_fields[i] = true;
    };
  }


  get_payload_data() {
    const payload = this.ind_cus_payload.getRawValue()
    payload['ministerial_status'] = payload['ministerial_status']?1 :0;
    return payload;
  }

  check_id_type_exist(auto_populate_trigger = false) {
    let payload;
    if (this.ind_cus_payload.controls['idtype'].value == 'National ID') {
      this.fill_data_based_on_id(this.ind_cus_payload.controls['idnumber'].value, auto_populate_trigger);
    }
    if (this.ind_cus_payload.controls['idtype'].value == 'National ID') {
      payload = {
        "idtype": this.ind_cus_payload.controls['idtype'].value,
        "id_value": this.ind_cus_payload.controls['idnumber'].value
      }
    } else {
      payload = {
        "idtype": this.ind_cus_payload.controls['idtype'].value,
        "id_value": this.ind_cus_payload.controls['passport_number'].value
      }
    }
    if(this.ind_cus_payload.controls['idnumber'].value != null && this.ind_cus_payload.controls['idnumber'].value.length==13 ){
      this._altServices.check_id_exits(payload).subscribe(res => { 
        if (res.errCode == 0) {
          if (res.individual_exist) {
            this.ind_cus_payload.controls['passport_number'].setValue('')
            this.ind_cus_payload.controls['idnumber'].setValue('')
            this._toastr.error('', 'This value against the ID type already exists.', this._toastrUtility.basic_configuration);
          }
        }
      })
    }
    
  }

  fill_data_based_on_id(id_value, auto_populate_trigger = false) {
    if(this.ind_cus_payload.controls['idnumber'].status == 'VALID') {
      this.parse_dob_using_id(id_value, auto_populate_trigger);
      const gender_value = id_value.substring(6,10)
      // const citizen_status = id_value.substring(10,11)
      if((parseInt(gender_value) > parseInt('0000') && parseInt(gender_value) < 5000) || parseInt(gender_value) == 0) {
      this.ind_cus_payload.controls.title.setValue('Mrs');
        this.ind_cus_payload.controls.gender.setValue('Female');
        this.title_list = this.female_title_list
        if(auto_populate_trigger){
          this.highlight_fields['gender'] = true;
        }
      } else if(parseInt(gender_value) >= 5000 && parseInt(gender_value) < 10000) {
        this.ind_cus_payload.controls.title.setValue('Mr');
        this.ind_cus_payload.controls.gender.setValue('Male');
        this.title_list = this.male_title_list;
        if(auto_populate_trigger){
          this.highlight_fields['gender'] = true;
        }
      } else {
        const temp_list= this.all_title_list
      }
    } else if(this.ind_cus_payload.controls['idnumber'].value == null || this.ind_cus_payload.controls['idnumber'].value.length ==0 || this.ind_cus_payload.controls['idnumber'].value.length > 13){
      this.ind_cus_payload.controls.gender.setValue('');
      this.ind_cus_payload.controls.title.setValue('');
      this.ind_cus_payload.controls.dob.setValue(null);
      this.title_list = [...this.male_title_list, ...this.female_title_list]
      this._toastr.error('', 'ID Number format Incorrect. YYMMDDSSSSCAZ', this._toastrUtility.basic_configuration);
    }
  }

  parse_dob_using_id(id_value, auto_populate_trigger = false) {
    let YYYY = '';
    const YY = id_value.substring(0, 2); 
    const MM = id_value.substring(2, 4);
    const DD = id_value.substring(4, 6);
    const year_start_count = 0;
    const today = new Date();
    const current_year = today.getFullYear().toString().substring(2, 4);
    if (YY <= current_year) {
      YYYY = '20' + YY;
      this._toastr.warning('', 'Please Verify Date Of Birth.', this._toastrUtility.basic_configuration);
    } else if (YY > current_year) {
      YYYY = '19' + YY;
    } else {
      this._toastr.error('', 'Date of Birth Cannot Be Filled.', this._toastrUtility.basic_configuration);
      this.ind_cus_payload.controls.dob.setValue(null);
      return;
    }
    const dob = YYYY + '-' + MM + '-' + DD;
    console.log(dob);
    this.ind_cus_payload.controls.dob.setValue(dob);
    if (auto_populate_trigger) {
      this.highlight_fields['dob'] = true;
    }
    this.ind_cus_payload.controls.title.setValue('');
    this.title_list = this.all_title_list

  }

  find_unique_value(data, key) {
    let final_list = []
    data.forEach(function (item) {
      var i = final_list.findIndex(x => x[key] == item[key]);
      if (i <= -1) {
        final_list.push(item);
      }
    })
    final_list.sort(function (a, b) {
      var keyA = a.value_value,
        keyB = b.value_value;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return final_list
  }

  // fill_idtype_values(data) {
  //   if (data.national_id && data.national_id != null) {
  //     this.pot_mem_details.patchValue({
  //       'idtype': 'National ID',
  //       'national_id': data.national_id,
  //     })
  //   } else if (data.passport_number && data.passport_number != null) {
  //     this.pot_mem_details.patchValue({
  //       'idtype': 'Passport',
  //       'passport_number': data.passport_number,
  //     })
  //   } else {
  //     this.pot_mem_details.patchValue({
  //       'idtype': '',
  //       'national_id': '',
  //       'passport_number': '',
  //     })
  //   }
  // }
}
