import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QueueSessionRenderComponent } from './queue-session-render/queue-session-render.component';
import { LiveChatHistoryComponent } from './live-chat-history/live-chat-history.component';

const routes: Routes = [
  {
    path: 'queue-session-render',
    component: QueueSessionRenderComponent
  },
  {
    path: 'history',
    component: LiveChatHistoryComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LiveChatRoutingModule { }
