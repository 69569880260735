import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericButtonService } from './generic-button.service';

@Component({
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss']
})
export class GenericButtonComponent implements OnInit {

  @Output() button_events: EventEmitter<any> = new EventEmitter<any>();
  @Input() payload_dict:any = {};
  @Input() service_url: string = '';
  @Input() button_class?: string = 'btn btn-primary btn-sm mb-2 mr-2';
  @Input() button_name? = 'Save';
  @Input() button_name_during_service? = 'Saving';
  @Input() button_id?:string = "";
  @Input() is_disabled?: boolean = false;
  @Input() title?: string = '';
  button_disabled_text: boolean = false;

  constructor(
    public _buttonService: GenericButtonService
  ) { }

  ngOnInit() {
  }

  click_event(event){
    this.button_events.emit({'case':'clicked','data':{},'event':event})
	}

  save_data_on_service(parent_payload = null){
    this.button_disabled_text = true;
    this.is_disabled = true
    let payload = this.payload_dict;
    if(parent_payload != null){
      payload = parent_payload
    }
    console.log(payload)
      this._buttonService.get_service_data(this.service_url,payload).subscribe(res => {
        this.is_disabled = false;
        this.button_disabled_text = false;
        this.button_events.emit({'case':'complete','data':res,'event':{}})
      },
      err => {
        this.is_disabled = false;
        this.button_disabled_text = false;
        this.button_events.emit({'case':'failed','data':err,'event':{}})
      }
    )
  }

}
