import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';
// import { Router, ActivatedRoute } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
// import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-notes-reply',
  templateUrl: './notes-reply.component.html',
  styleUrls: ['./notes-reply.component.scss']
})
export class NotesReplyComponent implements OnInit {

  @Input() notes_data = [];
  @Input() note_case = '';
  @Input() reply?:boolean =true;
  @Output() reply_notes_events: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public _commonService: CommonService,
    public _env: EnvService,
    private sharedService: SharedService
    // private _router: Router,
    // private _activatedRoute: ActivatedRoute,
    // private _toastr: ToastrService,
    // private _toastrUtility: ToastrUtilityService,
    // private _fb: FormBuilder,
  ) { }

  ngOnInit() {
  }

  get_workflow_notes_documents(index, note_id) {
    console.log(index, note_id)
    const payload = {
      'obj_type': this.note_case + '_view',
      'note_id': note_id
    };
    this._commonService.get_workflow_notes_documents(payload).subscribe(res => {
      if (res.errCode === 0) {
        this.view_doc_links(res.doc_data, index, note_id);
      }
    });
  }

  view_doc_links(doc_data, index, note_id) {
    var html_links = '';
    html_links += '<ul style="padding-left: 17px;">';
    let regex_string = '';
    for (let i = 0; i < this._env.show_file_in_browser.length; i++) {
      if (i != this._env.show_file_in_browser.length - 1) {
        regex_string += this._env.show_file_in_browser[i] + '|'
      } else {
        regex_string += this._env.show_file_in_browser[i];
      }
    }
    var regex = new RegExp("[.](" + regex_string + ")");
    for (var i = 0; i < doc_data.length; i++) {
      if (regex.test(doc_data[i]['document_link'])) {
        // html_links += "<li><a target='_blank' href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' id='" + doc_data[i]['document_link'] + "$"  + doc_data[i]['document_name'] +"'>" + "(Download)" + "</span></li>";
        html_links += "<li><a target='_blank' href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' class='fas fa-file-download' title='Click to download document' id='" + doc_data[i]['document_link'] + "$" + doc_data[i]['document_name'] + "'></span></li>";

      } else {
        // html_links += "<li><a target='_blank' download='" + doc_data[i]['document_name'] + "'" + "href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' id='" + doc_data[i]['document_link'] + "$"  + doc_data[i]['document_name'] +"'>" + "(Download)" + "</span></li>";
        html_links += "<li><a target='_blank' download='" + doc_data[i]['document_name'] + "'" + "href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' class='fas fa-file-download' title='Click to download document' id='" + doc_data[i]['document_link'] + "$" + doc_data[i]['document_name'] + "'></span></li>";
      }
    }
    html_links += '</ul>';
    if (doc_data.length) {
      $('#doc_' + index + '_' + note_id).html(html_links);
    } else {
      const no_doc_msg = 'No documents found';
      $('#doc_' + index + '_' + note_id).html(no_doc_msg);
    }
    const self = this;
    for (var i = 0; i < doc_data.length; i++) {
      document.getElementById(doc_data[i]['document_link'] + '$' + doc_data[i]['document_name']).addEventListener("click", function(event) {
        const file_data = event.target['id'].split('$');
        const url = file_data[0]
				self.sharedService.spinnerVisibility(true);
        self._commonService.get_b64_doc_from_url({url:url}).subscribe(res => {
          if(res.errCode == 0) {
            const link = document.createElement('a');
            link.setAttribute('target', '_self');
            link.setAttribute('href', res.mimetype + ';base64,' + res.data);
            link.setAttribute('download', file_data[1]);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          self.sharedService.spinnerVisibility(false);
        })
      });
    }
  }

  reply_note(note_type, node_data) {
    this.reply_notes_events.emit({ case: 'create_log_' + note_type, data: node_data });
  }



}
