import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class Config {

    menu_map = {

        "busorgdetails": {
            "menu": {
                "biz_org": {
                    "4__4001": "add_new_division",/*add_new_division*/
                },
                "div_org": {
                    "36__44005": "",/*add_new_division*/
                    "36__44006": "",/*remove_division*/
                }
            },
            "service": "get_tree_biz",
        },

        "busorgdetails_division": {
            "menu": {
                "biz_org": {
                    "36__44001": "",/*add_new_division*/
                },
                "div_org": {
                    "7__7001": "add_new_division",/*add_new_division*/
                    "7__7002": "remove_division",/*remove_division*/
                },
                "no_context": {}
            },
            "service": "get_tree_biz",
        },

        "busorgdetails_employee": {
            "menu": {
                "biz_org": {
                    "36__44001": "",/*add_new_division*/
                },
                "div_org": {
                    "36__44005": "",/*add_new_division*/
                    "36__44006": "",/*remove_division*/
                }
            },
            "service": "get_tree_biz",
        },

        'sitedetails': {
            "menu": {
                "biz_org": {
                    "14__14001": "add_new_site",
                    "36__44002": "",/*add_contact*/
                },
                "site_leaf": {
                    "36__44005": "",/*add_new_site*/
                    "36__44006": "",/*add_contact*/
                },
                "site_non_leaf": {
                    "36__44005": "",/*add_new_site*/
                    "36__44006": "",/*add_contact*/
                },
                "contact_rec": {
                    "36__44005": "",/*add_new_site*/
                    "36__44006": "",/*add_contact*/
                }
            },
            "service": "get_tree_biz_site",
        },

        'sitedetails_location': {
            "menu": {
                "biz_org": {
                    "36__44001": ""
                },
                "site_leaf": {
                    "17__17001": "add_new_site",
                    "17__17002": "remove_site",
                    "17__17003": "add_contact"
                },
                "site_non_leaf": {
                    "17__17002": "remove_site",
                    "17__17001": "add_new_site"
                },
                "contact_rec": {}
            },
            "service": "get_tree_biz_site",
        },
        'sitedetails_contact': {
            "menu": {
                "biz_org": {
                    "36__44001": ""
                },
                "site_leaf": {
                    "36__44006": ""
                },
                "site_non_leaf": {
                    "36__44006": ""
                },
                "contact_rec": {}
            },
            "service": "get_tree_biz_site",
        },
        "employeedetails": {
            "menu": {
                "biz_org": {
                    "38__46001": "",/*add_new_division*/
                },
                "div_org": {
                    "38__46004": "",/*add_new_division*/
                    "38__46005": "",/*remove_division*/
                }
            },
            "service": "get_tree_biz",
        },

        "classification": {
            "menu": {
                "root_class": {
                    "26__26001": "add_sub_classification"
                },
                "sub_class": {
                    "26__26001": "add_sub_classification",
                    "26__26002": "remove_classification",
                    "26__26005": "remove_all_classification",
                },
                "sub_class_inactive": {
                    "26__26003": "activate_classification",
                    "26__26004": "activate_all_classification",
                }
            },
            "plugins": ["types", "contextmenu", "search", "dnd"],
            "service": "get_classification_tree",
        },


        "discipline_classification": {
            "menu": {
                "root_class": [],
                "sub_class": [],
                "leaf_class": []
            },
            "service": "get_internal_class_priority_severity",
        },

        "task_status_classification": {
            "menu": {
                "root_class": [],
                "sub_class": [],
                "leaf_class": []
            },
            "service": "task/classification/tree/extensions",
        },

        "queue_classification": {
            "menu": {
                "root_class": [],
                "sub_class": [],
                "leaf_class": [],
            },
            "service": "classification/chatqueue/mapping/tree",
        },

        "mobile_classification": {
            "menu": {
                "no_leaf": [],
                "mapped_leaf": [],
                "no_mapped_leaf": []


            },
            "service": "get_mobile_classification_mapping",
        },

        'asidetails_division': {
            "menu": {
                "asi_biz": {
                    "37__37001": "add_new_department"
                },
                "asi_div": {
                    "37__37001": "add_new_department",
                    "37__37002": "remove_department"
                },
                "asi_div_leaf": {
                    "37__37001": "add_new_department",
                    "37__37002": "remove_department",
                    "37__37003": "add_new_employee"
                },
                "asi_emp": []
            },
            "service": "internal/org/department/tree"
        },
        'asidetails_employee': {
            "menu": {
                "asi_biz": [],
                "asi_div": [],
                "asi_div_leaf": [""],
                "asi_emp": [""]
            },
            "service": "internal/org/department/tree"
        },
        "bizorg_productmap": {
            "menu": {
                'biz_org': {
                    "41_00001": ""
                },
                "product_type": {
                    "41_00001": ""
                },
                "product_house": {
                    "41_10001": "",
                },
                "product": {
                    "73__73001": "remove_prod",
                },
                "fund_description": {
                    "41_10001": "",
                },
                "eb_without_json": {
                    "73__73005": "add_eb_json",
                    // "73__73001": "remove_prod",
                },
                "eb_with_json": {
                    "73__73004": "update_eb_json",
                    // "73__73001": "remove_prod",
                },
            },
            "service": "get_biz_prod_tree",
        },
        "portfolio-manager": {
            "menu": {},
            "service": "portfolio/get_portfolio_Tree",
        },
    }

    context_menu_items_map = {
        "add_new_site": "Add Location",
        "remove_site": "Remove Location",
        "add_contact": "Add Contact",
        "add_new_division": "Add Division",
        "remove_division": "Remove Division",
        "add_new_department": "Add Department",
        "remove_department": "Remove Department",
        "add_sub_classification": "Add Sub Classification",
        "remove_classification": "Remove Classification",
        "remove_all_classification": "Remove All Including Children",
        "add_new_employee": "Add Employee",
        "remove_employee": "Remove Employee",
        "update_employee": "Update Employee",
        "remove_prod_house": "Remove Product House",
        "remove_prod": "Remove Product",
        "add_eb_json": "Add Employee Benefits",
        "update_eb_json": "Update Employee Benefits",
        "activate_classification": "Activate Classification",
        "activate_all_classification": "Activate All Including Children"
    }

    icon_types = {
        "default": {
            "icon": "jstree-remove-icon"
        },
        "biz_org": {
            "icon": "jstree-remove-icon"
        },
        "div_org": {
            "icon": "jstree-remove-icon"
        },
        "root_class": {
            "icon": "jstree-remove-icon"
        },
        "sub_class": {
            "icon": "jstree-remove-icon"
        },
        "asi_biz": {
            "icon": "i-Building"
        },
        "asi_div": {
            "icon": "i-Conference"
        },
        "asi_div_leaf": {
            "icon": "i-Conference"
        },
        "asi_emp": {
            "icon": "i-Administrator"
        },
        "site_leaf": {
            "icon": "i-Map-Marker"
        },
        "site_non_leaf": {
            "icon": "i-Map-Marker"
        },
        "contact_rec": {
            "icon": "i-Administrator"
        },
        "product_house": {
            "icon": "jstree-remove-icon"
        },
        "product": {
            "icon": "jstree-remove-icon"
        },
        "eb_with_json": {
            "icon": "i-Yes"
        },
        "eb_without_json": {
            "icon": "i-Close-Window"
        },
        "mapped_leaf": {
            "icon": "i-Yes"
        },
        "no_mapped_leaf": {
            "icon": "i-Close-Window"
        },
        "sub_class_inactive": {
            "icon": "i-Close-Window"
        },

    }

    constructor() {}
}
