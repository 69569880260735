export const colorConfig = {
  default:{
		defaultThemeColorsSurvey: {
			"$main-color": "#294178",
			"$main-hover-color": "#294178",
			"$text-color": "#fffff",
			"$body-container-background-color": "#f8f8f8"
		},
		defaultThemeColorsEditor: {
			"$primary-color": "#294178 ",
			"$secondary-color": "#294178",
			"$primary-hover-color": "#a71d21 ",
			"$primary-text-color": "#fffff",
			"$selection-border-color": "#294178"
		}
  }
};

export const surveyStrings = {
  default:{
		creator_options: {
			completingSurvey: "Your response has been saved.",
			completeText: "Complete",
			emptySurvey: "The current form is empty"
		},
		display_options: {
			completingSurvey: "Your response has been saved.",
			completeText: "Complete",
			emptySurvey: "The current form is empty"
		}
	}
};

export const surveyOptions = {
	default: {
		creator_options: {
			haveCommercialLicense:true,
			generateValidJSON: true,
			showJSONEditorTab: true,
			showTestSurveyTab: true,
			showEmbededSurveyTab: false,
			showTranslationTab: false,
			showLogicTab: false,
			showElementEditorAsPropertyGrid: true,
			showPropertyGrid: 'right',
			questionTypes: ['boolean','checkbox','comment','dropdown','expression','file','radiogroup','rating','text'],
			isAutoSave: false,
			isRTL: true,
			// designerHeight: '1200px',
			showPagesToolbox: false,
			useTabsInElementEditor: true,
			showState: false,
			showPagesInTestSurveyTab: false,
			showDefaultLanguageInTestSurveyTab: "auto",
			showInvisibleElementsInTestSurveyTab: false,
			showTitlesInExpressions: false,
			hideExpressionHeaderInLogicTab: true,
			allowControlSurveyTitleVisibility: false,
			showSurveyTitle: "never",
			showToolbox: 'right',
			showProgressBar: "off",
			showSimulatorInTestSurveyTab: false
		},
		display_options: {
			showNavigationButtons: false
		}
	}
}

// https://github.com/surveyjs/survey-creator/blob/master/packages/survey-creator/src/localization/english.ts
export const curStrings = {
	default: {
		"ed": { // Name Changes tabs
			"survey": "Form",
			"saveSurvey": "Save ",
			"designer": "Design Form",
			"testSurvey": "Test Form",
			"settings": "Form Settings",
			"testSurveyAgain": "Test Form Again",
			"surveyResults": "Form Results",
			"surveyResultsTable": "Form Results",
			"surveyResultsJson": "Form JSON"
		},
		"pe": {
			"surveyEditorTitle": "Edit Form Settings",
			"cookieName": "Cookie name (to disable run form two times locally)",
			"sendResultOnPageNext": "Send form results on page next",
			"firstPageIsStarted": "The first page in the form is a started page.",
			"maxTimeToFinish": "Maximum time to finish the form",
			"maxTimeToFinishPage": "Maximum time to finish a page in the form",
			"noquestions": "There is no any question in the form.",
			"surveyTitlePlaceholder":"Input form title here",
			"surveyDescriptionPlaceholder": "Enter a form description",
			"startSurveyText":"Start form text",
			"tabs": {
				"logo": "Logo in form Title"
			}
		},
	}
}

// https://surveyjs.io/Examples/Library?id=survey-customcss&platform=jQuery&theme=modern#content-docs
export const css_classes = {
	'default': {
		// "root": "",
		"navigation": {
			"complete": "btn btn-primary",
			"prev": "btn btn-primary",
			"next": "btn btn-primary",
			"start": "btn btn-primary",
			"preview": "btn btn-primary",
			"edit": "btn btn-primary"	
		}
	}
}

export const showingProperties = {
	//Show properties for all survey object types
	default: {
		default_properties: [
			"title", // Text
			"description",
			"visible",
			"isRequired",
			"readOnly",
			"inputType",
			"placeHolder",
			"visibleIf",
			"requiredIf",
			"enableIf",
			"startWithNewLine",
			"hideNumber",
			"titleLocation",
			"descriptionLocation",
			"indent",
			"width",
			"maxWidth",
			"minWidth",
			"size",
			"valueName",
			"defaultValue",
			"useDisplayValuesInTitle",
			"textUpdateMode",
			"requiredErrorText",
			"validators",
			"maxLength",
			"hasComment",
			"choices", // Checkbox
			"choicesOrder",
			"selectAllItem",
			"hasSelectAll",
			"noneItem",
			"noneText",
			"hasNone",
			"choicesEnableIf",
			"choicesVisibleIf",
			"hideIfChoicesEmpty",
			"colCount", 
			"showClearButton", // Radiogroup
			"optionsCaption",
			"showOptionsCaption", // Dropdown
			"choicesMin",
			"choicesMax",
			"rows", // comment
			"rateMax", // Rating
			"rateMin",
			"rateStep",
			"minRateDescription",
			"maxRateDescription",
			"labelFalse", // Boolean
			"labelTrue",
			"hasTitle",
			"html", // HTML
			"allowClear", // Signature Pad
			"height",
			"penColor",
			"dataFormat",
			"allowMultiple", // File Upload
			"allowImagesPreview",
			"acceptedTypes",
			"showPreview",
			"storeDataAsText",
			"imageHeight",
			"imageWidth",
			"maxSize",
			"waitForUpload",
			"needConfirmRemoveFile",
			"expression", // Expression
			"currency",
			"displayStyle",
			"format",
			"maximumFractionDigits",
			"minimumFractionDigits",
			"useGrouping",
			"items", // Multiple Text
			"itemSize",
			"correctAnswer",
			"columnsVisibleIf", // Matrix (Single Input)
			"rowsVisibleIf",
			"showHeader",
			"isAllRowRequired",
			"hideIfRowsEmpty",
			"rowsOrder",
			"columns",
			"state", // Panel
			"questionTitleLocation",
			"innerIndent",
			"showNumber",
			"showQuestionNumbers",
			"questionStartIndex"
		],
		 //Show properties for radiogroup/checkbox question only
		// radiogroup: ["name"],
		// expression: ["currency"],
		// text:["title"],
		// checkbox: ["name"],
		isInList: (name, propName) => {
			if(!showingProperties['default'][name]) return false;
			return showingProperties['default'][name].indexOf(propName) > -1;
		}
	}
};
