import { Component, OnInit,OnChanges, Input, Output, EventEmitter, HostListener, ElementRef, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';

import { VisConfigService } from '../../dce-service/vis-config.service';
import * as Vis from 'vis';
import { debug } from 'util';
declare var $: any;
import { CommonService } from '../../dce-service/common.service';
import { NodeShape, Colors, InitialNodeData, InitialEdgeData} from './vis.config';

import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { TaskClassificationComponent } from '../task-classification/task-classification.component';
import { taskClassificationDeserializerById, taskClassificationSerializerByName, updateConfig } from '../task-classification/task-serializer';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';
@Component({
  selector: 'app-vis',
  templateUrl: './vis.component.html',
  styleUrls: ['./vis.component.scss']
})
export class VisComponent implements OnInit, OnChanges, OnDestroy {

  @Input() nodes_data?: any;
  @Input() edges_data?: any;
  @Input() disable_node_list?: any;
  @Input() mode: any;
  @Input() height?:any = 'calc(100vh - 330px)';
  @Input() width?:any = '100%';
  @Input() id?:any = '';
  @Input() chart_type?: string = 'task';
  @Input() workgroup_list?: any =[];
  @Input() node_connection_type?:string ='both'; // parallel/series/both
  @Input() is_recurring?:boolean =false;

  @Output() json_data: EventEmitter<any> = new EventEmitter<any>(); // emit the data of nodes and edges
  @Output() loading: EventEmitter<any> = new EventEmitter<any>(); // only used for view internal employee heirarchy
  @Input() root_id?:any = ''; // only used for view internal employee heirarchy

  @ViewChild('vis_task_classification', {static: false}) taskClassification: TaskClassificationComponent;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
    } else {
      if(this.mode == 'full-view') {
        $('.edit-node-data').hide();
      }
      $('.edit-edge-data ').hide();
      $('.edit-decision-edge-data').hide();
      $('.node-delete-confirmation').hide();
      $('.edge-delete-confirmation').hide();
    }
  }

  // engagement_id = -1;
  // node_id = -1;
  start_id = 0;
  end_id = 9999;

  // Form variables
    node_label = '';
    node_type = 'task';
    // duration = 1;
    days = 0;
    hours = 0;
    minutes = 0;

    start_duration = {
      days: 0,
      hours: 0,
      minutes: 0,
    }

    assign_or_dispatch = 'assign';
    assign_to = null
    members_list = []
    dispatch_to = null
    approval_required = true;
    external_reference_1 = [];

    node_data_id = [];
    individual_node_data: any = {};
    node_late_submission = false;
    book_automatic = true;
    edge_pre_emtive = true;
  
  // other Variables
    new_node = true;

  node_data_list = [];
  // id_exist: boolean;

  disable_node_id_list = [];
  // new_edge_data = {};

  position: any = {};
  options: any = {};

  drag_element_id = '';

  edge_shape: any = {
    default: {
      color: 'default_edge'
    },
    decision: {
      color: 'blue_edge'
    }
  };

  node_shape = NodeShape;
  colors = Colors;

  nodes: any;
  edges: any;
  selected_edge: any = {};
  selected_node: any = {};
  decision_label = '';
  decision_data = [];
  selected_node_for_deletion: any = {};
  selected_edge_for_deletion: any = {};

  provider_individual_schedule = [];
  individual_provider_name = '';
  individual_provider_division = '';
  individual_provider_division_list = [];

  provider_group_schedule = [];
  group_provider_id = -1;
  group_division_id = -1;
  group_class_list = [];
  classification_heirarchy = [];
  classification_data = [];
  class_dropdown = []
  classification_root = -1;
  task_function_type = "";
  task_function_subtype = "";
  task_function_type_id = -1;
  task_function_subtype_id = -1;
  myBounds: any;

  // Variables for chart type = service
  // workgroup_list = [];
  services_list = [];
  task_group_list = [];

  selected_service: any = {};
  selected_task_group: any = {};
  selected_workgroup: any = {};

  vis_network:any; 
  // task_classification_config = {
  //   // l2:{'name':'Task Function SubType', 'class':'col-6'},
  //   l2:{'name':'Business Function', 'class':'col-6'},
  //   l3:{'name':'Function Area', 'class':'col-6'},
  //   l4:{'name':'Area', 'class':'col-6'},
  //   l5:{'name':'Sub Area', 'class':'col-6'}
  // }
  task_classification_config = this._env.task_classification_config

  constructor(
    private visConfigService: VisConfigService,
    private _commonService: CommonService,
    public _sharedService: SharedService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private eRef: ElementRef,
    private _env: EnvService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.node_connection_type || (changes.is_recurring && this.mode == 'edit')) {
      //  Do nothing
    } else {
      setTimeout(() => {
        this.height_and_width();
        this.draw_flowChart();
      }, 200);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.height_and_width();
      this.draw_flowChart();
    }, 200);
    this.disable_node_id_list = [this.start_id, this.end_id];
    if (this.chart_type == 'service') {
      this.get_all_services();
    } else if (this.chart_type == 'task') {
      this.get_task_classification_dropdown();
    }
    if(this.members_list.length == 0) {
      this.get_member_list()
    }
    this.node_type = this.chart_type;
    this.task_classification_config = updateConfig(this.task_classification_config, 'col-6')
  }

  ngOnDestroy() {
    this.destroy_vis_network()
  }

  destroy_vis_network() {
    if(this.vis_network) {
      this.remove_event_flowchart();
      this.destroy_flowchart();
    }
  }

  remove_event_flowchart() {
    this.vis_network.off("doubleClick")
    this.vis_network.off("hold")
  }

  destroy_flowchart() {
    this.vis_network.destroy();
  }
  get_task_classification_dropdown(){
    this._commonService.get_task_classification_dropdown({}).subscribe(res => {
      this.classification_heirarchy = res.parent_children_dict;
      this.classification_data = res.msg;
      this.classification_root = res.root_id
      this.populate_next_dropdown(1);

    })
  }

  get_all_services() {
    this._commonService.get_all_items({'item_type': 'Service'}).subscribe(res => {
      if (res.errCode == 0 ) {
        this.services_list =  res.data.top_products;
      }
    });
  }

  get_member_list() {
    this._commonService.get_users_list({}).subscribe(res => {
      if (res.errCode == 0) {
        this.members_list = this._commonService.sortByKey(res.msg,'full_name','asc');
      }
    });
  }

  height_and_width() {
    const currentId = 'network' + this.id;
    const element = document.getElementById(currentId);
    element.style.height = this.height;
    element.style.width = this.width;
    element.style.border = '1px solid lightgray';
    this.myBounds = element;
  }
  // set_class(level){
  //   for (let index = 0; index < this.class_dropdown['level_'+level].length; index++) {
  //     const element = this.class_dropdown['level_'+level][index];
  //     if(level == 1 && element.value_value.toLowerCase() == this.task_function_type.toLowerCase()){
  //       this.task_function_type_id = this.class_dropdown['level_'+level][index]['id']
  //       break;
  //     }else if(level == 2 && element.value_value.toLowerCase() == this.task_function_subtype.toLowerCase()){
  //       this.task_function_subtype_id = this.class_dropdown['level_'+level][index]['id']
  //       break;
  //     }
  //   }
  // }
  populate_next_dropdown(level){
    var sel_id;
    if(level != undefined){
      sel_id = this.task_function_type_id;
    }
    if(sel_id == -1){
      sel_id = this.classification_root;
    }
    var child_ids = [];
    switch(level) {
      case 1:
      case 2:
      case 3:
      case 4:
        for(i=level; i<=4; i++){
          this.class_dropdown['level_'+i] = [];
        }
        child_ids = this.classification_heirarchy[sel_id];
        for(var i=0; i<this.classification_data.length; i++){
          if($.inArray(this.classification_data[i]['id'], child_ids) !== -1){
            this.class_dropdown['level_'+level].push(this.classification_data[i]);
          }
        }
        if(this.class_dropdown['level_'+level].length == 1){
          this.populate_next_dropdown(level+1);
        }
        break;
      }
    }
  draw_flowChart() {
    if (this.nodes_data == undefined && this.edges_data == undefined) {
      this.nodes = new Vis.DataSet(InitialNodeData);
      this.edges = new Vis.DataSet(InitialEdgeData);
    } else {
      this.nodes = new Vis.DataSet(this.nodes_data);
      this.edges = new Vis.DataSet(this.edges_data);
    }
    const self = this;

    const data = {
      nodes: this.nodes,
      edges: this.edges
    };
    this.temp_nodes = [];
    this.clusterIndex =1;

    const currentId = 'network' + this.id;
    const container = document.getElementById(currentId);

    let network;
    switch (this.mode) {
      case 'view':
        this.options = this.visConfigService.flowchart_view_options();
        network = new Vis.Network(container, data, self.options);
        setTimeout(() => {
          network.fit();
        }, 200);
      break;
      case 'hierarchy':
      this.options = this.visConfigService.flowchart_hierarchy_options();
      network = new Vis.Network(container, data, self.options);
      setTimeout(() => {
        network.fit();
      }, 200);
      const parent_data = this.nodes.get(this.root_id)
      if(parent_data != null) {
        this.temp_nodes.push(parent_data)
      }
      this.get_third_level_nodes(network,this.root_id);
      const temp_data = {
        nodes: this.temp_nodes,
        edges: this.edges
      };
      network.setData(temp_data);
    break;
      case 'edit':
        this.flowchart_options();
        const options = this.options
        network = new Vis.Network(container, data, options);
        this.hold_event(network);
        this.double_click_event(network);
      break;
      case 'full-view':
        this.destroy_vis_network()
        this.options = this.visConfigService.flowchart_view_options();
        network = new Vis.Network(container, data, self.options);
        setTimeout(() => {
          network.fit();
        }, 200);
        this.double_click_event(network);
      break;
    }
    network.on("selectNode", function (params) {
      if (params.nodes.length == 1) {
        console.log(params.nodes);
        if (network.isCluster(params.nodes[0]) == true) {
          network.openCluster(params.nodes[0]);
        }
      }
    });
    // network.once("initRedraw", function () {
    //   if (self.lastClusterZoomLevel === 0) {
    //     self.lastClusterZoomLevel = network.getScale();
    //   }
    // });
    
    // // we use the zoom event for our clustering
    // network.on("zoom", function (params) {
    //   console.log(params.scale)
    //   console.log(self.lastClusterZoomLevel * self.clusterFactor);
    //   if (params.direction == "-") {
    //     // if (params.scale < self.lastClusterZoomLevel * self.clusterFactor) {
    //       self.makeClusters(params.scale)
    //       self.lastClusterZoomLevel = params.scale;
    //     // }
    //   } else {
    //     self.openClusters(params.scale);
    //   }
    // });
    this.vis_network = network;
    this.makeClusters()
    this.loading.emit({status: false});
  }

  hold_event(network) {
    network.on('hold', function (properties) {
      network.fit();
    });
  }

  flowchart_options() { //  VIS with Updates
    const self = this;
    this.options = this.visConfigService.flowchart_edit_options();
    this.options['manipulation'] = {
      enabled: true,
      initiallyActive: true,
      addNode: function (nodeData, callback) {
        self.add_new_node(nodeData);
      },
      addEdge: function (edgeData, callback) {
        const data = self.restrict_edges(edgeData);
        const is_connection_valid = self.allow_connection_types(edgeData);
        if (data != '' && is_connection_valid ) {
          self.add_new_edge(data);
        } else {
          // self.snotifyService.error('Cannot add', self.utilityService.getConfig());
        }
      },

      editEdge: true,
      deleteNode: function (nodeData, callback) {
        self.delete_node(nodeData);
        callback(null);
      },
      deleteEdge: function (edgeData, callback) {
        // self.delete_edge(edgeData);
        self.selected_edge_for_deletion = edgeData;
        $('.edge-delete-confirmation').show();
        callback(null);
      }
    };
  }

  // Nodes Code

  add_new_node(node_data) {
    $('.edit-node-data').show();
    $('#node_label').focus();
    this.new_node = true;
    this.position = {
      x: node_data.x,
      y: node_data.y
    };
  }

  create_update_node_data() {
    if (this.validate()) {
      this.hide_node_modal();
      if (this.new_node) {
        const node_obj = this.create_new_node_object(this.new_node);
        console.log(node_obj);
        this.nodes.update(node_obj);
        this.clear_form();
        this.emitted_event();
      } else {
        const node_obj = this.create_new_node_object(this.new_node);
        this.nodes.update(this.selected_node);
        this.selected_node = {};
        this.clear_form();
        this.emitted_event();
      }
    }
  }

  create_new_node_object(new_node) {
    let obj = {};
    const current_shape = this.node_shape[this.node_type]['shape'];
    let current_icon = '';
    let current_image = '';
    if (current_shape != 'image') {
      current_icon = this.node_shape[this.node_type]['icon'];
    } else {
      current_image = this.node_shape[this.node_type]['image'];
    }
    const current_color = this.node_color(this.node_type);
    if (new_node) {
      const tc_payload = taskClassificationSerializerByName(this.taskClassification.get_payload_values());
      const temp_obj = {
        margin: 10,
        label: this.node_label,
        activity_name: this.node_label,
        duration: {
          days: this.days,
          hours: this.hours,
          minutes: this.minutes,
        },
        start_duration:this.start_duration,
        assign_to: this.assign_or_dispatch == 'assign'? this.assign_to: null,
        dispatch_to: this.assign_or_dispatch == 'dispatch'? this.dispatch_to: null,
        approval_required: this.approval_required ?1:0,
        external_reference_1: this.external_reference_1.length? this.external_reference_1[0]: "",
        assign_or_dispatch: this.assign_or_dispatch,
        type: this.node_type,
        shape: current_shape,
        color: current_color,
        icon: current_icon,
        image: current_image,
        size: 20,
        x: this.position.x,
        y: this.position.y,
      };
      obj = {...temp_obj,...tc_payload}
    } else {
      this.selected_node.shape = current_shape;
      this.selected_node.color = current_color;
      this.selected_node.icon = current_icon;
      this.selected_node.image = current_image;
      this.selected_node.activity_name = this.node_label;
      this.selected_node.label = this.node_label,
      this.selected_node.type = this.node_type;
      const tc_payload = taskClassificationSerializerByName(this.taskClassification.get_payload_values());
      this.selected_node.task_function_type = tc_payload.task_function_type;
      this.selected_node.task_function_subtype = tc_payload.task_function_subtype;
      this.selected_node.task_function_l3 = tc_payload.task_function_l3;
      this.selected_node.task_function_l4 = tc_payload.task_function_l4;
      this.selected_node.task_function_l5 = tc_payload.task_function_l5;
      this.selected_node.duration =  {
        days: this.days,
        hours: this.hours,
        minutes: this.minutes,
      };
      this.selected_node.start_duration = this.start_duration;
      this.selected_node.assign_to = this.assign_or_dispatch == 'assign'? this.assign_to: null;
      this.selected_node.dispatch_to = this.assign_or_dispatch == 'dispatch'? this.dispatch_to: null;
      this.selected_node.approval_required = this.approval_required ?1:0;
      this.selected_node.external_reference_1 = this.external_reference_1.length? this.external_reference_1[0]: "",
      this.selected_node.assign_or_dispatch = this.assign_or_dispatch;
      return 0;
    }
    return obj;
  }

  hide_node_modal() {
    $('.edit-node-data').hide();
  }

  delete_node(nodeData) {
    // dont delete start and end
    const node_data = this.nodes.get(nodeData.nodes[0]);
    if (node_data.id == 9999 || node_data.id == 0) {
      this._toastr.success('', 'Start and End cannot be Removed', this._toastrUtility.basic_configuration);
      this.nodes.update(node_data); //  required to prevent buttons from disapprearing
    } else {
      // delete node and all connecting edges
      this.selected_node_for_deletion = nodeData;
      $('.node-delete-confirmation').show();
    }
  }

  node_delete_confirmation() {
    this.nodes.remove(this.nodes.get(this.selected_node_for_deletion.nodes[0]));
    this.edges.forEach((edge) => {
      if ($.inArray(edge.id, this.selected_node_for_deletion.edges) !== -1) {
        this.edges.remove(edge);
      }
    });
    this.emitted_event();
    this.close_node_deletion_confirmation();
  }

  close_node_deletion_confirmation() {
    $('.node-delete-confirmation').hide();
  }

  disable_node(id) {
    let disable = false;
    for (let i = 0; i < this.disable_node_id_list.length; i++) {
      if (this.disable_node_id_list[i] == id) {
        disable = true;
        break;
      }
    }
    return disable;
  }

  // Edges Code

  add_new_edge(data) {
    //To show model againg uncomment the line below &&  comment this.update_edge_data();
    // $('.edit-edge-data').show();
    this.selected_edge = data;
    this.update_edge_data();
  }

  restrict_edges(edge_data) { // VIS
    // start and end cannot be connected directly
    // Node Cannot connect to itself
    if (edge_data.to == this.start_id ||
        edge_data.to == edge_data.from ||
        edge_data.from == this.end_id ||
        (edge_data.to == this.end_id && edge_data.from == this.start_id)) {
      return 0;
    } else {
      return edge_data;
    }
  }

  allow_connection_types(edgeData) {
    switch(this.node_connection_type) {
      case 'parallel' :
        if(edgeData.from == this.start_id || edgeData.to== this.end_id) {
          return true;
        } else {
          this._toastr.warning('', 'Task can be connected only in parallel', this._toastrUtility.basic_configuration);
          return false
        }
      case 'both':
        return true
      break;
    }
    return true;
  }

  edge_delete_confirmation() {
    // console.log(this.edges.get(edgeData.edges[0]));debugger
    const edge_info = this.edges.get(this.selected_edge_for_deletion.edges[0]);
    const to_node = this.nodes.get(edge_info.to);
    if (to_node.type == 'decision') {
      to_node.previous_node_json = undefined;
      this.nodes.update(to_node);
      this.edges.forEach((edge) => {
        if (edge.from == to_node.id) {
          // console.log(edge);

          this.edges.remove(edge);
          // edge.decision_data = undefined;
          // this.edges.update(edge);
        }
      });
        // for(var i=0; i<this.edges.length; i++){
        //   // if(this.edges[i].type == 'decision'){
        //     console.log(this.edges);
        //   // }
        // }
      // clear all decision information in following nodes/edges
      // console.log('nodes/edges');
    } else {
      // do nothing
    }
    // delete edge
    this.edges.remove(this.selected_edge_for_deletion.edges[0]);
    this.close_edge_deletion_confirmation();
    this.emitted_event();
  }

  close_edge_deletion_confirmation() {
    $('.edge-delete-confirmation').hide();
  }

  // Other Function
  allowDrop(ev) {
    ev.preventDefault();
  }

  drop(ev) {
    ev.preventDefault();
    this.new_node = true;
    this.selected_node = {};
    this.node_type = this.drag_element_id;
    // this.duration = 1;
    this.days = 0;
    this.hours = 0;
    this.minutes = 0;
    $('.edit-node-data').show();
    // this.drag_data('draggable');
    $('#node_label').focus();
  }

  emitted_event() {
    const data = {
      nodes: this.nodes.get(),
      edges: this.edges.get()
    };
    this.json_data.emit(data);
  }

  // Function that activate on double click on any node,and edge or in free area
  double_click_event(network) { //  VIS
    const self = this;
    network.on('doubleClick', function (properties) {
      if (properties.nodes.length == 0 && properties.edges.length == 0) { // create new node
        if (self.mode != 'full-view') {
          self.new_node = true;
          $('.edit-node-data').show();
          $('#node_label').focus();
          self.position = properties.pointer.canvas;
          self.node_data_id = [];
          self.individual_node_data = {};
          self.selected_node = {};
          self.individual_provider_name = '';
          self.individual_provider_division = '';
          self.group_division_id = -1;
          self.group_provider_id = -1;
        }
      } else if (properties.nodes.length != 0) { // update the selected node
        self.new_node = false;
        const nodeID = properties.nodes[0];
        const nodeData = self.nodes.get(nodeID);
        const disable = self.disable_node(nodeData.id);
        if (!disable) {
          $('.edit-node-data').show();
          $('#node_label').focus();
          self.update_node_by_double_click(nodeData, properties.pointer.canvas);
        }

      } else if (properties.nodes.length == 0 && properties.edges.length != 0) { // Update the selected edge
        if (self.mode != 'full-view') {
          const edgeID = properties.edges[0];
          const edgeData = self.edges.get(edgeID);
          if (edgeData.edge_type == 'decision') {
            $('.edit-decision-edge-data').show();
            $('#builder').queryBuilder('destroy');
            $('#builder').queryBuilder({            
              filters: self.nodes.get(edgeData.from).previous_node_json,
            });
            // $('#builder').queryBuilder('reset');
            if(edgeData.decision_data != undefined){
              $('#builder').queryBuilder('setRules', edgeData.decision_data);
            } else {
              // not required - since destroyed above
              $('#builder').queryBuilder('reset');
            }
            self.decision_data = edgeData.decision_data;
            self.decision_label = edgeData.label;
          } else {
            $('.edit-edge-data').show();
            self.edge_pre_emtive = edgeData.preemptive;
          }
          self.selected_edge = edgeData;
        }
      }

    });
  }

  update_node_by_double_click(data, position) { //  VIS with Updates or fill data
    this.selected_node = data;
    this.node_label = data.activity_name;
    this.node_type = data.type;
    this.position = position;
    this.days = data.duration.days;
    this.hours = data.duration.hours;
    this.minutes = data.duration.minutes;
    this.start_duration = data.start_duration? data.start_duration: {days:0,hours:0,minutes:0};
    this.assign_to = data.assign_to? data.assign_to: null;
    this.dispatch_to = data.dispatch_to? data.dispatch_to: null;
    this.approval_required = data.approval_required && data.approval_required == 1? true: false;
    if(data.external_reference_1 && data.external_reference_1 != '') {
      this.external_reference_1 = [data.external_reference_1]
    } else {
      this.external_reference_1 = []
    }
    this.assign_or_dispatch = data.assign_or_dispatch? data.assign_or_dispatch: 'assign';

    // for (let index = 0; index < this.classification_data.length; index++) {
    //   if(this.classification_data[index]['valus_value'] == this.task_function_type){
    //     this.task_function_type_id = this.classification_data[index]['id']
    //   }
    // }
    this.taskClassification.populate_data_by_values(taskClassificationDeserializerById(data));
    // this.set_class(1);
    // this.populate_next_dropdown(2);
    // this.task_function_type = data.task_function_type;
    // this.task_function_subtype = data.task_function_subtype;
  }

  close() {
    this.hide_node_modal();
    // this.id_exist = false;
    // this.node_id = null;
    this.clear_form();
    this.new_node = true;
    // this.node_data_list = [];
    this.selected_node = {};
  }

  // fixed_nodes(node_id_list) {
  //   for (let i = 0; i < node_id_list.length; i++) {
  //     for (let j = 0; j < this.nodes.length; j++) {
  //       if (node_id_list[i] == this.nodes[i].id) {
  //         this.nodes[i]['fixed'] = {
  //           x: true,
  //           y: true
  //         };
  //       }
  //     }
  //   }
  // }

  node_color(node_type) {
    return this.colors[this.node_shape[node_type]['color']];
  }


  // Event for chart type = service
  onSelectService(event) {
    console.log(this.selected_service);
    this.node_label = this.selected_service['name'];
  }

  // get_all_workgroups(){
  //   this._commonService.get_user_workgroups({'wrkgrp_type': 'Task'}).subscribe(res => {
  //     if(res.errCode == 0) {
  //       this.workgroup_list = res.msg;
  //     }
  //   });
  // }

  // onSelectIndividual(value, current_case, division_id) {
  //   // console.log(value);
  //   this.provider_individual_schedule.map( provider => {
  //     if (provider.practitioner_id == value) {
  //       this.individual_provider_division_list = provider.divisions;
  //     }
  //   });
  //   if (current_case == 'update') {
  //     this.individual_provider_division = division_id;
  //   }
  //   // this.individual_provider_division_list = 
  //   // this.individual_node_data = event;
  // }

  // onSelectDivision(event) {
  //   // console.log(event.target.value);
  // }

  // onSelectIndividualInGroup(value, current_case, class_id) {
  //   // console.group(value);
  //   this.provider_group_schedule.map( provider => {
  //     if (provider.provider_id == value) {
  //       this.group_class_list = provider.classes;
  //     }
  //   });
  //   if (current_case == 'update') {
  //     this.group_division_id = class_id;
  //   }
  //   // console.log(this.group_division_id);
  //   // console.log(class_id);
  // }

  validate_vis_graph() {
    const nodes = this.nodes.get();
    const edges = this.edges.get();
    const node_id = [];
    const to_edges = [];
    const from_edges = [];
    nodes.map(node => {
      if (node.id != 0 && node.id != 9999) {
        node_id.push(node.id);
      }
    });
    edges.map(edge => {
      if (edge.to != 9999) {
        to_edges.push(edge.to);
      }
      if (edge.from != 0) {
        from_edges.push(edge.from);
      }
    });
    if (node_id.length == 0 && to_edges.length == 0 && from_edges.length == 0) {
      this._toastr.warning('', 'Add some Task', this._toastrUtility.basic_configuration);
      return false;
    } else if (node_id.length <= to_edges.length && node_id.length <= from_edges.length) {
      return true;
    } else {
      this._toastr.warning('', 'All Tasks should be connected', this._toastrUtility.basic_configuration);
      return false;
    }
  }

  validate() {

    switch(this.node_type){
      case 'individual':
      case 'group':
        if ( this.node_label == ''){
          // this.snotifyService.error("All fields are Mandatory", this.utilityService.getConfig());
          return false;
        }
      break;
      case 'assessment':
      case 'video':
        if (this.node_data_id.length == 0 ||  this.node_label == ''){
          // this.snotifyService.error("All fields are Mandatory", this.utilityService.getConfig());
          return false;
        }
      break;
      case 'task':
        if ( this.node_label == ''){
          // this.snotifyService.error("All fields are Mandatory", this.utilityService.getConfig());
          this._toastr.error('', 'Task Name is mandatory', this._toastrUtility.basic_configuration);
        return false;
      }
      const temp_duration = {
        days: this.days,
        hours: this.hours,
        minutes: this.minutes
      };
      if(!this.validation_duration(temp_duration,'Duration')) {
        return false;
      }
      if(!this.validation_duration(this.start_duration,'Start After')) {
        return false
      }
      if(!this.taskClassification.validate_task_classification_payload()) {
        return false;
      }
      // if(this.task_function_type == '' ) {
      //   this._toastr.error('', 'Please fill Task Function Type ', this._toastrUtility.basic_configuration);
      //   return false;
      // }
      // if(this.task_function_subtype == '' ) {
      //   this._toastr.error('', 'Please fill Task Function Sub Type ', this._toastrUtility.basic_configuration);
      //   return false;
      // }

        // if (this.days==null || this.hours==null || this.minutes==null) {
        //   this._toastr.error("Duration of task should be filled")
        //   return false;
        // }
        // const time = this.days + this.hours + this.minutes;
        // if (time <= 0) {
        //   // this.snotifyService.error("All fields are Mandatory", this.utilityService.getConfig());
        //   this._toastr.error('', 'Duration of Task cannot be Zero', this._toastrUtility.basic_configuration);
        //   return false;
        // }
      break;
      default: 
        // do nothing
      break;
    }
    return true;

  }

  validation_duration(data, type) {
    if (data.days==null || data.hours==null || data.minutes==null) {
      this._toastr.error(type +" of task should be filled")
      return false;
    }
    const time = data.days + data.hours + data.minutes;
    if(type == 'Duration') {
      if (time <= 0) {
        this._toastr.error('', type+' of Task cannot be Zero', this._toastrUtility.basic_configuration);
        return false;
      }
    }else if(type == 'Start After') {
      if (time < 0) {
        this._toastr.error('', type+' of Task cannot be negative', this._toastrUtility.basic_configuration);
        return false;
      }
    }
    return true;
  }

  clear_form() {
    this.node_label = '';
    this.node_type = 'task';
    // this.duration = 1;
    this.days = 0;
    this.hours = 0;
    this.minutes = 0;
    this.start_duration = {
      days: 0,
      hours: 0,
      minutes: 0
    }
    this.assign_to = null;
    this.dispatch_to = null;
    this.approval_required = true;
    this.external_reference_1 = [];
    this.assign_or_dispatch = 'assign';
    this.taskClassification.clear_form();
    this.taskClassification.reset_dropdowns();
    // this.task_function_subtype = "";
    // this.task_function_subtype_id = -1;
    // this.task_function_type = "";
    // this.task_function_type_id = -1;
  }

  clear_chart_data() {
    this.nodes = null;
    this.edges = null;
  }

  // update_node_data() {

  //   // if(this.validate())

  //   if (this.validate()) {
  //     $('.edit-node-data').hide();
  //     let current_shape = this.node_shape[this.node_type]['shape'];
  //     let current_icon = this.node_shape[this.node_type]['icon'];
  //     let current_color = this.node_color(this.node_type);
  //     // console.log(current_color);
  //     // console.log(this.selected_node);debugger
  //     if (this.selected_node['id'] == undefined) {
        
  //       var labl = this.node_label + '\n(' + this.duration + ' Days)';
  //       if (this.node_type == 'decision') labl = this.node_label;

  //       var node_obj = {
  //         margin: 10,
  //         // id: this.node_id,
  //         label: labl,
  //         activity_name: this.node_label,
  //         type: this.node_type,
  //         shape: current_shape,
  //         color: current_color,
  //         icon: current_icon,
  //         x: this.position.x,
  //         y: this.position.y,
  //         duration: this.duration,
  //         late_submission: this.node_late_submission
  //       };
  //       // switch
  //       if (this.node_type == 'group') {
  //         node_obj['node_data_id'] = this.group_division_id;
  //         node_obj['book_automatic'] = this.book_automatic;
  //         node_obj['group_provider_id'] = this.group_provider_id;
  //         this.nodes.update(node_obj);
  //       } else if (this.node_type == 'individual') {
  //         node_obj['node_data_id'] = {
  //           'division_id': this.individual_provider_division,
  //           'practitioner_id': this.individual_provider_name
  //         };
  //         // node_obj['individual_node_data'] = this.individual_node_data;
  //         node_obj['individual_provider_name'] = this.individual_provider_name;
  //         node_obj['individual_provider_division'] = this.individual_provider_division;

  //         this.nodes.update(node_obj);
  //       } else {
  //         node_obj['node_data_id'] = this.node_data_id;
  //         this.nodes.update(node_obj);
  //       }

  //     } else {
  //       this.selected_node.shape = current_shape;
  //       this.selected_node.color = current_color;
  //       this.selected_node.icon = current_icon;
  //       this.selected_node.activity_name = this.node_label;
  //       this.selected_node.type = this.node_type;
  //       this.selected_node.node_data_id = this.node_data_id;
  //       this.selected_node.duration = this.duration;
  //       this.selected_node.label = this.node_label + '\n(' + this.duration + ' Days)';
  //       if (this.node_type == 'group') {
  //         this.selected_node.book_automatic = this.book_automatic;
  //       } else if (this.node_type == 'individual') {
  //         // this.selected_node.individual_node_data = this.individual_node_data;
  //         this.selected_node.individual_provider_name = this.individual_provider_name;
  //         this.selected_node.individual_provider_division = this.individual_provider_division;
  //       }
  //       this.nodes.update(this.selected_node);
  //       this.selected_node = {};
  //     }
      
  //     this.node_label = '';
  //     this.node_data_id = [];
  //     this.duration = 1;
  //     this.individual_node_data = {};
  //     // this.node_late_submission = false;
  //     // this.new_node = false;
  //   }
  // }

  // async get_assessment_questions(assessment_id){
  //   var data = await this._commonService.get_assessment_metadata({"assessment_id": assessment_id}).toPromise();
  //   console.log(data.msg);
  //   return data.msg;
  // }

  add_new_edge_old(data) {
    // console.log(data);
    if (this.nodes.get(data.to).type == 'decision') {
      data['type'] = 'default';
      data['color'] = this.colors[this.edge_shape[data['type']]['color']];
      const edge_case = this.validate_decision_edge(data);
      if (edge_case == 'true') {
        var temp_to_node_data = this.nodes.get(data.to);
        // temp_to_node_data['previous_node_json'] = this.nodes.get(data.from).node_data_id;

        var assessment_id = this.nodes.get(data.from).node_data_id;
        // this._commonService.get_assessment_metadata({"assessment_id": assessment_id}).subscribe(questions => {
        //   console.log(questions);
        //   // function to generate filters / operators
        //   temp_to_node_data['previous_node_json'] = questions.msg;
        //   this.nodes.update(temp_to_node_data);

        //   $('.edit-edge-data').show();
        //   this.selected_edge = data;
        // });

      } else if (edge_case == 'connection') {
        // this.snotifyService.error('Only assessment and decision can be connected', this.utilityService.getConfig());
      } else if (edge_case == 'one_edge') {
        // this.snotifyService.error('Only one edge can be connect between assessment and decision', this.utilityService.getConfig());
      }
    } else if (this.nodes.get(data.from).type == 'decision') {
      data['type'] = 'decision';
      data['color'] = this.colors[this.edge_shape[data['type']]['color']];
      var previous_node_json = this.nodes.get(data.from).previous_node_json;
      // console.log(previous_node_json);debugger
      if(previous_node_json != undefined){
        $('#builder').queryBuilder('destroy');
        $('#builder').queryBuilder({
          filters: previous_node_json
        });
        $('#builder').queryBuilder('reset');
        $('.edit-decision-edge-data').show();
        this.selected_edge = data;
        this.decision_label = '';
        
        // show empty queryBuilder
        // this._commonService.get_assessment_metadata({"assessment_id": assessment_id}).subscribe(questions => {
        //   // console.log(questions);debugger
          
        // });
          
      } else {
        // this.snotifyService.error('Decision node needs to be connected first.', this.utilityService.getConfig());
      }
    } else {
      // data['type'] = 'default';
      // data['color'] = this.colors[this.edge_shape[data['type']]['color']];
      $('.edit-edge-data').show();
      this.selected_edge = data;
    }
  }

  validate_decision_edge(new_edge_data) {
    const fromNodeData = this.nodes.get(new_edge_data.from);
    const toNodeData = this.nodes.get(new_edge_data.to);

    const all_edges_list = this.edges.get();
    // Assessment that are already connected to decision;
    const assessment_decision = [];
    for (let i = 0; i < all_edges_list.length; i++) {
      if (this.nodes.get(all_edges_list[i].from).type == 'assessment' &&
          this.nodes.get(all_edges_list[i].to).type == 'decision') {
            assessment_decision.push(this.nodes.get(all_edges_list[i].from));
      }
    }

    if (toNodeData.type == 'decision' && fromNodeData.type != 'assessment') {
      return 'connection';
    }
    for (let i = 0; i < assessment_decision.length; i++) {
      if (assessment_decision[i].id == fromNodeData.id && toNodeData.type == 'decision') {
        // alert();
        return 'one_edge';
      }
    }
    return 'true';
  }

  update_edge_data() {
    $('.edit-edge-data').hide();
    this.selected_edge.preemptive = this.edge_pre_emtive;
    if (this.edge_pre_emtive == true) {
      this.selected_edge.type = 'preemptive';
      this.selected_edge.color = this.colors.preemptive_color;
      // this.selected_edge.label = 'Preemptive';
      // this.selected_edge.font = this.colors.preemptive_color;
      // this.selected_edge['color'] = this.colors[this.edge_shape[this.selected_edge['type']]['color']];
    } else {
      this.selected_edge.type = 'non_preemptive';
      this.selected_edge.color = this.colors.non_preemptive_color;
      // this.selected_edge.label = 'Non Preemptive';
      // this.selected_edge.font = this.colors.non_preemptive_color;
      // this.selected_edge['color'] = this.colors[this.edge_shape[this.selected_edge['type']]['color']];
    }
    this.edges.update(this.selected_edge);
    const data = {
      nodes: this.nodes.get(),
      edges: this.edges.get()
    };
    this.json_data.emit(data);
    this.edge_pre_emtive = true;
  }

  update_decision_edge_data() {
    // add decision_data to edge from queryBuilder
    var result = $('#builder').queryBuilder('getRules');
    if (!$.isEmptyObject(result)) {
      // console.log((result));
      this.decision_data = result;
      $('.edit-decision-edge-data').hide();
      this.selected_edge.label = this.decision_label;
      this.selected_edge.decision_data = this.decision_data;
      this.selected_edge.edge_type = 'decision';
      this.edges.update(this.selected_edge);
      this.decision_data = [];

    } else {
      alert("Incomplete form");
    }


    
  }

  close_edit_configuration() {
    $('.edit-edge-data').hide();
  }

  close_decision_configuration() {
    $('.edit-decision-edge-data').hide();
  }

  validate_graph() {
    const node_edge_map = [];
    const all_nodes = this.nodes.get();
    const all_edges = this.edges.get();

    const graph_nodes = [];
    var graph_data = {}


    for (var i=0;i<all_nodes.length; i++){
      graph_nodes.push(all_nodes[i].id);
      graph_data[all_nodes[i].id] = [];
    }

    
    for (let i=0;i<graph_nodes.length; i++) {
      for (var j=0;j<all_edges.length; j++){

        if (all_edges[j].from ==  graph_nodes[i]) {
          graph_data[graph_nodes[i]].push(all_edges[j].to);
        }

      }
    }

    var dfs_nodes = this.do_dfs(graph_data);

    const connectivity_check = this.compare_nodes(graph_nodes, dfs_nodes);
    return connectivity_check;
    // console.log(connectivity_check);

  }

  do_dfs(graph_data) {
    var source_node= 0;
    var stack = [];

    stack.push(source_node);
    var neighbors = [];
    var traversed_nodes = [];
    traversed_nodes.push();

    var top = -9999;
    var my_check = 0;

    var visited_nodes = [];
    var check_flag = false;
    traversed_nodes.push(source_node);
    while (stack.length > 0){

      my_check += 1;
      if (my_check > 100){
        break;
      }

      for (var i=0;i<visited_nodes.length;i++) {
        if (top == visited_nodes[i]) {
          check_flag = true;
        }
      }

      if (check_flag) {
        continue;
      }

      top = stack[stack.length-1];
      neighbors = graph_data[top];
      // console.log(top);
      // console.log(neighbors);
      // traversed_nodes.push(top);

      stack.pop();

      for (var i=0;i<neighbors.length;i++){
        traversed_nodes.push(neighbors[i]);
        stack.push(neighbors[i]);
      }
      


    }
    return traversed_nodes;
  }

  compare_nodes(graph_nodes, dfs_nodes) {
    var sorted_graph_nodes = graph_nodes.sort();
    var sorted_dfs_nodes = dfs_nodes.sort();

    // console.log(sorted_dfs_nodes);
    // console.log(sorted_graph_nodes);

    if (sorted_dfs_nodes.length != sorted_graph_nodes.length){
      return false;
    }

    for (var i =0;i<sorted_dfs_nodes.length; i++) {
      if (sorted_dfs_nodes[i] != sorted_graph_nodes[i]){
        return false;
      }
    }

    return true;
  }

  remove_edges() {
    const all_edges = this.edges.get();
    for(let i =0 ; i< all_edges.length; i++) {
      if(all_edges[i].from != this.start_id) {
        this.edges.remove(all_edges[i].id);
      }
    }
  }

  get_member_mame_from_id() {

  }

  get_workgroup_name_from_id() {

  }

  is_editable() {
    if(this.mode == 'full-view') {
      return false;
    }
    return true;
  }

  tags_event(event) {
    this.external_reference_1 = event.all;
  }

  // add_cluster_id(id) {
  //   const data = this.get_childs_with_and_without_child(id)
  //   console.log(data);
  //   for(let  i =0 ; i< data.without.length; i++) {
  //     data.without[i]['cid'] = this.clusterIndex;
  //     this.nodes.update(data.without[i]);
  //   }
  //   for(let  i =0 ; i< data.with.length; i++) {
  //     this.clusterIndex++;
  //     this.add_cluster_id(data.with[i]['id']);
  //   }
  // }

  // get_childs_with_and_without_child(id) {
  //   const final_node = {
  //     with:[],
  //     without:[]
  //   };
  //   const connected_node = this.vis_network.getConnectedNodes(id,'to')
  //   for(let  i =0 ; i< connected_node.length; i++) {
  //     const connected_to_nodes = this.vis_network.getConnectedNodes(connected_node[i],'to')
  //     if(connected_to_nodes.length) {
  //       final_node.with.push(this.nodes.get(connected_node[i]));
  //       // this.child_recursion(this.nodes.get(connected_to_nodes))
  //     } else {
  //       final_node.without.push(this.nodes.get(connected_node[i]));
  //     }
  //   }
  //   return final_node;
  // }

  clusterIndex = 1;
  temp_nodes = [];
  get_third_level_nodes(network,id) {
    let  connected_to1 = this.nodes.get(network.getConnectedNodes(id,'to'));
    for(let  i =0 ; i< connected_to1.length; i++) {
      this.temp_nodes.push(connected_to1[i])
      const connected_to2 =  this.nodes.get(network.getConnectedNodes(connected_to1[i]['id'],'to'))
      for(let  k =0 ; k< connected_to2.length; k++) {
        const connected_to3 =  this.nodes.get(network.getConnectedNodes(connected_to2[k]['id'],'to'))
        this.temp_nodes.push(connected_to2[k])
        if(connected_to3.length) {
          this.add_cluster_id_recursive(network,connected_to3)
          this.clusterIndex++;
        }
      }     
    }
  }

  add_cluster_id_recursive(network,connected_to2) {
    for(let  j =0 ; j< connected_to2.length; j++) {
      connected_to2[j]['cid'] = this.clusterIndex;
      // this.nodes.update(connected_to2[j]);
      this.temp_nodes.push(connected_to2[j])
      const connected_to_recur =  this.nodes.get(this.vis_network.getConnectedNodes(connected_to2[j]['id'],'to'))
      if(connected_to_recur.length) {
        this.add_cluster_id_recursive(network,connected_to_recur)
      } 
    }
  }


  makeClusters() {
    // this.get_third_level_nodes_v2(338,1)
    var clusterOptionsByData;
    for (var i = 1; i <this.clusterIndex; i++) {
      clusterOptionsByData = {
        joinCondition: function (childOptions) {
          return childOptions.cid == i; 
        },
        processProperties: function (clusterOptions, childNodes, childEdges) {        
          // var totalMass = 0;
          var childrenCount = 0;
          for (var i = 0; i < childNodes.length; i++) {
            childrenCount += childNodes[i].childrenCount || 1;
            // totalMass += childNodes[i].mass;
          }
          clusterOptions.childrenCount = childrenCount;
          clusterOptions.label = "+ " + childrenCount;
          // clusterOptions.mass = totalMass;
          return clusterOptions;
        },
        clusterNodeProperties: {
          id: "cluster:" + i,
          borderWidth: 3,
          shape: "database",
        },
      };
      this.vis_network.cluster(clusterOptionsByData);
    }
	}

  // openClusters(scale) {
  //   var newClusters = [];
  //   var declustered = false;
  //   for (var i = 0; i < this.clusters.length; i++) {
  //     if (this.clusters[i].scale < scale) {
  //       this.vis_network.openCluster(this.clusters[i].id);
  //       this.lastClusterZoomLevel = scale;
  //       declustered = true;
  //     } else {
  //       newClusters.push(this.clusters[i]);
  //     }
  //   }
  //   this.clusters = newClusters;
  // }

}



