import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { SharedService } from 'src/app/dce-service/shared.service';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss']
})
export class EmailInputComponent implements OnInit, OnChanges {

  @Input() email_case: string = '';
  @Input() initialData?: any = [];
  @Input() is_multiple?: boolean = true;
  @Input() maxSelectedItems?: Number = 10;
  @Input() placeholder?: string = 'Select';
  @Input() notFoundText?: string = 'No items found';
  @Input() is_disable?: boolean = false;
  @Input() get_users?: boolean = true;

  @Output() selected_email_data_event?: EventEmitter<any> = new EventEmitter<any>();

  email_data: any = [];
  selected_email = [];

  constructor(
    private _commonService: CommonService,
    private _sharedService: SharedService
  ) { }

  ngOnInit() {
    if (this.get_users) {
      this.get_emails_data()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialData) {
      this.selected_email = changes.initialData.currentValue;
    }
  }

  get_emails_data() {
    const payload = {}
    this._commonService.get_all_users_for_filters(payload).subscribe(res => {
      if (res.errCode == 0) {
        this.email_data = res.msg.map(user => {return user.email} )
      }
    });
  }

  addTagFn(name) {
    return name;
  }

  onAddEmail(name) {
    this.selected_email_data_event.emit({
      'emails': this.selected_email
    });
  }

  onRemoveEmail(event) {
    this.selected_email_data_event.emit({
      'emails': this.selected_email
    });
  }

  onClearEmail() {
    this.selected_email_data_event.emit({
      'emails': this.selected_email
    });
  }
}
