import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, HostListener, Output, Inject } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-side-container',
  templateUrl: './side-container.component.html',
  styleUrls: ['./side-container.component.scss']
})
export class SideContainerComponent implements OnInit {
  @Input() container_width: string;
  @Output() side_div_events = new EventEmitter();
  @Input() container_title = '';
  @Input() unique_id? = '';
  @Input() date_visible?:boolean=false;
  @Input() date?:Date=null;
  @Input() disable_external_area: boolean = false;

  constructor(
    private _elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit() {
  }

  openContainer() {
    $('#sideContainer' + this.unique_id).css('box-shadow', '-8px 0px 9px 1px #d1d1d1');
    $('#sideContainer' + this.unique_id).width(this.container_width);
    // $('#main').css('background-color', 'rgba(0,0,0,0.4)');
    if(this.disable_external_area){
      this.set_pointer_events()
    }
  }

  closeContainer() {
    this.side_div_events.emit({"type": "close"})
    $('#sideContainer' + this.unique_id).css('border', '0');
    $('#sideContainer' + this.unique_id).width('0');
    // $('#main').css('background-color', 'white');
    if(this.disable_external_area){
      this.remove_pointer_events()
    }
  }

  set_pointer_events() {
    $('#sideContainer' + this.unique_id).css('pointer-events', 'auto');
    this.document.body.classList.add('pe-none');
  }
  
  remove_pointer_events() {
    $('#sideContainer' + this.unique_id).css('pointer-events', 'auto');
    this.document.body.classList.remove('pe-none');
  }
  

}
