import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';

@Component({
  selector: 'app-chatbot-history',
  templateUrl: './chatbot-history.component.html',
  styleUrls: ['./chatbot-history.component.scss']
})
export class ChatbotHistoryComponent implements OnInit {
  @Input() session_id:any = null;
  @Input() case:any = 'chatbot';
  @Input() user_data:any =null
  @Output() chatbot_history_events: EventEmitter<any> = new EventEmitter();
  decoreMessage = 'Loading...';
  constructor(
    public _liveChatService: LiveChatService,
    private _commonService:CommonService
  ) { }
  messages=[];
  ngOnInit() {
    if(this.session_id){
      // this.get_chatbot_history();
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.session_id && changes.session_id.currentValue != null) {
      this.decoreMessage = 'Loading...';
      this.messages=[];
      this.get_chatbot_history();
    }
  }

  get_chatbot_history(){
    let payload = {}
    switch(this.case){
      case 'chatbot':
        payload = {
          "chatsession_guid":this.session_id
        }
        this._commonService.get_chatbot_history(payload).subscribe(res => {
          if(res.errCode == 0){
          this.messages=res.datarec.infolog;
          this.chatbot_history_events.emit({"type":'get_chatbot_source',"datarec": {'source':res.source}});
          this.decoreMessage = 'No message available';
          }
        })
        break;
      case 'livechat':
        payload = {
          "chatsession_id":this.session_id
        }
        this._liveChatService.get_chat_history(payload).subscribe(res =>{
          if(res.errCode == 0){
            this.messages = res.data;
            this.decoreMessage = 'No message available';
          }
        });
        break;
    }
  }
}
