import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { EnvService } from 'src/app/dce-service/env.service';

@Component({
  selector: 'app-filter-rule-type',
  templateUrl: './filter-rule-type.component.html',
  styleUrls: ['./filter-rule-type.component.scss']
})
export class FilterRuleTypeComponent implements OnInit {

  @Input() rule_type:string = ""  //action_rule, dispatch_rule, notification_rule
  @Input() initial_data_list=[]

  @Output() filtered_data_for_table = new EventEmitter<any>();
  @Output() refresh_data_for_table = new EventEmitter<any>();
  @Output() togglee = new EventEmitter<any>();  // sending apply filter clicked condition to parent components

  wf_obj_list=[]
  fields_list=[]
  selected_rule_type="";
  selected_field="";
  value_by_user=""
  flag=false

  rule_type_list=[]
  business_object_list=[]
  notif_business_object_list=[]

  global_initial_data_list = []

  constructor(public _commonService: CommonService,
    private _env: EnvService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService) { }

  ngOnInit() {
    this.get_classification_data()
    this.get_field_data()
    this.switch_object_list()
    this.global_initial_data_list = JSON.parse(JSON.stringify(this.initial_data_list));
  }

  switch_object_list(){
    switch(this.rule_type) {
      case 'action_rule':
        this.wf_obj_list=this.rule_type_list
        break;
      case 'dispatch_rule':
        this.wf_obj_list=this.business_object_list
        break;
      case 'notification_rule':
        this.wf_obj_list=this.notif_business_object_list
        break;
    }
  }

  apply_filters(){
    if(this.selected_rule_type!=""){
      this.flag=true
      this.togglee.emit(this.flag)
      let data_for_datatable=[]
      const json_data={
        'user_rule_type':this.selected_rule_type,
        'user_field':this.selected_field,
        'user_value':this.value_by_user
      }
      data_for_datatable=this._commonService.filter_data_in_table(this.initial_data_list, json_data, this.rule_type)
      this.filtered_data_for_table.emit(data_for_datatable)
    }else {
      this._toastr.error('', 'Please fill Rule Type', this._toastrUtility.basic_configuration);
    }
  }

  toggle_filter() {
    this.flag=false
    $('#case-filters').toggle()
  }

  get_field_data(){
    this.selected_field=""
    this.value_by_user=""
    const obj_type= this.selected_rule_type
    const payload = {
      "object_type": obj_type
    };
    switch(this.rule_type) {
      case 'action_rule':
        this._commonService.get_workflow_action_decision_data(payload).subscribe(res => {
          if(res.errCode == 0){
            this.fields_list = res.msg;
          }
        });
        break;
      case 'dispatch_rule':
        this._commonService.get_dispatch_decision_data(payload).subscribe(res => {
          if(res.errCode == 0){
            this.fields_list=res.msg
          }
        })
        break;
      case 'notification_rule':
        this._commonService.get_notification_decision_data(payload).subscribe(res => {
          if(res.errCode == 0){
            this.fields_list=res.msg.fire_after
          }
        })
        break;
    }
  }

  refresh_data(){
    this.selected_rule_type="";
    this.selected_field="";
    this.value_by_user=""
    this.fields_list=[]
    this.flag=true
    let refresh_data=[]
    switch(this.rule_type) {
      case 'action_rule':
        this._commonService.get_all_workflow_actions({}).subscribe(res=>{
          if(res.errCode == 0){
            refresh_data = res.msg
            this.refresh_data_for_table.emit(refresh_data)
          }
        })
        break;
      case 'dispatch_rule':
        this._commonService.get_all_dispatch_definition({}).subscribe(res=>{
          if(res.errCode == 0){
            refresh_data = res.msg
            this.refresh_data_for_table.emit(refresh_data)
          }
        })
        break;
      case 'notification_rule':
        this._commonService.get_all_notification_definition({}).subscribe(res=>{
          if(res.errCode == 0){
            refresh_data = res.msg
            this.refresh_data_for_table.emit(refresh_data)
          }
        })
        break;
    }
  }

  get_classification_data() {
    const payload = {
      'classification_type_id': [this._env.dropdown_list.workflow_action_object, this._env.dropdown_list.dispatch_business_object, this._env.dropdown_list.business_object] ,
      'classification_value_id': [-1,-1,-1]
    };
    this._commonService.get_particular_classification_data(payload).subscribe(res=>{
      if (res.errCode == 0) {
        for (let i = 0; i < res.msg.length; i++) {
          if (res.msg[i].classification_type == this._env.dropdown_list.workflow_action_object) {
            this.rule_type_list = res.msg[i].data;
          }else if (res.msg[i].classification_type == this._env.dropdown_list.dispatch_business_object) {
            this.business_object_list = res.msg[i].data;
          }else if (res.msg[i].classification_type == this._env.dropdown_list.business_object) {
            for (let j = 0; j < res.msg[i].data.length; j++) {
              if (res.msg[i].data[j].value2parent_value == res.msg[i].root_value_id) {
                this.notif_business_object_list.push(res.msg[i].data[j])
              }
            }
          } 
        }
      }
    });
    
  }
}
