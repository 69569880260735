import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonObject } from './common-object';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class AltProjectService {

  constructor(
		protected http: HttpClient,
		protected env: EnvService,
  ) { }

  // Get first 100 individual customers
  private get_first_hundred_customer_url = `${this.env.service_url}/individuals/get_all`;
  get_first_hundred_customer(payload) {
    return this.http.post<any>( this.get_first_hundred_customer_url , payload);
  }

  private get_individual_customer_data_url = `${this.env.service_url}/individuals/get`;
  get_individual_customer_data(payload) {
    return this.http.post<any>( this.get_individual_customer_data_url , payload);
  }

  public create_individual_customer_url = `${this.env.service_url}/individuals/create`;
  create_individual_customer(payload) {
    return this.http.post<any>( this.create_individual_customer_url , payload);
  }	

  public update_individual_customer_url = `${this.env.service_url}/individuals/update`;
  update_individual_customer(payload) {
    return this.http.post<any>( this.update_individual_customer_url , payload);
  }

  private remove_individual_customer_url = `${this.env.service_url}/individuals/remove`;
  remove_individual_customer(payload) {
    return this.http.post<any>( this.remove_individual_customer_url , payload);
  }

  public search_individual_customer_url = `${this.env.service_url}/individuals/search_individual`;
  search_individual_customer(payload) {
    return this.http.post<any>( this.search_individual_customer_url , payload);
  }

  public check_id_exits_url = `${this.env.service_url}/individuals/check_exist`;
  check_id_exits(payload) {
    return this.http.post<any>( this.check_id_exits_url , payload);
  }

  public _get_all_faq_url = `${this.env.service_url}/cmm/get_all`;
  get_all_faq(payload) {
    return this.http.post<any>(this._get_all_faq_url, payload);
  }

  // biz_org/check_reg_num_exist : check if company exists using reg number

  // Knowledge Management
  private get_all_contents_url = `${this.env.service_url}/cmm/get_all`;
  get_all_contents(payload) {
    return this.http.post<any>( this.get_all_contents_url , payload);
  }

  public create_content_url = `${this.env.service_url}/cmm/create`;
  create_content(payload) {
    return this.http.post<any>( this.create_content_url , payload);
  }
  
  public update_content_url = `${this.env.service_url}/cmm/update`;
  update_content(payload) {
    return this.http.post<any>( this.update_content_url , payload);
  }

  private remove_content_url = `${this.env.service_url}/cmm/remove`;
  remove_content(payload) {
    return this.http.post<any>( this.remove_content_url , payload);
  }

  private set_status_url = `${this.env.service_url}/cmm/set_status`;
  set_status(payload) {
    return this.http.post<any>( this.set_status_url , payload);
  }

  private get_all_content_object_url = `${this.env.service_url}/cmm/content_object/get_all`;
  get_all_content_objects(payload) {
    return this.http.post<any>( this.get_all_content_object_url , payload);
  }

  private create_content_object_url = `${this.env.service_url}/cmm/content_object/create`;
  create_content_objects(payload) {
    return this.http.post<any>( this.create_content_object_url , payload);
  }

  private mark_active_content_objects_url = `${this.env.service_url}/cmm/content_object/mark_active`;
  mark_active_content_objects(payload) {
    return this.http.post<any>( this.mark_active_content_objects_url , payload);
  }

  private get_content_type_data_url = `${this.env.service_url}/cmm/get`;
  get_content_type_data(payload) {
    return this.http.post<any>( this.get_content_type_data_url , payload);
  }

  private get_search_data_url = `${this.env.service_url}/cmm/search_data`;
  get_search_data(payload) {
    return this.http.post<any>( this.get_search_data_url , payload);
  }

  private get_multiple_tag_search_data_url = `${this.env.service_url}/cmm/multiple_tag/search_data`;
  get_multiple_tag_search_data(payload) {
    return this.http.post<any>( this.get_multiple_tag_search_data_url , payload);
  }

}
