import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-ongoing-chat-header',
  templateUrl: './ongoing-chat-header.component.html',
  styleUrls: ['./ongoing-chat-header.component.scss']
})
export class OngoingChatHeaderComponent implements OnInit {
  @Input() chat_state:any;

  @Output() end_chat = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }
  close_chat(){
    this.end_chat.emit({event:'close_chat'});
  }

  cancel_chat() {
    this.end_chat.emit({event:'cancel_chat'});
  }
}
