import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { EnvService } from 'src/app/dce-service/env.service';

@Component({
  selector: 'app-pagniated-datatable',
  templateUrl: './pagniated-datatable.component.html',
  styleUrls: ['./pagniated-datatable.component.scss']
})
export class PagniatedDatatableComponent implements OnInit {

  @Input() _case :string = 'default';
  @Input() resetPage = false;
  @Input() datalist?: any = [];
  @Input() columns?: any = [];
  @Input() number_of_records?:number = 10;
  @Input() total_records?:number = 10;
  @Input() loadingIndicator = true;
  @Input() datatable_height?:any = 'calc(100vh - 340px)';
  @Input() current_page?:number = 0;

  @Input() table_message?: any = {emptyMessage: 'There are no records'};
  @Output() button_click_event: EventEmitter<any> = new EventEmitter<any>();  
  @Output() dt_selected_event: EventEmitter<any> = new EventEmitter<any>();  

  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }
  total_pages = 0;
  page_num_list = [];

  dt_selected = [];

  // parent -> input param value set happens at what point in it's child?

  constructor(
    private _commonService: CommonService,
    private _env: EnvService,
  ) {
    this.page.pageNumber = 0;
    this.page.size = this.number_of_records;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    // if (changes.id) {
    //   if (changes.id.currentValue != null && changes.id.currentValue != -1){
    //     this.id = changes.id.currentValue;
    //     this.setPage({ offset: 0, limit:this.number_of_records });
    //     // this.get_workflow_objects_for_wrkgrp(changes.id.currentValue);
    //   } else {
    //     this.off_load_indicator();
    //   }
    // }

    // if (changes.active_tab) {
    //   if (changes.active_tab.currentValue != ''){
    //     if(this.id != null && this.id != -1) {
    //       this.change_wfobj_data_list(changes.active_tab.currentValue,false);
    //     } else {
    //       this.off_load_indicator();
    //     }
    //   }
    // }
      if (changes.number_of_records) {
        this.page.size = changes.number_of_records.currentValue;
      }
      // if (changes.total_records) {
      //   this.total_records = changes.total_records.currentValue;
      // }
      
      if(changes.datalist) {
          this.total_pages = Math.ceil(this.total_records/ this.page.size)
          this.page_num_list = Array(this.total_pages).fill(0).map((x,i)=>i);
          this.page.totalElements = this.total_records
      }
      if(changes._case) {
          this.page.pageNumber = 0;
      }
      if (changes.resetPage) {
        if (changes.resetPage.currentValue) {
            this.page.pageNumber = 0;
        }
      }

      if(changes.current_page) {
        this.page.pageNumber = changes.current_page.currentValue;
      }
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    this.page.pageNumber =pageInfo.offset;
    this.button_click_event.emit({page_number:pageInfo.offset})
    if(1) {
    // if(this.id != null && this.id != -1) {
      // this.change_wfobj_data_list(this.active_tab,true);
    } else {
      this.off_load_indicator();
    }
  }

  onChangePage(value) {
    this.setPage({offset:parseInt(value)})
  }

  reset_load_indicator() {
    this.loadingIndicator=true
  }

  off_load_indicator() {
    this.loadingIndicator=false
  }

  clear_dt_selection() {
    this.dt_selected = []
  }

  dt_item_select (value_to_compare,key_to_compare) {
    // this.dt_selected = this._commonService.select_row(this.wfobj_data_list,value_to_compare,key_to_compare)
    console.log(this.dt_selected)
  } 

  onActivate(event) {
    this.dt_selected_event.emit(event);
  }

  getRowClass(row) {
    return ''
  }
}

