import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { KnowledgeSearchFaqComponent } from 'src/app/dce-views/knowledge-management-search/knowledge-search-faq/knowledge-search-faq.component';
import { KnowledgeSearchAllComponent } from 'src/app/dce-views/knowledge-management-search/knowledge-search-all/knowledge-search-all.component';
import { WizardComponent } from '../../../shared/components/form-wizard/wizard/wizard.component';

declare var $: any;
@Component({
  selector: 'app-knowledge-search',
  templateUrl: './knowledge-search.component.html',
  styleUrls: ['./knowledge-search.component.scss']
})
export class KnowledgeSearchComponent implements OnInit, AfterViewInit {
  @ViewChild(KnowledgeSearchFaqComponent, { static: false }) KnowledgeSearchFaqComponent: KnowledgeSearchFaqComponent;
  @ViewChild(KnowledgeSearchAllComponent, { static: false }) KnowledgeSearchAllComponent: KnowledgeSearchAllComponent;
  @ViewChild(WizardComponent, {static: false}) wizardComponent: WizardComponent;
  @Input() default_search_string?: any = [];

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    $('#search').focus();
  }

  // ngOnChanges(changes: SimpleChanges): void{
  //   if(changes.default_search_string){
  //     if (this.wizardComponent !== undefined) {
  //       this.wizardComponent.goToStep(this.wizardComponent.steps[0])
  //     }
  //     if(this.KnowledgeSearchAllComponent !== undefined){
  //       this.KnowledgeSearchAllComponent.tags_string = this.default_search_string.join(' ');   
  //       this.KnowledgeSearchAllComponent.get_complete_data();
  //       this.KnowledgeSearchAllComponent.complete_search.setValue('');
  //     }
  //   }
  // }

  step_changed(event) {
    switch(event.title) {
      case 'FAQ': 
        // this.KnowledgeSearchFaqComponent.faq_search.setValue('');
        this.KnowledgeSearchFaqComponent.clear_faq_search()
        setTimeout(()=>{
          $('#search_faq').focus();
        },100)
        if(!this.KnowledgeSearchFaqComponent.faq_list.length  && !this.KnowledgeSearchFaqComponent.global_faq_list.length){
          this.KnowledgeSearchFaqComponent.get_all_faqs()
        }
      break;
      case 'Knowledge Base':
        // this.KnowledgeSearchAllComponent.complete_search.setValue('');
        setTimeout(()=>{
          $('#search').focus();
        },100)
        this.KnowledgeSearchAllComponent.clear_all_search();
        this.KnowledgeSearchAllComponent.tags_string = '';
        this.KnowledgeSearchAllComponent.all_data = [];
        this.KnowledgeSearchAllComponent.search = false;
        this.KnowledgeSearchAllComponent.global_complete_list = [];
        this.KnowledgeSearchAllComponent.complete_list = [];
      break;
      default:
        // Do nothing
      break;
    }
  }
}
