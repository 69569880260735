import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/dce-service/common.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { SipService } from '../sip.service';
declare var $:any;
@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.scss']
})
export class CallHistoryComponent implements OnInit {
  loadingIndicator = false;
  table_message = {emptyMessage: 'There are no records'};

  cols = [
    {name:'Disposition',prop:'action',use_date_pipe:false,width:300},
    {name:'Full Name',prop:'agent_full_name',use_date_pipe:false,width:200},
    {name:'Start Time',prop:'start_time',use_date_pipe:true,width:100},
    {name:'End Time',prop:'end_time',use_date_pipe:true,width:100},
    {name:'Incoming Number',prop:'incoming_number',use_date_pipe:false,width:200},
    // {name:'Despostion',prop:'reason_name',use_date_pipe:false,width:200},
  ]
  agent_list:any = []
  selected:any;
  agent_keyword = 'full_name'
  agent_name = ''
  search_payload = {
    'agent_user_id':-1,
    'date':null
  }

  call_history = []
  constructor(
    private _commonsService:CommonService,
    public _shared:SharedService,
    public _env:EnvService,
    private _sipService:SipService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { 
    this.initializeComp()
  }

  ngOnInit() {
    this.get_agent_list()
  }

  initializeComp(){  
    let date = new Date()    
    this.search_payload['date'] = date.toISOString().split('T')[0];
    this.search_payload['agent_user_id'] = this._shared.getValue('user_id')
    this.agent_name = this._shared.getValue('user_details')['full_name']
    this.filter_history()
  }

  get_agent_list(){
    this._commonsService.get_telephony_agent_list({}).subscribe(res => {
      if(res.errCode == 0){
        this.agent_list = res.data
      }
    })
  }

  
  completer_select(event){
    if(event != null){
      this.agent_name = event.full_name
      this.search_payload.agent_user_id = event.id
    }else{
      this.agent_name = ''
      this.search_payload.agent_user_id = -1
    }
  }

  filter_history(){
    if(this.search_payload['date'] && this.agent_name != ''){
      this._commonsService.get_call_history(this.search_payload).subscribe(res => {
        if(res.errCode == 0){
          this.call_history = res.data
          for (let i = 0; i < this.call_history.length; i++) {
            this.call_history[i]['reason_bk'] =  this.call_history[i]['reason_code']
          }
          this.call_history = [...this.call_history]
          if(this.call_history.length == 0){
            this._toastr.error("", "No Logs Found.", this._toastrUtility.basic_configuration);
          }
        }
      })
    }
  }

  check_call_id(row){
    let payload = {
      "telephone_call_id":row.telephone_call_id
    }
    this._sipService.check_dispo_allowed(payload).subscribe(res => {
      if(res.errCode == 0 && res['datarec']['is_outcome_allowed']){
        if(row.call_id){
          if(row.reason_code){
            this.patch_disposition(row.call_id,row)
          }
        }else{
          this.get_call_id(row)
        }
      }else{
        let mins = this._env.dispostion_minutes
        this._toastr.error("", "Cannot set disposition for calls "+mins+" minutes ago or older records", this._toastrUtility.basic_configuration);
      }
    })
  } 

  get_call_id(row){
    if(row['telephone_call_id']){
      let payload = {
        'start_time':row['start_time'],
        'user_id':row['user_id'],
        'telephone_call_id':row['telephone_call_id'],
      }
      this._commonsService.get_call_id(payload).subscribe(res => {
        if(res.errCode == 0){
          this.patch_disposition(res.datarec['call_id'],row)
        }else{
          row.reason_code = row.reason_bk
        }
      })
    }else{
      this._toastr.error("", "Cannot set Disposition.Call Id not Found", this._toastrUtility.basic_configuration);
      row.reason_code = row.reason_bk
    }
  }
  patch_disposition(call_id,row) {
      const payload = {
        action: "calloutcome",
        callid: call_id,
        outcome: row.reason_code,
        server: ""
      };
      this._sipService.unpause_agent(payload).subscribe(res => {
        if (res.resultStatus == "OK") {
          this._toastr.success("", "Set Disposition Successfully.", this._toastrUtility.basic_configuration);
          this.add_disposition_code(row.telephone_call_id,row.reason_code,row.user_id)
          row.reason_bk = row.reason_code
        } else {
          this._toastr.error("", "Failed to set Disposition.", this._toastrUtility.basic_configuration);
          row.reason_code = row.reason_bk
        }
      })
  } 

  add_disposition_code(call_id,reason_code,user_id) {
    const payload = {
      telephone_call_id: call_id,
      reason_code: reason_code,
      user_id:user_id,
    };
    this._sipService.telephony_log_call('add_disposition_code_user', payload).subscribe(res => {
      if (res.errCode === 0) {
      }
    });
  }
}
