import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, AfterViewInit} from '@angular/core';
import { CommonService } from '../../dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FilterJson } from '../../dce-service/filter_json'
import { AutocompleteComponent } from 'angular-ng-autocomplete/lib/autocomplete/autocomplete.component';
import { EnvService } from 'src/app/dce-service/env.service';
import { CustomValidators } from 'src/app/dce-service/custom-validators.component';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit,AfterViewInit {
	filter_list:any;
	operator:any;
	@Input() object_type:String;
    @Output() filter_event: EventEmitter<any> = new EventEmitter<any>();
    // public filter_child = {}
    @ViewChildren('autocomplete') autocomplete:QueryList<AutocompleteComponent>;
    autocomplete_obj = {}
    // @ViewChild('creator_full_name', {static: false}) filter_child['creator_full_name'];
    // @ViewChild('prev_owner_full_name', {static: false}) filter_child['creator_full_name'];
	payload :any= {}
	mandatory:any= {}
	form_show = true;
	new_filter_list = [];
	classification_heirarchy = [];
	classification_data = [];
	class_dropdown = []
	classification_root = -1;
	task_function_type_id = -1;
	task_function_subtype_id = -1;

	callService = {
		'get_users_list': async function(self,i){
			await self.commonService.get_all_users_for_filters({}).toPromise().then(res => {
				if(res.errCode == 0){
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg
					}
				}
			});
		},
		'get_user_workgroups':async function(self,i){
			await self.commonService.get_user_workgroups({"wrkgrp_type": self.object_type}).toPromise().then(res => { 
				if (res.errCode == 0) { 
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg
					}
				}
			})
		},
		'get_all_workgroups':async function(self,i){
			await self.commonService.get_all_workgroups({"wrkgrp_type": self.object_type}).toPromise().then(res => { 
				if (res.errCode == 0) { 
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg
					}
				}
			})
		},
		'get_all_biz_org':async function(self,i){
			await self.commonService.get_all_biz_org({"columns": ['id','company_name']}).toPromise().then(res => { 
				if (res.errCode == 0) { 
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg
					}
				}
			})
		},
		'get_all_potential_biz_org':async function(self,i){
			await self.commonService.get_all_biz_org({"columns": ['id','company_name'],"company_role_type": ['employer','potential_employer']}).toPromise().then(res => { 
				if (res.errCode == 0) { 
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg
					}
				}
			})
		},
		'get_task_classification_dropdown':async function(self){
			await self.commonService.get_task_classification_dropdown({}).toPromise().then(res => { 
				if (res.errCode == 0) {
					self.classification_heirarchy = res.parent_children_dict;
					self.classification_data = res.msg;
					self.classification_root = res.root_id
					self.populate_next_dropdown(1);
				}
			})
		},
		'get_classification_value_data':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.wfobj_status],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z < i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},

		'get_classification_mode_value_data':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.case_classification_type],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z < i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},
		'get_classification_value_data_advice':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.advice_status],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},
		'get_all_case_status':async function(self,i){
			await self.commonService.get_all_case_status({}).toPromise().then(res => { 
				if (res.errCode == 0) {
					// self.filter_list[i]['data'] = res.data 
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.data
					}
				}
			})
		},
		'get_request_approval_status':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.request_approval_status],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z < i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},
		'get_classification_value_data_meeting_type':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.meeting_type],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},
		'get_classification_value_data_meeting_channel':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.channel],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},
		'get_classification_value_data_meeting_status':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.meeting_status],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},
		'get_all_task_type':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.task_type],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},
		'get_all_task_deadline':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.task_deadline],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z< i.length ;z++){
						let data = res.msg[0]['data']
						let retd = []
						for(let i=0;i<data.length;i++){
							retd.push({'value':data[i]['value_value'],'key':data[i]['value_name']})
						}
						self.filter_list[i[z]]['data'] = retd
					}
				}
			})
		},
		'get_all_task_status':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.task_status],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					for(let z=0;z< i.length ;z++){
						self.filter_list[i[z]]['data'] = res.msg[0]['data']
					}
				}
			})
		},
	}

	validations = {
		'validate_case_id':(id) => {
			const res = CustomValidators.validateCaseId(id);
			if(res == null){
				return true;
			}
			this._toastr.error('', res.validateCaseId.message , this._toastrUtility.basic_configuration)
			return false;
		}
	} 

	process_and_validate_time(key1,key2,_case=''){
		let new_payload = {};
		let self = this;
		if(self.payload[key1] != '' && self.payload[key2] != ""){
			let start_date = self.payload[key1] + 'T00:00';
			let end_date = self.payload[key2] + 'T23:59';
			if(start_date >= end_date){
				switch(_case) {
					case 'meeting':
						self._toastr.error('', 'To Date cannot be less than From Date', self._toastrUtility.basic_configuration);
						break;
					default:
						self._toastr.error('', 'End Date cannot be less than Start Date', self._toastrUtility.basic_configuration);
						break;
				}
				return false;  
			}else{
				new_payload[key1] = start_date
				new_payload[key2] =end_date
			}
		}else{
			if(self.payload[key1] != ''){
				new_payload[key1] = self.payload[key1] + 'T00:00';
			}
			if(self.payload[key2] != ""){
				new_payload[key2] =self.payload[key2] + 'T23:59';
			}
		}	
		return this.process_multiselect(new_payload);	
	}
	process_multiselect(new_payload){
		for (var i = this.filter_list.length - 1; i >= 0; i--) {
			if(this.filter_list[i]['type'] == 'multiselect'){
				new_payload[this.filter_list[i].key] = []
				for (var j = this.payload[this.filter_list[i].key].length - 1; j >= 0; j--) {
					let temp = this.payload[this.filter_list[i].key][j]
					new_payload[this.filter_list[i].key].push(temp)
				}
			}
		}
		return new_payload
	}
	validation_function = {
		'case': 
			function(self){
				let new_payload = self.process_and_validate_time('from_case_createtime','to_case_createtime');
				for(let i in self.payload){
					if(new_payload[i] == undefined){
						new_payload[i] = self.payload[i] 	
					}
				}	
				return new_payload
			},
		'task':
			function(self){
				let new_payload = self.process_and_validate_time('from_task_create_time','to_task_create_time');
				for(let i in self.payload){
					if(new_payload[i] == undefined){
						new_payload[i] = self.payload[i] 	
					}
				}	
				return new_payload
			},
		'advice':
			function(self){
				let new_payload = self.process_and_validate_time('advice_create_time','advice_last_change_time');
				for(let i in self.payload){
					if(new_payload[i] == undefined){
						new_payload[i] = self.payload[i] 	
					}
				}	
				return new_payload
			},
		'lead':
			function(self){
				let new_payload = self.process_and_validate_time('lead_createtime','lead_lastchange_time');
				for(let i in self.payload){
					if(new_payload[i] == undefined){
						new_payload[i] = self.payload[i] 	
					}
				}	
				return new_payload
			},
			
		'meeting':
			function(self){
				let new_payload = self.process_and_validate_time('meeting_start_date','meeting_start_date2', 'meeting');
				for(let i in self.payload){
					if(new_payload[i] == undefined){
						new_payload[i] = self.payload[i] 	
					}
				}	
				return new_payload
			},
	}
	constructor(
		private _fb: FormBuilder,
		private _fj: FilterJson,
		protected commonService: CommonService,
		protected env: EnvService,
		private _toastr: ToastrService,
		private _toastrUtility: ToastrUtilityService,
	) {

	}

	ngOnInit() {
		this.initializeFilter();
	}
	filter_call_services = {}

	call_all_filter_services(){
		let services = Object.keys(this.filter_call_services)
		for (var i = services.length - 1; i >= 0; i--) {
			this.callService[services[i]](this,this.filter_call_services[services[i]]);
		}
	}

	initializeFilter(){
		this.filter_list = this._fj.filters[this.object_type.toLowerCase()];
		this.operator = this._fj.operator
		for (var i = this.filter_list.length - 1; i >= 0; i--) {
			let filter = JSON.parse(JSON.stringify(this.filter_list[i]))
			// filter['type'] != 'completer' -> Added as because completer can handle API internally
			if(filter['service'] != null && filter['type'] != 'completer'){
				if(Object.keys(this.filter_call_services).indexOf(filter['service']) != -1){
					this.filter_call_services[filter['service']].push(i)
				}else{
					this.filter_call_services[filter['service']] = [i]
				}
				// this.callService[filter['service']](this,i);
			}
			if(filter['type'] == 'task_function'){
				this.callService['get_task_classification_dropdown'](this);
			}
			if(filter['op']){
				this.operator[filter['key']]['op'] = filter['op']
			}
			this.payload[filter['key']] = filter['default_val'];
			this.mandatory[filter['key']] = filter['mandatory'];
		}
		this.call_all_filter_services()

	}
	ngAfterViewInit(){
		$('#item_0').focus();
		let auto_list = this.autocomplete.toArray()
		for (var i = auto_list.length - 1; i >= 0; i--) {
			this.autocomplete_obj[auto_list[i]['elementRef']['nativeElement']['id']] = auto_list[i]
		}
	}
	populate_next_dropdown(level){
	    var sel_id;
	    if(level != undefined){
	      sel_id = this.task_function_type_id;
	    }
	    if(sel_id == -1){
	      sel_id = this.classification_root;
	    }
	    var child_ids = [];
	    switch(level) {
	      case 1:
	      case 2:
	        for(i=level; i<=4; i++){
	          this.class_dropdown['level_'+i] = [];
	        }
	        child_ids = this.classification_heirarchy[sel_id];
	        for(var i=0; i<this.classification_data.length; i++){
	          if($.inArray(this.classification_data[i]['id'], child_ids) !== -1){
	            this.class_dropdown['level_'+level].push(this.classification_data[i]);
	          }
	        }
	        if(this.class_dropdown['level_'+level].length == 1){
	          this.populate_next_dropdown(level+1);
	        }
	        break;
	      }
	}
	set_class(level){
	    for (let index = 0; index < this.class_dropdown['level_'+level].length; index++) {
	      const element = this.class_dropdown['level_'+level][index];
	      if(level == 1 && element.value_value.toLowerCase() == this.payload['task_function_type'].toLowerCase()){
	        this.task_function_type_id = this.class_dropdown['level_'+level][index]['id']
	        break;
	      }else if(level == 2 && element.value_value.toLowerCase() == this.payload['task_function_type'].toLowerCase()){
	        this.task_function_subtype_id = this.class_dropdown['level_'+level][index]['id']
	        break;
	      }
	    }
	}
	completer_select(event,filter_key,data_key){
		this.payload[filter_key] = event[data_key]
	}

	get_typeOf(data){
		return typeof data
	}
	get_value_from_key(id,key){
		for(let i=0;i<this.filter_list.length;i++){
			if(this.filter_list[i]['key'] == id){
				return this.filter_list[i][key]
			}
		}
		return null
	}
	onItemSelect(item: any) {

	}
	process_payload(payload){
		if(payload){
			let sent_payload = {};
			for (var i in payload) {
			  if((i != "wrkgrp_id" && payload[i] != "") || (i == "wrkgrp_id" && payload[i].length >0)){
					if(i == 'wrkgrp_id'){
						let dk = this.get_value_from_key(i,'data_id_key')
						if(dk != null){
							for(let x = 0;x<payload[i].length;x++){
								payload[i][x] = payload[i][x][dk]
							}
						}
					}
					if(i == 'task_planned_end_date_status'){
						let cdate = new Date()
						let cd = cdate.toISOString().split('T')[0] + 'T23:59'
						let cd0 = cdate.toISOString().split('T')[0] + 'T00:00'
						if(payload[i] == 'Overdue'){
							sent_payload['task_planned_end_date'] = {
				       'operator': '<',
				       'data': cd0,
				       'type': 'str'
				    	}
						}else if(payload[i] == 'Approaching Deadline in Next 15 Days'){
							let cdate15 = new Date(cdate.setDate(cdate.getDate() + 15));
							let cd15 = cdate15.toISOString().split('T')[0]+'T23:59'
							sent_payload['task_planned_end_date'] = {
				       'operator': 'between_op',
				       'data': [cd0,cd15],
				       'type': 'str'
				    	}
						}else if(payload[i] == 'Approaching Deadline in Next 30 Days'){
							let cdate30 = new Date(cdate.setDate(cdate.getDate() + 30));
							let cd30 = cdate30.toISOString().split('T')[0]+'T23:59'
							sent_payload['task_planned_end_date'] = {
				       'operator': 'between_op',
				       'data': [cd0,cd30],
				       'type': 'str'
				    	}
						}
					}else if(this.operator[i].op.indexOf('like') != -1){
				    sent_payload[i] = {
				       'operator': this.operator[i].op,
				       'data': "%"+payload[i]+"%",
				       'type': this.operator[i].type
				    }

			  	}else{
				    sent_payload[i] = {
				       'operator': this.operator[i].op,
				       'data': payload[i],
				       'type': this.operator[i].type
				     }
				    if(i == 'prev_owner_full_name' && this.object_type == 'Task'){
				    	sent_payload['wfobj_status'] = {
				       'operator': this.operator['wfobj_status'].op,
				       'data': ['Closed'],
				       'type': this.operator['wfobj_status'].type
				      }
				    }
			  	}
			  }
			}
			// sent_payload["wfobj_status"] = {
			//    'operator': "==",
			//    'data': "Closed",
			//    'type': "str"
			// }
			return sent_payload;
		}else{
			return false;
		}
	}
	get_process_key_from_key(key){
		for(let i = 0;i < this.filter_list.length;i++){
			if(this.filter_list[i]['key'] == key){
				return this.filter_list[i]['process_key']
			}
		}
		return false
	}

	process_payload_v2(payload){
		if(payload){
			let sent_payload = [];
			for (var i in payload) {
			  if((i != "wrkgrp_id" && payload[i] != "") || (i == "wrkgrp_id" && payload[i].length >0)){
					if(i == 'wrkgrp_id'){
						let dk = this.get_value_from_key(i,'data_id_key')
						if(dk != null){
							for(let x = 0;x<payload[i].length;x++){
								payload[i][x] = payload[i][x][dk]
							}
						}
					}
					if(i == 'task_planned_end_date_status'){
						let cdate = new Date()
						let cd = cdate.toISOString().split('T')[0] + 'T23:59'
						let cd0 = cdate.toISOString().split('T')[0] + 'T00:00'
						if(payload[i] == 'Overdue'){
							sent_payload.push({
				       'operator': '<',
				       'data': cd0,
				       'type': 'str',
				       'key':'task_planned_end_date'
				    	})
						}else if(payload[i] == 'Approaching Deadline in Next 15 Days'){
							let cdate15 = new Date(cdate.setDate(cdate.getDate() + 15));
							let cd15 = cdate15.toISOString().split('T')[0]+'T23:59'
							sent_payload.push({
				       'operator': 'between_op',
				       'data': [cd0,cd15],
				       'type': 'str',
				       'key':'task_planned_end_date'
				    	})
						}else if(payload[i] == 'Approaching Deadline in Next 30 Days'){
							let cdate30 = new Date(cdate.setDate(cdate.getDate() + 30));
							let cd30 = cdate30.toISOString().split('T')[0]+'T23:59'
							sent_payload.push({
				       'operator': 'between_op',
				       'data': [cd0,cd30],
				       'type': 'str',
				       'key':'task_planned_end_date'
				    	})
						}
					}else if(this.operator[i].op.indexOf('like') != -1){
				    sent_payload.push({
				       'operator': this.operator[i].op,
				       'data': "%"+payload[i]+"%",
				       'type': this.operator[i].type,
				       'key': this.get_process_key_from_key(i)
				    })

			  	}else{
				    sent_payload.push({
				       'operator': this.operator[i].op,
				       'data': payload[i],
				       'type': this.operator[i].type,
				       'key':this.get_process_key_from_key(i)
				     })
				    if(i == 'prev_owner_full_name' && this.object_type == 'Task'){
				    	sent_payload.push({
				       'operator': this.operator['wfobj_status'].op,
				       'data': ['Closed'],
				       'type': this.operator['wfobj_status'].type,
				       'key':'wfobj_status'
				      })
				    }
			  	}
			  }
			}
			// sent_payload["wfobj_status"] = {
			//    'operator': "==",
			//    'data': "Closed",
			//    'type': "str"
			// }
			return sent_payload;
		}else{
			return [];
		}
	}
	validate_payload(){
		let sent_payload = [];
		let form_valid = true;
		for (var i = this.filter_list.length - 1; i >= 0; i--) {
			let filter = this.filter_list[i]
			if(filter['type'] == 'autocomplete' && this.autocomplete_obj[filter['key']]['query'] == ""){
				this.payload[filter['key']] = ''
			}
			
			if(filter['mandatory']){
				if(this.payload[filter['key']] == ''){
          			this._toastr.warning('', 'Enter '+filter['label'], this._toastrUtility.basic_configuration);
					form_valid = false
				}
			}
		}
		if(form_valid){
			let _object_type = this.object_type.toLowerCase()
			sent_payload = this.process_payload_v2(this.validation_function[_object_type](this));
			if(sent_payload.length > 0){
				this.filter_event.emit({'case': 'search', 'data': sent_payload });
				this.form_show=false;
			}else{
				this._toastr.error('', 'Fill atleast 1 Filter', this._toastrUtility.basic_configuration);
			}
		}
	}

	reset_payload(){
		for (var i = this.filter_list.length - 1; i >= 0; i--) {
			let filter = this.filter_list[i]
			this.payload[filter['key']] = '';
			if(filter['type'] == 'autocomplete'){
				this.autocomplete_obj[filter['key']].clear();
				this.autocomplete_obj[filter['key']].close();
			}
			if(filter['key'] == 'multi_select_case_id'){
				filter.data = [];
				this.payload[filter['key']] = [];
			}
			if(filter['type'] == 'completer'){
				filter.completer_config.input_name = ''
			}
		}
		this.filter_event.emit({'case': 'reset', 'data': {} });
	}

	addTagFn(item){
		return item;
	}

	add_tag(event, filter){
       event = event.trim();
		const input = event;
		if(event.includes(',')){
			event = event.split(',');
			for(let i=0;i< event.length;i++){
				event[i] = event[i].trim()
				if(event[i] != '' && !filter.data.includes(event[i])){
					if(filter.validations && this.validations[filter.validations](event[i])){
						filter.data.push(event[i]);
						this.payload[filter.key].push(event[i]);						
					}
				}
			}
			filter.data = [...filter.data];
			const idx = this.payload[filter.key].indexOf(input);
			this.payload[filter.key].splice(idx,1);
			this.payload[filter.key] = [...this.payload[filter.key]]
		}else{
			if(filter.validations && this.validations[filter.validations](event)){
				if(!filter.data.includes(event)){
					filter.data.push(event);
				}
			}else{
				filter.data = [...filter.data];
				const idx = this.payload[filter.key].indexOf(input);
				this.payload[filter.key].splice(idx,1);
				this.payload[filter.key] = [...this.payload[filter.key]]
			}
			
		}
	}

	completer_v2(event,filter) {
		switch (event.case) {
		  case 'select_v2':
			if (event.data != null) {
				this.payload[filter.key] = event.data[filter.data_id_key]
				filter.completer_config.input_name = event.data[filter.data_id_key]
			}
		  break;
		  case 'clear':
			this.payload[filter.key] = ''
			filter.completer_config.input_name = ''
		  break;
		  default:
		  break;
		}
	}

}
