import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
declare var $:any;
@Component({
  selector: 'app-ongoing-chat-list',
  templateUrl: './ongoing-chat-list.component.html',
  styleUrls: ['./ongoing-chat-list.component.scss'],
})
export class OngoingChatListComponent implements OnInit {
  @ViewChild('message_scroll', {static: false}) private message_scroll: ElementRef;
  decoreMessage: string = 'LOADING...';
  @Input() live_chat_session_id:any;
  @Input() messages:any=[];
  @Input() user_data:any;

  scroll_position = 0;
  constructor(
    public _liveChatService: LiveChatService,
  ) {}

  ngOnInit() {
    const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(sess_id => sess_id.session_id == this.live_chat_session_id);
    this._liveChatService.live_chat_sessions[sessionIndex].document_to_upload_change.subscribe(response => {
      console.log(response)
      if(response) {
        if(this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload.length>0){
          this.set_document_position();
        }
      }
    })
  }

  scroll_to_bottom(){
    setTimeout(()=>{
      this.message_scroll.nativeElement.scrollTop = this.message_scroll.nativeElement.scrollHeight;
      this.scroll_position=this.message_scroll.nativeElement.scrollTop;
    },10);
  }

  set_document_position(){
    this.scroll_position=1;
    this.scroll_to_bottom();
  }

  remove_file(index){
    this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload.splice(index,1);
    this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload_change.next(true);
  }

  list_resize(){
    if(this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload.length>0){
      this.scroll_position=1;
      this.scroll_to_bottom();
    }
  }
}
