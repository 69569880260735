import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DigitalAssistantBotSetupRoutingModule } from './digital-assistant-bot-setup-routing.module';
import { DigitalAssitantBotComponent } from './digital-assitant-bot/digital-assitant-bot.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { ChatbotListComposerV1Module } from '../chatbot-list-composer-v1/chatbot-list-composer-v1.module';
@NgModule({
  declarations: [DigitalAssitantBotComponent, ChatListComponent, ChatBubbleComponent],
  imports: [
    CommonModule,
    DigitalAssistantBotSetupRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ChatbotListComposerV1Module
  ],
  exports: [DigitalAssitantBotComponent]
})
export class DigitalAssistantBotSetupModule { }
