import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementMarqueeComponent } from './announcement-marquee/announcement-marquee.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [AnnouncementMarqueeComponent],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [AnnouncementMarqueeComponent]
})
export class AnnouncementMarqueeModule { }
