import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/dce-service/env.service';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  chat_conf = this._env.chatbot_conf
  constructor(
    protected _http: HttpClient,
		protected _env: EnvService
  ) { }

  get_request_formatter(url, payload) {
    const req = `${url}?${this.convert_dict_to_param(payload)}`;
    return req;
  }

  convert_dict_to_param(payload) {
    let query_param = "";
    for (let key in payload) {
      query_param += key + "=" + payload[key] + '&'
    }
    return query_param.slice(0, -1);
  }


  private get_intent_response_url = `${this._env.chat_service_url}/get_intent_response`;
  get_intent_response(payload) {
    return this._http.post<any>(this.get_intent_response_url, payload);
  }

  private get_intent_response_for_demo_url = `${this._env.chat_service_url}/demo/chat`;
  get_intent_response_for_demo(payload) {
    return this._http.post<any>(this.get_intent_response_for_demo_url, payload);
  }

  private chat_process_demo_url = `${this._env.chat_service_url}/demo/chat`;
  chat_process_demo(paylaod: any) {
    return this._http.post<any>( this.chat_process_demo_url,paylaod)
  }

}
