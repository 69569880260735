import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, OnChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form/dynamic-form.component';
import { ConfigService } from '../config.service';
import { FontawesomeService } from '../dynamic-form/fontawesome.service';
declare var $: any;
interface ChatBubbleEmitter {
  case: String;
  data: Object;
}

@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          animate('0.3s ease-in', keyframes([
            style({ height: 0, opacity: 0, offset: 0 }),
            style({ height: '*', opacity: 1, offset: 1 })
          ]))
        ]),
        transition(':leave', [
          animate('0.3s ease', keyframes([
            style({ height: '*', opacity: 1, offset: 0 }),
            style({ height: 0, opacity: 0, offset: 1 })
          ]))
        ])
      ],
    ),
    trigger('option_transition',
    [
      transition(':enter', [
        animate('0.3s ease-in', keyframes([
          style({ height: 0, opacity: 0, offset: 0 }),
          style({ height: '*', opacity: 1, offset: 1 })
        ]))
      ]),
    ])
  ]
})
export class ChatBubbleComponent implements OnInit, OnChanges, OnDestroy {
  //use jquery approch if there are multiple forms at the same time
  // data: index, data, 
  @ViewChild('chatbotDynamicForm', {static: false}) chatDynamicForm: DynamicFormComponent;
  @Input() message: any="message"
  @Input() user_data:any;
  @Input() unique_id:string;
  @Input() message_index:number = -1;
  @Output() chat_bubble_emitter: EventEmitter<any> = new EventEmitter<ChatBubbleEmitter>();
  date_format = 'HH:mm'
  object = Object;
  selected_faq_data = {};
  isMaximized = false;
  default_km_summary = '';
  current_animated_message_index = -1;
  animated_option_interval: any
  next_animated_interval: any;
  message_type = {
    "TEXT": "text",
    "DOCUMENT":"document",
    "NOTIFICATION":"notification",
    "FORM": "form_label",
    "OPTION": "option_label",
    "FAQ": "faq"
  }
  constructor(
    private _el:ElementRef,
    private _cdr: ChangeDetectorRef,
    public configService: ConfigService,
    public _fa: FontawesomeService
  ) { 
  }

  ngOnInit() {
    this.remove_option_animation();
    if(this.message.type == this.message_type.OPTION){
      this.start_option_animation();
    }
  }

  ngOnDestroy() {
    clearInterval(this.animated_option_interval);
    this.current_animated_message_index = -1;
  }

  faq_clicked(unique_id:string){
    let response = {
      case: 'scroll_to_faq_container',
      data : unique_id,
    }
    this.chat_bubble_emitter.emit(response)
  }

  ngOnChanges(){
    this.remove_option_animation();
    if(this.message.type == this.message_type.OPTION){
      this.start_option_animation();
    }
    if(this.message.type == this.message_type.FORM && this.message.submit_form == true){
      setTimeout(()=>{
        this.submit_form();
      })
    }
  }

  start_option_animation(index = -1){
    this.current_animated_message_index = index;
    if(this.message.options.length > 0){
      this.animated_option_interval = setInterval(()=>{
        if(this.message.options.length == 0){
          this.remove_option_animation();
        }
        document.querySelectorAll('[id^="message_option_"]').forEach(element => element.classList.remove("animated_chat_options"));
        if(this.current_animated_message_index == this.message.options.length - 1){
          this.current_animated_message_index = 0;
        }else{
          this.current_animated_message_index ++;
        }
        let option_to_highlight = document.getElementById(`message_option_${this.current_animated_message_index}`);
        if(option_to_highlight){
          option_to_highlight.classList.add("animated_chat_options");
        }
      }, 1500)
    }
  }

  remove_option_animation(){
    clearInterval(this.animated_option_interval);
    clearInterval(this.next_animated_interval);
    document.querySelectorAll('[id^="message_option_"]').forEach(element => element.classList.remove("animated_chat_options"));
  }



  submit_form(){
    if(!this.chatDynamicForm){
      return;
    }
    const forms_data = this.chatDynamicForm.get_payload_values();
    if(forms_data.valid){
      let response = {
        case: 'form_submit',
        data : forms_data.response,
      }
      this.chat_bubble_emitter.emit(response)
    }
  }

  skip_form(){
    if(!this.chatDynamicForm){
      return;
    }
    const forms_data = this.chatDynamicForm.get_payload_values();
    if(forms_data.valid){
      let response = {
        case: 'form_skipped',
        data : forms_data.response,
      }
      this.chat_bubble_emitter.emit(response)
    }
  }

  capture_options(name: string, intent: number, reset_flow: number){
    let response = {
      case: 'access_options',
      data: {
        "name": name,
        "intent": intent,
        "reset_flow": reset_flow
      },
    }
    this.chat_bubble_emitter.emit(response)
  }

  capture_focus_in_event(event: object){
    let response = {
      case: 'focus_in_form',
      data: event,
    }
    this.chat_bubble_emitter.emit(response)
  }

  open_faq_container(faq){
    if(faq.content_type == 'link' || faq.content_type == 'doc-link'){
      this.redirect_to_new_window(faq.body_text)
    }else{
      this.isMaximized = false;
      this.selected_faq_data = faq;
      this.selected_faq_data = {...this.selected_faq_data}
      this._cdr.detectChanges();
      $(`#${this.unique_id}`).modal()
    }
  }

  redirect_to_new_window(url) {
    window.open(url);
  }

  show_disabled_form(show_form){
    let msg_index = show_form ? this.message_index - 1 : this.message_index;
    let response = {
      case: 'show_disabled_form',
      data : {
        'msg_index': msg_index,
        'show_form': show_form
      }
    }
    this.chat_bubble_emitter.emit(response)
  }

}
