import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/dce-service/common.service';
import { CustomValidators } from 'src/app/dce-service/custom-validators.component';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { EnvService } from 'src/app/dce-service/env.service';

@Component({
  selector: 'app-add-pot-mem',
  templateUrl: './add-pot-mem.component.html',
  styleUrls: ['./add-pot-mem.component.scss']
})
export class AddPotMemComponent implements OnInit {

  pot_mem_details: FormGroup;
  submitted_pot_mem_details = false;
  @Input() bizorg_id:BigInteger = null;
  gender_list = [];
  // @Output() pot_mem_payload: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _fb: FormBuilder,
    private _commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private _env: EnvService
  ) { }

  ngOnInit() {
    this.initialize_payload();
    this.get_classification_data()
  }

  initialize_payload () {
    this.pot_mem_details = this._fb.group({
      'first_name': this._fb.control('', Validators.required),
      'last_name': this._fb.control('', Validators.required),
      'dob':this._fb.control(null, [Validators.required , CustomValidators.birthYear]),
      'gender': this._fb.control('', Validators.required),
      'id_type': this._fb.control('National ID', Validators.required),
      'id_number': this._fb.control('', [Validators.required, CustomValidators.ID_no_format]),
      'passport_number': this._fb.control(''),
      'email': this._fb.control('', Validators.required),
      'cellphone': this._fb.control('', [Validators.required, CustomValidators.mobileNo_10]),
      'alternate_phone': this._fb.control(''),
      'alternate_email': this._fb.control(''),
      'date_of_employment': this._fb.control(null,Validators.required)
    })
  }

  get_classification_data() {
    const payload = {
      'classification_type_id': [this._env.dropdown_list.gender] ,
      'classification_value_id': [-1]
    };
    this._commonService.get_particular_classification_data(payload).subscribe(res => {
      if (res.errCode == 0) {
        for (let i = 0; i < res.msg.length; i++) {
           if (res.msg[i].classification_type == this._env.dropdown_list.gender) {
            this.gender_list = res.msg[i].data;
          }
        }
      }
    });
  }

  onSelectIdentificationType(value) {
    if(this.pot_mem_details.controls['id_type'].value == 'Passport'){
      this.pot_mem_details.controls.id_number.setValue('');
      this.pot_mem_details.get('passport_number').setValidators([Validators.required, Validators.minLength(6),Validators.maxLength(15)]);
      this.pot_mem_details.get('id_number').setValidators([]);
    } else if(this.pot_mem_details.controls['id_type'].value == 'National ID'){
      this.pot_mem_details.controls.passport_number.setValue('');
      this.pot_mem_details.get('id_number').setValidators([Validators.required, CustomValidators.ID_no_format]);
      this.pot_mem_details.get('passport_number').setValidators([]);
    }
    this.pot_mem_details.get('id_number').updateValueAndValidity();
    this.pot_mem_details.get('passport_number').updateValueAndValidity();
  }

  create_new_pot_mem_btn() {
    this.clear_pot_mem_details();
    this.toggle_pot_mem_details(true);
  }

  clear_pot_mem_details(){
    this.pot_mem_details.patchValue({
      'first_name': '',
      'last_name': '',
      'dob': null,
      'gender': '',
      'id_type': 'National ID',
      'id_number': '',
      'passport_number': '',
      'email': '',
      'cellphone': '',
      'alternate_phone': '',
      'alternate_email': '',
      'date_of_employment': null
    })
  }

  toggle_pot_mem_details(bool) {

    if (bool){
      this.pot_mem_details.enable();
    } else {
      this.pot_mem_details.disable();
    }
  }

  fill_pot_mem_details(data) {
    this.pot_mem_details.patchValue({
      'first_name': data.first_name,
      'last_name': data.last_name,
      'id_type': data.id_type,
      'id_number': data.id_number,
      'gender': data.gender,
      'dob': data.dob
    })
  }

  validate_pot_mem_detail() {
    this.submitted_pot_mem_details = true;
    if (this.pot_mem_details.status == 'VALID' || this.pot_mem_details.status == 'DISABLED') {
      return true;
    } else {
      this._toastr.error('','Please fill all mandatory fields', this._toastrUtility.basic_configuration);
    }
    return false;
  }

  // create_new_pot_mem() {
  //   const payload = this.pot_mem_details.getRawValue();
  //   if (this.validate_pot_mem_detail()) {
  //     payload['bizorg_id'] = this.bizorg_id;
  //     return {case:'create', is_valid:true, data:payload};
  //   }
  //   return {case:'create',is_valid:false, data:{}};
  // }

  fill_id_type_values(data) {
    if (data.national_id && data.national_id != null) {
      this.pot_mem_details.patchValue({
        'id_type': 'National ID',
        'national_id': data.national_id,
      })
    } else if (data.passport_number && data.passport_number != null) {
      this.pot_mem_details.patchValue({
        'id_type': 'Passport',
        'passport_number': data.passport_number,
      })
    } else {
      this.pot_mem_details.patchValue({
        'id_type': '',
        'national_id': '',
        'passport_number': '',
      })
    }
  }

  validate_dates(){
    if(this.pot_mem_details.value.dob < this.pot_mem_details.value.date_of_employment){
      return true;
    } else {
      this._toastr.error('','Date of employement should be greater than date of birth', this._toastrUtility.basic_configuration);
    }
    return false;
  }

  async create_new_pot_mem() {
    const payload = this.pot_mem_details.getRawValue();
    payload['bizorg_id'] = this.bizorg_id;
    if (this.validate_pot_mem_detail() && this.validate_dates()) {
      if(this.pot_mem_details.value.id_type === 'National ID'){
        const national_id_payload = {
          'national_id': this.pot_mem_details.value.id_number
        };
        var nval = await this._commonService.get_potential_member_from_national_id(national_id_payload).toPromise(); 
        if (nval.errCode == 0) {
          if (nval.status == false) {
            return {case:'create', is_valid:true, data:payload};
          } else {
            this._toastr.warning('','National ID already exists', this._toastrUtility.basic_configuration);
            return {case:'create',is_valid:false, data:{}};
          }
        }
      }
      else{   
        const passport_payload = {
          'passport_number': this.pot_mem_details.value.passport_number
        };
        var pval = await this._commonService.get_potential_member_from_passport_number(passport_payload).toPromise(); 
        if (pval.errCode == 0) {
          if (pval.status == false) {
            return {case:'create', is_valid:true, data:payload};
          } else {
            this._toastr.warning('','Passport Number already exists', this._toastrUtility.basic_configuration);
            return {case:'create',is_valid:false, data:{}};          
          }
        }
      }
    }
    return {case:'create',is_valid:false, data:{}};
  }

}
