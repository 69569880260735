import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { BusinessObjectWorkflowService } from 'src/app/dce-service/business-object-workflow.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';

@Component({
  selector: 'app-bus-wrkflw-forms-ans',
  templateUrl: './bus-wrkflw-forms-ans.component.html',
  styleUrls: ['./bus-wrkflw-forms-ans.component.scss']
})
export class BusWrkflwFormsAnsComponent implements OnInit {

  workflow_forms = [];
  busobj_instance_id = null;
  task_forms_config = {
    is_wf_processed: false,
  }

  constructor(
    private _commonService: CommonService,
    private _busObjWorkflowService: BusinessObjectWorkflowService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  get_workflow_xfields(busobj_instance_id: number) {
    this.clear_forms();
    this.busobj_instance_id = busobj_instance_id;
    const payload = {
      busobj_instance_id: busobj_instance_id
    };
    this._busObjWorkflowService.get_workflow_xfields(payload).subscribe(res => {
      if (res.errCode === 0) {
        this.form_parser(res.data);
      }
    })
  }

  clear_forms() {
    this.workflow_forms = [];
    this.busobj_instance_id = null;
    this.task_forms_config = {
      is_wf_processed: false
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  form_parser(data) {
    for (let stage = 0; stage < data.length; stage++) {
      for (let task = 0; task < data[stage].task_list.length; task++) {
        // assign parsed_survey key in task instance
        data[stage].task_list[task].parsed_survey = this.onTaskChange(data[stage].task_list[task]);
      }
    }
    console.log(data);
    this.workflow_forms = data;
    this.task_forms_config.is_wf_processed = true;
  }

  onTaskChange(task_ins) {
    console.log(task_ins);
    const parsed_ans_list = [];
    const task_form = task_ins;
    const form_answers = task_form.form_answers;
    if (form_answers !== null && Object.keys(form_answers).length) {
      for (let j = 0; j < task_form.form_data.pages.length; j++) {
        for (let k = 0; k < task_form.form_data.pages[j].elements.length; k++) {
          for (let elementVal in task_form.form_data.pages[j].elements[k]) {
            for (let answer_key in form_answers) {
              // console.log('anskey', answer_key, 'element', task_form.form_data.pages[j].elements[k])
              if (task_form.form_data.pages[j].elements[k][elementVal] === answer_key) {
                const answer = form_answers[answer_key];
                task_form.form_data.pages[j].elements[k].result_value = answer;
                task_form.form_data.pages[j].elements[k].result_display_value = this.json_result_type_parser(task_form.form_data.pages[j].elements[k]);
                parsed_ans_list.push(task_form.form_data.pages[j].elements[k]);
              }
            }
          }
        }
      }
      return parsed_ans_list;
    }
    return []; // forms_answers doesnt exist.
  }

  json_result_type_parser(element) {
    let display_val = null;
    const answer_key = element.result_value;
    const literal = {
      text: () => {
        display_val = answer_key;
      },
      rating: () => {
        display_val = answer_key;
      },
      comment: () => {
        display_val = answer_key;
      },
      radiogroup: () => {
        display_val = this.extract_text_from_choices(element.choices, answer_key);
      },
      checkbox: () => {
        display_val = this.extract_text_from_choices(element.choices, answer_key);
      },
      dropdown: () => {
        display_val = this.extract_text_from_choices(element.choices, answer_key);
      },
      boolean: () => {
        display_val = answer_key ? element.labelTrue : element.labelFalse;
      },
      file: () => {

      }
    }
    if (literal[element.type]) {
      literal[element.type](this);
    }
    return display_val;
  }

  extract_text_from_choices(choices, result_val) {
    let display_val = ''
    for (let i = 0; i < choices.length; i++) {
      if (typeof (choices[i]) == 'object') {
        if (result_val.includes(choices[i].value)) {
          display_val = display_val + ' ' + choices[i].text;
        }
      } else {
        if (result_val.includes(choices[i])) {
          display_val = display_val + ' ' + choices[i];
        }
      }
    }
    return display_val == '' ? result_val : display_val;
  }

  open_link(filepath) {
    window.open(filepath, '_blank');
  }

}
