import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EnvService } from 'src/app/dce-service/env.service';
import { LiveChatService, live_chat_session } from 'src/app/dce-service/live-chat.service';
import { SocketService } from 'src/app/dce-service/socket.service';
import { defaultOutcomeValue, welcomeMessage } from '../const';

@Component({
  selector: 'app-socket',
  templateUrl: './socket.component.html',
  styleUrls: ['./socket.component.scss']
  // providers:[SocketService,LiveChatService]
})
export class SocketComponent implements OnInit {

  constructor(
    private socket: SocketService,
    public _liveChatService: LiveChatService,//chat store
    private _env: EnvService
  ) { }

  ngOnInit() {
  }

  initiate_chat_connection() :any {
    const data = this.socket.initiate_connection({
      "user_id": this._liveChatService.live_chat_store.agent_id, // TO be used later if required
      "user_type": 'agent',
      "user_name": this._liveChatService.live_chat_store.agent_username,
    });
    this._liveChatService.update_network_status('ONLINE');
    this.socket_subscribe();
    return data;
  }

  join_session(chat_session_id,agent_data) {
    this.socket.socket_event('join_room', chat_session_id, agent_data);
  }

  socket_subscribe(){
    this.socket.get_data_all().subscribe(res => {
      this.socket_event_handler(res);
    });
  }

  socket_session_close() {
    // this disconnects from the server
    this.socket.io.close();
  }
  
  public socket_event_handler(res):void {
    if(res.event_type != 'typing') {
      console.log(res);
    }
    switch (res.event_type) {
      // console.log(res)
      case 'join_room':
      break;
      case 'connect_agent_to_customer':
        const user_data = {
          user_name: this._env.live_chat_agent_name
        }
        let data = { type: "text", message: welcomeMessage , user_data: user_data}
        this.socket.socket_event('data_in_all', res.session_id, data);
        // this.join_session(res.session_id,res.agent_data);
        this._liveChatService.call_connect_audio();
        const temp_data : live_chat_session= {
          'message_data':[],
          'session_id': res.session_id,
          'chatbot_session_id': res.chatbot_session_id,
          "client_data":res.agent_data,
          "notification_count":0,
          "customer_data": res.customer_data,
          // "chatbot_session_id": res.chatbot_session_id,
          "connection_status": 'connected',
          'current_chat_outcome':'',
          'customer_inactivity_timer': 0,
          'customer_inactivity_interval':null,
          'enable_agent_end_chat': false,
          'end_chat_confirmation': false,
          'chat_outcome_timer': "0",
          'chat_outcome_interval': null,
          'chat_outcome_time_completed': new BehaviorSubject(false),
          'current_screen_mode': 'chat',
          'chat_linked_data': {
            "case_id": "",
            "case_table_id": "",
            "customer_id": "",
            "company_name": "",
            "link_type": ""
          },
          "typing_conf": {
            "is_typing": false,
            "typing_message": ""
          },
          'document_to_upload': [],
          'document_to_upload_change': new BehaviorSubject(false),
          'queue_data':res.queue_data,
          'btn_loaders':{
            'save_outcome': false
          }
        }
        if(this._liveChatService.live_chat_sessions.length) {
          // Do nothing
        } else {
          this._liveChatService.selected_session = temp_data;
          this._liveChatService.selected_session_index = 0;
        }
        this._liveChatService.live_chat_sessions.push(temp_data)
        this._liveChatService.live_chat_store.lifecycle_status = 'chat-on';
        this._liveChatService.manipulate_socket_event_data(res);
        this._liveChatService.start_pause_timer(res.session_id);
      break;
      case 'disconnect':
        const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == res.room);
        this._liveChatService.live_chat_sessions[sessionIndex].connection_status = 'disconnected';
        this._liveChatService.live_chat_sessions[sessionIndex].current_chat_outcome = defaultOutcomeValue;
        this._liveChatService.call_disconnect_audio();
        this._liveChatService.end_pause_timer(res.room);
        this._liveChatService.start_chat_outcome_timer(res.room);
        this._liveChatService.manipulate_socket_event_data(res);
      break;
      case 'typing':
        this._liveChatService.typing_operation(res.room,res.data);
      break;
      case 'data_in_all':  // broadcast
        this._liveChatService.live_chat_broadcast_operations(res.room,res.data);
        this._liveChatService.manipulate_socket_event_data(res);
        break;
      default:
        break;
    }
    // this.socket_events.emit(res);
  }

}
