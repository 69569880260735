import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as JsSIP from 'jssip';
import { RTCSession } from 'jssip/lib/RTCSession';
import { ToastrService } from 'ngx-toastr';
import { check_internet } from 'src/app/dce-service/check_internet';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { SipService } from '../sip.service';
import { config, sip_user_config } from './config';

declare let navigator: Navigator;
type _mode = 'default' | 'calling';
type _state = null | 'local' | 'remote';

@Component({
  selector: 'app-sip-wrapper',
  templateUrl: './sip-wrapper.component.html',
  styleUrls: ['./sip-wrapper.component.css']
})
export class SipWrapperComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  // @Input() sip_user_config: sip_user_config;
  @Input() mode: _mode = 'default';

  @Output() ua_events: EventEmitter<any> = new EventEmitter<any>();
  @Output() call_events: EventEmitter<any> = new EventEmitter<any>();
  @Output() wrapper_state_events: EventEmitter<any> = new EventEmitter<any>();

  ua: JsSIP.UA
  rtcSession: RTCSession

  _state: _state = null; // default is null else either local or remote 
  receiver_config = {
    receiver_uri: null
  }
  retry_conf = {
    retry_interval: null,
    retry_exist: false
  }
  net_interval = null;
  ping_internet = true;
  constructor(
    private config: config,
    private _shared: SharedService,
    private _env: EnvService,
    private sip_service: SipService,
    private check_internet: check_internet,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { }

  ngOnInit() {
    this.initialize_interface();
    // JsSIP.debug.enable('JsSIP:*');
    JsSIP.debug.disable();
  }

  ngAfterViewInit() {
    this.wrapper_state_events.emit({ type: 'sip_general', case: 'init', data: null });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  ngOnDestroy() {
    if (this.ua != undefined) {
      this.disconnect();
      this.ua.removeAllListeners();
    };
    if (this.rtcSession != undefined) { this.rtcSession.removeAllListeners(); };
  }

  initialize_interface() {
    // this.sip_user_config = {
    //   uri: null,
    //   password: null,
    //   authorization_user: null,
    // };
  }

  isConnected() {
    console.log(this.ua.isConnected());
  }

  is_mic_access() {
    // console.log(navigator)
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        console.log('You let me use your mic!')
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'mic_access', data: true });
      })
      .catch((err) => {
        console.log('No mic for you!')
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'mic_access', data: false });
      });
  }

  set_ua_config() {
    this.destroy_ua(); // as fail-safe
    console.log("STEP 4.0: Setting Up UA Configuration");
    const socket = new JsSIP.WebSocketInterface(this.sip_service.sip_service_url);
    console.log(this._shared.sip_storage)
    const configuration = {
      sockets: [socket],
      uri: this._shared.sip_storage.uri,
      password: this._shared.sip_storage.password,
      authorization_user: this._shared.sip_storage.authorization_user,
      register: false,
      register_expires: 60,
      connection_recovery_max_interval: 10,
      connection_recovery_min_interval: 9,
      // registrar_server: 'rtc.telviva.com',
      // contact_uri: 'sip:2841754@rtc.telviva.com',
      // instance_id: null,
      // session_timers: true,
      // use_preloaded_route: false,
    };
    this.ua = new JsSIP.UA(configuration);
    console.log("STEP 4.1: UA SETUP: ", this.ua);
    // TODO exception hanlding if ua failed to configure
    this.wrapper_state_events.emit({ type: 'sip_general', case: 'ua_configured', data: null });
  }

  destroy_ua() {
    console.log(this.ua)
    if (this.ua !== undefined) {
      console.log('UA force stop')
      if (0) {
        // we need tofire this when user disconnect happens due to internet loss.
        // emit for firing our sip logout logging service
        this.logoff_agent_from_queues();
      }
      this.ua.stop();
    }
  }

  invoke_ua() {
    console.log("STEP 1: Invoking UA.........");
    console.log("STEP 2.0: Checking window navigator's mediaDevices object availability: ", navigator.mediaDevices !== undefined);
    if (navigator.mediaDevices === undefined) {
      console.log("STEP 2.1: Navigator media-device not available for un-secure connection(HTTP)");
      this._shared.sip_storage.qm_login = false;
      this._shared.sip_storage.is_loading = false;
      this._shared.sip_storage.lifecycle_status = 'pre-login';
      return this.mic_allowed_fire_ua();
    };
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.mic_allowed_fire_ua();
      })
      .catch((err) => {
        console.log('STEP 2.1: Mic Aceess not granted.')
        this._shared.sip_storage.qm_login = false;
        this._shared.sip_storage.is_loading = false;
        this._shared.sip_storage.lifecycle_status = 'pre-login';
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'mic_access', data: false });
      });
  }

  mic_allowed_fire_ua() {
    console.log('STEP 2.1: Mic Access granted.')
    this.wrapper_state_events.emit({ type: 'sip_general', case: 'mic_access', data: true });
    console.log("STEP 3: Checking UA Exist: ", this.ua);
    this.set_ua_config(); // re-init
    // this.retrieve_qm_agent_queues();
    console.log("STEP 6: Starting UA now");
    this.start_register_listen_ua();
  }

  start_register_listen_ua() {
    try {
      const ua_start = this.ua.start();
      const ua_register = this.ua.register();
    } catch (error) {
      this._shared.sip_storage.qm_login = false;
      this._shared.sip_storage.is_loading = false;
      this._shared.sip_storage.lifecycle_status = 'pre-login';
      console.log("Err starting UA: ", error);
    }
    // this.ua.start();
    console.log("STEP 7: Checking UA connection: ", this.ua.isConnected());
    if (!this.ua.isConnected()) {
      this.ua_events_listener();
    }
  }

  attempt_reconnection() {
    this.ping_internet = true;
    if (!this.retry_conf.retry_exist) {
      let is_net = null;
      this.net_interval = setInterval(async () => {
        is_net = await this.check_internet.ret_net_connection();
        if (is_net) {
          if (this.ping_internet) {
            this._toastr.success('', 'Internet Connectivity is back. Trying to Reconnect the Telephony Interface.', this._toastrUtility.basic_configuration);
            this.ua.stop();
            this.retry_conf.retry_exist = true;
            this.ping_internet = false;
            // this.logoff_agent_from_queues();
            this.wrapper_state_events.emit({ type: 'network', case: 'sip_reconnect_success', data: true });
            // no need for retry_interval, net_interval is sufficient
            this.retry_conf.retry_interval = setInterval(() => {
              this.retry_connection();
            }, this._env.telephony_conf.conn_retry_attempt_interval); // check ua conn after 10 secs
          }
          clearInterval(this.net_interval);
        }
      }, this._env.net_ping_timer)
    }
    this.set_reconnect_duration();
  }

  retry_connection() {
    if (!this.ua.isConnected()) {
      try {
        console.log('retrying invoke ua')
        this._shared.sip_storage.is_loading = true;
        this._shared.sip_storage.lifecycle_status = 'logged-in';
        this.invoke_ua();
      } catch (error) {
        console.log('Err invoking UA: ', error);
      }
    } else {
      this._toastr.success('', 'Successfully Reconnected the Telephony Interface.', this._toastrUtility.basic_configuration);
      this.clear_reconnect_instance();
    }
    console.log(this.ua.isConnected());
    console.log(this.retry_conf.retry_interval);
    // debugger;
  }

  set_reconnect_duration() {
    setTimeout(() => {
      this.clear_reconnect_instance();
    }, this._env.telephony_conf.conn_retry_limit);
  }

  clear_reconnect_instance() {
    this.ping_internet = false;
    clearInterval(this.retry_conf.retry_interval);
    clearInterval(this.net_interval);
    this.retry_conf.retry_interval = 0;
    this.retry_conf.retry_exist = false;
  }

  retrieve_qm_agent_queues() {
    // agent/agent_num
    // const payload = 'agent/2841300'
    console.log("STEP 5.0: Retrieving QM Agent QUEUES from API");
    const payload = `agent/${this._shared.sip_storage.authorization_user}`;
    this.sip_service.retrieve_qm_agent_queues(payload).subscribe(res => {
      console.log("STEP 5.0.1: Retievied QM Agent Queues ----->", res);
      if (res.result[0][1] == 'OK') {
        this.serialize_agent_queues(res.output);
        this.login_agent_to_queues();
        this.retrieve_available_pause_codes();
      } else {
        this._shared.sip_storage.is_loading = false;
        this._toastr.error("", "Failed to fetch Agent Queues from Queuemetrics.", this._toastrUtility.basic_configuration);
      }
      this.log_error('retrieve_qm_agent_queues', this._shared.sip_storage.config_data.retrieve_qm_agent_queues, payload, res);
    }, err => {
      this._shared.sip_storage.is_loading = false;
      this._toastr.error("", "Failed to fetch Agent Queues from Queuemetrics.", this._toastrUtility.basic_configuration);
      this.log_error('retrieve_qm_agent_queues_err', this._shared.sip_storage.config_data.retrieve_qm_agent_queues, payload, err);
    });
  }

  login_agent_to_queues() {
    console.log("STEP 5.1: Logining Agent to QM Agents QUEUES from API");
    const payload = {
      action: "join",
      extension: "",
      queues: this._shared.sip_storage.agent_queues,
      agent: `agent/${this._shared.sip_storage.authorization_user}`,
      server: ""
    };
    this.sip_service.login_agent_to_queues(payload).subscribe(res => {
      console.log("STEP 5.1.1: AGENT Logged in queue. ----->", res);
      if (res.resultStatus == 'OK') {
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'login_agent_to_queue', data: true });
      } else {
        this._shared.sip_storage.is_loading = false;
        this._toastr.error("", "Failed to Log Agent into Queues on Queuemetrics.", this._toastrUtility.basic_configuration);
      }
      this.log_error('login_agent_to_queues', this._shared.sip_storage.config_data.login_logoff_queues, payload, res);
    }, err => {
      this._shared.sip_storage.is_loading = false;
      this._toastr.error("", "Failed to Log Agent into Queues on Queuemetrics.", this._toastrUtility.basic_configuration);
      this.log_error('login_agent_to_queues_err', this._shared.sip_storage.config_data.login_logoff_queues, payload, err);
    });
  }

  logoff_agent_from_queues() {
    if (1) { // condition on user paused
      this.unpause_agent();
    }
    const payload = {
      action: "remove",
      extension: "",
      queues: this._shared.sip_storage.agent_queues,
      agent: `agent/${this._shared.sip_storage.authorization_user}`,
      server: ""
    };
    this.sip_service.login_agent_to_queues(payload).subscribe(res => {
      console.log(res);
      if (res.resultStatus == 'OK') {
        this._shared.sip_storage.qm_login = false;
      } else {
        this._toastr.error("", "Failed to Logout Agent from Queues on Queuemetrics.", this._toastrUtility.basic_configuration);
      }
      this.log_error('logoff_agent_from_queues', this._shared.sip_storage.config_data.login_logoff_queues, payload, res);
    }, err => {
      this._toastr.error("", "Failed to Logout Agent from Queues on Queuemetrics.", this._toastrUtility.basic_configuration);
      this.log_error('logoff_agent_from_queues_err', this._shared.sip_storage.config_data.login_logoff_queues, payload, err);
    });
  }

  retrieve_available_pause_codes() {
    // agent/agent_num
    console.log("STEP 5.2: Retrieving available Pause Codes from API");
    const payload = `agent/${this._shared.sip_storage.authorization_user}`;
    this.sip_service.retrieve_available_pause_codes(payload).subscribe(res => {
      console.log("STEP 5.2.2: Retievied pause Codes ----->", res);
      if (res.result[0][1] == 'OK') {
        this.serialize_pause_codes(res.output);
      } else {
        this._toastr.error("", "Failed to retrieve Pause codes from Queuemetrics.", this._toastrUtility.basic_configuration);
      }
      this.log_error('retrieve_available_pause_codes', this._shared.sip_storage.config_data.retrieve_available_pause_codes, payload, res);
    }, err => {
      this._toastr.error("", "Failed to retrieve Pause codes from Queuemetrics.", this._toastrUtility.basic_configuration);
      this.log_error('retrieve_available_pause_codes_err', this._shared.sip_storage.config_data.retrieve_available_pause_codes, payload, err);
    });
  }

  pause_agent(reconnection = false) {
    const payload = {
      action: "pause",
      extension: "",
      pause: reconnection ? this._shared.sip_storage.previous_pause_code : this._shared.sip_storage.current_pause_code,
      agent: `agent/${this._shared.sip_storage.authorization_user}`,
      server: ""
    };
    this.sip_service.pause_agent(payload).subscribe(res => {
      if (res.resultStatus == "OK") {
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'telephony_paused', data: true });
      } else {
        this._toastr.error("", "Failed to Pause Agent on Queuemetrics.", this._toastrUtility.basic_configuration);
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'telephony_paused_err', data: null });
      }
      this.log_error('pause', this._shared.sip_storage.config_data.pause_unpause_agent, payload, res);
    }, err => {
      this._toastr.error("", "Failed to Pause Agent on Queuemetrics.", this._toastrUtility.basic_configuration);
      this.log_error('pause_err', this._shared.sip_storage.config_data.pause_unpause_agent, payload, err);
    })
  }

  unpause_agent() {
    const payload = {
      action: "unpause",
      extension: "",
      agent: `agent/${this._shared.sip_storage.authorization_user}`,
      server: ""
    };
    this.sip_service.unpause_agent(payload).subscribe(res => {
      if (res.resultStatus == "OK") {
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'telephony_paused', data: false });
      } else {
        this._toastr.error("", "Failed to Unpause Agents on Queuemetrics.", this._toastrUtility.basic_configuration);
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'telephony_unpaused_err', data: null });
      }
      this.log_error('unpause', this._shared.sip_storage.config_data.pause_unpause_agent, payload, res);
    }, err => {
      this._toastr.error("", "Failed to Unpause Agents on Queuemetrics.", this._toastrUtility.basic_configuration);
      this.log_error('unpause_err', this._shared.sip_storage.config_data.pause_unpause_agent, payload, err);
    })
  }

  dispose_code() {
    const payload = {
      action: "calloutcome",
      callid: this._shared.sip_storage.qm_call_id,
      outcome: this._shared.sip_storage.disposition_dict.value_value,
      server: ""
    };
    if (this._shared.sip_storage.qm_call_id) {
      this.sip_service.unpause_agent(payload).subscribe(res => {
        this.wrapper_state_events.emit({ type: 'sip_general', case: 'call_disposition', data: res.resultStatus == "OK" ? true : false });
        this.log_error('call_outcome', this._shared.sip_storage.config_data.pause_unpause_agent, payload, res);
      }, err => {
        this._toastr.error("", "Failed to set Disposition code on Queuemetrics.", this._toastrUtility.basic_configuration);
        this.log_error('call_outcome_err', this._shared.sip_storage.config_data.pause_unpause_agent, payload, err);
      })
    } else {
      this._toastr.error("", "Failed to set Disposition code on Queuemetrics.", this._toastrUtility.basic_configuration);
    }
  }

  log_error(log_type, service, _payload, data) {
    const log = {
      service: service,
      agent: this._shared.sip_storage.authorization_user,
      time: new Date(),
      extension: this._shared.sip_storage.sip_ext,
      uri: this._shared.sip_storage.uri,
      payload: _payload,
      response: data
    }
    const payload = {
      'log_type': log_type,
      'data': log
    };
    this.sip_service.log_error(payload).subscribe(res => {
      if (res.errCode === 0) {

      }
    })
  }

  serialize_agent_queues(data) {
    const queues = [];
    for (let i = 0; i < data.length; i++) {
      // sample [ "Agent/2841300", "TQ", "Inbound Test Queue", "0" ]
      queues.push(data[i][1]);
    }
    this._shared.sip_storage.agent_queues = queues;
  }

  serialize_pause_codes(data) {
    const pause_code_list: any = [];
    for (let i = 0; i < data.length; i++) {
      // sample [ "Agent/2841300", "13", "Backoffice", "NBP" ],
      const obj = {
        pause_code: data[i][1],
        pause_reason: data[i][2]
      }
      pause_code_list.push(obj);
    }
    this._shared.sip_storage.available_pause_codes = pause_code_list;
  }

  ua_events_listener() {
    console.log("STEP 8: Listening to UA Events----->")
    for (let i = 0; i < this.config.event_dict[this.mode]['ua_events'].length; i++) {
      const event_name = this.config.event_dict[this.mode]['ua_events'][i];
      // console.log(event_name)
      this.ua_events_emitter(event_name);
    }
  }

  ua_events_emitter(event_name) {
    this.ua.on(event_name, (e) => {
      // console.log(event_name, e);
      if (this.ua_event_functions[event_name]) {
        this.ua_event_functions[event_name](e, this)
      }
      console.log("STEP 9: UA EVENT: " + event_name + " received----->", e);
      this.ua_events.emit({ type: 'ua_evt', case: event_name, data: e });
    });
  }

  ua_event_functions = {
    connected: (e) => {
      console.log("connected", e);
    },
    registered: (e) => {
      console.log("registered", e);
      console.log("User Register Time:", new Date());
    },
    registrationFailed: (e) => {
      // this.ua = undefined;
      console.log("registrationFailed", e);
      console.log("User registrationFailed Time:", new Date());
    },
    unregistered: (e) => {
      console.log("unregistered", e);
      console.log("User Unregister Time:", new Date());
    },
    disconnected: (e) => {
      this.ua.removeAllListeners();
      console.log("disconnected", e);
    },
    newRTCSession: (e) => {
      this._state = e.originator;
      if (this._state === 'remote') { // incoming
        this.rtcSession = e.session;
        console.log("STEP 10: Listening to Call Events ----->");
        this.call_receiver_events_listener();
      }
    }
  }

  call_event_functions = {
    accepted: (e) => {
      // console.log("accepted", e);
      console.log('call started on: ', this.rtcSession.start_time)
    },
    progress: (e) => {
      // console.log("progress", e);
    },
    hold: (e) => {
      // console.log("hold", e);
    },
    muted: (e) => {
      // console.log("muted", e);
    },
    ended: (e) => {
      // console.log("ended", e);
      console.log('call ended on: ', this.rtcSession.end_time)
    },
  }

  call_receiver_events_listener() {
    for (let i = 0; i < this.config.event_dict[this.mode]['call_events'].length; i++) {
      const event_name: any = this.config.event_dict[this.mode]['call_events'][i];
      this.rtcSession.on(event_name, (e) => {
        console.log(event_name, e);
        if (this.call_event_functions[event_name]) {
          this.call_event_functions[event_name](e, this);
        }
        console.log("STEP 11: Call EVENT: " + event_name + " received----->", e);
        this.call_events.emit({ type: 'call_evt', case: event_name, data: e });
      });
    }
  }

  // call_receiver_emitter(event_name) {
  //   this.rtcSession.on(event_name, (e) => {
  //     // console.log(event_name, e);
  //     this.call_events.emit({ type: 'call_evt', case: event_name, data: e });
  //   });
  // }

  call_ua(receiver_uri: string) {
    this.receiver_config.receiver_uri = receiver_uri;
    // Register callbacks to desired call events
    const evt_handler: any = {};
    // dow we need to segregate event listnr.
    for (let i = 0; i < this.config.event_dict[this.mode]['call_events'].length; i++) {
      const event_name = this.config.event_dict[this.mode]['call_events'][i];
      evt_handler[event_name] = (e) => {
        console.log(event_name, e)
        if (this.call_event_functions[event_name]) {
          this.call_event_functions[event_name](e, this);
        }
        console.log("STEP 11: Call PLACE EVENT: " + event_name + " received----->", e);
        this.call_events.emit({ type: 'call_evt', case: event_name, data: e });
      }
    };
    const options = {
      'eventHandlers': evt_handler,
      'mediaConstraints': { 'audio': true, 'video': false }
    };
    this._state = 'local';
    this.rtcSession = this.ua.call(this.receiver_config.receiver_uri, options);
    // console.log(session)
    this.generate_audio_stream();
  }

  answer_call() {
    const options = {
      'mediaConstraints': { 'audio': true, 'video': false },
    };
    this.rtcSession.answer(options);
    this.generate_audio_stream();
  }

  generate_audio_stream() {
    this.rtcSession.connection.addEventListener('addstream', (e: any) => {
      const audio = document.createElement('audio');
      audio.srcObject = e.stream;
      audio.play();
    });
  }

  reject_call() {
    const options = {};
    this.rtcSession.terminate();
  }

  transfer_call(sip_uri) {
    this.rtcSession.refer(sip_uri);
    this.reject_call();
  }

  transfer_call_user1() {
    const sip_uri = 'sip:2841754@rtc.telviva.com';
    this.rtcSession.refer(sip_uri);
    this.reject_call();
  }

  transfer_call_user2() {
    const sip_uri = 'sip:2841755@rtc.telviva.com';
    this.rtcSession.refer(sip_uri);
    this.reject_call();
  }

  transfer_call_user3() {
    const sip_uri = 'sip:2841756@rtc.telviva.com';
    this.rtcSession.refer(sip_uri);
    // check right approach (how to dissconnect call after refer)
    this.reject_call();
  }

  hold_call() {
    this.rtcSession.hold();
  }

  unhold_call() {
    this.rtcSession.unhold();
  }

  mute_call() {
    this.rtcSession.mute();
  }

  unmute_call() {
    this.rtcSession.unmute();
  }

  register_ua() {
    this.ua.register();
  }

  unregister_ua() { // DND
    const options = {
      all: true
    };
    this.ua.unregister(options)
  }

  disconnect() {
    if (this._shared.sip_storage.lifecycle_status !== 'pre-login') {
      this._shared.sip_storage.sip_login = false;
      this.ua.stop();
      // this.logoff_agent_from_queues();
    }
  }

  is_call_onhold() {
    return this.rtcSession.isOnHold().local;
  }

  is_call_onmute() {
    return this.rtcSession.isMuted();
  }

  send_msg() {
    // Register callbacks to desired message events
    const eventHandlers = {
      'succeeded': (e) => { console.log('msg success', e) },
      'failed': (e) => { console.log('msg failed', e) }
    };
    const op = {
      contentType: "text/plain",
      eventHandlers: eventHandlers
    }
    this.ua.sendMessage(this.receiver_config.receiver_uri, 'Hello', op);
  }

  hi_message() {
    var eventHandlers = {
      'succeeded': function (e) { console.log("succeeded", e); },
      'failed': function (e) { console.log("failed", e); }
    };

    var options = {
      'eventHandlers': eventHandlers
    };
    this.ua.sendMessage(this.receiver_config.receiver_uri, "Hello World", options);
  }

}