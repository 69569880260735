import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { colorConfig, curStrings, showingProperties, surveyOptions, surveyStrings } from 'src/app/dce-views/generic-surveyjs/generic-surveyjs.model';
@Component({
  selector: 'app-knowledge-content',
  templateUrl: './knowledge-content.component.html',
  styleUrls: ['./knowledge-content.component.scss']
})
export class KnowledgeContentComponent implements OnInit, OnChanges {

  @Input() data : any = {};
  modules_readonly = {toolbar: false };

  survey_config = {
    survery_mode: 'write_mode', //creator/read_mode
    survey_questionaire: null,
    survey_answers: null,
    survey_identifier: 'default',
    color_config: colorConfig['default'],
    surveyOptions: surveyOptions['default'],
    surveyStrings: surveyStrings['default'],
    curStrings: curStrings['default'],
    showingProperties: showingProperties['default']
  }

  constructor(
    private elRef: ElementRef
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    const audio_player = this.elRef.nativeElement.querySelector('audio');
    const video_player = this.elRef.nativeElement.querySelector('video')
    if(audio_player && changes.data.currentValue.content_type == 'audio'){
      audio_player.load();
    }
    if(video_player && changes.data.currentValue.content_type == 'video'){
      video_player.load();
    }
    if(changes.data && changes.data.currentValue.content_type == 'call-script'){
      console.log(changes.data.currentValue.body_json)
      this.survey_config.survey_questionaire = changes.data.currentValue.body_json;
    }
  }

  show_doc_data(document_link) {
    window.open(document_link, '_blank');
  }

}
