import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
declare var $: any; // Declare jQuery

@Component({
  selector: 'app-content-expand-view',
  templateUrl: './content-expand-view.component.html',
  styleUrls: ['./content-expand-view.component.scss']
})
export class ContentExpandViewComponent implements OnInit, AfterViewInit {

  @Input() note_text = ''
  @Input() view_text = 'View'

  showNote: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // Initialize the popover
    // $('[data-toggle="popover"]').popover();
    $('[data-toggle="popover"]').popover({
      container: '.modal'  // This appends the popover to the modal
    });
    $('.example-popover').popover({
      container: 'body'
    })
    
  }

}
