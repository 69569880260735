import { Component, OnInit, Input, ElementRef, NgZone, ViewChild } from '@angular/core';
import { CommonService } from '../../dce-service/common.service';
import { EnvService } from '../../dce-service/env.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { MouseEvent } from '@agm/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { AddressService } from './address.service';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { pipe } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() heading: string;
  @Input() is_disabled?: boolean = true;
  @Input() unique_id?: string = 'true';

  address_payload: FormGroup;
  disabled: boolean;
  submitted = false;


  enable_biz_addr_form = true;
  city_dropdown_list = [];
  province_dropdown_list = [];
  country_dropdown_list = [];

  selectedCountry = 0;
  selectedProvince = 0;
  title = 'app';
  province = [];
  cities = [];

  dropdown_values = [];
  country_dropdown = [];
  province_dropdown = [];
  city_dropdown = [];
  current_case = '';
  country_category_dropdown = [];

  address_info = {
    'street1' : '',
    'street2' : '',
    'locality1' : '',
    'locality2' : '',
    'city' : '',
    'metro_municipality' : '',
    'province' : '',
    'country' : '',
    'zip_code': ''
  }

  //Map variables
  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;

  public my_lng: number;
  public my_lat: number;

  public user_select_lat : number;
  public user_select_long : number;
  public show_map: boolean;

  // @ViewChild("search")
  public searchElementRef: ElementRef;

  address_form = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };


  // New  Variables
    country_list = [];
  constructor(
    private _commonService: CommonService,
    private _env: EnvService,
    private _fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private address_service: AddressService,
    ) {

  }

  private subject: Subject<string> = new Subject();

  ngOnInit() {
    this.get_classification_data();
    $(".start_tooltip").tooltip();

    this.disabled = this.is_disabled;

    this.address_payload = this._fb.group({
      'build_number': this._fb.control(''),
      'latitude': this._fb.control(null),
      'longitude': this._fb.control(null),
      'city' : this._fb.control(''),
      'country' : this._fb.control(''),
      'country_call_code': this._fb.control(''),
      'country_code' : this._fb.control(''),
      'locality1' : this._fb.control(''),
      'locality2' : this._fb.control(''),
      'municipality' : this._fb.control(''),
      'po_box': this._fb.control(''),
      'metro_municipality' : this._fb.control(''),
      'province' : this._fb.control('', Validators.required),
      'province_code' : this._fb.control(''),
      'street1' : this._fb.control(''),
      'street2' : this._fb.control(''),
      'time_zone': this._fb.control(''),
      'zip_code': this._fb.control('')
    });

    this.toggle_form(this.disabled);

    // this.zoom = 14;
    // this.latitude = -26.0131;
    // this.longitude = 28.0328;
    // this.show_map = false;


    // //create search FormControl
    this.searchControl = new FormControl();

    // //set current position
    // this.setCurrentPosition();

    //load Places Autocomplete
    // this.mapsAPILoader.load().then(() => {
    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
    //     types: ["address"],
    //     componentRestrictions: {country: "za"}
    //   });
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();
    //       // this.user_select_lat = place.geometry.location.lat();
    //       // this.user_select_long = place.geometry.location.lng();
    //       this.address_payload.patchValue({
    //         'latitude' : place.geometry.location.lat(),
    //         'longitude' : place.geometry.location.lng()
    //       });
    //       this.getAddress(this.latitude, this.longitude).subscribe(
    //         res => {
    //           console.log(res);
    //           this.  populate_fields(res);

    //         },
    //         err => {
    //           console.log(err);
    //         }
    //       );
    //       this.zoom = 12;
    //     });
    //   });
    // });

    // this.subject.pipe(debounceTime(200)).subscribe((title) => {
    //   if($( "#"+this.current_case ).data('ui-autocomplete') != undefined){
    //     $( "#"+this.current_case ).autocomplete( "option", "source", [] );
    //   }
    //   this.address_service.address_search(this.current_case,  title).subscribe(
    //     res => {
    //       this.render_dropdown(res.results);
    //     }
    //   );
    // });


    //  initialised autocomplete
    // var self = this;
    // var search_case_list = ["locality1", "locality2", "city", "metro_municipality", "province"];

    // for(var i=0; i<search_case_list.length; i++){

    //   $('#'+search_case_list[i]).autocomplete({
    //     source: [],
    //     focus: ( event, ui ) => {
    //       // console.log(ui);
    //       $( "#"+search_case_list[i] ).val( ui.item.value );
    //       return false;
    //     },
    //     select: ( event, ui ) => {
    //       $( "#"+search_case_list[i] ).val( ui.item.value );

    //       return false;
    //     }
    //   }).autocomplete( "instance" )._renderItem = ( ul, item ) => {
    //     return $( "<li>" )
    //       .append( "<div>" + item.value + "<br>" + item.locality+" "+item.suburb+" "+item.city+" "+item.district+" "+item.province + "</div>" )
    //       .appendTo( ul );
    //   };

    //   $( "#" + search_case_list[i] ).on('autocompleteselect', function (e, ui) {
    //     self.case_click(ui, 1);
    //   });

    // }

  } //  oninit ends


  get_classification_data() {
    const payload = {
      'classification_type_id': [this._env.dropdown_list.country,this._env.dropdown_list.provinces] ,
      'classification_value_id': [-1,-1]
    };
    this._commonService.get_particular_classification_data(payload).subscribe(res => {
      if (res.errCode == 0) {
        for (let i = 0; i < res.msg.length; i++) {
          if (res.msg[i].classification_type == this._env.dropdown_list.country) {
            this.country_list = res.msg[i].data;
          }else if (res.msg[i].classification_type == this._env.dropdown_list.provinces) {
            this.province_dropdown_list = res.msg[i].data;
          }
        }
      }
    });
  }

  render_dropdown(results){
    $( "#"+this.current_case ).autocomplete( "option", "source", results );
    $( "#"+this.current_case ).autocomplete( "search" );
  }

  db_search(event, case_type){
    // console.log(event.key);
    // console.log(this.address_payload.value[case_type]);
    this.current_case = case_type;
    var forbibben_keys = ["Enter", "ArrowDown", "ArrowRight", "ArrowLeft", "ArrowUp"];

    if($.inArray(event.key, forbibben_keys) === -1){
      this.subject.next(this.address_payload.value[case_type]);
    }

  }

  case_click(data, reset_coords){
    if(reset_coords){
      // reset lat long
      this.address_payload.patchValue({
        'latitude' : null,
        'longitude' : null
      });

    }
    // console.log(data.item);
    // console.log(this.current_case);
    switch(this.current_case){
      case "locality1":
        this.address_payload.patchValue({
          'locality1' : data.item.locality,
          'locality2' : data.item.suburb,
          'city' : data.item.city,
          'metro_municipality' : data.item.district,
          'province' : data.item.province,
          'country' : 'South Africa'
        });
      break;
      case "locality2":
        this.address_payload.patchValue({
          'locality2' : data.item.suburb,
          'city' : data.item.city,
          'metro_municipality' : data.item.district,
          'province' : data.item.province,
          'country' : 'South Africa'
        });

      break;
      case "city":
        this.address_payload.patchValue({
          'city' : data.item.city,
          'metro_municipality' : data.item.district,
          'province' : data.item.province,
          'country' : 'South Africa'
        });

      break;
      case "metro_municipality":
        this.address_payload.patchValue({
          'metro_municipality' : data.item.district,
          'province' : data.item.province,
          'country' : 'South Africa'
        });

      break;
      case "province":
        this.address_payload.patchValue({
          'province' : data.item.province,
          'country' : 'South Africa'
        });
      break;
      default:
        //  do nothing
      break;
    }

  }

  clear_form() {
    this.address_payload.patchValue({
      'build_number': '',
      'latitude': null,
      'longitude': null,
      'city' : '',
      'country' : '',
      'country_call_code': '',
      'country_code' : '',
      'locality1' : '',
      'locality2' : '',
      'municipality' : '',
      'po_box': '',
      'metro_municipality' : '',
      'province' : '',
      'province_code' : '',
      'street1' : '',
      'street2' : '',
      'time_zone': '',
      'zip_code': ''
    });
    // this.user_select_lat = 0;
    // this.user_select_long = 0;
    this.show_map = false;
    this.searchControl.patchValue('');
    this.toggle_form(false);
    this.submitted = false;
  }

  toggle_form(bool) {
    this.disabled = bool;
    if (!this.disabled) {
      this.address_payload.enable();
    } else {
      this.address_payload.disable();
      //  TODO - remove from here and add to payload if field is being populate_fields
      $('#google_search').val("");
      // this.user_select_lat = 0;
      // this.user_select_long = 0;
    }
    this.submitted = false;
  }

  validate(): boolean {
    this.submitted = true;
    var valid = false;
    if (this.address_payload.status == 'VALID' || this.address_payload.status == 'DISABLED') {
      valid = true;
    } else {
      // this.snotifyService.error('Please enter mandatory field', this.utilityService.getConfig());
    }

    return valid;
  }


  //  rahul
  onSelectCountry(country_id: number) {
    this.selectedCountry = country_id;
    this.selectedProvince = 0;
    this.cities = [];
    this.province = this.province_dropdown.filter((item) => {
      return item.country_id === Number(country_id);
    });
  }

  //  rahul
  onSelectProvince(province_id: number) {
    this.selectedProvince = province_id;
    this.cities = this.city_dropdown.filter((item) => {
      return item.city_id === Number(province_id);
    });
  }




  /*
On map click place marker and get address.
 */

  mapClicked($event: MouseEvent) {
    // this.placemarker($event.coords.lat, $event.coords.lng)
  }

  toggle_map() {
    this.show_map = !this.show_map;
  }

  /*
  place marker and reverse geode address and fill the input box.
   */

  placemarker(lat, lng) {
    this.address_payload.patchValue({
      'latitude' : lat,
      'longitude' : lng
    });
    this.user_select_lat = lat;
    this.user_select_long = lng;

    this.getAddress(lat,lng).subscribe(
      res => {
        this.searchControl.patchValue(res.results[0].formatted_address);
        console.log(res);
        this.populate_fields(res);

      },
      err => {
        console.log(err);
      }
    );
}

  populate_fields(response){

    if(response.results.length == 0){
      return 0;
    }
    this.address_payload.enable();
    var address_data = response.results[0]['address_components'];

    var address_info = this.address_info;

    var data_map = {
      'street1': 'street_number',
      'street2': 'route',
      'locality1': 'sublocality',
      'locality2': 'locality',
      'city': 'administrative_area_level_2',
      'metro_municipality': '',
      'province': 'administrative_area_level_1',
      'country': 'country',
      'zip_code': 'postal_code'
    };

    for( var i=0 ; i < address_data.length ; i++) {
      $.each( data_map, function( key, value ) {
        if($.inArray( value, address_data[i].types) != -1) {
          address_info[key] = address_data[i].long_name;
        }
      });
    }

    this.address_service.match_and_populate_address(address_info).subscribe(
      res => {
        this.current_case = 'locality1';
        var data = {};
        data['item'] = res.results[0];

        console.log(data);

        this.case_click(data, 0);

        var address_info_temp = address_info;
        address_info_temp['locality1'] = res.results[0]['locality'];
        address_info_temp['locality2'] = res.results[0]['suburb'];
        address_info_temp['city'] = res.results[0]['city'];
        address_info_temp['metro_municipality'] = res.results[0]['district'];
        address_info_temp['province'] = res.results[0]['province'];

        this.address_payload.patchValue(address_info_temp);

        for (var key in address_info_temp) {
          if (address_info_temp[key] != '' && this.address_payload.controls[key] != undefined){
            this.address_payload.controls[key].disable();
          }
        }

      }
    );


    // console.log(address_info);
    // debugger

    // this.address_payload.patchValue(address_info);

    // for (var key in address_info) {
    //   if (address_info[key] != ''){
    //     this.address_payload.controls[key].disable();
    //   }
    // }

  }

  /*
  Get location for the current position, get address using reverse geocode
  and fill the input box with result.
   */

  getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
        this.my_lng = position.coords.longitude;
        this.my_lat = position.coords.latitude;
        this.placemarker(this.my_lat, this.my_lng);
        this.getAddress(this.my_lat,this.my_lng).subscribe(
          res => {
            this.searchControl.patchValue(res.results[0].formatted_address);
            console.log(res);
            res.results[0].address_components.map(val => {
                var addressType = val.types[0];
                if (this.address_form[addressType]) {
                  this.address_form[addressType] = val.long_name;
                  console.log(this.address_form);
                }
            });
          },
          err => {
            console.log(err);
          }
        );
    })
    } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }

  getAddress (latitude, longitude) {
    var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+latitude+','+longitude+'&key=AIzaSyAY-u0VehX9ismAOG8igZ3g4KcAuGtaTTk';
    return this.http.get<any>(url);
  };

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  fill_data(data) {
    console.log(data);
    this.address_payload.patchValue({
      'build_number': data.build_number,
      'latitude': data.latitude,
      'longitude': data.longitude,
      'city': data.city,
      'country': data.country,
      'country_call_code': data.country_call_code,
      'country_code': data.country_code,
      'locality1': data.locality1,
      'locality2': data.locality2,
      'municipality': data.municipality,
      'po_box': data.po_box,
      'metro_municipality': data.metro_municipality,
      'province': data.province,
      'province_code': data.province_code,
      'street1': data.street1,
      'street2': data.street2,
      'time_zone': data.time_zone,
      'zip_code': data.zip_code
    });
  }

  get_payload_data() {
    return this.address_payload.getRawValue();
  }














}
