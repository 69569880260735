import { Injectable } from '@angular/core';
import { EnvService } from './env.service';
import { HttpClient } from '@angular/common/http';

interface AgentDetails {
  'token': string;
  'user_id': string;
  'internal_org_biz_id': string;
  'user_details': string;
  'internal_biz_name': string;
  'user_roles': string;
  'current_role': string;
}

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  private agent_details: AgentDetails = {
    token: '',
    user_id: '',
    internal_org_biz_id: '',
    user_details: '',
    internal_biz_name: '',
    user_roles: '',
    current_role: '',
  };

  constructor(
    private _env: EnvService,
		protected http: HttpClient
  ) { }

  public setAgentData(data) {
    this.agent_details = {
      token: data.token,
      user_id: data.user_id,
      internal_org_biz_id: data.internal_org_biz_id,
      user_details: data.user_details,
      internal_biz_name: data.internal_biz_name,
      user_roles: data.user_roles,
      current_role: data.current_role,
    };
    localStorage.setItem('agent_token', data.token)

  }

  public clearAgentData() {
    this.agent_details = {
      token: '',
      user_id: '',
      internal_org_biz_id: '',
      user_details: '',
      internal_biz_name: '',
      user_roles: '',
      current_role: '',
    };
    localStorage.removeItem('agent_token')
  }

  public getAgentData(key: string) {
    return this.agent_details[key]
  }

  private _agent_login_url = `${this._env.service_url}/user/login/pin`;
  agent_login(payload) {
    return this.http.post<any>( this._agent_login_url , payload);
  }

  private _reset_agent_pin_url = `${this._env.service_url}/user/pin/admin/reset`;
  reset_agent_pin(payload) {
    return this.http.post<any>( this._reset_agent_pin_url , payload);
  }

  private _get_all_task_items_url = `${this._env.service_url}/task/records/get_all`;
  get_all_task_items(payload) {
    return this.http.post<any>( this._get_all_task_items_url , payload);
  }

  private _update_task_item_url = `${this._env.service_url}/task/records/status/update`;
  update_task_item(payload) {
    return this.http.post<any>( this._update_task_item_url , payload);
  }

  private _create_task_item_url = `${this._env.service_url}/task/records/create`;
  create_task_item(payload) {
    return this.http.post<any>( this._create_task_item_url , payload);
  }

  private _remove_task_item_url = `${this._env.service_url}/task/records/delete`;
  remove_task_item(payload) {
    return this.http.post<any>( this._remove_task_item_url , payload);
  }
}
