import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../dce-service/env.service';

@Injectable({
  providedIn: 'root'
})
export class TreeService {

  constructor(	private http: HttpClient, 
  				private env: EnvService) { }

  	private _div_tree_data = `${this.env.service_url}/`;

	get_tree_data(service, id, menu_items, extra_payload_keys) { // extra_payload_keys(Object) is additional payload keys(if any) needed in tree-services
		const data = { "id" : id , "menu_items": menu_items}
		const payload = { ...data, ...extra_payload_keys }; // generate tree_payload

		return this.http.post<any>(this._div_tree_data + service, payload)
	}

}
