import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AltProjectService } from 'src/app/dce-service/alt-project.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { SharedService } from 'src/app/dce-service/shared.service';
// import { htmlStrings } from '../case-management-text';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss'],
})
export class SelectCustomerComponent implements OnInit {

  @Input() phone_number: string = '';
  @Output() customer_event: EventEmitter<any> = new EventEmitter<any>();
  case = 'customer';
  // html_strings:any = htmlStrings[this.case];
  show_customer = true;

  global_client_list = [];
  client_list = [];
  loading_text = "No new clients"
  loading = false;
  selected_index = -1;
  search_type = 'Organisation'

  // organisation search
  name = ''
  service_call_url = this._commonService._get_all_bizorg_v3_url;
  service_call_payload = {
    columns: [],
    data_records: 100,
    page: 1,
    company_role_type: 'Organisation'
  }

  individual_name = ''
  individual_service_call_payload =  {
    columns: [],
    data_records: 100,
    page: 1,
  }
  individual_completer_service_url = this._altServices.search_individual_customer_url
  search_details:any = {};
  activate_on_call = false;
  constructor(
    private _commonService: CommonService,
    private _router: Router,
    private _shared: SharedService,
    private _altServices: AltProjectService
  ) { }

  ngOnInit() {
    // this.phone_number = '1234567890'
    // this.get_clients();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.phone_number) {
      if (changes.phone_number.currentValue != null && changes.phone_number.currentValue != -1) {
        this.activate_on_call = true;
        this.phone_number = changes.phone_number.currentValue;
        this.get_clients();
        // this.get_workflow_objects_for_wrkgrp(changes.id.currentValue);
      } else {
        this.activate_on_call = false;
      }
    }

  }

  // toggle_customer() {
  //   this.show_customer = !this.show_customer
  //   if (this.show_customer) {
  //     this.client_list = this.global_client_list;
  //   }
  // }

  get_clients() {
    const payload = {
      "phone_number": this.phone_number
    }
    this.client_list = [];
    this.loading_text = "Fetching Client"
    this.loading = true;
    this._commonService.get_clients_based_on_phone_no(payload).subscribe(res => {
      if (res.errCode == 0) {
        this.loading = false;
        this.global_client_list = res.data;
        this.client_list = res.data;
      }
    })
  }

  select_client(client,index) {
    this.selected_index = index
    // this.toggle_customer()
    // this.client_list = [];
    // this.client_list.push(client);
    const rec_type = client.rec_type;
    const data = {
      biz_id: client.bizorg_id,
      ind_id: (rec_type === 'individual'|| rec_type === 'bizorg_individual') ? client.individual_id: null,
      role: (rec_type === 'individual') ? 'Individual' : client.company_role_type
    }
    // console.log(data);
    if(data.biz_id ==null && data.role == 'Individual'){
      // service call 
      const payload = {
        individual_id: data.ind_id
      }
      this._commonService.get_biz_id_based_on_individual_id(payload).subscribe(res => {
        if (res.errCode == 0) {
          this.client_list[index]['bizorg_id'] = res.biz_org_id;
          data['biz_id'] = res.biz_org_id;
          this._router.navigateByUrl('/case-management/case-management/customer/' + btoa(JSON.stringify(data)));
        }
      })
    } else {
      this._router.navigateByUrl('/case-management/case-management/customer/' + btoa(JSON.stringify(data)));
    }
  }

  new_case_v2() {
    this.customer_event.emit({ case: 'new_case' })
  }

  close_customer_information() {
    this._shared.sip_storage.cus_info_toggle = false;
  }

  onSelect_type(type) {
    this.search_type = type
  }

  completer_select_organisation(event) {
    this.selected_index = -1
    switch (event.case) {
      case 'select_v2':
        if (event.data != null) {
          const temp_data= event.data
          temp_data['rec_type'] = 'bizorg'
          temp_data['bizorg_id'] = event.data.id
          this.client_list = [temp_data]
        }
      break;
      case 'clear':
        this.name = '';
        this.client_list = this.global_client_list;
      break;
      default:
      break;
    }
  }

  completer_select_individual(event) {
    this.selected_index = -1
    switch (event.case) {
      case 'select_v2':
        if (event.data != null) {
          this.activate_on_call = false;
          const temp_data = {
            'rec_type': 'individual',
            'company_role_type': 'Individual',
            'company_name': event.data.full_name,
            'registration_number': event.data.idtype == 'Passport'? event.data.passport_number: event.data.idnumber,
            'phone_number1': event.data.phone_number1,
            'email_address1' : event.data.email_address1,
            'individual_first_name': event.data.first_name,
            'individual_last_name': event.data.last_name,
            'individual_idnumber': event.data.idnumber,
            'individual_passport_number': event.data.passport_number,
            'individual_id':event.data.individual_id,
            'bizorg_id':event.data.biz_org_id
          }
          const temp_aray = [temp_data]
          this.client_list = [...temp_aray];
        }
      break;
      case 'clear':
        this.individual_name = '';
        this.client_list = this.global_client_list;
      break;
      default:
      break;
    }
  }
}
