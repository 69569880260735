import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AltProjectService } from '../../../dce-service/alt-project.service';
import { CommonService } from '../../../dce-service/common.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SharedService } from 'src/app/dce-service/shared.service';
import { DatatableSearchComponent } from 'src/app/dce-shared/datatable-search/datatable-search.component';
declare var $: any;

@Component({
  selector: 'app-knowledge-search-faq',
  templateUrl: './knowledge-search-faq.component.html',
  styleUrls: ['./knowledge-search-faq.component.scss']
})
export class KnowledgeSearchFaqComponent implements OnInit {
  @ViewChild('faq_search_data' ,{static: false}) faq_search_data: DatatableSearchComponent;

  global_faq_list = [];
  faq_list = [];
  content_type_data:any = {};
  faq_search: FormControl = new FormControl();  
  dt_selected = [];
  selected_row : any = {};
  columns: any = [
    { prop: 'subject', name: "FAQ"},
  ];
  loadingIndicator=true;
  table_message = {emptyMessage: 'There are no records.'};
  msg='';

  constructor(
    public _altService: AltProjectService,
    private _sharedService: SharedService,
    public _commonService: CommonService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    // this.faq_datatable_search();
  }

  get_all_faqs(){
    this.loadingIndicator=true;
    const payload = {
      category: "faq",
      columns: ['subject', 'content_base_id', 'content_base_status']
    };
    this._altService.get_all_faq(payload).subscribe(res => {
      if(res.errCode == 0) {
        this.faq_list = res.data;
        this.global_faq_list = res.data;
      }  
      this.loadingIndicator=false;
    });
  }

  // faq_datatable_search(){
  //   this.faq_search.valueChanges
  //   .pipe(debounceTime(200))
  //   .subscribe(value => {
  //     this.search_in_nt(value);
  //   });
  // }

  search_in_nt(val) {
    const data = this.global_faq_list;
    const faq_columns = this.columns.map(col => col.prop );
    const filtered_data = this._commonService.filter_data_in_datatable(val, faq_columns, data);
    this.faq_list = [...filtered_data];
    if(this.faq_list.length == 0){
      if(val == ''){
        this.table_message.emptyMessage="There are no records."
      }else{
        this.table_message.emptyMessage="No records matched for entered search criteria. Please try a new search."
      }
    }
  }

  on_activate(event){
    switch(event.type){
      case 'click':
      this.selected_row = event.row;
      this.open_modal()
      break;
      case 'dblclick':

      break;
      case 'mouseenter':

      break;
    }
  }

  open_modal(){
    $('#faq_modal').modal({backdrop: 'static', keyboard: false});
    this.get_content_type_data();
  }

  close_modal(){
    const audio_player = this.elRef.nativeElement.querySelector('audio');
    if(audio_player){
      audio_player.pause();
    }
    const video_player = this.elRef.nativeElement.querySelector('video');
    if(video_player){
      video_player.pause();
    }
    $('#faq_modal').modal('hide');
  }

  get_content_type_data(){
    this.content_type_data=[];
    this.msg='Fetching Data';
    const payload = {
      "content_base_id": this.selected_row.content_base_id
    }
    this._altService.get_content_type_data(payload).subscribe(res => {
      if(res.errCode == 0) {
        this.content_type_data = res.data;
        this. msg='';
      }
    });
  }

  clear_faq_search() {
    this.faq_search_data.reset_search();
    // this.get_all_faqs();
    this.faq_list = this.global_faq_list
  }

  search_dt_role(event){
    switch(event.type){
      case 'search':
      case 'clear':
      case 'change':
        this.search_in_nt(event.data)
      break;
    }
  }

}
