import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../../dce-service/common.service';
import { SharedService } from '../../../dce-service/shared.service';
import { AltProjectService } from '../../../dce-service/alt-project.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../../dce-service/toastr-utility.service';

type searchType = 'Organisation' | 'Individual';

interface orgRecord {
  rec_type: searchType;
  biz_data: string;
  bizorg_id: string;
  category: string;
  company_name: string;
  email_address1: string;
  email_address2: string;
  id: string;
  phone_number1: string;
  phone_number2: string;
  phone_number3: string;
  registration_date: string;
  registration_number: string;
  trading_name: string;
  uifrefno : string;
}

interface indRecord {
  rec_type: searchType;
  company_role_type: string;
  company_name: string;
  registration_number: string;
  phone_number1: string;
  email_address1: string;
  individual_first_name: string;
  individual_last_name: string;
  individual_idnumber: string;
  individual_passport_number: string;
  individual_id: string;
  bizorg_id: string;
}
  // search_type, this.selected_organsation, this.selected_individual, type

interface customerEmitter {
  type : 'link' | 'cancel',
  search_type: string,
  selected_organsation: object,
  selected_individual: object
}

@Component({
  selector: 'app-history-customer-link',
  templateUrl: './history-customer-link.component.html',
  styleUrls: ['./history-customer-link.component.scss']
})
export class HistoryCustomerLinkComponent implements OnInit {

  @Output() customer_emitter: EventEmitter<any> = new EventEmitter<customerEmitter>();

  search_type: searchType = 'Organisation'
  selected_organsation: orgRecord | {} = {};
  selected_individual: indRecord | {} = {};

  global_client_list = [];
  client_list = [];
  loading_text = "No new clients"
  loading = false;

  // organisation search
  name = ''
  service_call_url = this._commonService._get_all_bizorg_v3_url;
  service_call_payload = {
    columns: [],
    data_records: 100,
    page: 1,
    company_role_type: 'Organisation'
  }

  individual_name = ''
  individual_service_call_payload = {
    columns: [],
    data_records: 100,
    page: 1,
  }
  individual_completer_service_url = this._altServices.search_individual_customer_url
  constructor(
    private _commonService: CommonService,
    private _altServices: AltProjectService,
  ) { }

  ngOnInit() {
  }

  onSelect_type(type) {
    if(type != this.search_type) {
      this.selected_organsation = {}
      this.selected_individual = {}
      this.individual_name = '';
      this.name = '';
    }
    this.search_type = type;
  }

  empty_modal(){
    this.selected_organsation = {}
    this.selected_individual = {}
    this.individual_name = '';
    this.name = '';
    this.search_type = 'Organisation';
  }


  completer_select_organisation(event) {
    switch (event.case) {
      case 'select_v2':
        if (event.data != null) {
          const temp_data: orgRecord = {
            rec_type: 'Organisation',
            biz_data: event.data.biz_data,
            bizorg_id: event.data.id,
            category: event.data.category,
            company_name: event.data.company_name,
            email_address1: event.data.email_address1,
            email_address2: event.data.email_address2,
            id: event.data.id,
            phone_number1: event.data.phone_number1,
            phone_number2: event.data.phone_number2,
            phone_number3: event.data.phone_number3,
            registration_date: event.data.registration_date,
            registration_number: event.data.registration_number,
            trading_name: event.data.trading_name,
            uifrefno: event.data.uifrefno
          }
          this.selected_organsation = temp_data
          this.name = event.data.company_name;
        }
        break;
      case 'clear':
        this.name = '';
        this.selected_organsation = {};
        break;
      default:
        break;
    }
  }

  completer_select_individual(event) {
    switch (event.case) {
      case 'select_v2':
        if (event.data != null) {
          let registration_number = '';
          if(event.data.idtype == 'National ID') {
            registration_number = event.data.idnumber
          } else if(event.data.idtype == 'Passport') {
            registration_number = event.data.idnumber
          } else if(event.data.idtype == 'Asylum Seeker ID') {
            registration_number = event.data.asylum_seeker_no
          }
          const temp_data = {
            'rec_type': 'Individual',
            'company_role_type': 'Individual',
            'company_name': event.data.full_name,
            'registration_number': registration_number,
            'phone_number1': event.data.phone_number1,
            'email_address1': event.data.email_address1,
            'individual_first_name': event.data.first_name,
            'individual_last_name': event.data.last_name,
            'individual_idnumber': event.data.idnumber,
            'individual_passport_number': event.data.passport_number,
            'individual_id': event.data.individual_id,
            'bizorg_id': event.data.biz_org_id
          }
          this.selected_individual = temp_data;
          this.individual_name = event.data.full_name;
        }
        break;
      case 'clear':
        this.individual_name = '';
        this.selected_individual = {};
        break;
      default:
        break;
    }
  }

  link_customer() {
    this.customer_emitter.emit({
      type: 'link',
      search_type: this.search_type,
      selected_organsation: this.selected_organsation,
      selected_individual: this.selected_individual
    })
  }

  cancel(){
    this.customer_emitter.emit({
      type: 'cancel',
      search_type: null,
      selected_organsation: null,
      selected_individual: null
    })
  }
}
