import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from 'src/app/dce-service/env.service';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { Observable, observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LgConfigService {

	constructor(
		protected http: HttpClient,
		protected env: EnvService,
	) {}

	private get_config_data_by_name_url = `${this.env.service_url}/dce/config/get/name_config`;
	get_config_data_by_name(payload) {
		return this.http.post<any>( this.get_config_data_by_name_url , payload)
	}

	private get_config_by_name_url = `${this.env.service_url}/dce/config/get/name`;
	get_config_by_name(payload) {
		return this.http.post<any>( this.get_config_by_name_url , payload)
	}
	
	private get_config_by_id_url = `${this.env.service_url}/dce/config/get`;
	get_config_by_id(payload) {
		return this.http.post<any>( this.get_config_by_id_url , payload)
	}

	private get_all_config_url = `${this.env.service_url}/dce/config/get_all`;
	get_all_config(payload) {
		return this.http.post<any>( this.get_all_config_url , payload)
	}
	public create_config_url = `${this.env.service_url}/dce/config/create`;
	create_config(payload) {
		return this.http.post<any>( this.create_config_url , payload)
	}
	public update_config_url = `${this.env.service_url}/dce/config/update`;
	update_config(payload) {
		return this.http.post<any>( this.update_config_url , payload)
	}
	private remove_config_url = `${this.env.service_url}/dce/config/remove`;
	remove_config(payload) {
		return this.http.post<any>( this.remove_config_url , payload)
	}
	public activate_config_url = `${this.env.service_url}/dce/config/activate`;
	activate_config(payload) {
		return this.http.post<any>( this.activate_config_url , payload)
	}
}
