import { Component, OnInit, Input, ElementRef, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { AltProjectService } from '../../../dce-service/alt-project.service';
import { CommonService } from '../../../dce-service/common.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SharedService } from 'src/app/dce-service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../../dce-service/toastr-utility.service';
import { DatatableSearchComponent } from 'src/app/dce-shared/datatable-search/datatable-search.component';

declare var $: any;

@Component({
  selector: 'app-knowledge-search-all',
  templateUrl: './knowledge-search-all.component.html',
  styleUrls: ['./knowledge-search-all.component.scss']
})
export class KnowledgeSearchAllComponent implements OnInit, OnChanges {
  @ViewChild('search_all' ,{static: false}) search_all: DatatableSearchComponent;

  global_complete_list = [];
  complete_list = [];
  complete_search: FormControl = new FormControl();  
  dt_selected = [];
  selected_row : any = {};
  content_type_data:any = {};
  columns: any = [
    { prop: 'subject', name: "Knowledge Base"},
  ];
  tags_string = '';
  all_data = [];
  @Input() default_search_string?: any = [];
  table_message = {emptyMessage: 'There are no records.'};
  search:boolean=false;
  loadingIndicator:boolean=true;
  total_count=0;
  msg='';
  modal_msg='';
  
  constructor(
    public _altService: AltProjectService,
    private _sharedService: SharedService,
    public _commonService: CommonService,
    private elRef: ElementRef,
    private toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { 
  }

  ngOnInit() {
    // this.complete_datatable_search()
  }

  ngOnChanges(changes: SimpleChanges): void{
    if(changes.default_search_string && changes.default_search_string.currentValue.length){
      this.tags_string = this.default_search_string.join(' ');
      this.get_complete_data();
    }
  }

  get_complete_data(){
    this.total_count=0
    this.complete_search.setValue('');
    this.complete_list = [];
    this.global_complete_list = [];
    this.loadingIndicator=true;
    this.all_data=[];
    this.msg='Fetching Data';
    const payload = {
      "query": this.tags_string
    }

    this._altService.get_search_data(payload).subscribe(res => {
      if(res.errCode == 0) {
        this.all_data = res.data
        if(this.all_data.length == 0){
          this.search=true;
          // this.table_message.emptyMessage = 'No results found. Try a different query'
        }
        this.total_count=res.total_count;
        if(res.total_count > 100){
          this._toastrUtility.basic_configuration.timeOut=15000
          this.toastr.warning('', 'Showing Top 100 Records', this._toastrUtility.basic_configuration)
        }
        this.complete_list = res.data;
        this.global_complete_list = res.data;
      }  
      this.loadingIndicator=false;
      if(this.all_data.length == 0){
        if(payload['query']!=''){
          this.msg='No records matched for entered search criteria. Please try a new search'
        }
      }
    });


    // this._altService.get_multiple_tag_search_data(payload).subscribe(res => {
    //   if(res.errCode == 0) {
    //     this.show_dt = true
    //     this.complete_list = res.data;
    //     this.global_complete_list = res.data;
    //   }  
    // });
  }

  // complete_datatable_search(){
  //   this.complete_search.valueChanges
  //   .pipe(debounceTime(200))
  //   .subscribe(value => {
  //     this.search_in_dt(value);
  //   });
  // }

  search_in_dt(val) {
    const data = this.global_complete_list;
    const complete_columns = this.columns.map(col => col.prop );
    const filtered_data = this._commonService.filter_data_in_datatable(val, complete_columns, data);
    this.complete_list = [...filtered_data];
    if(this.complete_list.length == 0){
      if(val == ''){
        this.table_message.emptyMessage = 'There are no records.'
      }else{
        this.table_message.emptyMessage = 'No records matched for entered search criteria. Please try a new search'
      }
    }
  }

  on_activate(event){
    switch(event.type){
      case 'click':
      this.selected_row = event.row;
      this.open_modal()
      break;
      case 'dblclick':

      break;
      case 'mouseenter':

      break;
    }
  }

  open_modal(){
    $('#complete_modal').modal({backdrop: 'static', keyboard: false});
    this.get_content_type_data();
  }

  close_modal(){
    const audio_player = this.elRef.nativeElement.querySelector('audio');
    if(audio_player){
      audio_player.pause();
    }
    const video_player = this.elRef.nativeElement.querySelector('video');
    if(video_player){
      video_player.pause();
    }
    $('#complete_modal').modal('hide');
  }

  get_content_type_data(){
    this.content_type_data=[];
    this.modal_msg='Fetching Data';
    const payload = {
      "content_base_id": this.selected_row.content_base_id
    }
    this._altService.get_content_type_data(payload).subscribe(res => {
      if(res.errCode == 0) {
        this.content_type_data = res.data;
        this.modal_msg='';
      }
    });
  }

  get_clear_data(){
    this.tags_string='';
    this.complete_list=[];
    this.all_data.length=0;
    this.search=false;
    this.msg='';
  }

  search_dt_role(event){
    switch(event.type){
      case 'search':
      case 'clear':
      case 'change':
        this.search_in_dt(event.data)
      break;
    }
  }

  clear_all_search(){
    this.msg='';
    this.search_all.reset_search();
  }

  search_knowledge_base(event){
    switch(event.type){
      case 'search':
        if(typeof(event.data) == 'string'){
          this.tags_string = event.data;
        }else{
          this.tags_string = this.default_search_string.join(' ');
        }
        this.get_complete_data();
      break;
      case 'clear':
        this.get_clear_data();
      break;
      case 'change':
      break;
    }
  }

  // addTagFn(name) {
  //   return name;
  // }

}
