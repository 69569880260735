import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from "ngx-toastr";
import { ToastrUtilityService } from "../../../dce-service/toastr-utility.service";


interface caseEmitter {
  type : 'link' | 'cancel',
  case_details: object
}
@Component({
  selector: 'app-history-case-link',
  templateUrl: './history-case-link.component.html',
  styleUrls: ['./history-case-link.component.scss']
})
export class HistoryCaseLinkComponent implements OnInit {
  @Output() case_emitter = new EventEmitter<caseEmitter>();
  case_id='';
  case_details={};
  case_details_length=0;
  mask_setting={
    'mask':'S-00000000-000000',
    'dropSpecialCharacters':false
  }
  disable_link= true;

  constructor(
    private _CommonService:CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { }

  ngOnInit() {
  }

  get_case_details(){
    if(this.case_id !=''){
      let payload={
        column_name:'case_id',
        case_id:this.case_id
      }
      this._CommonService.get_case_details(payload).subscribe(res=>{
        if(res.errCode==0){
          if(res.data == null){
            this._toastr.error(
              "",
              "Please enter valid Case Id ",
              this._toastrUtility.basic_configuration
            );
          }
          this.case_details=res.data?res.data[0]:{};
          if(!this.isEmptyObject(this.case_details))
          this.disable_link = false;
        }
      })
    }
    else{
      this._toastr.error(
        "",
        "Please fill the case Id to see the details",
        this._toastrUtility.basic_configuration
      );
    }
    
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  onChangeInput(){
    this.disable_link=true;
  }

  empty_modal(){
    this.case_id='';
    this.case_details={};
  }

  cancel(){
    this.case_emitter.emit({
      type:'cancel',
      case_details: {}
    });
  }
  link_case(){
    this.case_emitter.emit({
      type:'link',
      case_details: this.case_details
    });
  }

}
