import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { DialerComponent } from '../dialer/dialer.component';
import { SipWrapperComponent } from '../sip-wrapper/sip-wrapper.component';
import { SipService } from '../sip.service';
import { check_internet } from 'src/app/dce-service/check_internet';

declare let navigator: Navigator;
import * as prettyMs from 'pretty-ms';
import { CommonService } from 'src/app/dce-service/common.service';
import { isThisQuarter } from 'date-fns';

declare var $: any;

type _state = null | 'local' | 'remote';

@Component({
  selector: 'app-sip-render',
  templateUrl: './sip-render.component.html',
  styleUrls: ['./sip-render.component.scss']
})
export class SipRenderComponent implements OnInit, AfterViewInit {

  @ViewChild(SipWrapperComponent, { static: false }) sip_wrapper: SipWrapperComponent;
  @ViewChild(DialerComponent, { static: false }) dialer: DialerComponent;
  @Output() sip_parent_events: EventEmitter<any> = new EventEmitter<any>();
  is_valid = true
  user = null;
  uri_config = {
    uri: null,
    password: null,
    authorization_user: null
  }
  receiver_config = {
    uri: null
  }
  call_config = {
    call_timer: null,
    call_interval: null,
    pause_timer: null,
    pause_interval: null,
    call_type: null,
    rtc_session_close_status: null,
    incoming_number: null,
    incoming_number_base64: null, // {phone_number: 123} required by case-mngmnt via route
    is_mic_access: false,
    call_autopick_disabled: false,
    is_call_incoming: false,
    is_call_establish: false,
    is_hold: false,
    is_mute: false,
    user_mute: false,
    is_dnd: false,
    service_ongoing: false,
    dialer_toggler: false,
    repeat_caller: false,
    previous_call_id: null,
    pause_titles: {
      fetch: 'View pause duration.',
      sync: 'View updated pause duration.'
    },
    mute_rec_identifier: null,
    call_disconnect_by: null
  }

  dialer_map = {
    'sip:2841755@rtc.telviva.com': 301,
    'sip:2841754@rtc.telviva.com': 300,
    'sip:2841756@rtc.telviva.com': 302
  }
  sip_unregister_flag = false;

  _state: _state = null; // default is null else either local or remote 

  constructor(
    private _env: EnvService,
    public _shared: SharedService,
    private _sipService: SipService,
    private commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private check_internet: check_internet,
  ) {
    this.uri_config = {
      uri: _shared.sip_storage.uri,
      password: _shared.sip_storage.password,
      authorization_user: _shared.sip_storage.authorization_user
    }
    this.receiver_config.uri = 'sip:2841756@rtc.telviva.com';
    const auto_pick = this._shared.sip_storage.telephony_keys ? this._shared.sip_storage.telephony_keys.auto_ans_disabled : true;
    this.call_config.call_autopick_disabled = auto_pick != undefined ? auto_pick : true;
  }

  ngOnInit() {
    this.init_sip_lifecycle();
    this.subscribe_to_call_trigger();
  }

  ngAfterViewInit() {
  }

  init_sip_lifecycle() {
    this._shared.sip_storage.lifecycle_status = 'pre-login';
  }

  subscribe_to_call_trigger() {
    this._shared.sip_storage.call_trigger_observer.subscribe(res => {
      if (res) {
        this.place_call(res);
        this._shared.sip_storage.call_trigger_observer.next(null);
      }
    })
  }

  async login() {
    this._shared.sip_storage.safe_logout = false;
    this._shared.sip_storage.is_loading = true;
    const is_net = await this.check_internet.ret_net_connection();
    const is_mic_access = await this.check_mic_access();
    if (is_mic_access) {
      if (is_net) {
        this.sip_wrapper.retrieve_qm_agent_queues();
        // this.sip_wrapper.invoke_ua();
      } else {
        this._shared.sip_storage.is_loading = false;
        this._toastr.warning('', 'Please check internet connection and try again.', this._toastrUtility.basic_configuration);
      }
    } else {
      this._shared.sip_storage.is_loading = false;
      this.sip_parent_events.emit({ type: 'sip_general', case: 'mic_access', data: false });
    }
  }

  async check_mic_access() {
    if (navigator.mediaDevices === undefined) {
      // Navigator media-device not available for un-secure connection(HTTP)
      return false;
    };
    return await navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  }

  async logout() {
    this._shared.sip_storage.is_loading = true;
    const is_net = await this.check_internet.ret_net_connection();
    if (is_net) {
      if (!this._shared.sip_storage.sip_login) {
        this._shared.sip_storage.lifecycle_status = 'pre-login';
        this.sip_wrapper.logoff_agent_from_queues();
        this.sip_logout();
        this._shared.sip_storage.is_loading = false;
        return;
      }
      this.sip_wrapper.disconnect();
    } else {
      this._shared.sip_storage.is_loading = false;
      this._toastr.warning('', 'Please check internet connection and try again.', this._toastrUtility.basic_configuration);
    }
  }

  async ua_events(e) {
    // console.log(e)
    let is_net = null;
    if (e.case === 'disconnected' || e.case === 'unregistered') {
      is_net = await this.check_internet.ret_net_connection();
    }
    const literal = {
      registered: () => {
        this._shared.sip_storage.sip_login = true;
        this._shared.sip_storage.is_loading = false;
        this._shared.sip_storage.lifecycle_status = 'logged-in';
        // this.sip_wrapper.retrieve_qm_agent_queues(); // move it to login onclick
        // performing actions after joining the queue thirugh evt
      },
      registrationFailed: () => {
        this._shared.sip_storage.is_loading = false;
      },
      disconnected: () => {
        // log if NOT unregistered but disconnected
        if (!this.sip_unregister_flag) {
          this.log_error('disconnect_without_unregister');
          this.on_telephony_unregister(is_net, e);
        } else {
          // do nothing
        }
        this.sip_unregister_flag = false;
      },
      unregistered: () => {
        this.on_telephony_unregister(is_net, e);
      },
      newRTCSession: async () => {
        await this.onRTCSession(e.data);
      }
    }
    if (literal[e.case]) { //fail-safe
      return literal[e.case](this);
    }
  }

  on_telephony_unregister(is_net, e) {
    this.sip_unregister_flag = true;
    this._shared.sip_storage.is_loading = false;
    if (this._shared.sip_storage.safe_logout) {
      // if (this._shared.sip_storage.is_net_on) { // internet checked on logout click.
      this._shared.sip_storage.lifecycle_status = 'pre-login';
      // this._shared.sip_storage.safe_logout = false;
      this._shared.sip_storage.sip_login = false;
      this.sip_wrapper.logoff_agent_from_queues();
      this.sip_logout();
      return;
      // }
    }
    if (this._shared.sip_storage.sip_login) {
      this._shared.sip_storage.sip_login = false;
      this._shared.sip_storage.lifecycle_status = 'pre-login';
      this._toastr.error('', 'Logged Out from Telephony Interface due to abrupt disconnection.', this._toastrUtility.basic_configuration);
      this.maintain_prev_state();
      if (is_net) {
        if (this._env.telephony_conf.err_log_enabled) {
          // log err evt
          this.log_error('telephony_abrupt_disconnect', e);
        }
        // this.sip_wrapper.logoff_agent_from_queues();
        // this.sip_logout();
        // auto-reconnect
        this._toastr.success('', 'Trying to reconnect the Telephony Interface.', this._toastrUtility.basic_configuration);
        this.sip_wrapper.invoke_ua();
      } else {
        this._toastr.warning('', 'Waiting for Internet Connectivity to resume.', this._toastrUtility.basic_configuration);
        this.sip_wrapper.attempt_reconnection();
      }
    }
    this.empty_sip_call_data();
    // this.sip_logout();
  }

  maintain_prev_state() {
    if (this.call_config.is_dnd) {
      this._shared.sip_storage.previous_pause_code = this._shared.sip_storage.current_pause_code;
      this._shared.sip_storage.previous_pause_reason = this._shared.sip_storage.current_pause_reason;
    } else {
      this.clear_prev_pause();
    }
  }

  clear_prev_pause() {
    this._shared.sip_storage.previous_pause_code = null;
    this._shared.sip_storage.previous_pause_reason = null;
  }

  log_error(logtype, data = null) {
    const payload = {
      'log_type': logtype,
      'data': data
    };
    this.commonService.log_error(payload).subscribe(res => {
      if (res.errCode === 0) {

      }
    })
  }


  call_events(e) {
    // console.log('call_events in parent', e)
    // this._state = e.data.originator;
    const literal = {
      sdp: () => {
        if (e.data.type && e.data.sdp) {
          this.log_sdp_codec('sdp_' + e.data.type, e.data.sdp);
        }
      },
      connecting: () => {
        // this._shared.sip_storage.lifecycle_status = 'call-fire';
        if (e.data.request.sdp && e.data.request.sdp.media) {
          const audio_media = e.data.request.sdp.media.find(media => { return media.type === 'audio' });
          const codec_data = audio_media.rtp;
          this.log_sdp_codec(e.case, codec_data);
        }
      },
      accepted: () => {
        this._shared.sip_storage.lifecycle_status = 'call-on';
        this.is_repeat_caller();
        this.call_connect_audio();
        this.log_call_answer();
        this.start_call_timer();
        setTimeout(() => {
          if (this._shared.sip_storage.lifecycle_status === 'call-on') {
            this.get_qm_stats();
          }
        }, this._env.telephony_conf.get_qm_stats_fire_after);
        if (this.call_config.call_type === 'incoming') {
          this._shared.sip_storage.cus_info_toggle = true;
        } else {
          this._shared.sip_storage.cus_info_toggle = false;
        }
      },
      ended: async () => {
        this._shared.sip_storage.lifecycle_status = 'logged-in';
        this.call_config.rtc_session_close_status = e.data.cause;
        this.call_config.call_disconnect_by = e.data.originator;
        this.call_config.user_mute = false;
        this.call_disconnect_audio();
        if (this.call_config.is_mute) {
          await this.log_mute("call_unmute");
        }
        if (this.sip_wrapper.is_call_onhold()) {
          await this.log_call_unhold_end();
        } else {
          await this.log_call_end();
        }
        this.empty_sip_call_data();
      },
      failed: () => {
        this._shared.sip_storage.lifecycle_status = 'logged-in';
        this.call_config.rtc_session_close_status = e.data.cause;
        this.call_config.call_disconnect_by = e.data.originator;
        this.call_config.user_mute = false;
        this.log_call_end();
        this.empty_sip_call_data();
      },
      hold: () => {
        this.call_config.is_hold = true;
        this.log_call_hold();
      },
      unhold: () => {
        this.call_config.is_hold = false;
        this.log_call_unhold();
      },
      muted: () => {
        if (!this.call_config.user_mute) { // physical mute
          this.unmute_call();
          return;
        }
        this.call_config.is_mute = true;
      },
      unmuted: () => {
        if (this.call_config.user_mute) { // physical mute
          this.mute_call();
          return;
        }
        this.call_config.is_mute = false;
      }
    }
    if (literal[e.case]) { //fail-safe
      return literal[e.case](this);
    }
  }

  wrapper_state_events(e) {
    const literal = {
      init: () => {
        // this.sip_wrapper.sip_user_config = this.uri_config;
      },
      mic_access: () => {
        this.call_config.is_mic_access = e.data;
        this.sip_parent_events.emit({ type: 'sip_general', case: 'mic_access', data: e.data });
      },
      ua_configured: () => {
      },
      login_agent_to_queue: () => {
        this._shared.sip_storage.qm_login = true;
        this.sip_wrapper.invoke_ua();
        this.clear_call_actions();
        this.sip_login();
        if (this._shared.sip_storage.previous_pause_code) {
          this.sip_dndon(true);
          this.clear_prev_pause();
        }
      },
      telephony_paused: () => {
        // if (e.data) {
        //   this.start_pause_timer();
        // } else {
        //   this.end_pause_timer();
        // }
      },
      telephony_paused_err: () => {
        this._toastr.warning('', 'Error Encountered while Pausing Telephony.', this._toastrUtility.basic_configuration);
      },
      telephony_unpaused_err: () => {
        this._toastr.warning('', 'Error Encountered while Unpausing Telephony.', this._toastrUtility.basic_configuration);
      },
      call_disposition: async () => {
        if (e.data) {
          await this.add_disposition_code();
        } else {
          this._toastr.warning('', 'Error Encountered while applying call disposition.', this._toastrUtility.basic_configuration);
        }
      },
      sip_reconnect_success: () => {
        this.empty_sip_call_data();
        // this.sip_logout();
      }
    }
    if (literal[e.case]) { //fail-safe
      return literal[e.case](this);
    }
  }

  dialer_events(e) {
    const literal = {
      place_call: () => {
        this.place_call(e);
      },
      transfer_call: () => {
        const sip_uri = e.data.number;
        this.sip_wrapper.transfer_call(sip_uri);
      },
      toggle_dialer: () => {
        this.toggle_dialer();
      }
    }
    if (literal[e.case]) { //fail-safe
      return literal[e.case](this);
    }
  }

  place_call(e) {
    console.log(e)
    const sip_uri = e.data.number;
    this.sip_wrapper.call_ua(sip_uri);
  }

  open_sip_logout_modal() {
    this.sip_parent_events.emit({ type: 'sip_general', case: 'sip_logout_modal', data: null });
  }

  open_repeat_caller_modal() {
    this.sip_parent_events.emit({ type: 'sip_general', case: 'repeat_caller_modal', data: null });
  }

  empty_sip_call_data() {
    this._shared.set_sip_call_data({
      call_id: null,
      incoming_number: null
    });
    this._shared.sip_storage.call_log_table_id = null;
    this._shared.sip_storage.disposition_dict = null;
    this._shared.sip_storage.telephone_log2target = null;
    this.call_config.rtc_session_close_status = null;
    this._shared.sip_storage.call_log_table_id = null;
    this._shared.sip_storage.call_hold_id = null;
    this.call_config.is_hold = false;
    this.call_config.is_mute = false;
    this.call_config.call_disconnect_by = null;
  }

  start_call_timer() {
    this.end_call_timer();
    let timer = 0;
    let interval = 1000;
    this.call_config.call_interval = setInterval(() => {
      timer = timer + interval;
      this.call_config.call_timer = prettyMs(timer);
    }, interval);
  }

  end_call_timer() {
    clearInterval(this.call_config.call_interval);
    this.call_config.call_timer = null;
  }

  start_pause_timer() {
    this.end_pause_timer();
    let timer = 0;
    let prev_timer = 0;
    let interval = 1000;
    this.call_config.pause_interval = setInterval(() => {
      timer = timer + interval;
      console.log('prev_timer:', prev_timer);
      console.log('curr_timer:', timer);
      if (prev_timer > timer) {
        console.log('Error:::::::', interval, timer);
        clearInterval(this.call_config.pause_interval);
      }
      prev_timer = timer;
      this.call_config.pause_timer = prettyMs(timer);
    }, interval);
  }

  end_pause_timer() {
    clearInterval(this.call_config.pause_interval);
    this.call_config.pause_timer = null;
  }

  async onRTCSession(data) {
    this._shared.sip_storage.qm_call_id = null;
    const call_direction = data.session.direction;
    const _from = data.request.from.uri.user;
    let _to = data.request.to.uri.user;
    this.call_config.call_type = call_direction;
    if (call_direction === 'incoming') {
      this._shared.sip_storage.lifecycle_status = 'call-incoming';
    } else {
      this._shared.sip_storage.lifecycle_status = 'call-outgoing';
      const oubtound_call_prefix = this._shared.sip_storage.telephony_keys.outbound_call_prefix;
      // remove outbound_call_prefix from _to
      _to = _to.split(oubtound_call_prefix)[1];
      this._shared.sip_storage.cus_info_toggle = false;
      // this.cus_info_toggle(); // diasbled customer information toggle for now
    }
    this._shared.set_sip_call_data({
      call_id: data.request.call_id,
      incoming_number: call_direction == 'incoming' ? _from : _to
    });
    await this.log_call_start();
    if (!this.call_config.call_autopick_disabled && this._shared.sip_storage.lifecycle_status == 'call-incoming') {
      this.sip_wrapper.answer_call();
    }
  }

  getDateTime() {
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    return cDate + '.' + cTime;
  }

  get_qm_stats() {
    // const payload = {
    //   telephone_call_id: this._shared.sip_storage.call_id,
    //   agent_id: this._shared.sip_storage.authorization_user
    // };
    // this._sipService.get_qm_stats(payload).subscribe(res => {
    //   if (res.errCode === 0) {
    //     if (res.datarec['RealTimeDO.RtCallsRaw']) {
    //       this.fetch_and_set_qm_call_id(res.datarec['RealTimeDO.RtCallsRaw']);
    //     }
    //   }
    // })

    const payload = `agent/${this._shared.sip_storage.authorization_user}`
    const request_time = this.getDateTime()
    this._sipService.retrieve_realtime_qm_stats(payload).subscribe(res => {
      const response_time = this.getDateTime()
      const raw_call_details = res["RealTimeDO.RtCallsRaw"]
      this.fetch_and_set_qm_call_id(raw_call_details);
      this.generate_log_data(request_time, response_time, res)
    })
  }

  generate_log_data(request_time, response_time, res) {
    const result_dict = res.result
    const raw_call_details = res["RealTimeDO.RtCallsRaw"]
    const log_type = this.set_qm_stats_log_type(result_dict, raw_call_details)
    const log_data = {
      "log_type": log_type,
      "data": {
        "service": this._sipService.retrieve_realtime_qm_stats,
        "request_time": request_time,
        "status": result_dict[0][1],
        "response_time": response_time,
        "logs_count": (raw_call_details.length) - 1,
        "agent": this._shared.sip_storage.authorization_user,
        "response": res,
      }
    }
    this.log_error(log_type, log_data);
  }

  set_qm_stats_log_type(result_dict, raw_call_details) {
    if (result_dict[0][1] != "OK") {
      return "qm_realtime_stats_status_error"
    }
    const ret_reason = {
      0: "qm_realtime_stats_no_log_found_error",
      1: "qm_realtime_stats_no_log_found_error",
      2: "qm_realtime_stats",
    }
    if (ret_reason[raw_call_details.length]) {
      return ret_reason[raw_call_details.length]
    } else {
      return "qm_realtime_stats_multiple_logs_found_error"
    }
  }

  is_repeat_caller() {
    this.call_config.repeat_caller = false;
    this.call_config.previous_call_id = null;
    const payload = {
      incoming_number: this._shared.sip_storage.incoming_number,
    };
    this._sipService.is_repeat_caller(payload).subscribe(res => {
      if (res.errCode === 0) {
        this.call_config.repeat_caller = res.datarec.is_repeat_caller;
        this.call_config.previous_call_id = res.datarec.previous_call_id;
        if (this.call_config.repeat_caller) {
          this.open_repeat_caller_modal();
        }
      }
    })
  }

  async fetch_and_set_qm_call_id(data) {
    if (Array.isArray(data) && data.length == 2) {
      const call_id_index = data[0].indexOf('RT_callId');
      this._shared.sip_storage.qm_call_id = data[1][call_id_index];
      await this.add_disposition_code(true);
    }
  }

  clear_call_actions() {
    this.call_config.is_dnd = false;
    this.call_config.is_hold = false;
    this.call_config.is_mute = false;
  }

  sip_login() {
    const payload = {};
    this._sipService.telephony_session('login', payload).subscribe(res => {
      if (res.errCode === 0) {

      }
    });
  }

  async sip_logout(register_ua = false) {
    if (this.call_config.is_dnd) {
      this.sip_dndoff(register_ua);
    }
    const payload = {};
    const res = await this._sipService.telephony_session('logout', payload).toPromise();
    if (res.errCode === 0) {

    }
  }

  patch_pause_code(obj) {
    this._shared.sip_storage.is_loading = true;
    this.call_config.pause_timer = null;
    this._shared.sip_storage.current_pause_code = obj.pause_code;
    this._shared.sip_storage.current_pause_reason = obj.pause_reason;
    this.sip_dndon();
  }

  sip_dndon(reconnection = false) {
    // const pause_reason = this.extract_pause_reason();
    this._shared.sip_storage.sip_login = false;
    try {
      this.sip_wrapper.ua.stop();
    } catch (error) {
      this._shared.sip_storage.sip_login = true;
      this._shared.sip_storage.is_loading = false;
      this._shared.sip_storage.lifecycle_status = 'logged-in';
      console.log("Err starting UA: ", error);
    }
    const payload = {
      pause_reason: reconnection ? this._shared.sip_storage.previous_pause_reason : this._shared.sip_storage.current_pause_reason
    };
    if (this.validate_pause_telephony()) {
      this._sipService.telephony_session('dndon', payload).subscribe(res => {
        if (res.errCode === 0) {
          this.call_config.is_dnd = true;
        }
      });
      this.sip_wrapper.pause_agent(reconnection);
    }
  }

  get_dnd_duration() {
    const payload = {};
    if (this.validate_pause_telephony()) {
      this._sipService.telephony_session('get_dnd_duration', payload).subscribe(res => {
        if (res.errCode === 0) {
          const duration_ms = res.duration * 1000;
          this.call_config.pause_timer = prettyMs(duration_ms);
        }
      });
    }
  }

  extract_pause_reason() {
    for (let i = 0; i < this._shared.sip_storage.available_pause_codes.length; i++) {
      const index = this._shared.sip_storage.available_pause_codes[i];
      if (this._shared.sip_storage.current_pause_code == index.pause_code) {
        return index.pause_reason;
      }
    }
  }

  validate_pause_telephony() {
    if (this._shared.sip_storage.previous_pause_code) {
      return true;
    }
    if (this._shared.sip_storage.current_pause_code === null) {
      this._toastr.warning('', 'Please Select a Pause Reason !', this._toastrUtility.basic_configuration);
      return false;
    }
    return true;
  }

  sip_dndoff(register_ua = false) {
    this._shared.sip_storage.is_loading = true;
    if (register_ua) {
      // this._shared.sip_storage.sip_login = true;
      this.sip_wrapper.start_register_listen_ua();
    }
    const payload = {};
    this._shared.sip_storage.current_pause_code = null;
    this._shared.sip_storage.current_pause_reason = null;
    this._sipService.telephony_session('dndoff', payload).subscribe(res => {
      if (res.errCode === 0) {
        this.call_config.is_dnd = false;
      }
    });
    this.sip_wrapper.unpause_agent();
  }

  async log_call_start() {
    this.call_config.service_ongoing = true;
    const payload = {
      telephone_call_id: this._shared.sip_storage.call_id,
      telephone_log2target: this._shared.sip_storage.telephone_log2target,
      incoming_number: this._shared.sip_storage.incoming_number,
      incoming_transfer_number: null,
      call_type: this.call_config.call_type
    }
    const res = await this._sipService.telephony_log_call('start', payload).toPromise();
    this.call_config.service_ongoing = false;
    if (res.errCode === 0) {
      this._shared.sip_storage.call_log_table_id = res.data.id;
    }
  }

  async log_call_answer() {
    this.call_config.service_ongoing = true;
    const payload = {
      telephone_call_id: this._shared.sip_storage.call_id,
    }
    const res = await this._sipService.telephony_log_call('answer', payload).toPromise();
    this.call_config.service_ongoing = false;
    if (res.errCode === 0) {
      this._shared.sip_storage.call_log_table_id = res.data.id;
    }
  }

  async log_call_hold() {
    this.call_config.service_ongoing = true;
    const payload = {
      telephone_call_id: this._shared.sip_storage.call_id,
    }
    const res = await this._sipService.telephony_log_call('hold', payload).toPromise();
    this.call_config.service_ongoing = false;
    if (res.errCode === 0) {
      this._shared.sip_storage.call_hold_id = res.data.id;
    }
  }


  async log_call_unhold() {
    this.call_config.service_ongoing = true;
    const payload = {
      telephone_call_id: this._shared.sip_storage.call_id,
    }
    const res = await this._sipService.telephony_log_call('unhold', payload).toPromise();
    this.call_config.service_ongoing = false;
    if (res.errCode === 0) {
      this._shared.sip_storage.call_hold_id = null;
      this.call_config.is_hold = false;
    }
  }

  mute_call() {
    this.log_mute('call_mute');
    this.call_config.user_mute = true;
    this.sip_wrapper.rtcSession.mute();
  }

  unmute_call() {
    this.log_mute('call_unmute');
    this.call_config.user_mute = false;
    this.sip_wrapper.rtcSession.unmute();
  }

  create_mute_rec_identity() {
    const identity = Date.now() + '|' + this._shared.sip_storage.call_id;
    this.call_config.mute_rec_identifier = identity;
  }

  async log_mute(log_type) {
    if (log_type == 'call_mute') {
      // log mute in DB
      await this.log_mute_db();
      this.create_mute_rec_identity();
    } else {
      await this.log_unmute_db();
    }
    const payload = {
      'log_type': log_type,
      'data': {
        'rec_identifier': this.call_config.mute_rec_identifier
      }
    };
    this.commonService.log_error(payload).subscribe(res => {
      if (res.errCode === 0) {

      }
    })
  }

  async log_mute_db() {
    const payload = {
      telephone_call_id: this._shared.sip_storage.call_id,
      event_type: "mute_unmute_call_event"
    };
    const res = await this._sipService.telephony_log_call('startevent', payload).toPromise();
      if (res.errCode === 0) {

      }
  }

  async log_unmute_db() {
    const payload = {
      telephone_call_id: this._shared.sip_storage.call_id,
      event_type: "mute_unmute_call_event"
    };
    const res = await this._sipService.telephony_log_call('endevent', payload).toPromise();
      if (res.errCode === 0) {

      }
  }

  log_sdp_codec(log_evt, data) {
    if (this._env.telephony_conf.codec_log_enabled) {
      const payload = {
        'log_type': 'codec_log_' + log_evt,
        'data': data
      };
      this.commonService.log_error(payload).subscribe(res => {
        if (res.errCode === 0) {

        }
      })
    }
  }

  async log_call_end() {
    this.call_config.service_ongoing = true;
    const payload = {
      telephone_call_id: this._shared.sip_storage.call_id,
      rtc_session_close_status: this.call_config.rtc_session_close_status,
      previous_call_id: this.call_config.previous_call_id,
      call_disconnect_by: this.call_config.call_disconnect_by === 'local' ? 'agent' : 'customer'
    }
    const res = await this._sipService.telephony_log_call('end', payload).toPromise();
    this.call_config.service_ongoing = false;
    if (res.errCode === 0) {

    }
  }

  async log_call_unhold_end() {
    await this.log_call_unhold();
    await this.log_call_end();
  }

  async log_call_start_ans() {
    await this.log_call_start();
    await this.log_call_answer();
  }

  patch_disposition(_code_dict) {
    this._shared.sip_storage.disposition_dict = _code_dict;
    this.sip_wrapper.dispose_code();
  }

  async add_disposition_code(set_qm_call_id = false) {
    let payload = {};
    if (set_qm_call_id) {
      payload = {
        telephone_call_id: this._shared.sip_storage.call_id,
        third_party_call_id: this._shared.sip_storage.qm_call_id,
      };
    } else {
      payload = {
        telephone_call_id: this._shared.sip_storage.call_id,
        reason_code: this._shared.sip_storage.disposition_dict.value_value,
      };
    }
    const res = await this._sipService.telephony_log_call('add_disposition_code', payload).toPromise();
      if (res.errCode === 0) {

      }
  }

  // dialer methods
  toggle_dialer() {
    this.call_config.dialer_toggler = !this.call_config.dialer_toggler;
    // focus num input
    setTimeout(() => {
      this.dialer.sipNumInput.nativeElement.focus();
    });
  }

  // customer-info methods
  cus_info_toggle() {
    this._shared.sip_storage.cus_info_toggle = true;
  }

  // audio pass
  call_connect_audio() {
    const audio_path = '/assets/telephony_sounds/call_connect.wav';
    let audio = new Audio(audio_path); // audio .mp3 etc to be passed
    audio.play();
  }

  call_disconnect_audio() {
    const audio_path = '/assets/telephony_sounds/call_disconnect.wav';
    let audio = new Audio(audio_path); // audio .mp3 etc to be passed
    audio.play();
  }

}
