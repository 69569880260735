import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { EnvService } from 'src/app/dce-service/env.service';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../../dce-service/toastr-utility.service';
import { CommonService } from '../../../dce-service/common.service';
declare var $:any;

interface ongoingChatEmitter {
  type : 'document' | 'text',
  document_data_list: any;
  message: string;
}

interface typingIndicatorEmitter {
  type : 'typing';
}

@Component({
  selector: 'app-ongoing-chat-composer',
  templateUrl: './ongoing-chat-composer.component.html',
  styleUrls: ['./ongoing-chat-composer.component.scss']
})
export class OngoingChatComposerComponent implements OnInit, OnDestroy,AfterViewInit {
  @ViewChild('text_container', {static: false}) private text_container: ElementRef;
  @Input() live_chat_session_id:any = null;
  doc_files:any=[];
  @Output() ongoing_chat_emitter = new EventEmitter<ongoingChatEmitter>();
  @Output() show_files = new EventEmitter<any>();
  @Output() input_focused = new EventEmitter<any>();
  @Output() typing_indicator_event = new EventEmitter<typingIndicatorEmitter>();
  @Output() send_left_character_count = new EventEmitter<number>();


  input_data:string='';
	max_file_size = this._env.max_file_size;
  max_file_count = this._env.max_file_count;
  chat_template_list:any=[];
  constructor(
    private _toastr : ToastrService,
    private _toastrUtility : ToastrUtilityService,
    public _env : EnvService,
    public _liveChatService: LiveChatService,
    private _commonService: CommonService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    this.get_all_email_content();
  }

  get_all_email_content() {
    const payload = {
      "record_type": 'live_chat'
    }
    if(this.chat_template_list.length == 0) {
      this._commonService.get_all_email_content(payload).subscribe(res => {
        if(res.errCode ==0 ) {
          this.chat_template_list = res.data;
        }
      })
    }
  }

  ngOnDestroy() {
    // const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(sess_id => sess_id.session_id == this.live_chat_session_id);
    // this._liveChatService.live_chat_sessions[sessionIndex].focus_input_observable.unsubscribe()
  }


  // ngOnChanges(changes: SimpleChanges) {
  //   console.log(changes)
  //   if(changes.live_chat_session_id) {
  //     // setTimeout(() => {
  //       document.getElementById('composer-' + changes.live_chat_session_id.currentValue).focus();
  //     // }, 2000);
  //   }
  // }

  ngAfterViewInit() {
    // console.log(this.live_chat_session_id)
    document.getElementById('composer-' + this.live_chat_session_id).focus();
  }

  msg_composer_focus_event() {
    // const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(sess_id => sess_id.session_id == this.live_chat_session_id);
    // console.log(this._liveChatService.live_chat_sessions[sessionIndex].focus_input_observable)
    // if(!this._liveChatService.live_chat_sessions[sessionIndex].focus_input_observable.closed) {
    //   this._liveChatService.live_chat_sessions[sessionIndex].focus_input_observable.subscribe(res => {
    //     console.log(document.getElementById('composer-' + res));
    //     console.log(res);
    //     setTimeout(() => {
    //       console.log(document.getElementById('composer-' + res));
    //     },3000)
    //     document.getElementById('composer-' + res).focus();
    //   })
    // }
    // if(!this._liveChatService.selected_session_observable.closed) {
    //   this._liveChatService.selected_session_observable.subscribe(res => {
    //     console.log(res);
    //     setTimeout(() => {
    //       if(document.getElementById('composer-' + res.session_id)) {
    //         document.getElementById('composer-' + res.session_id).focus();
    //       }
    //     },500)
    //   })
    // }
  }

  clear_input(){
    this.input_data='';
  }

  onActionGenerated() {
    const doc_files= this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload;
    if(doc_files.length>0){
      this.ongoing_chat_emitter.emit({
        type:'document',
        document_data_list: doc_files,
        message: ''
      });
      this.reset_files();
      this.empty_file();
      setTimeout(()=>{
        this.send_user_text();
      },1000)
    }
    else{
      this.send_user_text();
    }
    // document.getElementById('message-composer').focus();
    return;
  } 

  send_user_text(){
    if(!(/^\s*$/.test(this.input_data))){
      this.ongoing_chat_emitter.emit({
        type:'text',
        document_data_list: [],
        message: this.input_data
      });
    }
    this.input_data='';
    this.send_left_character_count.emit(this._env.live_chat.max_character_limit - this.input_data.length);
    setTimeout(()=>{
      this.autoResize();
    },10)
  }

  // this.socket_event_data.next(data)

  reset_files(){
    this.doc_files = [];
    this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload = [];
    this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload_change.next(false);
  }

  onchangeDetect = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.onActionGenerated();
    }
    this.typing_indicator_event.emit({type: 'typing'})
  }

  autoResize(){
    const textarea = this.el.nativeElement.querySelector(`#composer-${this.live_chat_session_id}`);
    let content = textarea.value == '' ? this.input_data : textarea.value;
    this.text_container.nativeElement.innerHTML = content;
    // $('#text-container').html(content + 'a');
    let row: number;
    const divText = this.el.nativeElement.querySelector('#text-container');
    row=(divText.scrollHeight-10)/24;
    if(divText.scrollHeight<=(this._env.live_chat.max_visible_rows*24)+10){
      $(`#composer-${this.live_chat_session_id}`).css("height",divText.scrollHeight + 'px');
      $(`#composer-${this.live_chat_session_id}`).css("overflow",'hidden');
    }
    else{
      $(`#composer-${this.live_chat_session_id}`).css("overflow-y",'scroll');
      $(`#composer-${this.live_chat_session_id}`).css("height",(this._env.live_chat.max_visible_rows*24)+10);
      textarea.scrollTop = textarea.scrollHeight;
    }
  }

  onChangeInput(){
    this.autoResize();
    this.send_left_character_count.emit(this._env.live_chat.max_character_limit - this.input_data.length);
  }

  onFocus(type,event) {
    this.input_focused.emit({type: type});
  }

  upload_file(fileInput){
		var self = this;
    if (fileInput.target.files && fileInput.target.files[0]) {
      if(fileInput.target.files.length + this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload.length> this.max_file_count){
        this.empty_file();
        this._toastr.warning('','Cannot select more than ' + this.max_file_count +' files', this._toastrUtility.basic_configuration);
      }
      else{
        var file_names:any = [];
        for(let i=0; i<fileInput.target.files.length; i++){
            if(fileInput.target.files[i].size > this.max_file_size){
              this._toastr.warning('Filename: '+ fileInput.target.files[i].name,'File is too big', this._toastrUtility.basic_configuration);
            }
            else if(fileInput.target.files[i].size==0){
              this._toastr.warning('Filename: '+ fileInput.target.files[i]['name'],'Cannot select empty files', this._toastrUtility.basic_configuration);
            }
            file_names.push(fileInput.target.files[i].name);
        }
        this.readFile(0, fileInput.target.files, file_names);
        document.getElementById('composer-' + this.live_chat_session_id).focus();
      }
    }
  }

  readFile(index, files, file_names) {
    var self = this;
    if( index >= files.length ) {
      this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload=[...this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload, ...this.doc_files];
      this._liveChatService.live_chat_sessions[this._liveChatService.selected_session_index].document_to_upload_change.next(true);
      this.doc_files = [];
      this.empty_file();
      return
    }
    var file = files[index];
    var reader = new FileReader();
    reader.onload = function (e: any) {
      if(files[index].size<=self.max_file_size && files[index].size > 0){
      let file_obj = {
        "document_name": file_names[index],
        "document_data": e.target.result,
        "size": files[index].size
      }
      self.doc_files.push(file_obj);
    }
      self.readFile(index + 1, files, file_names);
    }
    reader.readAsDataURL(file);
    return self.doc_files;
  }

  empty_file(){
	  var input = $("#upload_file");
    input.files=[];
    input.val('');
  }

  onTemplateChanged(index){
    if(index==-1){
      this.input_data='';
    }
    else{
      this.input_data = this.chat_template_list[index].body.slice(0,this._env.live_chat.max_character_limit);
      this.onChangeInput();
      document.getElementById('composer-' + this.live_chat_session_id).focus();
    }
  }
}