import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class KeysInfoConfig {
    key_events_info = {

        classification: [
            {   
                feature: 'Toggle Shortcuts Panel',
                keys: [
                    {
                        case: 'alt_h',
                        combination: ['Alt','H'],
                        description: 'Toggle Shortcuts Panel'
                    },
                ],    
            },
            {   
                feature: 'Navigation',
                keys: [
                    {
                        case: 'alt_shift_b',
                        combination: ['Alt','Shift','B'],
                        description: 'To Go Back'
                    },
                ],    
            },

            {
                feature: 'Toggle Pane',
                keys: [
                    {
                        case: 'alt_shift_arrowleft',
                        combination: ['Alt','Shift','<-'],
                        description: 'Expand Left Pane'
                    },
                    {
                        case: 'alt_shift_arrowright',
                        combination: ['Alt','Shift','->'],
                        description: 'Expand Right Pane'
                    },
                ],
            },

            {
                feature: 'CRUD Operations',
                keys: [
                    {
                        case: 'control_alt_n',
                        combination: ['Ctrl','Alt','N'],
                        description: 'New Classification',
                    },
                    {
                        case: 'control_alt_a',
                        combination: ['Ctrl','Alt','A'],
                        description: 'Add Sub-Classification'
                    },
                    {
                        case: 'control_alt_r',
                        combination: ['Ctrl','Alt','R'],
                        description: 'Deactivate Sub-Classification'
                    },
                    {
                        case: 'control_alt_e',
                        combination: ['Ctrl','Alt','E'],
                        description: 'Edit Sub-classification'
                    },
                ],
            }
        ],

        case_management: [
            {
                feature: 'Toggle Shortcuts Panel',
                keys: [
                    {
                        case: 'alt_h',
                        combination: ['Alt','H'],
                        description: 'Toggle Shortcuts Panel'
                    },
                ]
            },
            {
                feature: 'Workflow Shortcuts',
                keys: [
                    {
                        case:'control_alt_n',
                        combination:['Ctrl','Alt','N'],
                        description:'New case'
                    },
                    {
                        case:'alt_shift_c',
                        combination: ['Alt','Shift','C'],
                        description: 'Open Case Details'
                    },
                    {
                        case:'alt_shift_n',
                        combination: ['Alt','Shift','N'],
                        description: 'Open Note Logs'
                    },
                    {
                        case:'alt_shift_w',
                        combination: ['Alt','Shift','W'],
                        description: 'Open Workflow Logs'
                    },
                    {
                        case:'alt_shift_l',
                        combination: ['Alt','Shift','L'],
                        description: 'Open Notification Logs'
                    },
                    {
                        case:'alt_shift_a',
                        combination: ['Alt','Shift','A'],
                        description: 'Open Audit Trails'
                    },
                ]

            },
            {
                feature: 'Log a note',
                keys:[
                    {
                        case:'alt_shift_o',
                        combination:['Alt','Shift','O'],
                        description:'To Open Notes Log'
                    },
                    {
                        case:'alt_shift_e',
                        combination:['Alt','Shift','E'],
                        description:'To Open Email Log'
                    },
                    {
                        case:'alt_shift_p',
                        combination:['Alt','Shift','P'],
                        description:'To Open Phone Log'
                    },
                ]
            },
            {
                feature: 'Toggle Pane',
                keys: [
                    {
                        case: 'alt_shift_arrowleft',
                        combination: ['Alt','Shift','<-'],
                        description: 'Expand Left Pane'
                    },
                    {
                        case: 'alt_shift_arrowright',
                        combination: ['Alt','Shift','->'],
                        description: 'Expand Right Pane'
                    },
                ],
            },
            {
                feature:'Sort Cases',
                keys: [
                    {
                        case: 'alt_a',
                        combination: ['Alt','A'],
                        description: 'Sort In Ascending Order'
                    },
                    {
                        case: 'alt_t',
                        combination: ['Alt','T'],
                        description: 'Sort In Descending Order'
                    },
                ]
            },
            {
                feature: 'Reload Cases',
                keys: [
                    {
                        case: 'alt_z',
                        combination: ['Alt','Z'],
                        description: 'Reload Cases'
                    },
                ],
            },
            {
                feature: 'Button Clicks',
                keys: [
                    {
                        case: 'alt_c',
                        combination: ['Alt','C'],
                        description: 'Close Case'
                    },
                    {
                        case: 'alt_s',
                        combination: ['Alt','S'],
                        description: 'Assign'
                    },
                    {
                        case: 'alt_l',
                        combination: ['Alt','L'],
                        description: 'Dispatch'
                    },
                    {

                        case: 'control_alt_a',
                        combination: ['Ctrl','Alt','A'],
                        description: 'Accept'
                    },
                    {
                        case: 'control_alt_r',
                        combination: ['Ctrl','Alt','R'],
                        description: 'Reject'
                    },
                ],
            },
        ],
        search_cases: [
            {
                feature: 'Toggle Shortcuts Panel',
                keys: [
                    {
                        case: 'alt_h',
                        combination: ['Alt','H'],
                        description: 'Toggle Shortcuts Panel'
                    },
                ]
            },
            {
                feature: 'Filter Events',
                keys: [
                    {
                        case: 'ctrl_alt_s',
                        combination: ['Ctrl','Alt','F'],
                        description: 'Search Case'
                    },
                    {
                        case: 'ctrl_alt_r',
                        combination: ['Ctrl', 'Alt','R'],
                        description: 'Reset Filters'
                    },
                    {
                        case: 'ctrl_alt_b',
                        combination: ['Ctrl', 'Alt','B'],
                        description: 'Change Filters'
                    }
                ],   
            },
            {
                feature: 'Navigate',
                keys: [
                    {
                        case:'alt_shift_c',
                        combination: ['Alt','Shift','C'],
                        description: 'Open Case Details'
                    },
                    {
                        case:'alt_shift_n',
                        combination: ['Alt','Shift','N'],
                        description: 'Open Note Logs'
                    },
                    {
                        case:'alt_shift_w',
                        combination: ['Alt','Shift','W'],
                        description: 'Open Workflow Logs'
                    },
                    {
                        case:'alt_shift_l',
                        combination: ['Alt','Shift','L'],
                        description: 'Open Notification Logs'
                    },
                    {
                        case:'alt_shift_a',
                        combination: ['Alt','Shift','A'],
                        description: 'Open Audit Trails'
                    },
                ]

            },
            {
                feature: 'Toggle Pane',
                keys: [
                    {
                        case: 'alt_shift_arrowleft',
                        combination: ['Alt','Shift','<-'],
                        description: 'Expand left pane'
                    },
                    {
                        case: 'alt_shift_arrowright',
                        combination: ['Alt','Shift','->'],
                        description: 'Expand right pane'
                    },
                ],
            },
            {
                feature: 'Datatable Events',
                keys: [
                    {
                        case:'arrowdown',
                        combination: ['Arrow-Down'],
                        description: 'Navigate down in datatable' 
                    },
                    {
                        case:'arrowup',
                        combination: ['Arrow-Up'],
                        description: 'Navigate up in datatable'
                    },
                ]
            }
            
        ],
        // Shortcut keys for telephony should be unique 
        telephony: [
            {   
                feature: 'Toggle Shortcuts Panel',
                keys: [
                    {
                        case: 'alt_h',
                        combination: ['Alt','H'],
                        description: 'Toggle Shortcuts Panel'
                    },
                ],    
            },
            {
                feature: 'Operations',
                keys: [
                    {
                        case: 'alt_r',
                        combination: ['Alt','R'],
                        description: 'To End Call',
                    },
                    {
                        case: 'alt_m',
                        combination: ['Alt','M'],
                        description: 'To Mute/Unmute'
                    },
                    {
                        case: 'alt_o',
                        combination: ['Alt','O'],
                        description: 'To Hold/Unhold'
                    },
                    {
                        case: 'alt_i',
                        combination: ['Alt','I'],
                        description: 'To Login'
                    },
                    {
                        case: 'alt_k',
                        combination: ['Alt','K'],
                        description: 'To Logout'
                    },
                ],
            }
        ],
    }
}