import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { ConfigService } from '../config.service';
import { FontawesomeService } from '../dynamic-form/fontawesome.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../dynamic-form/show-errors/custom-validators.component';
declare var $: any;

interface ChatListComposerEmitter {
  case: String;
  data: Object;
}

interface ChatBubbleEmitter {
  case: String;
  data: Object;
}

@Component({
  selector: 'app-chat-list-composer',
  templateUrl: './chat-list-composer.component.html',
  styleUrls: ['./chat-list-composer.component.scss']
})
export class ChatListComposerComponent implements OnInit {
  @ViewChild('message_scroll', { static: false }) private message_scroll: ElementRef;
  @ViewChild('option_scroll', { static: false }) private option_scroll: ElementRef;
  decoreMessage: string = 'LOADING...'
  @Input() messages: any = [];
  @Input() config_list: any = {};
  @Input() user_data: any;
  @Input() chat_state: any;
  @Input() show_typing_indicator: boolean = false;
  @Input() close_chat: boolean = false;
  @Input() input_disabled: boolean = false;
  @Input() start_chat_tour: boolean = false;
  @Input() height: string = "100svh - 58px"
  @Input() typing_msg: string = "";
  @Output() chat_list_composer_emitter: EventEmitter<any> = new EventEmitter<ChatListComposerEmitter>();
  document_list = [];
  selected_form_field: any = {};
  default_placeholder = 'Enter your text here...'
  option_placeholder = 'Please choose one of the options above.'
  placeholder = '';
  search_command_value = ''
  current_command_list = [];
  Object = Object;
  selected_command_index = 0;
  hover_command = false;
  selected_form_label = '';
  input_type = 'default';
  default_date_format = 'yyyy-dd-MM';
  input_type_map = {
    "TEXT": "text",
    "DATE":"date",
    "NUMBER":"number",
    "TEXTAREA": "textarea",
    "TEL": "tel",
    "DEFAULT": "default"
  }
  chat_form_group: FormGroup;
  submitted = false;

  showScrollToBottom = false;
  disableLeftArrow = true;
  disableRightArrow = false;
  submit_chat_form = false;
  selected_form_index = 0;
  form_field = {
    'previous_field_available': false,
    'next_field_available': true
  }
  dummy_messages = [
    {
      "from": "bot",
      "msg_text": "My name is RAF Bot, I am going to try my best to assist you today.",
      "form_question": {},
      "options": [],
      "type": "text",
      "faq_list": [],
      "sent_time": "",
      "use_preview": true
    },
    {
      "from": "user",
      "msg_text": "Please give my query response which I have asked earlier",
      "form_question": {},
      "options": [],
      "type": "text",
      "faq_list": [],
      "sent_time": "",
      "use_preview": true
    },
    {
      "from": "bot",
      "msg_text": "Please tell me about your query",
      "form_question": {},
      "options": [],
      "type": "text",
      "faq_list": [],
      "sent_time": "",
      "use_preview": true
    },
    {
      "from": "user",
      "msg_text": "I want to check my case status",
      "form_question": {},
      "options": [],
      "type": "text",
      "faq_list": [],
      "sent_time": "",
      "use_preview": true
    }
  ]
  constructor(
    public _fa: FontawesomeService,
    private _el: ElementRef,
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    public _config: ConfigService,
  ) {
    this.placeholder = this.default_placeholder;
    this.chat_form_group = this.add_form_groups();
  }

  ngOnInit() {
    this.dummy_messages.push(...this.dummy_messages);
    this.dummy_messages.push(...this.dummy_messages);
  }

  ngOnChanges() {
    if(this.chat_form_group){
      if(this.input_disabled || this.show_typing_indicator){
        this.input_type = this.input_type == this.input_type_map['DEFAULT'] ? 'textarea': 'default';
        this.chat_form_group.disable();
      }else{
        this.chat_form_group.enable();
      }
    }
  }

  ngAfterViewInit() {
    this.updateArrowStates();
    setTimeout(() => {
      $('#message_list').scroll(() => {
        this.on_chat_window_scroll();
      });
      this.autoResize()
    }, 100);
  }
  onScroll() {
    this.updateArrowStates();
  }

  scroll_to_bottom() {
    if(!this.start_chat_tour){
      setTimeout(() => {
        let element = this.message_scroll.nativeElement;
        element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
        this.showScrollToBottom = false;
      }, 400);
    }
  }

  scroll_commands_to_top() {
    setTimeout(() => {
      const container = document.getElementById('shortcut_options_container');
      container.scrollTop = 0;
    }, 10);
  }

  scroll_options(to_right: boolean = true) {
    if (!this.close_chat) {
      setTimeout(() => {
        let element = this.option_scroll.nativeElement;
        let scroll_position = 0;
        if (to_right) {
          scroll_position = element.scrollLeft + element.clientWidth - (element.clientWidth/2);
        } else {
          scroll_position = element.scrollLeft - element.clientWidth + (element.clientWidth/2);          
        }
        element.scrollTo({ left: scroll_position, behavior: 'smooth' });
        this.updateArrowStates(); 
      }, 10);
    }
  }

  onNumberKeyPress(event: KeyboardEvent): void {
    if (event.key == '-' || event.key == '+' || event.key == '.') {
        event.preventDefault();
        return;
    }
    this.onchangeDetect(event);
  }

  updateArrowStates() {
    if(this.option_scroll){
      const scrollElement = this.option_scroll.nativeElement;
      const atStart = scrollElement.scrollLeft === 0;
      const atEnd = (scrollElement.scrollWidth - scrollElement.clientWidth) === scrollElement.scrollLeft;
      this.disableLeftArrow = atStart;
      this.disableRightArrow = atEnd;
    }
  }

  // get_user_input(event){
  //   this.capture_user_input.emit(event);
  // }
  // get_feedback_input(event){
  //   this.capture_feedback_input.emit(event);
  // }
  // get_faq_input(event){
  //   this.capture_faq_input.emit(event);
  // }
  // visible_option(data){
  //   this.visible_option_length.emit(data);
  //   this.scroll_to_bottom();
  // }


  onActionGenerated() {
    let response = {};
    if (this.Object.keys(this.selected_form_field).length) {
      this.select_next_form_field();
      this.chat_list_composer_emitter.emit(response);
    } else {
      if(!(/^\s*$/.test(this.chat_form_group.get('input_data').value))){
        response = {
          case: 'user_input',
          data: this.chat_form_group.get('input_data').value,
        }
        this.chat_list_composer_emitter.emit(response);
      }
      this.reset_and_clear_form_input();
    }
    this.autoResize();
  }

  change_selected_form_field_input() {
    if(this.input_type == 'date' && this.selected_form_field.required_format && this.selected_form_field.required_format != '' && this.chat_form_group.get('input_data').value != ''){
        this.selected_form_field['default_value'] = new DatePipe('en-US').transform(this.chat_form_group.get('input_data').value, this.selected_form_field.required_format)
    }else{
      this.selected_form_field['default_value'] = this.chat_form_group.get('input_data').value.trim();
    }

    let response = {
      case: 'capture_form_field_data',
      data: this.selected_form_field,
    }
    this.chat_list_composer_emitter.emit(response);
    this.autoResize();
    this.remove_invalid_field_class();
  }

  clear_form_focus() {
    $('[id^="chatbot-form"]').each(function () {
      $(this).css('border-color', '');
      $(this).css('box-shadow', '')
      $(this).removeClass('focus_input');
    });
  }


  focus_input() {
    $('.form_reply').removeClass('animated');
    setTimeout(() => {
      this.focus_input_using_id('message-composer');
      this.clear_form_focus();
      $('#chatbot-form' + (this.messages.length -1) + '-' + this.selected_form_field.key).addClass('focus_input');
      $('.form_reply').addClass('animated');
    }, 50);
  }

  scroll_to_faq_container(event) {
    setTimeout(() => {
      const container = document.getElementById('message_list');
      const targetDiv = document.getElementById(event.data);
      const containerRect = container.getBoundingClientRect();
      if(targetDiv){
        const targetRect = targetDiv.getBoundingClientRect();
        const scrollTop = targetRect.top - containerRect.top + container.scrollTop;
        container.scrollTo({ top: scrollTop - 15, behavior: 'smooth' });
      }
      this.on_chat_window_scroll();
    }, 300)
  }

  validate_form(selected_field) {
    this.submitted = true;
    this.focus_input_using_id('message-composer');
    if (!this.Object.keys(this.selected_form_field).length || this.chat_form_group.status == 'VALID' || this.chat_form_group.status == 'DISABLED') {
      this.submitted = false;
      this.change_selected_form_field(selected_field)
    }
  }

  change_selected_form_field(selected_field) {
    console.log(selected_field);
    this.submitted = false;
    if(selected_field.input_type != 'rating'){  
      this.selected_form_field = selected_field;
      let form_field_label = this.selected_form_field.label ? this.selected_form_field.label : this.messages[this.messages.length - 1].msg_text;
      this.chat_form_group = this.add_form_groups(this.selected_form_field);
      this.placeholder = this.selected_form_field['placeholder'];
      if(this.selected_form_field['is_disable']){
        this.selected_form_label = form_field_label + ' (Disabled)'
        this.chat_form_group.disable();
      }else{
        this.selected_form_label = form_field_label;
        this.chat_form_group.enable();
      }
      this.input_type = this.selected_form_field.input_type;
      if(this.input_type == 'date' && this.selected_form_field.required_format && this.selected_form_field.required_format != '' && this.chat_form_group.get('input_data').value){
        let formattedDate: any
        const dateParts = this.chat_form_group.get('input_data').value.split('/');
        if(dateParts[0].length >= 4){
          formattedDate = this.chat_form_group.get('input_data').value;
        }else{
          const day = dateParts[0];
          const month = dateParts[1];
          const year = dateParts[2];
          formattedDate = `${year}-${month}-${day}`;
        }
        this.chat_form_group.get('input_data').setValue(formattedDate)
      }
      if(this.input_type == "date"){
        this.selected_form_field.min_value = this.get_date_value(this.selected_form_field.min_value);
        this.selected_form_field.max_value = this.get_date_value(this.selected_form_field.max_value);
      }
      this.check_form_position(this.selected_form_field.key);
      this.focus_input();
      this._cdr.detectChanges();
      this.autoResize();
      //for realtime update in form
      this.chat_form_group.get('input_data').valueChanges.subscribe(value => {
        this.autoResize();
        if (this.Object.keys(this.selected_form_field).length) {
          this.change_selected_form_field_input();
        }
      });
    }
  }

  remove_invalid_field_class(){
    setTimeout(()=>{
      let current_dynamic_form_field = $('#chatbot-form' + (this.messages.length -1) + '-' + this.selected_form_field.key)
      current_dynamic_form_field.removeClass('is-invalid');
    }, 50)
  }

  add_form_groups(form_data={}){
    const children_keys = {};
    if(!this.Object.keys(form_data).length){
      children_keys['input_data'] = new FormControl('')
    }else{
      if (form_data['validation_list'].length > 0) {
        const validationsArray = this.make_validation(form_data);
        console.log(validationsArray)
        form_data = {...form_data}
        children_keys['input_data'] = new FormControl(form_data['default_value'], validationsArray);
      } else {
        children_keys['input_data'] = new FormControl(form_data['default_value']);
        }
    }
    let new_form_fields = this._fb.group(children_keys);
    return new_form_fields;
  }

  make_validation(fields) {
    const validationsArray = [];
    fields.validation_list.forEach(val => {
      if (val.validator == 'custom') {
          validationsArray.push(
          CustomValidators[val.name]
        );
      } else if (val.validator == 'validator') {
        if(val.value){
          validationsArray.push(
            Validators[val.name](val.value)
          )
          if(val.name == 'required') {
            fields.mandatory = true;
          }
        }else{
          validationsArray.push(
            Validators[val.name]
          )
          if(val.name == 'required') {
            fields.mandatory = true;
            fields = {...fields}
          }
        }
      }
    });
    return validationsArray;
  }

  get_date_value(date: string){
    if(date == "today"){
      return this.todayDate();
    }else{
      return date;
    }
  }

  todayDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  check_form_position(key, form_data = null) {
    this.form_field = {
      'previous_field_available': false,
      'next_field_available': true
    }
    if (!form_data) {
        form_data = this.messages[this.messages.length - 1].form_question;
    }

    let found = false;
    for (let i = 0; i < form_data.length; i++) {
        const currentField = form_data[i];
        if (key === currentField.key) {
            found = true;
            if (i === 0) {
                this.form_field.previous_field_available = false;
            } else {
                this.form_field.previous_field_available = true;
            }
            if (i === form_data.length - 1 && (!currentField.children || currentField.children.length === 0)) {
                this.form_field.next_field_available = false;
            } else {
                this.form_field.next_field_available = true;
            }
            break;
        } else if (currentField.children && currentField.children.length > 0) {
            this.check_form_position(key, currentField.children);
        }
    }

    // If key is not found, disable both next and previous fields
    if (!found) {
        this.form_field.next_field_available = false;
        this.form_field.previous_field_available = false;
    }
  }

  onchangeDetect = (event) => {
    if(this.start_chat_tour){
      return false;
    }
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      if (this.Object.keys(this.selected_form_field).length) {
        let form_data = this.messages[this.messages.length - 1].form_question;
        let next_form_field = this.get_next_form_field(form_data);
        if (next_form_field) {
          this.validate_form(next_form_field);
        } else {
          this.submit_form();
        }
      } else {
        this.onActionGenerated();
      }
    }
  }

  oncommandchange = (event) => {
    if(this.current_command_list.length > 0 && !event.shiftKey){
      if (event.keyCode === 38) {
        event.preventDefault();
        if(this.selected_command_index == 0){
          this.selected_command_index = this.current_command_list.length -1;
        }else{
          this.selected_command_index --;
        }
        this.scrollToCommandItem();
      }else if(event.keyCode === 40) {
        event.preventDefault();
        if(this.selected_command_index == this.current_command_list.length -1){
          this.selected_command_index = 0;
        }else{
          this.selected_command_index ++;
        }
        this.scrollToCommandItem();
      }else if(event.keyCode == 13){
        event.preventDefault();
        this.capture_options(this.current_command_list[this.selected_command_index], 1)
        this.selected_command_index = 0;
      }
    }
  }

  submit_form(){
    this.submitted = true;
    if (!this.Object.keys(this.selected_form_field).length || this.chat_form_group.status == 'VALID' || this.chat_form_group.status == 'DISABLED') {
      this.submitted = false;
      this.messages[this.messages.length - 1].submit_form = true;
      this.messages[this.messages.length - 1] = {...this.messages[this.messages.length - 1]}
      this.reset_and_clear_form_input();
    }
  }

  scrollToCommandItem() {
    const container = document.getElementById('shortcut_options_container');
    const list = document.getElementById(`command_${this.selected_command_index}`);
    const containerRect = container.getBoundingClientRect();
    const itemRect = list.getBoundingClientRect();
  
    // Check if the selected item is above the visible area
    if (itemRect.top < containerRect.top) {
      container.scrollTop -= containerRect.top - itemRect.top;
    }
    // Check if the selected item is below the visible area
    else if (itemRect.bottom > containerRect.bottom) {
      container.scrollTop += itemRect.bottom - containerRect.bottom;
    }
  }

  focus_input_using_id(id) {
    setTimeout(() => {
      if(!this.start_chat_tour){
        $('#' + id).focus();
      }
    }, 10)
  }

  chat_bubble_emitter(event: ChatBubbleEmitter) {
    this.chat_list_composer_emitter.emit(event);
    switch (event.case) {
      case 'focus_in_form':
        this.validate_form(event.data);
        break;
      case 'form_submit':
      case 'form_skipped':
        this.reset_and_clear_form_input();
        break;
      case 'scroll_to_faq_container':
        this.scroll_to_faq_container(event);
        break;
      case "show_disabled_form":
        this.scroll_to_form_div(event.data);
    }
  }

  scroll_to_form_div(data){
    setTimeout(() => {
      const container = document.getElementById('message_list');
      const targetDiv = document.getElementById(`chat_bubble_${data.msg_index}`);
      const containerRect = container.getBoundingClientRect();
      if(targetDiv){
        const targetRect = targetDiv.getBoundingClientRect();
        const scrollTop = targetRect.top - containerRect.top + container.scrollTop;
        container.scrollTo({ top: scrollTop, behavior: 'smooth' });
        this.show_disabled_form(data);
        this.on_chat_window_scroll();
      }
    }, 200)
  }

  show_disabled_form(data){
    if(this.messages[data.msg_index].show_disabled_form == data.show_form){
      data.show_form = !data.show_form;
    }
    setTimeout(() => {
      this.messages[data.msg_index + 1].form_icon_title = data.show_form ? 'Hide Form': 'Show Form'
      this.messages[data.msg_index].show_disabled_form = data.show_form;
      this.messages[data.msg_index].show_form = data.show_form;
    }, 200)
  }

  capture_options(option, reset_flow: number = 0) {
    if (!this.show_typing_indicator && !this.close_chat) {
      let response = {
        case: 'access_options',
        data: {
          "name": option.name,
          "intent": option.intent,
          "reset_flow": reset_flow,
        }
      }
      this.chat_list_composer_emitter.emit(response);
      $('#shortcut_data').dropdown('toggle')	
      this.reset_and_clear_form_input();
    }
  }

  reset_and_clear_form_input(disable_input=true) {
    this.clear_form_focus();
    this.selected_form_field = {};
    this.selected_form_label = ''
    this.input_type = 'default';
    this.placeholder =  this.default_placeholder;
    this.selected_command_index = 0;
    this.submitted = false;
    this.chat_form_group.reset();
    this.chat_form_group = this.add_form_groups();
    if(this.chat_form_group && !this._config.chat_conf.input_always_enabled){
      this.chat_form_group.get('input_data').disable();
      this._cdr.detectChanges();
    }
  }

  onchangeDateDetect = (event) => {
    if (event.keyCode == 38 && (this.get_year_from_date(this.chat_form_group.get('input_data').value) == this.get_year_from_date())) {
      event.preventDefault();
      return false;
    }
    this.onchangeDetect(event);
  }

  get_year_from_date(date=null){
    let dateObject:any
    if(date){
      dateObject = new Date(date);
    }else{
      dateObject = new Date();
    }
    const year = dateObject.getFullYear();
    return year;
  }

  select_next_form_field() {

    let next_form_field = this.get_next_form_field(this.messages[this.messages.length - 1].form_question);
    if (next_form_field) {
      this.validate_form(next_form_field);
    } else {
      this.submit_form();
      this.reset_and_clear_form_input();
    }
    this.autoResize();
  }

  select_previous_form_field() {
    let previous_form_field = this.get_previous_form_field(this.messages[this.messages.length - 1].form_question);
    if (previous_form_field) {
      this.validate_form(previous_form_field);
    } else {
      this.reset_and_clear_form_input();
    }
    this.autoResize();
  }

  get_previous_form_field(form_data: any[], parent: any = null): any {
    let result = null;
    const totalFields = form_data.length;

    for (let i = totalFields - 1; i >= 0; i--) {
      const currentField = form_data[i];

      if (currentField.key === this.selected_form_field.key) {
        if (i > 0) {
          result = form_data[i - 1];
        } else if (parent) {
          result = parent;
        } else {
          result = form_data[totalFields - 1];
        }
        break;
      } else if (currentField.children && currentField.children.length > 0) {
        result = this.get_previous_form_field(currentField.children, currentField);
        if (result) break;
      }
    }

    return result;
  }

  onChangeInput(event) {
    this.autoResize();
    if (this.Object.keys(this.selected_form_field).length) {
      this.change_selected_form_field_input();
    }
  }

  

  onChangeCommandInput() {
    var matching_command_list = [];
    this.config_list.commands.forEach(item => {
      if (item.name.toLowerCase().includes(this.search_command_value.toLowerCase())) {
        matching_command_list.push(item)
      }
    });
    this.current_command_list = matching_command_list
    setTimeout(() => {
      this.selected_command_index = 0;
    })
    $('#shortcut_data').dropdown('update');
  }

  open_command_area() {
    this.current_command_list = this.config_list.commands;
    this.focus_input_using_id('command_search');
    this.search_command_value = '';
    this.selected_command_index = 0;
    this.scroll_commands_to_top();
    $('#shortcut_data').dropdown('update');
  }

  autoResize(index = 0) {
    setTimeout(() => {
      $('#text-container').html('');
      if(this.input_type != 'default' && this.input_type != 'textarea'){
        return;
      }
      let rowHeight = 24;
      const textarea = this._el.nativeElement.querySelector('#message-composer');
      let content = '';
      if(textarea){
        content = textarea.value;
      }
      if (index != -1) {
        $('#text-container').html(content + 'a');
      }
      else {
        $('#text-container').html('');
      }
      let row: number;
      const divText = this._el.nativeElement.querySelector('#text-container');
      if(divText){
        let scrollHeight = divText.scrollHeight? divText.scrollHeight: 21;
        row = (divText.scrollHeight - 10) / 24;
        if (divText.scrollHeight <= (this._config.chat_conf.max_visible_rows * 24) + 10) {
          $('#message-composer').css("height", (scrollHeight + 7) + 'px');
          $('#message-composer').css("overflow", 'hidden');
        }
        else {
          $('#message-composer').css("overflow-y", 'scroll');
          $('#message-composer').css("height", (this._config.chat_conf.max_visible_rows * 24) + 7);
          textarea.scrollTop = textarea.scrollHeight;
        }
      }
      // this.send_left_character_count.emit({
      //   'left_characters':(this._env.live_chat_max_character - this.input_data.length),
      //   'row': row<=this._env.max_visible_rows?row:this._env.max_visible_rows
      // });
    }, 10)
  }

  get_next_form_field(form_data, parent = null) {
    let result = null;
    for (let i = 0; i < form_data.length; i++) {
        const currentField = form_data[i];
        if (currentField.key === this.selected_form_field.key) {
            if (i < form_data.length - 1) {
                result = form_data[i + 1];
            } else {
                if (parent) {
                    const parentIndex = parent.findIndex(field => field.key === currentField.key);
                    if (parentIndex < parent.length - 1) {
                        result = parent[parentIndex + 1];
                    } else {
                        result = null;
                    }
                } else {
                    result = null;
                }
            }
            break;
        } else if (currentField.children && currentField.children.length > 0) {
            result = this.get_next_form_field(currentField.children, form_data);
            if (result) break;
        }
    }
    return result;
}


  on_chat_window_scroll() {
    if (this.message_scroll) {
      var element = this.message_scroll.nativeElement;
      setTimeout(() => {
        const scrollDistanceToBottom = element.scrollHeight - element.offsetHeight - element.scrollTop;
        if (scrollDistanceToBottom >= 50) {
          this.showScrollToBottom = true;
        } else {
          this.showScrollToBottom = false;
        }
      }, 100);
    }
  }

}
