import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusFormat'
})
export class StatusFormatPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    // Normal Instance, Meeting-Task, Ad-Hoc, SubTask, SuperTask
    var split_val;
    var ret_val = '';
    split_val = value.split(/(?=[A-Z])/);
    for(let  i =0; i< split_val.length; i++) {
      let temp_val = '';
      const last_string = split_val[i].slice(-1)
      if(last_string == ' ' || last_string == '-') {
        temp_val = split_val[i].substring(0, split_val[i].length - 1) + ' ';
      } else {
        temp_val = split_val[i] + ' ';
      }
      ret_val += temp_val
    }
    return ret_val;
  }

}
