import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatbotListComposerV1RoutingModule } from './chatbot-list-composer-v1-routing.module';
import { ChatListComposerComponent } from './chat-list-composer/chat-list-composer.component';
import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { QuillModule } from 'ngx-quill';
import { KnowledgeContentDefaultComponent } from './knowledge-content-default/knowledge-content-default.component';
import { ChatbotListComponent } from './chatbot-list/chatbot-list.component';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';

@NgModule({
  declarations: [ChatListComposerComponent, ChatBubbleComponent, KnowledgeContentDefaultComponent, ChatbotListComponent],
  imports: [
    CommonModule,
    ChatbotListComposerV1RoutingModule,
    FontAwesomeModule,
    FormsModule,
    QuillModule,
    ReactiveFormsModule,
    DynamicFormModule,
    AutocompleteLibModule,
  ],
  exports: [ChatbotListComponent]
})
export class ChatbotListComposerV1Module { }
