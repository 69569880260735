import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FormConfig {

	public config_Services = {
		'get_gender_list': async function(_cService){
			let res = await _cService.get_lg_query('').toPromise()
			return res.objects;
		},
		'get_skill_list': async function(_cService){
			let res = [
				{'skill':'C'},
				{'skill':'C++'},
				{'skill':'Java'},
				{'skill':'Python'},
				{'skill':'HTML'},
				{'skill':'CSS'},
				{'skill':'Javascript'},
				{'skill':'Jquery'},
				{'skill':'Angular'},
				{'skill':'AngularJS'},
				{'skill':'Django'},
				{'skill':'Cordova'},
				{'skill':'ReactJS'},
				{'skill':'Flutter'}
			]
			return res;
		},
	}



	form_json_map = {
		'test':[
			{
				'key': 'registration_number',
				'label': 'Registration Number',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': 'Enter your registration number',
				'input_type': 'text',
				'type': 'number',
				'data': [],
				'validation_list': [
					{
					  'name': 'required',
					  'validator': 'validator',
					  'message': 'Registration Required'
					},
					// {
					//   'name': 'min',
					//   'validator': 'validator',
					//   'message': 'Minimum Value 10',
					//   'value':10	
					// },
					// {
					//   'name': 'max',
					//   'validator': 'validator',
					//   'message': 'Maximum Value 100000',
					//   'value':100000	
					// }
				],
				'input_class':'',
				'label_class': '',
				'service':'',
				'default_value': 12312312,
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			},
			{
				'key': 'adas_date',
				'label': 'sdfds Date',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': '',
				'input_type': 'date',
				'type': 'date',
				'validation_list': [],
				'data': [],
				'input_class':'',
				'label_class': '',
				'service':'',
				'default_value': new Date(),
				'input_type_config':  {
					bigBanner: false,
					timePicker: false,
					format: 'dd-MMM-yyyy',
					defaultOpen: false
				},
				"is_hidden": false,
				"is_disable": false,
				"children": []
    		},
	      	{
		        'key': 'gender',
		        'label': 'Gender',
		        'columnClass': "col-xs-12 col-sm-12 col-md-12 col-lg-12",
		        'offset': 0,
		        'placeholder': '',
		        'input_type': 'radio',
		        'type': 'radio',
		        'data': [
		          	{
						'label':"Male",
						'value':"male"
					},
					{
						'label':"Female",
						'value':"female"
					}
				],
		        'validation_list': [],
		        'input_class':'',
		        'label_class': '',
		        'service':'',
		        'default_value': 'male',
		        'input_type_config':null,
		        "is_hidden": false,
		        "is_disable": false,
		        "children": []
	      	},
	      	{
		        'key': 'company_name',
		        'columnClass': "col-xs-12 col-sm-12 col-md-12 col-lg-12",
		        "children": [
	            	{
						'key': 'trading_name',
						'label': 'Trading Name',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': 'Enter your official business name',
						'input_type': 'text',
						'type': 'text',
						'data': [],
						'validation_list': [],
						'input_class':'',
						'label_class': '',
						'service':'',
						'default_value': 'sadasd',
						'input_type_config':null,
						"is_hidden": false,
						"is_disable": false,
						"children": []
		            },
		            {
						'key': 'query',
						'label': 'Query',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': 'Select Query',
						'input_type': 'multiselect',
						'type': 'dropdown',
						'validation_list': [
							// {
							//   'name': 'required',
							//   'validator': 'validator',
							//   'message': 'Query Required'
							// }
						],
						'data': [],
						'input_class':'',
						'label_class': '',
						'service':'get_gender_list',
						'default_value': [],
						'input_type_config':{
							"singleSelection": false,
							"idField": "name",
							"textField": "name",
							"selectAllText": "Select All",
							"unSelectAllText": "UnSelect All",
							"itemsShowLimit": 3,
							"allowSearchFilter": true
						},
						"is_hidden": false,
						"is_disable": false,
						"children": []
	            	},
		            {
						'key': 'sub_query',
						'label': 'Sub Query',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': 'Select Query',
						'input_type': 'dropdown',
						'type': 'dropdown',
						'validation_list': [
							{
							  'name': 'required',
							  'validator': 'validator',
							  'message': 'Query Required'
							}
						],
						'data': [],
						'input_class':'',
						'label_class': '',
						'service':'get_gender_list',
						'default_value': [],
						'input_type_config':{
							"key": "name",
							"value": "name",
						},
						"is_hidden": false,
						"is_disable": false,
						"children": []
	            	},
	            	{
						'key':'child2',
						'columnClass': "col-xs-12 col-sm-12 col-md-12 col-lg-12",    
						'children':[
	                		{
								'key': 'registration_date',
								'label': 'Registration Date',
								'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
								'offset': 0,
								'placeholder': '',
								'input_type': 'date',
								'type': 'date',
								'validation_list': [],
								'data': [],
								'input_class':'',
								'label_class': '',
								'service':'',
								'default_value': new Date(),
								'input_type_config':  {
									bigBanner: false,
									timePicker: false,
									format: 'dd-MMM-yyyy',
									defaultOpen: false
								},
								"is_hidden": false,
								"is_disable": true,
								"children": []
	                		}
              			]
	            	},
	        	]
			}
	    ],
	    'test2':[
			{
				'key': 'registration_number',
				'label': 'Registration Number',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': 'Enter your registration number',
				'input_type': 'text',
				'type': 'number',
				'data': [],
				'validation_list': [
				],
				'input_class':'',
				'label_class': '',
				'service':'',
				'default_value': -1,
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": true,
				"children": []
			},
			{
				'key': 'registration_detail',
				'columnClass': "col-xs-12 col-sm-12 col-md-12 col-lg-12",
				"children": [
					{
						'key': 'first_name',
						'label': 'First Name',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': 'Enter first name',
						'input_type': 'text',
						'type': 'text',
						'data': [],
						'validation_list': [
							{
							  'name': 'required',
							  'validator': 'validator',
							  'message': 'First name required'
							},
						],
						'input_class':'',
						'label_class': '',
						'service':'',
						'default_value': "Shaksham",
						'input_type_config':null,
						"is_hidden": false,
						"is_disable": false,
						"children": []
					},		
					{
						'key': 'last_name',
						'label': 'Last Name',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': 'Enter last name',
						'input_type': 'text',
						'type': 'text',
						'data': [],
						'validation_list': [
							{
							  'name': 'required',
							  'validator': 'validator',
							  'message': 'Last name required'
							},
						],
						'input_class':'',
						'label_class': '',
						'service':'',
						'default_value': "Saxena",
						'input_type_config':null,
						"is_hidden": false,
						"is_disable": false,
						"children": []
					},
			      	{
				        'key': 'gender',
				        'label': 'Gender',
				        'columnClass': "col-xs-12 col-sm-12 col-md-12 col-lg-12",
				        'offset': 0,
				        'placeholder': '',
				        'input_type': 'radio',
				        'type': 'radio',
				        'data': [
				          	{
								'label':"Male",
								'value':"male"
							},
							{
								'label':"Female",
								'value':"female"
							}
						],
				        'validation_list': [],
				        'input_class':'',
				        'label_class': '',
				        'service':'',
				        'default_value': 'male',
				        'input_type_config':null,
				        "is_hidden": false,
				        "is_disable": false,
				        "children": []
			      	},
            		{
						'key': 'dob',
						'label': 'Date Of Birth',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': '',
						'input_type': 'date',
						'type': 'date',
						'validation_list': [
							{
							  'name': 'required',
							  'validator': 'validator',
							  'message': 'Dob required'
							},
							{
							  'name': 'birthYear',
							  'validator': 'custom',
							  'message': 'Minimum age should be 18 years'
							},
						],
						'data': [],
						'input_class':'',
						'label_class': '',
						'service':'',
						'default_value': new Date(),
						'input_type_config':  {
							bigBanner: false,
							timePicker: false,
							format: 'dd-MMM-yyyy',
							defaultOpen: false
						},
						"is_hidden": false,
						"is_disable": false,
						"children": []
            		},
			      	{
			      		'key': 'contact_details',
						'columnClass': "col-xs-12 col-sm-12 col-md-12 col-lg-12",
						'children':[
							{
								'key': 'email',
								'label': 'Email ID',
								'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
								'offset': 0,
								'placeholder': 'Enter email id',
								'input_type': 'text',
								'type': 'email',
								'data': [],
								'validation_list': [
									{
									  'name': 'required',
									  'validator': 'validator',
									  'message': 'Email Id is required'
									},
									{
									  'name': 'email',
									  'validator': 'validator',
									  'message': 'Invaild Email Id'
									},
								],
								'input_class':'',
								'label_class': '',
								'service':'',
								'default_value': "shaksham@gmail.com",
								'input_type_config':null,
								"is_hidden": false,
								"is_disable": false,
								"children": []
							},
							{
								'key': 'contact_number',
								'label': 'Contact Number',
								'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
								'offset': 0,
								'placeholder': 'Enter contact number',
								'input_type': 'text',
								'type': 'number',
								'data': [],
								'validation_list': [
									{
									  'name': 'mobileNo_10',
									  'validator': 'custom',
									  'message': 'Enter 10-digit number'
									},
								],
								'input_class':'',
								'label_class': '',
								'service':'',
								'default_value': 3452678989,
								'input_type_config':null,
								"is_hidden": false,
								"is_disable": false,
								"children": []
							},

						]	
			      	}
				]
			},
			{
				'key':'professional_detail',
				'columnClass': "col-xs-12 col-sm-12 col-md-12 col-lg-12",
				'children':[
		            {
						'key': 'skills',
						'label': 'Skill Set',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': 'Select Skills',
						'input_type': 'multiselect',
						'type': 'dropdown',
						'validation_list': [
							{
							  'name': 'required',
							  'validator': 'validator',
							  'message': 'Query Required'
							}
						],
						'data': [],
						'input_class':'',
						'label_class': '',
						'service':'get_skill_list',
						'default_value': [],
						'input_type_config':{
							"singleSelection": false,
							"idField": "skill",
							"textField": "skill",
							"selectAllText": "Select All",
							"unSelectAllText": "UnSelect All",
							"itemsShowLimit": 4,
							"allowSearchFilter": true
						},
						"is_hidden": false,
						"is_disable": false,
						"children": []
	            	},
	            	{
						'key': 'experience',
						'label': 'Experience in years',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': 'Enter Experience Years',
						'input_type': 'text',
						'type': 'number',
						'validation_list': [
							{
							  'name': 'required',
							  'validator': 'validator',
							  'message': 'Experience Required'
							},
							{
							  'name': 'min',
							  'validator': 'validator',
							  'message': 'Minimum Year Experience Required: 3',
							  'value':3	
							}
						],
						'data': [],
						'input_class':'',
						'label_class': '',
						'service':'',
						'default_value': 0,
						'input_type_config':null,
						"is_hidden": false,
						"is_disable": false,
						"children": []
	            	},
	            	{
						'key': 'new_skill',
						'label': 'Select 1 skill to learn',
						'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
						'offset': 0,
						'placeholder': 'Select Skill',
						'input_type': 'dropdown',
						'type': 'dropdown',
						'validation_list': [
							{
							  'name': 'required',
							  'validator': 'validator',
							  'message': 'Query Required'
							}
						],
						'data': [],
						'input_class':'',
						'label_class': '',
						'service':'get_skill_list',
						'default_value': [],
						'input_type_config':{
							"value": "skill",
							"key": "skill",
						},
						"is_hidden": false,
						"is_disable": false,
						"children": []
	            	},
				]
			}

	    ],
		'login':[
			{
				'key': 'user_name',
				'label': 'Email',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': 'Enter Email',
				'input_type': 'text',
				'type': 'text',
				'data': [],
				'validation_list': [
					{
					  'name': 'required',
					  'validator': 'validator',
					  'message': 'Email Required'
					},
					{
					  'name': 'email',
					  'validator': 'validator',
					  'message': 'Invalid Email'
					}
				],
				'input_class':'form-control bg-dark no-border text-white',
				'label_class': 'text-white',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			},			
			{
				'key': 'password',
				'label': 'Password',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': 'Enter Password',
				'input_type': 'text',
				'type': 'password',
				'data': [],
				'validation_list': [
					{
					  'name': 'required',
					  'validator': 'validator',
					  'message': 'Password Required'
					}
				],
				'input_class':'form-control bg-dark no-border text-white',
				'label_class': 'text-white',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			},

		],
		'forgot-password':[
			{
				'key': 'user_name',
				'label': 'Email ID',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': 'Eg: roberttsmith@gmail.com',
				'input_type': 'text',
				'type': 'text',
				'data': [],
				'validation_list': [
					{
						'name': 'required',
						'validator': 'validator',
						'message': 'Email ID Required'
					}
				],
				'input_class':'form-control bg-dark no-border',
				'label_class': 'text-primary',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			}
		],
		'sign-up':[
			{
				'key': 'first_name',
				'label': 'First Name',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': 'Eg: Robert',
				'input_type': 'text',
				'type': 'text',
				'data': [],
				'validation_list': [
					{
						'name': 'required',
						'validator': 'validator',
						'message': 'First name is Required'
					}
				],
				'input_class':'form-control form-control-rounded',
				'label_class': '',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			},
			{
				'key': 'last_name',
				'label': 'Last Name',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': 'Eg: Smith',
				'input_type': 'text',
				'type': 'text',
				'data': [],
				'validation_list': [],
				'input_class':'form-control form-control-rounded',
				'label_class': '',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			},
			{
				'key': 'email',
				'label': 'Email',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': 'Eg: roberttsmith@gmail.com',
				'input_type': 'text',
				'type': 'text',
				'data': [],
				'validation_list': [
					{
						'name': 'required',
						'validator': 'validator',
						'message': 'Email ID Required'
					}
				],
				'input_class':'form-control form-control-rounded',
				'label_class': '',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			},
			{
				'key': 'password',
				'label': 'Password',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': '',
				'input_type': 'text',
				'type': 'text',
				'data': [],
				'validation_list': [
					{
						'name': 'required',
						'validator': 'validator',
						'message': 'Password is Required'
					}
				],
				'input_class':'form-control form-control-rounded',
				'label_class': '',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			}
		],
		'forgot-reset-password': [
			{
				'key': 'new_password',
				'label': 'New Password',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': '',
				'input_type': 'text',
				'type': 'password',
				'data': [],
				'validation_list': [
					{
						'name': 'required',
						'validator': 'validator',
						'message': 'Password is Required'
					}
				],
				'input_class':'form-control form-control-rounded',
				'label_class': '',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			},
			{
				'key': 'cpassword',
				'label': 'Confirm Password',
				'columnClass': "col-xs-12 col-sm-12 col-md-6 col-lg-6",
				'offset': 0,
				'placeholder': '',
				'input_type': 'text',
				'type': 'password',
				'data': [],
				'validation_list': [
					{
						'name': 'required',
						'validator': 'validator',
						'message': 'Confirm password is required'
					}
				],
				'input_class':'form-control form-control-rounded',
				'label_class': '',
				'service':'',
				'default_value': "",
				'input_type_config':null,
				"is_hidden": false,
				"is_disable": false,
				"children": []
			}
		],
	}
}

