import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent implements OnInit {

  @Input() email_validation_func: (email) => boolean;
  @Input() tag_bg_color: string = '';
  @Input() clear_all: boolean = false;
  @Input() copy_all: boolean = false;
  @Input() is_disabled: boolean = false;
  @Input()
  set tags_data(val: any) {
    this.value_list = val;
  }
  @Output() tags_dataChange: EventEmitter<any> = new EventEmitter()
  
  value_list = [];
  input_text = '';
  get tags_data(): any { return this.value_list; }

  constructor(
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.is_disabled){
      this.input_text = ''
    }
  }

  onKeyup(e){
    if (e.keyCode == 13) {
      let input_val = e.target.value.replace(/\s+/g, ' ');
      if (input_val != '') {
        //TODO- currently this functionality does not allows to add space in the input field
        input_val.split(' ').forEach(val => {
          const valid = this.email_validation_func(val)
          if (valid) {
            if (!this.value_list.includes(val)) {
              this.value_list.push(val.trim());
              this.tags_dataChange.emit(this.value_list)
              e.target.value = "";
            } else {
            }
          } else {
          }
        });
        //e.target.value = ''
      }
    }
  }

  onKeyDown(e){
    if (e.keyCode == 32) {
      e.preventDefault()
    } else if (e.keyCode == 8) {
      let input_val = e.target.value.replace(/\s+/g, ' ');
      input_val.split(',').forEach(val => {
        if (val == '') {
          this.value_list.pop()
        }
      });
    }
  }

  value_status(item) {
  }

  copy(type) {
    let copyText = document.createElement('input');
    if(type == 'right') {
      copyText.value = this.value_list.join(' ');
    } else if(type == 'wrong'){
      //copyText.value = this.wrong_entries_list.map(id => {return id['new_value']}).join(',');
    } else {
      // do nothing
    }
    document.body.appendChild(copyText);
    copyText.select();
    document.execCommand('copy');
    this._toastr.success('', 'Copied Successfully', this._toastrUtility.basic_configuration);
    document.body.removeChild(copyText);
  }

  remove(input_val) {
    let index = this.value_list.indexOf(input_val);
    this.value_list = [...this.value_list.slice(0, index), ...this.value_list.slice(index + 1)];
    //this.show_table = false
  }

  clearAll(){
    this.value_list = []
  }

}
