import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { AgentService } from 'src/app/dce-service/agent.service';
import { Md5 } from 'md5-typescript';
import { PinInputComponent } from '../pin-input/pin-input.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reset-mpin',
  templateUrl: './reset-mpin.component.html',
  styleUrls: ['./reset-mpin.component.scss']
})
export class ResetMpinComponent implements OnInit {

  @Input() user_name: any;
  @ViewChild('agentPIN', {static: false}) agentPIN: PinInputComponent; 
  @ViewChild('agentConfirmPIN', {static: false}) agentConfirmPIN: PinInputComponent; 

  reset_details_payload: FormGroup;
  submitted = false;
  valid_pin = true;

  constructor(
    private _commonService: CommonService,
    private _fb: FormBuilder,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private _agentService: AgentService
  ) { }

  ngOnInit() {
    this.initialize_payload()
  }

  initialize_payload(){
    this.reset_details_payload = this._fb.group({
      'login_password': this._fb.control('',Validators.required),
      'new_pin': this._fb.control('', [Validators.required, Validators.minLength(4)]),
      'cpin': this._fb.control('', [Validators.required, Validators.minLength(4)]),
    });
  }

  validate_payload() {
    this.submitted = true;
    if (this.reset_details_payload.status == 'VALID' || this.reset_details_payload.status == 'DISABLED') {
      return true;
    } else {
      // this._toastr.error('','Please fill all mandatory fields', this._toastrUtility.basic_configuration);
    }
    return false;
  }

  validate_pin(){
    this.submitted = true;
    this.reset_details_payload.controls['new_pin'].setValue(this.agentPIN.get_mpin())
    this.reset_details_payload.controls['cpin'].setValue(this.agentConfirmPIN.get_mpin())
    let payload_value = this.reset_details_payload.value;
    if (this.reset_details_payload.status == 'VALID' || this.reset_details_payload.status == 'DISABLED') {
      if(payload_value['new_pin'] == payload_value['cpin']){
        this.valid_pin = true;
        return true;
      }else{
        this.valid_pin = false;
      }
    }
    return false;
  }
  
  update_pin() :Observable<any> {
    let login_detail = this.reset_details_payload.value;
    const payload = {
      'admin_password':Md5.init(Md5.init(login_detail['login_password'])),
      'user_name': this.user_name,
      'new_pin':Md5.init(Md5.init(this.agentPIN.get_mpin())),
    }
    const response = this._agentService.reset_agent_pin(payload).pipe(map(result => {
      return result
    }))
    return response

  }
  
  clear_payload() {
    this.reset_details_payload.reset();
    this.agentPIN.clear_mpin()
    this.agentConfirmPIN.clear_mpin()
    this.valid_pin = true;
    this.submitted = false;
  }

}
