import {
    Directive,
    ElementRef,
    // HostListener,
    Input,
    Renderer2,
    SimpleChanges,
  } from '@angular/core';
  
  @Directive({
    selector: '[nzBtnLoader]',
  })
  export class BtnLoaderDirective {
    @Input('nzBtnLoader') btnLoader : boolean = false;
    @Input() loadingText?: string = 'Loading...';
  
    initialText = '';
    constructor(private renderer: Renderer2, private el: ElementRef) {}
  
//     <button class="btn {{btnClass}}" [type]="type" [disabled]="loading" [ngClass]="{loading: loading}">
//   <div class="btn-spinner float-left" *ngIf="loading"></div>
//   <span *ngIf="!loading">  
//     <ng-content></ng-content>
//   </span>
//   <span *ngIf="loading">{{loadingText}}</span>
// </button>
    ngOnChanges(changes: SimpleChanges) {
      if (changes.btnLoader) {
        const nativeElement = this.el.nativeElement;
        if (changes.btnLoader.currentValue == true) {
          const loaderHTML = `<div class="btn-loader float-left"></div> <span> ${this.loadingText}</span>`
          this.initialText = nativeElement.innerHTML;
          this.renderer.setProperty(nativeElement, 'innerHTML', loaderHTML);
          this.renderer.setProperty(
            nativeElement,
            'disabled',
            changes.btnLoader.currentValue
          );
        } else if (
          changes.btnLoader.currentValue == false &&
          this.initialText != ''
        ) {
          this.renderer.setProperty(nativeElement, 'innerHTML', this.initialText);
          this.renderer.setProperty(
            nativeElement,
            'disabled',
            changes.btnLoader.currentValue
          );
        }
      }
    }
  }
  