import { Component, OnInit } from '@angular/core';
import { ChatLinkingService } from 'src/app/dce-service/chat-linking.service';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../../dce-service/toastr-utility.service';

@Component({
  selector: 'app-case-link',
  templateUrl: './case-link.component.html',
  styleUrls: ['./case-link.component.scss']
})
export class CaseLinkComponent implements OnInit {

  link_case_btn_loader = false;
  constructor(
    public _chatLinkingService: ChatLinkingService,
    private _liveChatService:  LiveChatService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { }

  ngOnInit() {
  }

  link_case() {
    let payload = {
      "case_table_id": this._chatLinkingService.get_selected_object.case_table_id,
      "live_chat_session_id": this._liveChatService.selected_session.session_id,
      "agent_id": this._liveChatService.live_chat_store.agent_id,
      "agent_username": this._liveChatService.live_chat_store.agent_username,
      "individual_id": this._chatLinkingService.get_selected_object.ind_id,
      "bizorg_id": this._chatLinkingService.get_selected_object.org_biz_id,
      "case_id": this._chatLinkingService.get_selected_object.case_id
    }
    const customer_id = this._chatLinkingService.get_selected_object.role_type == 'Organisation' ? this._chatLinkingService.get_selected_object.org_biz_id: this._chatLinkingService.get_selected_object.ind_id
    const company_name = this._chatLinkingService.get_selected_object.role_type == 'Organisation' ? this._chatLinkingService.get_selected_object.org_company_name: this._chatLinkingService.get_selected_object.ind_full_name
    console.log(payload);
    if(this._chatLinkingService.get_selected_object.role_type == 'Individual' || this._chatLinkingService.get_selected_object.role_type == 'Organisation' ) {
      this.link_case_btn_loader = true;
      this._liveChatService.link_case_with_chat(payload).subscribe(res => {
        if(res.errCode == 0) {
          const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == this._liveChatService.selected_session.session_id);
          this._liveChatService.live_chat_sessions[sessionIndex].current_screen_mode = 'chat';
          this._liveChatService.live_chat_sessions[sessionIndex].chat_linked_data = {
            "case_id": this._chatLinkingService.get_selected_object.case_id,
            "case_table_id": this._chatLinkingService.get_selected_object.case_table_id,
            "customer_id": customer_id,
            "company_name": company_name,
            "link_type": 'case'
          }
          this._toastr.success('', 'Case Linked successfully', this._toastrUtility.basic_configuration);
        }
        this.link_case_btn_loader = false;
      })
    } else {
      this._toastr.error('', 'Please add customer details for this case.', this._toastrUtility.basic_configuration);
    }

  }

  cancel() {
    const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == this._liveChatService.selected_session.session_id);
    this._liveChatService.live_chat_sessions[sessionIndex].current_screen_mode = 'chat';
  }

}
