import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { FormCreatorComponent } from '../form-creator/form-creator.component';
import { FormConfig } from '../form-config'
import { DynamicFormService } from '../dynamic-form.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class DynamicFormComponent implements OnInit {
  @ViewChild(FormCreatorComponent, {static: false}) formCreatorComponent:FormCreatorComponent;
  @Input() json_fields: any = [];
  @Input() case?: any = 'default';
  @Input() unique_id?:string = '';
  @Input() show_button?: boolean = true
  @Input() is_disabled?: boolean = false
  @Output() send_payload_value: EventEmitter<any> = new EventEmitter<any>();
  @Output() focus_out_event: EventEmitter<any> = new EventEmitter<any>();
 
  button_name_map:any = {
    'edit':"Edit",
    'cancel_edit':"Cancel Edit",  
    'update':"Update",  
    'submit':"Save",  
  }
  populate_payload_data = {}
  payload_data = {};
  form_type = 'reactive';
  form_fields = []
  afterInit = false;

  constructor(
    private _dService:DynamicFormService,
    private _config:FormConfig
  ) { 
  }

  ngOnInit() {
    this.get_service_data(this.form_fields,0);
  }

  async get_service_data(form_fields,complete_int){
    for (let i = form_fields.length - 1; i >= 0; i--) {
      if(form_fields[i].children.length == 0){
        if(form_fields[i]['service'] && form_fields[i]['service'] != ''){
          await this._config.config_Services[form_fields[i]['service']](this._dService).then(res=>{
            form_fields[i].data = res
            // console.log("In For"+i)
          })
        }
      }else{
        await this.get_service_data(form_fields[i].children,complete_int+1)
      }
    }
    if(complete_int == 0){
      this.afterInit = true
    }
    // console.log("Out For"+complete_int)
  }

  get_payload(val){
    this.payload_data = val;
  }
  emit_payload(){
    this.send_payload_value.emit(this.payload_data);
  }

  populate_data(data) {
    this.formCreatorComponent.populate_data(data);
  }
  submit_form(){
    this.formCreatorComponent.submit_form();
    this.emit_payload()
  }
  reset_form(){
    this.formCreatorComponent.clear_form()
  }
  toggle_form(bool) {
    this.formCreatorComponent.toggle_form(bool);
  }
  get_payload_values() {
    this.formCreatorComponent.submit_form();
    const form_data:any = this.payload_data;
    return {
      case : this.case,
      valid: form_data.valid,
      response:form_data.data
    };
  }

  focusOutEvent(event) {
    this.focus_out_event.emit(event)
  }

}
