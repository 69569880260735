import { Injectable } from '@angular/core';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class FilterJson{

  _env = new EnvService();
  
  public filters = {
      'case':[
        {
          'key':'multi_select_case_id',
          'process_key':'case_id',
          'disabled':false,
          'is_hidden':false,
          'default_val':'',
          'label':'Ticket Reference No. List',
          'type':'ngselect',
          'data_type':'text',
          'service':null,
          'data':[],
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':{
            'searchable':true,
            'virtualScroll':true,
            'multiple':true,
            'addTag':true,
          },
          'validations':'validate_case_id'
        },
        {
          'key':'case_id',
          'process_key':'case_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Ticket Reference No',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
          'mask_setting':{'mask':'S-00000000-000000','dropSpecialCharacters':false}
        },
        {
          'key':'party1_email_address1_biz',
          'process_key':'party1_email_address1_biz',
          'is_hidden':false,
          'default_val':'',
          'label':'Customer Email',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'party1_idnumber',
          'process_key':'party1_idnumber',
          'is_hidden':false,
          'default_val':'',
          'label':'First Party ID Number',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'party1_passport_num',
          'process_key':'party1_passport_num',
          'is_hidden':false,
          'default_val':'',
          'label':'First Party Passport Number',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'party1_registration_number',
          'process_key':'party1_registration_number',
          'is_hidden':false,
          'default_val':'',
          'label':'First Party Company Registration Number',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'party1_company_name',
          'process_key':'party1_company_name',
          'is_hidden':false,
          'default_val':'',
          'label':'First Party Company / Individual Name',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'party2_idnumber',
          'process_key':'party2_idnumber',
          'is_hidden':false,
          'default_val':'',
          'label':'Second Party ID Number',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'party2_passport_num',
          'process_key':'party2_passport_num',
          'is_hidden':false,
          'default_val':'',
          'label':'Second Party Passport Number',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'party2_registration_number',
          'process_key':'party2_registration_number',
          'is_hidden':false,
          'default_val':'',
          'label':'Second Party Company Registration Number',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'party2_company_name',
          'process_key':'party2_company_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Second Party Company / Individual Name',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        // {
        //   'key':'potential_member_id_number',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Potential Member ID Number',
        //   'type':'input',
        //   'data_type':'text',
        //   'service':null,
        //   'data':null,
        //   'mandatory':false,
        //   'data_key':null,
        //   'dropdown_settings':null,
        // },
        // {
        //   'key':'emp_membership_number',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Client Membership Number',
        //   'type':'input',
        //   'data_type':'text',
        //   'service':null,
        //   'data':null,
        //   'mandatory':false,
        //   'data_key':null,
        //   'dropdown_settings':null,
        // },
        // {
        //   'key':'emp_national_id',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Client ID Number',
        //   'type':'input',
        //   'data_type':'text',
        //   'service':null,
        //   'data':null,
        //   'mandatory':false,
        //   'data_key':null,
        //   'dropdown_settings':null,
        // },
        {
          'key':'from_case_createtime',
          'process_key':'case_createtime',
          'is_hidden':false,
          'default_val':'',
          'label':'From: Created Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'to_case_createtime',
          'process_key':'case_createtime',
          'is_hidden':false,
          'default_val':'',
          'label':'To: Created Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        // {
        //   'key':'company_name',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Company Name',
        //   'type':'autocomplete',
        //   'data_type':'text',
        //   'service':'get_all_biz_org',
        //   'data':[],
        //   'mandatory':false,
        //   'data_key':'company_name',
        //   'dropdown_settings':null,
        // },
        {
          'key':'creator_full_name',
          'process_key':'creator_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'data_id_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'prev_owner_full_name',
          'process_key':'prev_owner_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Closed By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'data_id_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'owner_full_name',
          'process_key':'owner_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Current Owner',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'data_id_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'priority',
          'process_key':'priority',
          'is_hidden':false,
          'default_val':'',
          'label':'Priority',
          'type':'dropdown',
          'data_type':'list',
          'service':null,
          'data':[{'key':'P1','value':'P1'},{'key':'P2','value':'P2'},{'key':'P3','value':'P3'},{'key':'P4','value':'P4'},{'key':'P5','value':'P5'}],
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'severity',
          'process_key':'severity',
          'is_hidden':false,
          'default_val':'',
          'label':'Severity',
          'type':'dropdown',
          'data_type':'list',
          'service':null,
          'data':[{'key':'S1','value':'S1'},{'key':'S2','value':'S2'},{'key':'S3','value':'S3'},{'key':'S4','value':'S4'},{'key':'S5','value':'S5'}],
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        // {
        //   'key':'prev_wrkgrp_id',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Workgroup',
        //   'type':'multiselect',
        //   'data_type':'list',
        //   'service':'get_user_workgroups',
        //   'data':[],
        //   'data_key':'name',
        //   'data_id_key':'wrkgrp_id',
        //   'dropdown_settings':{
        //     singleSelection: false,
        //     idField: 'wrkgrp_id',
        //     textField: 'name',
        //     selectAllText: 'Select All',
        //     unSelectAllText: 'UnSelect All',
        //     itemsShowLimit: 3,
        //     allowSearchFilter: true
        //   },
        // },
        {
          'key':'wfobj_status',
          'process_key':'wfobj_status',
          'is_hidden':false,
          'default_val':[],
          'label':'Case Workflow Status',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_classification_value_data',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          }
        },
        {
          'key':'case_status',
          'process_key':'case_status',
          'is_hidden':false,
          'default_val':[],
          'label':'Case Status',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_all_case_status',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          }
        },
        {
          'key':'case_classification_type',
          'process_key':'case_classification_type',
          'is_hidden':false,
          'default_val':[],
          'label':'Mode',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_classification_mode_value_data',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          }
        },
      ],
      'task':[
        {
          'key':'task_gen_id',
          'process_key':'task_gen_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Task ID',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'task_name',
          'process_key':'task_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Task Name',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'from_task_create_time',
          'process_key':'task_create_time',
          'is_hidden':false,
          'default_val':'',
          'label':'From: Created Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'to_task_create_time',
          'process_key':'task_create_time',
          'is_hidden':false,
          'default_val':'',
          'label':'To: Created Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        // {
        //   'key':'task_function_subtype',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Business Function',
        //   'type':'input',
        //   'data_type':'text',
        //   'service':null,
        //   'data':null,
        //   'mandatory':false,
        //   'data_key':null,
        //   'dropdown_settings':null,
        // },
        // {
        //   'key':'task_function_l3',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Function Area',
        //   'type':'input',
        //   'data_type':'text',
        //   'service':null,
        //   'data':null,
        //   'mandatory':false,
        //   'data_key':null,
        //   'dropdown_settings':null,
        // },
        {
          'key':'creator_full_name',
          'process_key':'creator_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created By',
          'type':'completer',
          'data_type':'text',
          'service': this._env.service_url + '/get_all_users',
          'data':[],
          'mandatory':false,
          'data_key':'search_col',
          'data_id_key':'full_name',
          'dropdown_settings':null,
          'disabled':false,
          'completer_config': {
            'placeholder': 'Created By',
            'search_key': 'query',
            'payload': {
              'data_records': 10,
              'page': 0,
            },
            'display_keyword': 'full_name',
            'search_keyword_list': ['full_name','user_name'],
            'z_index': 14,
            'input_name':''
          }
        },
        {
          'key':'prev_owner_full_name',
          'process_key':'prev_owner_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Closed By',
          'type':'completer',
          'data_type':'text',
          'service': this._env.service_url + '/get_all_users',
          'data':[],
          'mandatory':false,
          'data_key':'search_col',
          'data_id_key':'full_name',
          'dropdown_settings':null,
          'disabled':false,
          'completer_config': {
            'placeholder': 'Closed By',
            'search_key': 'query',
            'payload': {
              'data_records': 10,
              'page': 0,
            },
            'display_keyword': 'full_name',
            'search_keyword_list': ['full_name','user_name'],
            'z_index': 14,
            'input_name':''
          }
        },
        {
          'key':'owner_full_name',
          'process_key':'owner_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Current Owner',
          'type':'completer',
          'data_type':'text',
          'service': this._env.service_url + '/get_all_users',
          'data':[],
          'mandatory':false,
          'data_key':'search_col',
          'data_id_key':'full_name',
          'dropdown_settings':null,
          'disabled':false,
          'completer_config': {
            'placeholder': 'Current Owner',
            'search_key': 'query',
            'payload': {
              'data_records': 10,
              'page': 0,
            },
            'display_keyword': 'full_name',
            'search_keyword_list': ['full_name','user_name'],
            'z_index': 14,
            'input_name':''
          }
        },
        //   'key':'task_task_type',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Task Type',
        //   'type':'dropdown',
        //   'data_type':'list',
        //   'service':null,
        //   'data':[{'key':"Ad-Hoc",'value':"Ad-Hoc"},{'key':"Normal Instance",'value':"Normal Instance"},{'key':"SuperTask",'value':"SuperTask"}],
        //   'mandatory':false,
        //   'data_key':null,
        //   'dropdown_settings':null,
        // },
        {
          'key':'task_task_type',
          'process_key':'task_task_type',
          'is_hidden':false,
          'default_val':'',
          'label':'Task Type',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_all_task_type',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          }
        },
        {
          'key':'task_planned_end_date_status',
          'process_key':'task_planned_end_date_status',
          'is_hidden':false,
          'default_val':'',
          'label':'Task Deadline',
          'type':'dropdown',
          'data_type':'list',
          'service':'get_all_task_deadline',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          }
        },
        {
          'key':'wrkgrp_id',
          'process_key':'wrkgrp_id',
          'is_hidden':false,
          'default_val':[],
          'label':'Workgroup',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_all_workgroups',
          'data':[],
          'data_key':'name',
          'data_id_key':'id',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        },
        {
          'key':'wfobj_status',
          'process_key':'wfobj_status',
          'is_hidden':false,
          'default_val':[],
          'label':'Task Workflow Status',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_classification_value_data',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        },
        {
          'key':'task_status',
          'process_key':'task_status',
          'is_hidden':false,
          'default_val':[],
          'label':'Task Status',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_all_task_status',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        },
        // {
        //   'key':'approval_status',
        //   'is_hidden':false,
        //   'default_val':[],
        //   'label':'Request Approved Status',
        //   'type':'multiselect',
        //   'data_type':'list',
        //   'service':'get_request_approval_status',
        //   'data':[],
        //   'data_key':'value_value',
        //   'data_id_key':'value_name',
        //   'dropdown_settings':{
        //     singleSelection: false,
        //     idField: 'value_name',
        //     textField: 'value_value',
        //     selectAllText: 'Select All',
        //     unSelectAllText: 'UnSelect All',
        //     itemsShowLimit: 3,
        //     allowSearchFilter: true
        //   },
        // },
        // {
        //   'key':'approval_requestee_fullname',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Approval Requested From',
        //   'type':'autocomplete',
        //   'data_type':'text',
        //   'service':'get_users_list',
        //   'data':[],
        //   'mandatory':false,
        //   'data_key':'full_name',
        //   'dropdown_settings':null,
        // },
        // {
        //   'key':'approval_requester_fullname',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Approval Requested By',
        //   'type':'autocomplete',
        //   'data_type':'text',
        //   'service':'get_users_list',
        //   'data':[],
        //   'mandatory':false,
        //   'data_key':'full_name',
        //   'dropdown_settings':null,
        // },
        {
          'key':'created_from_schedule',
          'process_key':'created_from_schedule',
          'is_hidden':false,
          'default_val':'',
          'label':'Is a Recurring Task',
          'type':'dropdown',
          'data_type':'list',
          'service':null,
          'data':[{'key':'Yes','value':1},{'key':'No','value':0}],
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
      ],
      'advice':[
        {
          'key':'advice_id',
          'process_key':'advice_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Advice ID',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'advice_create_time',
          'process_key':'advice_create_time',
          'is_hidden':false,
          'default_val':'',
          'label':'Start Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'advice_last_change_time',
          'process_key':'advice_last_change_time',
          'is_hidden':false,
          'default_val':'',
          'label':'End Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'creator_full_name',
          'process_key':'creator_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'data_id_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'created_for_full_name',
          'process_key':'created_for_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created For',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },        
        // {
        //   'key':'prev_owner_full_name',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Closed By',
        //   'type':'autocomplete',
        //   'data_type':'text',
        //   'service':'get_users_list',
        //   'data':[],
        //   'mandatory':false,
        //   'data_key':'full_name',
        //   'dropdown_settings':null,
        // },
        // {
        //   'key':'prev_wrkgrp_id',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Workgroup',
        //   'type':'multiselect',
        //   'data_type':'list',
        //   'service':'get_user_workgroups',
        //   'data':[],
        //   'data_key':'name',
        //   'data_id_key':'wrkgrp_id',
        //   'dropdown_settings':{
        //     singleSelection: false,
        //     idField: 'wrkgrp_id',
        //     textField: 'name',
        //     selectAllText: 'Select All',
        //     unSelectAllText: 'UnSelect All',
        //     itemsShowLimit: 3,
        //     allowSearchFilter: true
        //   },
        // },
        {
          'key':'advice_status',
          'process_key':'advice_status',
          'is_hidden':false,
          'default_val':[],
          'label':'Advice Status',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_classification_value_data_advice',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        }
      ],
      'lead':[
        {
          'key':'lead_id',
          'process_key':'lead_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Lead ID',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'lead_createtime',
          'process_key':'lead_createtime',
          'is_hidden':false,
          'default_val':'',
          'label':'Start Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'lead_lastchange_time',
          'process_key':'lead_lastchange_time',
          'is_hidden':false,
          'default_val':'',
          'label':'End Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'creator_full_name',
          'process_key':'creator_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'data_id_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'lead_company_name',
          'process_key':'lead_company_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Customer Name',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_all_potential_biz_org',
          'data':[],
          'mandatory':false,
          'data_key':'company_name',
          'data_id_key':'company_name',
          'dropdown_settings':null,
        }
      ],

      'meeting':[
        {
          'key':'meeting_id',
          'process_key':'meeting_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Meeting ID',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'meeting_start_date',
          'process_key':'meeting_start_date',
          'is_hidden':false,
          'default_val':'',
          'label':'From: Meeting Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'meeting_start_date2',
          'process_key':'meeting_start_date2',
          'is_hidden':false,
          'default_val':'',
          'label':'To: Meeting Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'meeting_title',
          'process_key':'meeting_title',
          'is_hidden':false,
          'default_val':'',
          'label':'Meeting Subject',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'creator_full_name',
          'process_key':'creator_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'search_col',
          'data_id_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'owner_full_name',
          'process_key':'owner_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Current Owner',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'search_col',
          'data_id_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'meeting_type',
          'process_key':'meeting_type',
          'is_hidden':false,
          'default_val':'',
          'label':'Meeting Type',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_classification_value_data_meeting_type',
          'data':[],
          'mandatory':false,
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          }
        },
        {
          'key':'meeting_channel',
          'process_key':'meeting_channel',
          'is_hidden':false,
          'default_val':'',
          'label':'Meeting Channel',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_classification_value_data_meeting_channel',
          'data':[],
          'mandatory':false,
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_value',
            textField: 'value_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          }
        },
        // {
        //   'key':'company_name',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Company Name',
        //   'type':'autocomplete',
        //   'data_type':'text',
        //   'service':'get_all_biz_org',
        //   'data':[],
        //   'mandatory':false,
        //   'data_key':'company_name',
        //   'dropdown_settings':null,
        // },
        {
          'key':'meeting_status',
          'process_key':'meeting_status',
          'is_hidden':false,
          'default_val':[],
          'label':'Meeting Status',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_classification_value_data_meeting_status',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_value',
            textField: 'value_name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        },
      ],
  }
  public operator = {
    'party1_idnumber': {"op":"==","type":"str"},
    'party2_idnumber': {"op":"==","type":"str"},
    'party1_registration_number': {"op":"==","type":"str"},
    'party1_passport_num': {"op":"==","type":"str"},
    'party2_passport_num': {"op":"==","type":"str"},
    'party2_registration_number': {"op":"==","type":"str"},
    'party1_company_name': {"op":"ilike_op","type":"str"},
    'party2_company_name': {"op":"ilike_op","type":"str"},
    'party1_email_address1_biz': {"op":"ilike_op","type":"str"},
    'task_function_subtype': {"op":"ilike_op","type":"str"},
    'task_function_l3': {"op":"ilike_op","type":"str"},


    'prev_wrkgrp_id':{"op":"in","type":"int"},
    'wrkgrp_id':{"op":"in","type":"int"},
    'created_from_schedule':{"op":"==","type":"int"},
    'wfobj_status':{"op":"in","type":"str"},
    'case_status':{"op":"in","type":"str"},
    'case_classification_type':{"op":"in","type":"str"},
    'advice_status':{"op":"in","type":"str"},
    'creator_full_name':{"op":"==","type":"str"},
    'prev_owner_full_name':{"op":"==","type":"str"},
    'owner_full_name':{"op":"==","type":"str"},
    'approval_requestee_fullname':{"op":"==","type":"str"},
    'approval_requester_fullname':{"op":"==","type":"str"},

    'from_case_createtime':{"op":">=","type":"str"},
    'to_case_createtime':{"op":"<=","type":"str"},
    'priority':{"op":"==","type":"str"},
    'severity':{"op":"==","type":"str"},
    'case_id':{"op":"like_op","type":"str"},
    'multi_select_case_id':{"op":"in","type":"str"},
    'emp_national_id':{"op":"==","type":"str"},
    'potential_member_id_number':{"op":"==","type":"str"},
    'emp_membership_number':{"op":"==","type":"str"},

    'from_task_create_time':{"op":">=","type":"str"},
    'task_name':{"op":"ilike_op","type":"str"},
    'to_task_create_time':{"op":"<=","type":"str"},
    'task_task_type':{"op":"in","type":"str"},
    'task_planned_end_date_status':{"op":"in","type":"str"},
    'tg_instance_name':{"op":"==","type":"str"},
    // 'company_name':{"op":"==","type":"str"},
    'lead_company_name':{"op":"==","type":"str"},
    'task_gen_id':{"op":"==","type":"str"},
    'approval_status':{"op":"in","type":"str"},
    'task_status':{"op":"in","type":"str"},

    'advice_id':{"op":"==","type":"str"},
    'advice_create_time': { 'op': '>=', 'type': 'str' },
    'advice_last_change_time': { 'op': '<=', 'type': 'str' },
    'created_for_full_name': { 'op': 'ilike_op', 'type': 'str' },
    
    'lead_id':{"op":"==","type":"str"},
    'lead_createtime': { 'op': '>=', 'type': 'str' },
    'lead_lastchange_time': { 'op': '<=', 'type': 'str' },
    'meeting_id':{"op":"==","type":"str"},
    'meeting_start_date':{"op":">=","type":"str"},
    'meeting_start_date2':{"op":"<=","type":"str"},
    'meeting_type':{"op":"in","type":"str"},
    'meeting_channel':{"op":"in","type":"str"},
    'meeting_status':{"op":"in","type":"str"},
    'meeting_title': { 'op': 'ilike_op', 'type': 'str' },
  }
}
