import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
import * as prettyMs from 'pretty-ms';
import { FontawesomeService } from '../../dynamic-form/fontawesome.service';
import { SocketComponent } from '../socket/socket.component';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../../dce-service/toastr-utility.service'

declare var $: any;
@Component({
  selector: 'app-queue-session-render',
  templateUrl: './queue-session-render.component.html',
  styleUrls: ['./queue-session-render.component.scss']
})
export class QueueSessionRenderComponent implements OnInit, OnDestroy {
  @ViewChild(SocketComponent,{static: false}) socketComp: SocketComponent
  @Output() live_chat_parent_events: EventEmitter<any> = new EventEmitter<any>();

  socket_instance = null;
  chat_config = {
    reason_code : ''
  }
  session_config = {
    is_dnd: false,
    pause_timer: null,
    pause_titles: {
      fetch: 'View pause duration.',
      sync: 'View updated pause duration.'
    },
    pause_interval: null,
  }
  log_type_conf = {
    pause_unpause : "dnd",
    auth : "signin"
  }

  constructor(
    public chat_store: LiveChatService,//chat store
    public fa:FontawesomeService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    // Might need later
    // this.socketComp.socket_session_close();
  }

  socket_ini_connection() {
    this.socket_instance = this.socketComp.initiate_chat_connection();
    this.socket_evts_listener(); // to do remove listener
  }

  socket_evts_listener() {
    this.socket_instance.on('connect', () => {
      this.chat_store.live_chat_store.agent_socket_session_id = this.socket_instance.id;
      if (this.chat_store.live_chat_store.lifecycle_status == 'pre-login') {
        this.chat_queue_login();
      }
    });
    this.socket_instance.on('disconnect', () => {
      this.chat_store.update_network_status('OFFLINE');
      if(!this.chat_store.live_chat_sessions.length){
        this.chat_store.live_chat_store.lifecycle_status = "pre-login";
        this.live_chat_parent_events.emit({ type: 'live_chat_general', case: 'live_chat_disconnect_modal', data: null });
      }
    });
  }

  chat_queue_login() {
    const payload = {
      "agent_id": this.chat_store.live_chat_store.agent_id,
      "agent_username": this.chat_store.live_chat_store.agent_username,
      "log_type": this.log_type_conf.auth,
      "agent_socket_session_id": this.chat_store.live_chat_store.agent_socket_session_id,
      "reason_code": this.chat_config.reason_code
    }
    console.log("login", payload)
    this.chat_store.chat_queue_login(payload).subscribe(res => {
      if (res.errCode == 0) {
        this.chat_store.live_chat_store.lifecycle_status = 'logged-in'
      }
    })
  }

  open_logout_modal() {
    console.log("open logout modal")
    this.live_chat_parent_events.emit({ type: 'live_chat_general', case: 'live_chat_logout_modal', data: null });
  }

  async chat_queue_logout() {
    const payload = {
      "agent_id": this.chat_store.live_chat_store.agent_id,
      "agent_username": this.chat_store.live_chat_store.agent_username,
      "log_type": this.log_type_conf.auth,
      "agent_socket_session_id": this.chat_store.live_chat_store.agent_socket_session_id,
    }
    console.log("logout",payload)
    if(this.session_config.is_dnd == true) {
      await this.unpause();
    }
    this.chat_store.chat_queue_logout(payload).subscribe(res => {
      if(res.errCode == 0){
      this.end_pause_timer();
      this.chat_store.live_chat_store.lifecycle_status = 'pre-login'
      this.chat_store.live_chat_store.current_pause_code = null;
      this.session_config.is_dnd = false;
      }
    })
  }

  async unpause() { 
    const payload = {
      "agent_id": this.chat_store.live_chat_store.agent_id,
      "agent_username": this.chat_store.live_chat_store.agent_username,
      "log_type": this.log_type_conf.pause_unpause,
      "agent_socket_session_id": this.chat_store.live_chat_store.agent_socket_session_id
    }
    console.log("unpause",payload)
    const res = await this.chat_store.chat_queue_logout(payload).toPromise();
    if (res.errCode == 0) {
      this.end_pause_timer();
      this.session_config.is_dnd = false;
      this.chat_store.live_chat_store.current_pause_code = null;
    }
  }

  async patch_pause_code(data) {
    this.chat_config.reason_code = data.value_name
    const payload = {
      "agent_id": this.chat_store.live_chat_store.agent_id,
      "agent_username": this.chat_store.live_chat_store.agent_username,
      "log_type": this.log_type_conf.pause_unpause,
      "reason_code": this.chat_config.reason_code,
      "agent_socket_session_id": this.chat_store.live_chat_store.agent_socket_session_id
    }
    console.log("pause",payload)
    const res = await this.chat_store.chat_queue_login(payload).toPromise();
    if(res.errCode == 0){
      this.start_pause_timer();
      this.chat_store.live_chat_store.current_pause_code = data.value_value
      this.session_config.is_dnd = true;
    }
  }

  start_pause_timer() { //timer check after few minutes
    this.end_pause_timer();
    let timer = 0;
    let prev_timer = 0;
    let interval = 1000;
    this.session_config.pause_interval = setInterval(() => {
      timer = timer + interval;
      if (prev_timer > timer) {
        console.log('Error:::::::', interval, timer);
        clearInterval(this.session_config.pause_interval);
      }
      prev_timer = timer;
      this.session_config.pause_timer = prettyMs(timer);
    }, interval);
  }

  end_pause_timer() {
    clearInterval(this.session_config.pause_interval);
    this.session_config.pause_timer = null;
  }
}
