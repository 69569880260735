import { Injectable } from '@angular/core';

type statuses = null | 'pre-login' | 'logged-in' | 'call-fire' | 'call-incoming' | 'call-outgoing' | 'call-on' | 'call-ended';

@Injectable({
    providedIn: 'root'
})

export class config {
    event_dict = { // segregated on modes : deault with all evt captured,calling...
        default: {
            call_options: {
                eventHandlers: ['connecting', 'confirmed']
            },
            call_answer_options: {

            },
            ua_events: ['connected', 'disconnected', 'newRTCSession',
                'newMessage', 'registered', 'unregistered', 'registrationFailed'],
            call_events: ['connecting', 'newInfo', 'accepted',
                'confirmed', 'progress', 'ended',
                'failed', 'newDTMF', 'hold',
                'unhold', 'muted', 'unmuted', 'refer', 'sdp']
        },
        calling: {
            ua_events: ['connected', 'disconnected'],
            call_events: ['connecting']
        }
    }
}

export interface sip_user_config {
    uri: string,
    password: string,
    authorization_user: string,
    // receiver_uri?: string, 
    // referer_uri: string,
}