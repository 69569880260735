import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    protected http: HttpClient,
    protected env: EnvService,
  ) { }

  public campaign_get_headers_url = `${this.env.service_url}/campaign/get_headers`;
  campaign_get_headers(payload) {
    return this.http.post<any>(this.campaign_get_headers_url, payload);
  }

  public campaign_create_url = `${this.env.service_url}/campaign/create`;
  campaign_create(payload) {
    return this.http.post<any>(this.campaign_create_url, payload);
  }

  public campaign_update_url = `${this.env.service_url}/campaign/update`;
  campaign_update(payload) {
    return this.http.post<any>(this.campaign_update_url, payload);
  }

  public campaign_close_url = `${this.env.service_url}/campaign/close`;
  campaign_close(payload) {
    return this.http.post<any>(this.campaign_close_url, payload);
  }

  public campaign_get_sample_data_url = `${this.env.service_url}/campaign/get_sample_data`;
  campaign_get_sample_data(payload) {
    return this.http.post<any>(this.campaign_get_sample_data_url, payload);
  }

  public get_campaign_version_url = `${this.env.service_url}/campaign/version/get_all`;
  get_campaign_version(payload) {
    return this.http.post<any>(this.get_campaign_version_url, payload);
  }

  public create_campaign_version_url = `${this.env.service_url}/campaign/version/create`;
  create_campaign_version(payload) {
    return this.http.post<any>(this.create_campaign_version_url, payload);
  }

  public change_status_campaign_version_url = `${this.env.service_url}/campaign/version/change_status`;
  change_status_campaign_version(payload) {
    return this.http.post<any>(this.change_status_campaign_version_url, payload);
  }

  public get_version_status_url = `${this.env.service_url}/campaign/version/get_status`;
  get_version_status(payload) {
    const params = this.get_version_status_url + '?' + this.object_to_query_param(payload);
    return this.http.get<any>(params);
  }

  private get_campaign_version_record_url = `${this.env.service_url}/campaign/version/get`;
  get_campaign_version_record(payload) {
    const params = this.get_campaign_version_record_url + '?' + this.object_to_query_param(payload);
    return this.http.get<any>(params);
  }

  public update_campaign_version_url = `${this.env.service_url}/campaign/version/update`;
  update_campaign_version(payload) {
    return this.http.post<any>(this.update_campaign_version_url, payload);
  }

  public update_version_target_status_url = `${this.env.service_url}/campaign/version/target/update`;
  update_version_target_status(payload) {
    return this.http.post<any>(this.update_version_target_status_url, payload);
  }

  public activate_campaign_version_url = `${this.env.service_url}/campaign/version/activate`;
  activate_campaign_version(payload) {
    return this.http.post<any>(this.activate_campaign_version_url, payload);
  }

  public cancel_campaign_version_url = `${this.env.service_url}/campaign/version/cancel`;
  cancel_campaign_version(payload) {
    return this.http.post<any>(this.cancel_campaign_version_url, payload);
  }

  private get_invalids_record_url = `${this.env.service_url}/campaign/version/target/get_all`;
  get_all_version_target(payload) {
    return this.http.post<any>(this.get_invalids_record_url, payload);
  }

  private get_version_error_records_url = `${this.env.service_url}/campaign/version/target/download_specific`;
  get_version_error_records(payload) {
    return this.http.post<any>(this.get_version_error_records_url, payload);
  }

  private get_version_count_url = `${this.env.service_url}/campaign/version/target/get_counts`;
  get_version_count(payload) {
    const params = this.get_version_count_url + '?' + this.object_to_query_param(payload);
    return this.http.get<any>(params);
  }

  private get_campaign_by_method_url = `${this.env.service_url}/campaign/get_by_method_type`;
  get_campaign_by_method(payload) {
    return this.http.post<any>(this.get_campaign_by_method_url, payload);
  }

  private get_allocated_targets_url = `${this.env.service_url}/campaign/version/target/user/get_allocated`;
  get_allocated_targets(payload) {
    return this.http.post<any>(this.get_allocated_targets_url, payload);
  }

  private get_filtered_allocated_targets_url = `${this.env.service_url}/campaign/version/target/get_filtered_target`;
  get_filtered_allocated_targets(payload) {
    return this.http.post<any>(this.get_filtered_allocated_targets_url, payload);
  }

  private get_filtered_target_count_url = `${this.env.service_url}/campaign/version/target/get_filtered_target_count`;
  get_filtered_target_count(payload) {
    return this.http.post<any>(this.get_filtered_target_count_url, payload);
  }

  private get_filtered_target_count_for_users_url = `${this.env.service_url}/campaign/version/target/get_filtered_target_count_for_users`;
  get_filtered_target_count_for_users(payload) {
    return this.http.post<any>(this.get_filtered_target_count_for_users_url, payload);
  }

  private get_biz_interaction_url = `${this.env.service_url}/interaction/biz_org/get_all`;
  get_biz_interaction(payload) {
    const params = this.get_biz_interaction_url + '?' + this.object_to_query_param(payload);
    return this.http.get<any>(params);
  }

  private get_ind_interaction_url = `${this.env.service_url}/interaction/individual/get_all`;
  get_ind_interaction(payload) {
    const params = this.get_ind_interaction_url + '?' + this.object_to_query_param(payload);
    return this.http.get<any>(params);
  }

  private get_interaction_doc_url = `${this.env.service_url}/interaction/documents/get_all`;
  get_interaction_doc(payload) {
    const params = this.get_interaction_doc_url + '?' + this.object_to_query_param(payload);
    return this.http.get<any>(params);
  }

  public allocate_campaign_version_url = `${this.env.service_url}/campaign/version/target/auto_allocate`;
  allocate_campaign_version(payload) {
    return this.http.post<any>(this.allocate_campaign_version_url, payload);
  }

  public individual_allocate_target_url = `${this.env.service_url}/campaign/version/target/individual/allocate_target_to_user`;
  individual_allocate_target(payload) {
    return this.http.post<any>(this.individual_allocate_target_url, payload);
  }

  public bulk_allocate_target_url = `${this.env.service_url}/campaign/version/target/bulk/allocate_targets`;
  bulk_allocate_target(payload) {
    return this.http.post<any>(this.bulk_allocate_target_url, payload);
  }

  public individual_unallocate_target_url = `${this.env.service_url}/campaign/version/target/individual/unallocate_target_by_ids`;
  individual_unallocate_target(payload) {
    return this.http.post<any>(this.individual_unallocate_target_url, payload);
  }

  public bulk_unallocate_target_url = `${this.env.service_url}/campaign/version/target/bulk/unallocate_targets`;
  bulk_unallocate_target(payload) {
    return this.http.post<any>(this.bulk_unallocate_target_url, payload);
  }

  public bulk_reallocate_target_url = `${this.env.service_url}/campaign/version/target/bulk/reallocate_targets`;
  bulk_reallocate_target(payload) {
    return this.http.post<any>(this.bulk_reallocate_target_url, payload);
  }

  public get_campaign_workgroup_members_url = `${this.env.service_url}/workgroup/campaign/member/get_all`;
  get_campaign_workgroup_members(payload) {
    return this.http.post<any>(this.get_campaign_workgroup_members_url, payload);
  }

  private _get_campaign_target_note_url = `${this.env.service_url}/campaign/version/target/note/get_all`;
	get_campaign_target_note(payload) {
		return this.http.post<any>(this._get_campaign_target_note_url, payload)
	}

  private _add_campaign_target_note_url = `${this.env.service_url}/campaign/version/target/note/add`;
	add_campaign_target_note(payload) {
		return this.http.post<any>(this._add_campaign_target_note_url, payload)
	}

  object_to_query_param(obj) {
    let query_param = "";
    for (let key in obj) {
      query_param += key + "=" + obj[key] + '&'
    }
    return query_param.slice(0, -1)
  }
  //   get_all_campaign_version - Done
  // create_campaign_version
  // update_campaign_version
  // get_tag_list
  // view_sample_file_records
  // error_records
  // activate_campaign_version
  // remove_campaign_version - optional
  // get_campaign_version_record



}
