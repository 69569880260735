import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { InitService } from './init.service';
import { EnvService } from './env.service';
import { Observable, of } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from './toastr-utility.service';
import { tap, catchError, finalize } from "rxjs/operators";
import { Router } from '@angular/router';
import { SharedService } from './shared.service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptService implements HttpInterceptor {

	service_count = 0; // counter for service

	constructor(
		private _init: InitService, 
		private _env: EnvService,
		private sharedService: SharedService,
		private _toastr: ToastrService,
		private _toastrUtility: ToastrUtilityService,
		private _route:Router
	) {}

  	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const info_to_pass = {
			url: this._route.url,
			IP:localStorage.getItem('user_ip') == 'null'? null:localStorage.getItem('user_ip'),
			device:localStorage.getItem('device') == 'null'? null:localStorage.getItem('device')
		}
		var temp = req.url.split('/');
		var service = "/"+temp.slice(3).join("/");
		service = service.split('?')[0]; // to extract url of get-reqs eliminating query-params.
		
		if (this._env.cache_service.includes(service)) {  
			let body = this.sharedService.get_cached_data(service,req.body) 
		  	if(body.status){
			  	return of(new HttpResponse(
			    	{ status: 200, body: body['data'] }
			  	));
		  	}
		}
		if (!this._env.skip_spinner_for_services.includes(service) && 0) {
			this.service_count++; // increament count for every http-req service-calls intercepted
			this.sharedService.spinnerVisibility(true);
		    console.log('total service count', this.service_count);
		}

		var token;
		// for(var i=0; i<this._env.services_to_skip.length; i++){
			if( this._env.services_to_skip.includes(service) ){	//	skip token addition to service
				token = null;
			} else {
				token = this._init.get_auth_token();
			}
		// }
		var is_raptor_service = this._env.raptor_db_service_url.indexOf(temp[2]) != -1 || this._env.raptor_wh_service_url.indexOf(temp[2]) != -1
		if(is_raptor_service){ // for Raptor Services
			token = this._init.get_wh_token();
		}
		// else if(req.method == 'GET'){ // For paramerterized restless services
		// 	if(service.substring(0,4) == '/api' || service.substring(0,6) == '/whapi'){
		// 		token = this._init.get_wh_token();
		// 	}
		// }
		if (!req.headers.get('Authorization')) { // FOR QM APIS which already have basic auth token OR for any 3rd PArty API Proxy PASS
			let authReq = req.clone({
				withCredentials: true
			});
			if(token){
				if(this._init.get_agent_token()) {
					authReq = authReq.clone({
						headers:authReq.headers.append('Authorization', 'Bearer ' + this._init.get_agent_token()).append('AdminToken' , 'Bearer ' + token)
					})
				} else {
					authReq = authReq.clone({
						headers:authReq.headers.append('Authorization', 'Bearer ' + token)
					})
				}
			}else{
				if(is_raptor_service){
					console.log("No wh_token present,raptor server is down.")
					return this.intercept_pass_api_response(200, 'raptor server down', 400);
				}
				else if(!this._env.services_to_skip.includes(service) && !this._env.lg_wh_services.includes(service)){
					this._toastr.error('', "You are not signed in", this._toastrUtility.basic_configuration);
					location.reload(true);
				}
				else if(this._env.GET_services_to_skip.includes(service.split("?")[0])){
					this._toastr.error('', "You are not signed in", this._toastrUtility.basic_configuration);
					location.reload(true);
				}else {
					//do nothing
				}
				// GET_services_to_skip: Loop on it for validation
			}
			if(req.method == 'GET'){
					if(this.sharedService.resetCache && this._env.force_cache_purge_services.includes(service)){
						authReq = authReq.clone({
							headers:authReq.headers.append('Cache-Purge',"true")
						});
					}
					return next.handle(authReq).pipe(
						catchError((err: any) => {
							console.log(err.error)
							if(err instanceof HttpErrorResponse) {
								this._toastr.error('','Some Internal Error Occurred', this._toastrUtility.basic_configuration);
							}
							// return of(err);
							return of(new HttpResponse(
								{ status: 200, body: {
									msg: err,
									errCode: 400
								} }
							));
						})
					
					)
			} else if (req.method == 'POST'){
				authReq = authReq.clone({
					headers: authReq.headers.append('_info', JSON.stringify(info_to_pass)),
				});
				return next.handle(authReq).pipe(
					tap((event: HttpEvent<any>) => {
						if (event instanceof HttpResponse && event.body.errCode != undefined ) {
							if(event.body.errCode != 0){
								this._toastr.error('', event.body.msg, this._toastrUtility.basic_configuration);
								if(event.body.errCode == 96){
									localStorage.removeItem('token');
									location.reload(true);
								}
							}else if(this._env.cache_service.includes(service)){
								this.sharedService.set_cached_data(service,authReq.body,event.body)
							}else if(this._env.update_cache_services.includes(service)){
								this.sharedService.get_classifications().subscribe(res => {
								})
							}
						}
					}),
					catchError((err: any) => {
						if(err instanceof HttpErrorResponse) {
							this._toastr.error('','Some Internal Error Occurred', this._toastrUtility.basic_configuration);
						}
						// return of(err);
						return of(new HttpResponse(
							{ status: 200, body: {
								msg: err,
								errCode: 400
							} }
						));
					}),
					finalize(() => {
						if (!this._env.skip_spinner_for_services.includes(service) && 0) {
							this.service_count--; // decreament when service is completed (success/failed both handled)
							if (this.service_count === 0) {
								this.sharedService.spinnerVisibility(false);
							}
						}
					}),
				);
			} else {
				return next.handle(authReq).pipe(
					catchError((err: any) => {
	    	            if(err instanceof HttpErrorResponse) {
        	                this._toastr.error('','Some Internal Error Occurred', this._toastrUtility.basic_configuration);
	    	            }
	    	            // return of(err);
						return of(new HttpResponse(
							{ status: 200, body: {
								msg: err,
								errCode: 400
							} }
						));
	    	        }),
					finalize(() => {
						if (!this._env.skip_spinner_for_services.includes(service) && 0) {
							this.service_count--; // decreament when service is completed (success/failed both handled)
							if (this.service_count === 0) {
								this.sharedService.spinnerVisibility(false);
							}
						}
					}),
				);
			}
		} else {
			return next.handle(req);
		}
	
	}

	intercept_pass_api_response(status, msg, errCode) {
        return of(new HttpResponse(
            { 
                status: status, 
                body: {
                    msg: msg,
                    errCode: errCode
                } 
            }
        ));
    }

}
