import { Component, OnInit, Input, Output, EventEmitter, DoCheck, OnChanges,ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CustomValidators } from '../show-errors/custom-validators.component';
import { FontawesomeService } from '../fontawesome.service';
declare var $:any;
@Component({
  selector: 'app-form-creator',
  templateUrl: './form-creator.component.html',
  styleUrls: ['./form-creator.component.css']
})
export class FormCreatorComponent implements OnInit, OnChanges {
  @ViewChild("canceledit",{static: false}) cancel_edit:any;

  @Input() form_fields: any = [];
  @Input() button_name_map: any = {};
  @Input() populateData?:boolean = false;
  @Input() dataForPopulate?: any = {};
  @Input() showButton?:boolean = true;
  @Input() case?:string = '';
  @Input() unique_id?:string = '';
  @Output() get_payload_value: EventEmitter<any> = new EventEmitter<any>();
  @Output() focus_out_event: EventEmitter<any> = new EventEmitter<any>();
  

  config_map = {};
  form_id = -1;

  form_edit = true;
  form_validate: boolean;

  dynamicForm: FormGroup;
  payload_initialized = false;
  submitted: boolean = false;
  icon_password:any;
  level_in_json = 1;

  is_form_enabled = true

  constructor(
    private _fb: FormBuilder,
    private datePipe: DatePipe,
    public fa:FontawesomeService,
    private cdRef: ChangeDetectorRef
  ) {
    this.icon_password = fa.icon_map.faEyeSlash
  }

  ngOnInit() {
    if(this.form_fields != null && this.form_fields.length) {
      this.initialize_payload();
      this.toggle_form(this.form_edit);
    }
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes.form_fields && !changes.form_fields.firstChange) {
      this.form_fields = changes.form_fields.currentValue
      if(this.form_fields != null && this.form_fields.length) {
        this.initialize_payload();
        this.toggle_form(this.form_edit);
      }
      this.form_validate = false;
    }
  }
  
  initialize_payload() {
    this.dynamicForm = this._fb.group({});
    // let data = 
    this.dynamicForm = this.add_form_groups(this.form_fields);
    if(this.form_fields.length) {
      this.payload_initialized = true;
    } else {
      this.payload_initialized = false;
    }
    this.disable_some_field(this.form_fields,this.dynamicForm);
    if (Object.keys(this.dataForPopulate).length > 0) {
      this.form_id = 2
      this.form_edit = false
      this.populate_data(this.dataForPopulate);
    }else{
      this.form_edit = true
    }
  }
  add_form_groups(form_fields) {
    const children_keys = {};
    var new_form_fields; 
    
    for (let i =0 ;i< form_fields.length; i++) {
      if (form_fields[i].children.length>0) {
        children_keys[form_fields[i].key] = this.add_form_groups(form_fields[i].children)
        this.level_in_json += 1
      } else {
        if (form_fields[i].validation_list.length > 0) {
          const validationsArray = this.make_validation(form_fields,i);
          children_keys[form_fields[i].key] = new FormControl(form_fields[i].default_value, validationsArray);
        } else {
          // if(form_fields[i].input_type == 'multiselect') {
          //   this.dropdown_settings(form_fields[i].input_type_config);
          // }
          if(form_fields[i].input_type == 'file' && (form_fields[i].input_type_config.upload_type == undefined || form_fields[i].input_type_config.upload_type == 'single')) {
            const control = new FormArray([])
            control.push(this.create_new_form_group_in_input_file_type());
            children_keys[form_fields[i].key] = control;
          } else {
          children_keys[form_fields[i].key] = new FormControl(form_fields[i].default_value);
          }
        }
        this.config_map[form_fields[i].key] = {"config":form_fields[i].input_type_config,"type":form_fields[i].input_type};
      }
      new_form_fields = this._fb.group(children_keys);
    }
    return new_form_fields;
  }

  create_new_form_group_in_input_file_type() {
    const form_group =  this._fb.group({
      file_data: '',
      file_name: ''
    })
    return form_group;
  }

  make_validation(fields,index) {
    const validationsArray = [];
    fields[index].validation_list.forEach(val => {
      if (val.validator == 'custom') {
          validationsArray.push(
          CustomValidators[val.name]
        );
      } else if (val.validator == 'validator') {
        if(val.value){
          validationsArray.push(
            Validators[val.name](val.value)
          )
        }else{
          validationsArray.push(
            Validators[val.name]
          )
        }
      }
    });
    return validationsArray;
  }
  test(event){
  }
  toggle_form(bool) {
    if (bool) {
      this.dynamicForm.enable();
      this.disable_some_field(this.form_fields,this.dynamicForm);
    } else {
      this.dynamicForm.disable();
    }
    this.is_form_enabled = bool;
  }

  clear_form() {
      this.dynamicForm.reset();
      this.setDefaultValueForDisabled(this.form_fields,this.dynamicForm)
  }

  payload_value() {
    let data = this.data_parser(this.dynamicForm.value,0);
    this.get_payload_value.emit({valid: true, data: data});
  }

  validate_form() {
    this.submitted = true;
    if (this.dynamicForm.status == 'VALID' || this.dynamicForm.status == 'DISABLED') {
      this.form_validate = false
      return true;
    }
    this.form_validate = true
    return false;
  }

  onSubmit() {
  }

  populate_data (data) {
    data = this.data_parser(data,0);
    for (let i =0 ;i< this.form_fields.length; i++) {
      if(this.form_fields[i].input_type == 'file') {
        for(let  j =0 ;j < data[this.form_fields[i].key].length; j++) {
          data[this.form_fields[i].key][j].document_name = data[this.form_fields[i].key][j].file_name;
          data[this.form_fields[i].key][j].file_name = '';
        }
      }
    }
    this.dynamicForm.patchValue(data);
    this.cdRef.detectChanges();
    // $('#multiselect').click()
    // No need as data will be serialized before coming to component
    //   console.log(data)
    //   if (this.payload_initialized) { 
    //     for (let i = 0; i < this.form_fields.length; i++) {
    //       const temp_json = {};
    //       temp_json[this.form_fields[i].key] = data[this.form_fields[i].key]
    //       this.dynamicForm.patchValue(temp_json);
    //     }
    //   }
  }

  disable_some_field(form_fields,dynamicForm) {
    if (this.payload_initialized) {
      for (let i = 0; i < form_fields.length; i++) {
        if (form_fields[i].children.length>0){
          this.disable_some_field(form_fields[i].children,dynamicForm.controls[form_fields[i].key])
        }else{
          if (form_fields[i].is_disable) {
            dynamicForm.controls[form_fields[i].key].disable();
          }
        }
      }
    }
  }
  setDefaultValueForDisabled(form_fields,dynamicForm){
    if (this.payload_initialized) {
      for (let i = 0; i < form_fields.length; i++) {
        if (form_fields[i].children.length>0){
          this.setDefaultValueForDisabled(form_fields[i].children,dynamicForm.controls[form_fields[i].key])
        }else{
          if (form_fields[i].is_disable) {
            dynamicForm.controls[form_fields[i].key].setValue(form_fields[i].default_value);
          }
        }
      }
    }
  }

  edit_event(event){
    switch (event.case) {
      case "edit":
        this.form_edit = event.editing
        break;

      case "cancel-edit":
          this.form_edit = event.editing
          this.dynamicForm.patchValue(event.payload.dynamicForm)
        break;

      case "update":
      case "submit":
        if (this.payload_initialized) {
          this.submit_form()
        }
        break;
      
      default:
        break;
    }
    this.toggle_form(this.form_edit)
  }
  submit_form(){
      if(this.validate_form()){
        this.form_edit = false
        this.payload_value();
        this.form_id = 2;
      }else{
        if(this.showButton){
          this.cancel_edit.show_edit_button = false
        }
        this.get_payload_value.emit({valid: false, data: {}});
        // this.form_edit = true
      }
  }

  data_parser(form,ix){
     let keys = Object.keys(form);
     for (var i = keys.length - 1; i >= 0; i--) {
       if (form[keys[i]] == null) {
          // Do nothing
       } else if(Object.keys(this.config_map).indexOf(keys[i]) == -1) {
        if((typeof form[keys[i]] === 'object') && ix < this.level_in_json) {
           form[keys[i]] = this.data_parser(form[keys[i]],ix+1);
        } else {
         // Not found
        }
      } else{
         if(this.config_map[keys[i]].type == "date"){
            // form[keys[i]] = (this.datePipe.transform(new Date(form[keys[i]]),this.config_map[keys[i]].config['format']))
         }else{
           if(form[keys[i]] === "true"){
             form[keys[i]] = true
           }else if(form[keys[i]] === "false"){
             form[keys[i]] = false
           }

         }
       }
       
     }
     return form
  }

  dateupdate(date_control){
    // Code
    // Code
  }

  show_password(type){
    var input = $('#'+type);
    if (input.attr("type") == "password") {
      input.attr("type", "text");
      if(type == "password"){
        this.icon_password = this.fa.icon_map.faEye
      }
    } else {
      input.attr("type", "password");
      if(type == "password"){
        this.icon_password = this.fa.icon_map.faEyeSlash
      }
    }
  }

  focusOutEvent(event) {
    this.focus_out_event.emit(event);
  }

  get_form_array(form_array_key) { return <FormArray>this.dynamicForm.get(form_array_key); }

  // clear_formArray(form_array_key) {
  //   const temp_form_array = this.get_form_array(form_array_key);
  //   temp_form_array.clear();
  // }

  async fileChange(form_array_key, event) {
    // this.clear_formArray(form_array_key);
    let fileList: FileList = event.target.files;
    if (fileList.length >0){
      for(let i =0 ; i< fileList.length; i++) {
        var fdata = await this.getBase64_new(fileList[i]);
        // const temp_form_array_control = <FormArray>this.dynamicForm.controls[form_array_key] ;
        // temp_form_array_control.push(this.create_new_form_group_in_input_file_type());
        const temp_form_array = this.get_form_array(form_array_key);
        temp_form_array.at(i).patchValue({
          file_data : fdata,
        });
        temp_form_array.at(i).value.file_name = temp_form_array.at(i).value.file_name.replace("C:\\fakepath\\", "");
      }
    }
  }

  async getBase64_new(file) {
    return new Promise(function(resolve, reject) {
      var reader = new FileReader();
      reader.onload = function(event: any) {
        let encoded = reader.result;
          resolve(encoded);
        };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

}
