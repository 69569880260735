import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {
  
  @Input() chat_list ={};
  @ViewChild('chatContainer',{static:false}) private chatContainer: ElementRef;
  
  constructor() { }
  
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
}
