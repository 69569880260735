import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormWizardModule } from 'src/app/shared/components/form-wizard/form-wizard.module';
import { KnowledgeManagementSearchRoutingModule } from './knowledge-management-search-routing.module';
import { KnowledgeContentComponent } from './knowledge-content/knowledge-content.component';
import { QuillModule } from 'ngx-quill'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DceSharedModule } from 'src/app/dce-shared/dce-shared.module';
import { KnowledgeSearchComponent } from './knowledge-search/knowledge-search.component';
import { KnowledgeSearchFaqComponent } from './knowledge-search-faq/knowledge-search-faq.component';
import { KnowledgeSearchAllComponent } from './knowledge-search-all/knowledge-search-all.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GenericSurveyjsModule } from '../generic-surveyjs/generic-surveyjs.module';

@NgModule({
  declarations: [KnowledgeContentComponent, KnowledgeSearchComponent, KnowledgeSearchFaqComponent, KnowledgeSearchAllComponent],
  imports: [
    CommonModule,
    QuillModule,
    KnowledgeManagementSearchRoutingModule,
    FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FormWizardModule,
    FontAwesomeModule,
    NgSelectModule,
    DceSharedModule,
    GenericSurveyjsModule
  ],
  exports: [
    KnowledgeSearchComponent,
    KnowledgeContentComponent
  ]
})
export class KnowledgeManagementSearchModule { }
