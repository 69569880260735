import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { isNumeric } from "rxjs/util/isNumeric"

export class CustomValidators implements OnInit {

    static birthYear(c: FormControl): ValidationErrors  {
        const user_dob = new Date(c.value);

        let currentDate = new Date();
        let lastDate = new Date();
        currentDate =  new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
        lastDate = new Date(lastDate.getFullYear() - 120, lastDate.getMonth(), lastDate.getDate());
        const isValid = (currentDate >= user_dob && lastDate <= user_dob);
        const message = {
          'years': {
            'message': 'Age cannot be less than 18 and greater than 120'
          }
        };
        return isValid ? null : message;
      }

      static minYear(c: FormControl): ValidationErrors  {
        const user_date = new Date(c.value);

        let lastDate = new Date("January 01, 1900 00:00:00");

        const isValid = (lastDate <= user_date);
        const message = {
          'years': {
            'message': 'Year cannot be less than 1900'
          }
        };
        return isValid ? null : message;
      }


     static mobileNo(c: FormControl): ValidationErrors {
     // let isValid = false;
     const mobile_number = c.value;
     const isValid = (mobile_number.toString().length == 9 && mobile_number != null);
     const message = {
       'message': 'Enter 9-digit number'
     };
     return isValid ? null : message;
   }
   static mobileNo_10(c: FormControl): ValidationErrors {
    // let isValid = false;
      const mobile_number = c.value;
      const isValid = (mobile_number != null && isNumeric(mobile_number) && mobile_number.toString().length == 10);
      const message = {
        'mobileNo_10': {
          'message':'Enter 10-digit number'
        }
      };

      return isValid ? null : message;
    }

    static startDate(c: FormControl): ValidationErrors {
      const date = new Date(c.value);
      const today = new Date();
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      const isValid =  (date > yesterday) ? true : false;
      const message = {
       'startDate': {
         'message': "Start date should be greater than or equal to current date"
       }
     };
     return isValid ? null : message;
    }

    static startDateTomorrow(c: FormControl): ValidationErrors {
      const date = new Date(c.value);
      const today = new Date();
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate())
      const isValid =  (date > yesterday) ? true : false;
      const message = {
       'startDate': {
         'message': "Start date should be greater than today"
       }
     };
     return isValid ? null : message;
    }

    static endDate(c: FormControl): ValidationErrors {
      const date = new Date(c.value);
      const today = new Date();
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() - 1)
      const isValid =  (date >= tomorrow) ? true : false;
      const message = {
       'startDate': {
         'message': "End date should be greater than or equal to current date"
       }
     };
     return isValid ? null : message;
    }
 

  static start_date_for_org(c: FormControl): ValidationErrors {
    const date = new Date(c.value);
    const today = new Date();
    const isValid = (date <= today) ? true : false;
    const message = {
      'start_date_org': {
        'message': 'Start date should be present date or a back date'
      }
    };
    return isValid ? null : message;
  }

    static ID_no_format(c: FormControl): ValidationErrors {
      var cc = c.value;
      // var cc = c.value.trim(); // use this
      var isValid = true;
      var message = {};
      if(cc != null && cc != ''){
        const d = new Date('19'+cc.substring(0,2)+'-'+cc.substring(2,4)+'-'+cc.substring(4,6));
        const x = (d.toString() == 'Invalid Date');
        // YYMMDDSSSSCAZ
        let nSum: number = 0;
        let isSecond = false;
        for (let i = cc.length - 1; i >= 0; i--) {
          let d = Number(cc[i]);
          if (isSecond == true) {
            d = d * 2;
          }
          nSum += Math.floor(d / 10);
          nSum += Math.floor(d % 10);
          isSecond = !isSecond;
        }

        if(cc.length != 13 || isNaN(cc) || x || (cc.substring(10,11) != '0' && cc.substring(10,11) != '1' && cc.substring(10,11) != '2') || Math.floor(nSum % 10 )!= 0 ){
            isValid = false;
            message = {
              'idNumber': {
                'message': 'ID Number format Incorrect. YYMMDDSSSSCAZ'
              }
            };
        }
      }
      return isValid ? null : message;
    }

    static isNotExemptionDependentDate(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = ageDate.getUTCFullYear() - 1970;
      const isValid =  (age_value >= 0 && age_value < 18) ? true : false;
      const message = {
       "isNotExemptionDependentDate":{
          'message': ' Age cannot be greater than 18'
        }
       };
       return isValid ? null : message;
    }

    static isExemptionDependentDate(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = ageDate.getUTCFullYear() - 1970;

      const isValid = (age_value >= 0 && age_value <= 26) ? true : false;
      const message = {
       "isExemptionDependentDate":{
          'message': ' Age cannot be greater than 26'
        }
       };
       return isValid ? null : message;
    }

    static adultAge(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = ageDate.getUTCFullYear() - 1970;

      const isValid =  (age_value >= 18 && age_value <= 120) ? true : false;
      const message = {
       "adultAge":{
          'message': ' Age cannot be less than 18 and greater than 120'
        }
       };
       return isValid ? null : message;
    }

    static multipleCheckboxRequireOne(fa: FormArray) {
      let valid = false;
      for (let x = 0; x < fa.length; ++x) {
        if (fa.at(x).value) {
          valid = true;
          break;
        }
      }
      const message = {
        "multipleCheckbox":{
          'message': 'Please select at least one checkbox'
        }
      };
      return valid ? null : message;
    }

    static invalidEmail(c: FormControl):ValidationErrors {
      const email = c.value;
      let regularExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let isValid = email!==undefined && regularExp.test(email)
      console.log(isValid)
      const message = {
       "invalidEmail":{
          'message': ' Invalid Email'
        }
       };
       return isValid ? null : message;
    }

    static contact_number(c: FormControl): ValidationErrors {
      if(c.value == null || c.value.length == 0) { return null; }
      let pattern = /(0)(6|7|8)(\d{8})/g;
      const isValid = pattern.test(c.value);
      const message = {
        'mobileNo_10': {
          'message': 'Enter valid phone number'
        }
      };
      return isValid ? null : message;
    }

    static sip_extension(c: FormControl): ValidationErrors {
      if(c.value == null || c.value.length == 0) { return  null;}
      var isValid = true;
      var message = {};
      if(c.value.toString().length != 4){
        isValid = false;
      }
      message = {
        'valid_sip_ext': {
          'message': 'Enter 4-Digit Sip Extension.'
        }
      };
      return isValid ? null : message;
    }

    static sip_authorization_user(c: FormControl): ValidationErrors {
      if(c.value == null || c.value.length == 0) { return null;}
      var isValid = true;
      var message = {};
      if(c.value.toString().length != 7){
        isValid = false;
      }
      message = {
        'sip_authorization_user': {
          'message': 'Enter 7-Digit Authorization User Value.'
        }
      };
      return isValid ? null : message;
    }

    static validateCaseId(c: string | FormControl): ValidationErrors {
      const reg_exp = /^[cC]-20[0-9]\d(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])-\d{6}$/;
      let isValid = false;
      let message = {}
      if(typeof(c) == 'string'){
        message = {
          "validateCaseId":{
            'message': c + ' is not a valid ID.'
          }
        };
        if(c == '' || reg_exp.test(c)){
          isValid = true;
        }
      }else{
        message = {
          "validateCaseId":{
            'message': c.value + ' is not a valid ID.'
          }
        };
        if(c.value == '' || reg_exp.test(c.value)){
          isValid = true;
        }
      }
      return isValid ? null : message;
    }

    ngOnInit() {

    }
}
