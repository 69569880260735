import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JssipRoutingModule } from './jssip-routing.module';
import { DceSharedModule } from '../../dce-shared/dce-shared.module';
import { SipWrapperComponent } from './sip-wrapper/sip-wrapper.component';
import { FormsModule } from '@angular/forms';
import { SipRenderComponent } from './sip-render/sip-render.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DialerComponent } from './dialer/dialer.component';
import { CallHistoryComponent } from './call-history/call-history.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [SipWrapperComponent, SipRenderComponent, DialerComponent, CallHistoryComponent],
  imports: [
    CommonModule,
    JssipRoutingModule,
    FormsModule, // we can export it from sharedModule
    FontAwesomeModule,
    DceSharedModule,
    NgxDatatableModule
  ],
  exports: [SipRenderComponent]
})
export class JssipModule { }
