import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { SharedService } from 'src/app/dce-service/shared.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnChanges {

  @Input() tagCase: string = '';
  @Input() initialTag?: any = [];
  @Input() is_multiple?: boolean = true;
  @Input() maxSelectedItems?:Number = 10;
  @Input() placeholder?: string = 'Select';
  @Input() notFoundText?: string = 'No items found';
  @Input() is_disable?: boolean = false;

  @Output() selected_tags_data_event?: EventEmitter<any> = new EventEmitter<any>(); 

  tags_data:any = [];
  selected_tags = [];
  selected_tags_not_in_list = [];
  selected_tags_in_list = [];
  search_tags_url = '/search_tags/get';

  constructor(
    private _commonService: CommonService,
    private _sharedService: SharedService
  ) { }

  ngOnInit() {
    this.get_tags_data()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.initialTag) {
      this.selected_tags = changes.initialTag.currentValue;
      this.selected_tags_in_list = changes.initialTag.currentValue;
    }
  }

  get_tags_data() {
    const payload = {
      tagident: this.tagCase
    }
    this._commonService.get_tags_data(payload).subscribe(res => {
      if(res.errCode==0) {
        this.tags_data = res.data;
      }
    });
  }

  update_tags_data(tag) {
    const payload = {
      tagident: this.tagCase,
      tag: tag
    }
    this._commonService.update_tags_data(payload).subscribe(res => {
      if(res.errCode==0) {
        this._sharedService.update_cached_data(this.search_tags_url,{tagident: this.tagCase},tag);
        const updated_data = this._sharedService.get_cached_data(this.search_tags_url,{tagident: this.tagCase})
        if(updated_data.status) {
          this.tags_data = [...updated_data['data']['data']];
        }
      }
    });
  }

  addTagFn(name) {
    return name.trim() == name ? name : false;
  }


  onAddTag(name) {
    name = name.trim();
    this.selected_tags = [...this.selected_tags]
    const temp_data = [...this.tags_data];
    const found = temp_data.find(element => element == name);
    if(found) {
      // Do nothing
      this.selected_tags_in_list.push(name);
    } else {
      this.selected_tags_not_in_list.push(name);
      // Update service call
      this.update_tags_data(name)
    }
    // console.log(this.selected_tags);
    this.selected_tags_data_event.emit({
      'existing':this.selected_tags_in_list,
      'new': this.selected_tags_not_in_list,
      'all':this.selected_tags
    });
  }

  onRemoveTag(event) {
    const found1_index = this.selected_tags_in_list.indexOf(event.value)
    if (found1_index !== -1) {
      this.selected_tags_in_list.splice(found1_index, 1);
    }
    const found2_index = this.selected_tags_not_in_list.indexOf(event.value)
    if (found2_index !== -1) {
      this.selected_tags_not_in_list.splice(found2_index, 1);
    }
    this.selected_tags_data_event.emit({
      'existing':this.selected_tags_in_list,
      'new': this.selected_tags_not_in_list,
      'all':this.selected_tags
    });
  }
}
