import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UploadDocumentComponent } from '../upload-document/upload-document.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { AddLogsService } from './add-logs.service';
import { SharedService } from 'src/app/dce-service/shared.service';
import { EnvService } from 'src/app/dce-service/env.service';

declare var $: any;

@Component({
  selector: 'app-log-details',
  templateUrl: './add-logs.component.html',
  styleUrls: ['./add-logs.component.scss']
})
export class AddLogsComponent implements OnInit {

  @Input() btn_visible = true;
  @Input() log_type = []; // ['note', 'email','phone']
  @Input() log_type_case = ''
  @Input() user_signature?: string = '';
  @Input() show_external_note_checkbox?: boolean = false;
  @Input() wf_type?: string = 'case';
  @Input() log_notes_icon: boolean

  @Input() notes_content: any = []
  @Input() call_notes_content_api : boolean = true
  @Input() email_content: any = []
  @Input() call_email_content_api : boolean = true
  @Input() unique_id ='';
  @Input() unique_dropdown_id:string='';
  @Output() unique_dropdown_id_change: EventEmitter<any> = new EventEmitter<any>();

  @Output() log_details_action_event: EventEmitter<any> = new EventEmitter<any>();
  show_note_box = false;
  note_text = '';
  current_log_type = '';

  log_email_value = '';
  log_email_value_list = [];
  is_signature = false;
  email_body: string;
  email_subject: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'size': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image']
    ]
  };
  modules_readonly = {toolbar: false };
  email_log_type = false;
  note_log_type = false;
  phone_note_log_type = false;

  document_list = [];
  document_request_log_type = false
  moduleLoading = false;
  log_email_cc_value = '';
  log_email_cc_value_list = [];
  log_email_bcc_value = '';
  email_body_empty = false;
  email_subject_empty = false; 
  is_external_note = false;
  send_documents_notif = false;
  email_value_from_evt = false;
 
  note_rec = {
    note2parent_note : null,
    is_external: false
  };
  doc_length = 0;
  email_content_list = [];
  email_template = -'-1';

  notes_content_list = [];
  

  @ViewChild(UploadDocumentComponent, { static: false }) uploadDocumentComponent: UploadDocumentComponent;

  // if logtype is email
  @Input()
  set email_value(val: string) {
    this.log_email_value = val;
    if(this.log_email_value !='') {
      this.log_email_value_list = val.split(';')
    } else {
      this.log_email_value_list = [];
    }
    this.log_email_bcc_value = '';
    this.email_value_from_evt = val ? true : false;
  }
  get email_value(): string { return this.log_email_value; }

  @Input()
  set cc_email_value(val: string) {
    this.log_email_cc_value = val;
    if(this.log_email_cc_value !='') {
      this.log_email_cc_value_list = val.split(';')
    } else {
      this.log_email_cc_value_list = [];
    }
  }
  get cc_email_value(): string { return this.log_email_cc_value; }

  @Input()
  set signature_bool(val: boolean) {
    this.is_signature = val;
  }
  get signature_bool(): boolean { return this.is_signature; }

  @Output('showNoteBoxChange') note_box_emitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('showNoteBox')
  set toggle_log_note_box(val: boolean) {
    this.show_note_box = val;
  }
  get toggle_log_note_box(): boolean { return this.show_note_box; }

  add_notes_config = {
    case_note: {
      email_url: 'send_case_log_email',
      note_url: 'add_case_notes'
    },
    case_note_document_request: {
      email_url: 'send_case_log_email',
      note_url: 'add_case_notes'
    },
    case_note_email: {
      email_url: 'workflow/log_email',
      note_url: 'add_case_notes'
    },
    case_note_phone: {
      email_url: 'send_case_log_email',
      note_url: 'add_case_notes'
    },
    lead_note: {
      // email_url: 'send_case_log_email',
      note_url: 'add_lead_note'
    },
    task_note: {
      email_url: 'task/send_log_email',
      note_url: 'add_task_note'
    },
    task_note_email: {
      email_url: 'workflow/log_email',
      note_url: 'add_task_note'
    },
    campaign_phone: {
      email_url: 'interaction/log_email',
      note_url: 'interaction/log_note'
    },
    campaign_email: {
      email_url: 'interaction/log_email',
      note_url: 'interaction/log_note'
    },
  }
  modal_open_time:any;
  show_send_doc_with_notification = false;
  prev_section_entry = -1; // used only in the task-list component
  constructor(
    private _fb: FormBuilder,
    private _commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private _logDetailsService: AddLogsService,
    private _sharedService: SharedService,
    private _env: EnvService
  ) { }

  ngOnInit() {
    this.initialize_log_type();
    this.show_send_doc_with_notification = this._env.send_doc_with_notification[this.wf_type]
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.unique_dropdown_id) {
      if(('logs-'+this.unique_id) !=changes.unique_dropdown_id.currentValue){
          $('#layout-dropdown-logs-' + this.unique_id).hide()   
      }
    }
  }

  open_log_modal() {
    this.send_documents_notif=false;
    $('#log-modal-' + this.log_type_case + '-' + this.unique_id ).modal({ backdrop: 'static', keyboard: false });
    setTimeout(()=>{
      $('#note_text').focus();
    }, 500)
    const today = this._commonService.formatDate(new Date());
    this.modal_open_time = this.serialize_date_time(today,this.time_formatter())
  }

  hide_log_modal() {
    this.show_note_box = false;
    this.log_email_cc_value = '';
    this.log_email_bcc_value = '';
    this.email_body_empty = false;
    this.email_subject_empty = false;
    console.log(this.log_type_case, this.btn_visible)
    this.note_box_emitter.emit(this.show_note_box);
    $('#log-modal-' + this.log_type_case + '-' + this.unique_id).modal('hide');
  }

  initialize_log_type() {
    for (let i = 0; i < this.log_type.length; i++) {
      if (this.log_type[i] == 'note') {
        this.note_log_type = true;
        // console.log(this.notes_content)
        if(this.call_notes_content_api) {
          this.get_all_notes_content();
        } else {
          this.notes_content_list = this.notes_content;
        }
      } else if (this.log_type[i] == 'email') {
        this.email_log_type = true;
        if(this.call_email_content_api) {
          this.get_all_email_content();
        } else {
          this.email_content_list = this.email_content;
        }
      } else if (this.log_type[i] == 'phone') {
        this.phone_note_log_type = true;
      } else if (this.log_type[i] == 'document_request') {
        this.document_request_log_type = true;
      }
    }
    if (this.email_log_type) {
      this.log_email_value = this.email_value;
    }
  }

  email_input_event(_case,event) {
    switch(_case) {
      case 'to':
        this.log_email_value = event.emails.join(';')
      break;
      case 'cc':
        this.log_email_cc_value = event.emails.join(';')
      break;

    }
  }

  show_log_note_box(val, current_case, note_rec = { id: null, is_external: false, user_name: '' }) {
    const is_external = (note_rec.is_external == null || note_rec.is_external == false) ? false : true;
    this.is_external_note = is_external;
    this.note_rec = {
      note2parent_note: note_rec.id,
      is_external: is_external
    };
    if (!this.email_value_from_evt) {
      this.log_email_value = note_rec.user_name;
      if(this.log_email_value !='') {
        this.log_email_value_list = this.log_email_value.split(';')
      } else {
        this.log_email_value_list = []
      }
    }
    this.note_text = '';
    this.email_body = '';
    this.email_subject = '';  
    this.show_note_box = val;
    this.current_log_type = current_case;
    this.doc_length=0;
    if (current_case == 'email') {
      if (this.user_signature == '') {
        this.is_signature = false;
      } else {
        this.is_signature = true;
      }
      if(note_rec['note_text'] && note_rec['note_text'] != '') {
        this.email_body = '<br><br>*******************  Quoted Text *******************<br>'+ note_rec['note_text'];
      }
    } else {
      this.is_signature = false;
    }
    this.note_box_emitter.emit(this.show_note_box);
    this.open_log_modal();
  }

  add_new_note_event(type) {
    this.log_details_action_event.emit({ 'type': type, 'data': {} });
  }

  add_new_note(_case, id,log_type_case='normal', note_type='Normal', view = null) {
    if (this.note_text == '') {
      this._toastr.error('', 'Please add a note', this._toastrUtility.basic_configuration);
    }
    else {
      this.moduleLoading = true;
      this.document_list = this.uploadDocumentComponent.document_list;
      if (log_type_case !='normal') { 
        this.document_list = [];
      }
      let payload = {};
      // Need a good solution for this
      if (_case == 'lead_note') {
        payload = this.create_notes_payload_lead(id,note_type,this.document_list);
      } else if(_case == 'campaign_phone'){
        payload = this.create_campaign_payload(_case,view);
      } else {
        payload = this.create_notes_payload(id,note_type,this.document_list);
      }
      this.document_list = [];
      const service = this.add_notes_config[_case].note_url;
      this._logDetailsService.send_data(service, payload).subscribe(res => {
        if (res.errCode == 0) {
          this._toastr.success('', 'New Note Added', this._toastrUtility.basic_configuration);
          this.show_note_box = false;
          this.is_external_note = false;
          this.send_documents_notif = false;
          this.uploadDocumentComponent.empty_file();
          this.log_details_action_event.emit({ 'type': _case + '_success', 'data': res });
          this.hide_log_modal();
        }
        this.moduleLoading = false;
      });
    }
  }

  validate_email(mail) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
    if (reg.test(mail) == false) {
      return false;
    }
    return true;
  }

  validate_emails_list (email_list) {
    const cc_emails_list = email_list.split(';');
    for (let i =0 ;i < cc_emails_list.length; i++) {
      if (cc_emails_list[i].trim() != '') {
        if(!this.validate_email(cc_emails_list[i].trim())) {
          return cc_emails_list[i];
        }
      }
    }
    return '';
  }

  create_email_notes_payload(_case,bobj_table_id,selected_bobj) {
    this.document_list = this.uploadDocumentComponent.document_list;
    this.note_text = '<b>To: </b> ' + this.log_email_value + '<br>' + '<b>CC: </b> ' + this.log_email_cc_value + '<br>' + '<b>' + this.email_subject + '</b><br>' + this.email_body;
    const payload = {
      "view_name": this.wf_type + "_view",
      "bobj_table_id": bobj_table_id,
      "note_text": this.note_text,
      "documents_data_list": this.document_list,
      "note_type": 'Email',
      "is_external": this.is_external_note,
      "note2parent_note": this.note_rec.note2parent_note,
      "send_documents_notif": this.send_documents_notif ? 1: 0,
      "note_request_start_time": this.modal_open_time,
      "telephone_call_id": this._sharedService.sip_storage.call_id? this._sharedService.sip_storage.call_id: null,
      "comm_email": this.log_email_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';'),
      "body": this.email_body,
      "send_signature": this.is_signature,
      "cc_list": this.log_email_cc_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';'),
      "bcc_list": this.log_email_bcc_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';')
    }
    let bo_payload = {}
    if (_case == 'task_note_email') {
      bo_payload = this.create_email_payload_task_v2(selected_bobj)
    } else if(_case == 'case_note_email') {
      bo_payload = this.create_email_payload_case_v2(selected_bobj)
    }
    const final_payload = {...payload,...bo_payload}
    return final_payload;
  }

  async send_case_log_email_v2(_case,id,selected_bobj) {
    const valid = this.validate_log_email_input();
    if(valid) {
      let note_email_payload = {};
      if (_case == 'campaign_email') {
        note_email_payload = this.create_campaign_payload(_case, selected_bobj);
      } else {
        note_email_payload = this.create_email_notes_payload(_case,id,selected_bobj);
      }
      const service = this.add_notes_config[_case].email_url;
      this.moduleLoading = true;
      const return_note_email_data = await this._logDetailsService.send_data(service,note_email_payload).toPromise();

      if (return_note_email_data.errCode == 0) {
        this._toastr.success('', `Email sent successfully to ${this.log_email_value}`, this._toastrUtility.basic_configuration);
        this.document_list = [];
        this.uploadDocumentComponent.empty_file();
        this.email_body_empty = false;
        this.email_subject_empty = false;
        this.log_email_cc_value = '';
        this.log_email_bcc_value = '';
        this.moduleLoading = false;
        this.show_note_box = false;
        this.is_external_note = false;
        this.send_documents_notif = false;
        this.hide_log_modal();
        this.log_details_action_event.emit({ 'type': _case + '_success', 'data': return_note_email_data });
      }
    }
    
  }

  create_campaign_payload(_case, view) {
    this.document_list = this.uploadDocumentComponent.document_list;
    let payload: any = {};
    if (_case === 'campaign_email') {
      this.note_text = '<b>To: </b> ' + this.log_email_value + '<br>' + '<b>CC: </b> ' + this.log_email_cc_value + '<br>' + '<b>' + this.email_subject + '</b><br>' + this.email_body;
      payload = {
        "interaction_type": "LogEmail",
        "interaction2cmptarget": view.target_id,
        "channel": "Voice",
        "document_data_list": this.document_list,
        // "telephone_call_id": this._sharedService.sip_storage.call_id ? this._sharedService.sip_storage.call_id : null,
        "comm_email": this.log_email_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';'),
        "subject": this.email_subject,
        "body": this.email_body,
        // "send_signature": this.is_signature,
        "cc_list": this.log_email_cc_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';'),
        "bcc_list": this.log_email_bcc_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';')
      }
    } else if (_case === 'campaign_phone') {
      payload = {
        "interaction_type": "LogNote",
        "interaction2cmptarget": view.target_id,
        "interaction_text": this.note_text,
        "channel": "Voice",
        "document_data_list": this.document_list
      };
      payload['interaction_text'] = payload['interaction_text'].replace(/\n/g, '<br>');
    }
    if (view.biz_org_id) {
      payload.interaction2biz_org = view.biz_org_id;
    } else if (view.individual_id) {
      payload.interaction2individual = view.individual_id;
    }
    return payload;
  }

  validate_log_email_input() {
    if (this.log_email_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';') == '') {
      this._toastr.error('', 'Please enter a email', this._toastrUtility.basic_configuration);
      return false;
    }
    if ((this.email_subject == '' || this.email_subject == null) && !this.email_subject_empty) {
      this.email_subject_empty = true;
      this._toastr.warning('', 'This email has no subject, Clicking send again will send the email.', this._toastrUtility.basic_configuration);
      return false;
    }
    if ((this.email_body == '' || this.email_body == null) && !this.email_body_empty) {
      this.email_body_empty = true;
      this._toastr.warning('', 'This email has no body, Clicking send again will send the email.', this._toastrUtility.basic_configuration);
      return false;
    }
    const to_emails_valid = this.validate_emails_list(this.log_email_value);
    if (to_emails_valid != '') {
      this._toastr.error('', to_emails_valid + ' is not valid email address', this._toastrUtility.basic_configuration);
      return false;
    }
    const cc_emails_valid = this.validate_emails_list(this.log_email_cc_value);
    if (cc_emails_valid != '') {
      this._toastr.error('', cc_emails_valid + ' is not valid email address', this._toastrUtility.basic_configuration);
      return false;
    }
    const bcc_emails_valid = this.validate_emails_list(this.log_email_bcc_value);
    if (bcc_emails_valid != '') {
      this._toastr.error('', bcc_emails_valid + ' is not valid email address', this._toastrUtility.basic_configuration);
      return false;
    }
    return true;
  }

  async send_case_log_email(_case, id, selected_bobj) {
    console.log(this.email_subject);
    if (this.log_email_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';') == '') {
      this._toastr.error('', 'Please enter a email', this._toastrUtility.basic_configuration);
      return;
    }
    if ((this.email_subject == '' || this.email_subject == null) && !this.email_subject_empty) {
      this.email_subject_empty = true;
      this._toastr.warning('', 'This email has no subject, Clicking send again will send the email.', this._toastrUtility.basic_configuration);
      return;
    }
    if ((this.email_body == '' || this.email_body == null) && !this.email_body_empty) {
      this.email_body_empty = true;
      this._toastr.warning('', 'This email has no body, Clicking send again will send the email.', this._toastrUtility.basic_configuration);
      return;
    }
    const note_payload = this.create_notes_payload(id,'Email',[]);
    const to_emails_valid = this.validate_emails_list(this.log_email_value);
    if (to_emails_valid != '') {
      this._toastr.error('', to_emails_valid + ' is not valid email address', this._toastrUtility.basic_configuration);
      return;
    }
    const cc_emails_valid = this.validate_emails_list(this.log_email_cc_value);
    if (cc_emails_valid != '') {
      this._toastr.error('', cc_emails_valid + ' is not valid email address', this._toastrUtility.basic_configuration);
      return;
    }
    const bcc_emails_valid = this.validate_emails_list(this.log_email_bcc_value);
    if (bcc_emails_valid != '') {
      this._toastr.error('', bcc_emails_valid + ' is not valid email address', this._toastrUtility.basic_configuration);
      return;
    }
    const service1 = this.add_notes_config[_case].note_url;
    const service2 = this.add_notes_config[_case].email_url;
    const return_note_data = await this._logDetailsService.send_data(service1,note_payload).toPromise();

    if (return_note_data.errCode == 0) {
      this.document_list = this.uploadDocumentComponent.document_list;
      this.moduleLoading = true;
      let basic_payload = {
        'comm_email': this.log_email_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';'),
        'body': this.email_body,
        'note_id': return_note_data.note_rec.id,
        'document_data_list': this.document_list,
        'send_signature': this.is_signature,
        'cc_list': this.log_email_cc_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';'),
        'bcc_list': this.log_email_bcc_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';')
      };
      let temp_data = {}
      if (_case == 'task_note_email') {
        temp_data = this.create_email_payload_task(selected_bobj,id)
      } else if(_case == 'case_note_email') {
        temp_data = this.create_email_payload_case(selected_bobj,id)
      }
      const email_payload = {...basic_payload, ...temp_data};
      this.document_list = [];
      email_payload['body'] = email_payload['body'].replace(/\n/g, '<br>');
      const wf = await this._logDetailsService.send_data(service2, email_payload).toPromise();

      this.uploadDocumentComponent.empty_file();
      const temp_log_type = _case.replace('_email','');
      if (wf.errCode == 0) {
        this.email_body_empty = false;
        this.email_subject_empty = false;
        this.note_text = `${wf.msg} <br><b>To: </b>${this.log_email_value} <br> <b>CC:</b> ${this.log_email_cc_value}`;
        // this.note_text = `${wf.msg} to ${this.log_email_value}`;
        this.add_new_note(temp_log_type, id,'email');
        this._toastr.success('', `Email sent successfully to ${this.log_email_value}`, this._toastrUtility.basic_configuration);
        this.log_email_cc_value = '';
        this.log_email_bcc_value = '';
      } else {
        this.note_text = `Error In Sending Email to ${this.log_email_value}`;
        this.add_new_note(temp_log_type, id, 'email');
      }
      this.moduleLoading = false;
      // this.log_details_action_event.emit({ 'type': _case + '_success', 'data': return_note_data }); 
      this.log_details_action_event.emit({ 'type': _case + '_success', 'data': wf });
    }
  }

  create_email_payload_case(bobj,bobj_id) {
    const payload = {
      'subject': '[CASE ID: ' + bobj.case_id + '] ' + this.email_subject,
      'case_id': bobj_id,
      'biz_org_id': bobj.biz_org_id? bobj.biz_org_id : null,
    };
    return payload;
  }

  create_email_payload_task(bobj,bobj_id) {
    const payload = {
      'subject': '[TASK ID: ' + bobj.task_id + '] ' + this.email_subject,
      'task_id': bobj_id,
    };
    return payload;
  }

  create_email_payload_case_v2(bobj) {
    const payload = {
      'subject': '[CASE ID: ' + bobj.case_id + '] ' + this.email_subject,
      'biz_org_id': bobj.biz_org_id? bobj.biz_org_id : null,
    };
    return payload;
  }

  create_email_payload_task_v2(bobj) {
    const payload = {
      'subject': '[TASK ID: ' + bobj.task_gen_id + '] ' + this.email_subject,
      'biz_org_id': bobj.biz_org_id? bobj.biz_org_id : null,
    };
    return payload;
  }

  create_notes_payload(id, note_type, doc_list) {
    if (note_type == 'Email') {
      this.note_text = '<b>' + this.email_subject + '</b><br>' + this.email_body;
    }
    let payload = {};
    payload = {
      "bobj_table_id": id,
      "note_text": this.note_text,
      'documents_data_list': doc_list,
      'note_type': note_type,
      'is_external': this.is_external_note,
      'note2parent_note': this.note_rec.note2parent_note,
      'send_documents_notif':this.send_documents_notif ? 1: 0,
      'start_time': this.modal_open_time,
      'call_id': this._sharedService.sip_storage.call_id? this._sharedService.sip_storage.call_id: null
    };
    payload['note_text'] = payload['note_text'].replace(/\n/g, '<br>');
    return payload;
  }

  create_notes_payload_lead(id, note_type, doc_list) {
    if (note_type == 'Email') {
      this.note_text = this.email_body;
    }
    let payload = {};
    payload = {
      "wf_obj_table_id": id,
      "note_text": this.note_text,
      'documents_data_list': doc_list,
      'note_type': note_type,
      'is_external': this.is_external_note,
      'note2parent_note': this.note_rec.note2parent_note
    };
    payload['note_text'] = payload['note_text'].replace(/\n/g, '<br>');
    return payload;
  }
  
  onContentChanged(event) { // quill event
  }

  get_doc_list(event){
    this.doc_length = event.document_data.length
    if(this.doc_length == 0){
      this.send_documents_notif = false
    } else {
      this.send_documents_notif = true
    }
  }

  checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  
  time_formatter() {
    var today = new Date();
    var h = today.getHours();
    var m = today.getMinutes();
    var s = today.getSeconds();
    // add a zero in front of numbers<10
    m = this.checkTime(m);
    s = this.checkTime(s);
    return  h + ":" + m + ":" + s;
  }

  serialize_date_time(date, time) {
    const today = new Date(date);
    const temp_datetime_v2 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.split(':')[0], time.split(':')[1],time.split(':')[2], 0)
    return temp_datetime_v2.toString();
  }

  get_all_email_content() {
    const payload = {
      "record_type": this.wf_type + "_notes_email"
    }
    if(this.email_content_list.length == 0) {
      this._commonService.get_all_email_content(payload).subscribe(res => {
        if(res.errCode ==0 ) {
          this.email_content_list = res.data;
        }
      })
    }
  }

  onEmailTemplateChanged(value) {
    if(value == -1) {
      this.email_subject = '';
      this.email_body = '';
    } else {
      this.email_subject = this.email_content_list[value].subject;
      this.email_body = this.email_content_list[value].body
    }
  }

  get_all_notes_content() {
    const payload = {
      "record_type": this.wf_type + "_notes"
    }
    if(this.notes_content_list.length == 0) {
      this._commonService.get_all_email_content(payload).subscribe(res => {
        if(res.errCode ==0 ) {
          this.notes_content_list = res.data;
        }
      })
    }
  }

  onNotesTemplateChanged(value) {
    if(value == -1) {
      this.note_text = '';
    } else {
      this.note_text = this.notes_content_list[value].body
    }
  }

  open_layout_dropdown(event,dropdown_id,_case){
    // console.log(section)
    // if(this.prev_section_entry !=-1 && this.prev_section_entry !=section){
    //   $('#layout-dropdown-' + this.prev_section_entry).hide() 
    // }
    // $('#layout-dropdown-' + section).toggle()
    // this.prev_section_entry = section;
    let curr_dropdown_id = _case+dropdown_id;
   
    if(this.unique_dropdown_id != curr_dropdown_id){
      $('#layout-dropdown-logs-' + this.unique_dropdown_id).hide() 
    }
    $('#layout-dropdown-' + curr_dropdown_id).toggle()
    this.unique_dropdown_id = curr_dropdown_id;  
    this.unique_dropdown_id_change.emit({data:dropdown_id,case:_case});
    
    event.stopPropagation();
   }

}
