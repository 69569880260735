import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
declare var $:any;

@Component({
  selector: 'dynamic-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.css']
})
export class ShowErrorsComponent implements OnInit {

  private static readonly errorMessages = {
    'required': (params) => params.message != undefined? params.message: 'This field is required',
    'min': (params) => params.message,
    'max': (params) => params.message,
    'birthYear': (params) => params.message,
    'uniqueEmail': (params) => params.message,
    'email': () => 'Enter a valid email address',
    'minlength': (params) => 'The min number of characters is ' + params.requiredLength,
    'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength,
    'pattern': (params) => 'Password must be of 8 or more characters. Must include atleast one upper case letter, one lower case letter, one number and one speacial character.',
    'mobileNo_10':(params) => params.message,
    'idNumber': (params) => params.message,
    'isNotExemptionDependentDate': (params) => params.message,
    'isExemptionDependentDate': (params) => params.message,
    'adultAge': (params) => params.message,
    'start_date_org': (params) => params.message,
    'matchText': (params) => params.message,
    'Ticket_no_format': (params) => params.message,
    'rating_format': (params) => params.message,
    'date_for_accident': (params) => params.message,
    'Link_no_format': (params) => params.message,
    'text_include_only_alphabets': (params) => params.message,
    'passport_id_format': (params) => params.message,
  };

  @Input() private control: AbstractControlDirective | AbstractControl;
  @Input() private validationList?:any = [];
  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {

  }
  list_of_errors() {
    if (this.control.errors != null || this.control.errors != undefined) {
      $(this.el.nativeElement).prev().addClass('is-invalid');
      return Object.keys(this.control.errors).map(field => this.get_message(field, this.parse_error_with_message(field)));
    } else {
      $(this.el.nativeElement).prev().removeClass('is-invalid');
    }
  }


  parse_error_with_message(field) {
    let error_name_and_object = {}; 
    for(let i =0; i< this.validationList.length; i++) {
      if (this.validationList[i].name == field ) {
        error_name_and_object= {'message' : this.validationList[i].message}
      }
    }
    return error_name_and_object;
  }
  private get_message(type: string, params: any) {
    return ShowErrorsComponent.errorMessages[type](params);
  }

}
