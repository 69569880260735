import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss']
})
export class CopyTextComponent implements OnInit {
  
  @Input() copy_content : any;

  constructor(
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService
  ) { }

  ngOnInit() {
  }

  copy_text(){
    let copyText = document.createElement('input');
    copyText.value = this.copy_content;
    console.log(copyText.value)
    document.body.appendChild(copyText);
    copyText.select();
    document.execCommand('copy');
    this._toastr.success('', 'Copied Successfully', this._toastrUtility.basic_configuration);
    document.body.removeChild(copyText);
  }

}
