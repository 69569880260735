import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
import { SocketService } from 'src/app/dce-service/socket.service';
declare var $: any;
import * as prettyMs from 'pretty-ms';
import { SideContainerComponent } from 'src/app/dce-shared/side-container/side-container.component';
import { OngoingChatComponent } from '../ongoing-chat/ongoing-chat.component';
import { EnvService } from 'src/app/dce-service/env.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../../dce-service/toastr-utility.service';
import { ChatLinkingService } from 'src/app/dce-service/chat-linking.service';
import { defaultOutcomeValue } from '../const';
@Component({
  selector: 'app-chat-popup',
  templateUrl: './chat-popup.component.html',
  styleUrls: ['./chat-popup.component.scss']
})
export class ChatPopupComponent implements OnInit {

  @ViewChild(SideContainerComponent ,{static: false}) sideContainer: SideContainerComponent;
  @ViewChildren(OngoingChatComponent) ongoing_chat: QueryList<any>;
  @ViewChild('dragDiv', { static: false }) dragDiv: ElementRef;
  @Output() chat_popup_event = new EventEmitter<any>();

  x = 0;
  y = 0;

  show_chat_data = true;
  
  user_data = {
    user_name: this._env.live_chat_agent_name
  }
  session_config = {
    pause_timer: null,
    pause_interval: null,
  }

  unlink_case_btn_loader = false;
  unlink_customer_btn_loader = false;
  default_outcome_value = defaultOutcomeValue;
  today_date=new Date();
  container_conf= {
    container_title : 'Web Self Service Chat Bot History',
    unique_id: 'chatbot_history'
  }

  constructor(
    public _liveChatService: LiveChatService,//chat store
    private socket: SocketService,
    private _env: EnvService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    public _chatLinkingService: ChatLinkingService
  ) { }

  ngOnInit() {
    this.get_socket_events()
    this.jqueryui_draggable();
  }

  jqueryui_draggable() {
    $("#chat-popup").draggable({handle: '#chat-popup-handler', containment: "window", scroll: false});
  }

  get_socket_events() {
    this._liveChatService.socket_event_data.subscribe(res => {
      this.socket_events(res);
    })
  }

  toggle_tab(session) {
    if(!this.show_chat_data) {
      this.toggle_chat();
    }
    this._liveChatService.set_selected_session(session);
    this.message_composer_input_focus(session.session_id);
    const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(sess_id => sess_id.session_id == session.session_id);
    this._liveChatService.live_chat_sessions[sessionIndex].notification_count = 0;
    const current_ongoing_chat = this.ongoing_chat.find(chat => chat.unique_id === `chat-popup-${this._liveChatService.selected_session.session_id}`)
    current_ongoing_chat.scroll_to_bottom();
  }

  // dragEnd(event) {
  //   const position = $('#chat-popup').offset()
  //   // console.log(position)
  //   // setTimeout(() => {
  //   const left_val = window.innerWidth - $('#chat-popup').width() - 35;
  //   // const top_val = window.innerHeight - $('#chat-popup').height() - 10;
  //   const popup_left_val = position.left + event.x;

  //   const popup_top_val = position.top + event.y;

  //   // console.log(popup_top_val)
  //   const top_val = popup_top_val+$('#chat-popup').height()+20;
  //   // console.log(top_val)
  //   // console.log(window.outerHeight)]
  //   console.log(popup_top_val)
  //   console.log(top_val);
  //   console.log(window.innerHeight)
  //   $("#chat-popup").css({ right:'unset',bottom:'unset' });

  //   if (popup_left_val < left_val && popup_left_val > 0) {
  //     $("#chat-popup").css({ left: popup_left_val + 'px' });
  //   } else {
  //     if (popup_left_val < 0) {
  //       $("#chat-popup").css({ left: 0 });
  //     } else {
  //       $("#chat-popup").css({ left: left_val });
  //     }
  //   }

  //   if(top_val <window.innerHeight ) {
  //     if (popup_top_val > 0) {
  //       $("#chat-popup").css({ top: popup_top_val + 'px', });
  //     } else {
  //       if (popup_top_val < 0) {
  //         $("#chat-popup").css({ top: 0, });
  //       } else {
  //         $("#chat-popup").css({ top: position.top + event.y + 'px' });
  //       }
  //     }
  //   } else {
  //     $("#chat-popup").css({ top: popup_top_val });
  //   }
   
  //   // },1000)

  //   console.log('Element was dragged', event);
  // }

  toggle_chat() {
    if(this.show_chat_data) {
      $("#chat-popup").css({ left: 'calc(50vw - 16px)', top: 'calc(100vh - 80px)'});
    } else {
      $("#chat-popup").css({ top:'0',left:'0' });
    }
    this.show_chat_data = !this.show_chat_data;
    const current_ongoing_chat = this.ongoing_chat.find(chat => chat.unique_id === `chat-popup-${this._liveChatService.selected_session.session_id}`)
    current_ongoing_chat.scroll_to_bottom();
  }

  socket_events(res) {
    switch (res.event_type) {
      case 'join_room':
      break;
      case 'connect_agent_to_customer':
        // Need to dicsuss this . We are not allowing user to enter message until the agent and customer are not connected
        // Make this synchronous with add welcome message in socket (data_in_all) otherwise it will not work
        // this.get_chat_history(res.session_id);
        this.message_composer_input_focus(res.session_id);
      break;
      case 'disconnect':
      case 'leave_room':
        const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == res.room);
        this._liveChatService.live_chat_sessions[sessionIndex].chat_outcome_time_completed.subscribe(response => {
          console.log(response)
          if(response) {
            this.save_chat_outcome(res.room);
          }
        })
      break;
      case 'data_in_all':  // broadcast
        const current_ongoing_chat = this.ongoing_chat.find(chat => chat.unique_id === `chat-popup-${this._liveChatService.selected_session.session_id}`)
        current_ongoing_chat.scroll_to_bottom();
        break;
      default:
        break;
    }
  }
  
  ongoing_chat_emitter(event) {
    switch(event.type) {
      case 'text':
        this.send_message(event.message);
      break;
      case 'document':
        this.send_document(event.document_data_list);
      break;
    }
  }

  typing_indicator_event(event){
    switch(event.type) {
      case 'typing':
        this.typing_indicator('start');
        this.reset_typing_indicator();
      break;
    }
  }

  reset_typing_indicator = this.debounce(() => this.typing_indicator('end'),2000);

  private debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  send_message(message_text:string): void{
    let data = { type: "text", message: message_text , user_data: this.user_data}
    // this._liveChatService.add_message(this._liveChatService.selected_session.session_id,data);
    this.socket.socket_event('data_in_all', this._liveChatService.selected_session.session_id, data);
  }

  send_document(document_data_list: any): void{
    console.log(document);
    let data = { type: "document", document_data_list: document_data_list,user_data: this.user_data}
    this.socket.socket_event('data_in_all', this._liveChatService.selected_session.session_id, data);
  }

  typing_indicator(type: 'start'| 'end'): void{
    let data = { type: type, user_data: this.user_data}
    this.socket.socket_event('typing', this._liveChatService.selected_session.session_id, data);
  }

  input_focused_event(event) {
    switch(event.type) {
      case 'fin':
        this._liveChatService.reset_notification_count();
      break;
      case 'fout':
        // this.typing_indicator('end');
      break
    }
  }
  
  open_side_container() {
    this.sideContainer.openContainer();
  }

  side_div_events(events) {}

  get_chat_history(session_id) {
    const payload = {
      chatsession_id: session_id
    }
    this._liveChatService.get_chat_history(payload).subscribe(res => {
      if(res.errCode == 0) {
        const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == session_id);
        this._liveChatService.live_chat_sessions[sessionIndex].message_data = res.data
      }
    })
  }

  save_chat_outcome(room = null) {
    let session_id = "";
    if(room == null) {
      session_id = this._liveChatService.selected_session.session_id;
    } else {
      session_id = room;
    }
    const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == session_id);
    const payload = {
      "live_chat_session_id": session_id,
	    "agent_id": this._liveChatService.live_chat_store.agent_id,
	    "agent_username": this._liveChatService.live_chat_store.agent_username,
	    "end_status": this._liveChatService.live_chat_sessions[sessionIndex].current_chat_outcome
    }
    this._liveChatService.live_chat_sessions[sessionIndex].btn_loaders.save_outcome = true;
    this._liveChatService.end_chat(payload).subscribe(res => {
      if(res.errCode == 0) {
        const sessionIndex2 = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == session_id);
        this._liveChatService.end_chat_outcome_timer(session_id)
        this._liveChatService.live_chat_sessions[sessionIndex2].btn_loaders.save_outcome = false;
        this._liveChatService.live_chat_sessions.splice(sessionIndex2,1)
        if(!this._liveChatService.live_chat_sessions.length) {
          this._liveChatService.live_chat_store.lifecycle_status = 'logged-in';
        } else {
          this._liveChatService.selected_session_index = 0;
          this._liveChatService.selected_session = this._liveChatService.live_chat_sessions[0];
        }
      }
    })
  }

  chat_linking(link_type,session_id) {
    const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == session_id);
    this._liveChatService.live_chat_sessions[sessionIndex].current_screen_mode = link_type;
  }

  unlink_customer(session_id) {
    let payload = {
      "live_chat_session_id": this._liveChatService.selected_session.session_id,
      "agent_id": this._liveChatService.live_chat_store.agent_id,
      "agent_username": this._liveChatService.live_chat_store.agent_username
    }
    this.unlink_customer_btn_loader = true;
    this._liveChatService.unlink_customer_with_chat(payload).subscribe(res => {
      if (res.errCode == 0) {
        const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == session_id);
        this._liveChatService.live_chat_sessions[sessionIndex].chat_linked_data = {
          "case_id": "",
          "customer_id": "",
          "company_name": "",
          "link_type": "",
          "case_table_id":""
        }
        this._toastr.success('', 'Customer Unlinked successfully', this._toastrUtility.basic_configuration);
      }
      this.unlink_customer_btn_loader = false;
    })
  }

  unlink_case(session_id) {
    let payload = {
      "live_chat_session_id": this._liveChatService.selected_session.session_id,
      "agent_id": this._liveChatService.live_chat_store.agent_id,
      "agent_username": this._liveChatService.live_chat_store.agent_username,
    }
    this.unlink_case_btn_loader = true;
    this._liveChatService.unlink_case_with_chat(payload).subscribe(res => {
      if (res.errCode == 0) {
        const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == session_id);
        this._liveChatService.live_chat_sessions[sessionIndex].chat_linked_data = {
          "case_id": "",
          "customer_id": "",
          "company_name": "",
          "link_type": "",
          "case_table_id":""
        }
        this._toastr.success('', 'Case Unlinked successfully', this._toastrUtility.basic_configuration);
      }
      this.unlink_case_btn_loader = false;
    })
  }

  end_chat() {
    const room = this._liveChatService.selected_session.session_id;
    const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == room);
    this._liveChatService.live_chat_sessions[sessionIndex].connection_status = 'disconnected';
    this._liveChatService.live_chat_sessions[sessionIndex].current_chat_outcome = defaultOutcomeValue;
    this._liveChatService.call_disconnect_audio();
    this._liveChatService.end_pause_timer(room);
    this._liveChatService.start_chat_outcome_timer(room);
    
    this._liveChatService.live_chat_sessions[sessionIndex].chat_outcome_time_completed.subscribe(response => {
      if(response) {
        this.save_chat_outcome(room);
      }
    })
    this.leave_session();
  }

  leave_session() {
    // TODO : Add callback on socket_event function.
    this.socket.socket_event('leave_room', this._liveChatService.selected_session.session_id, {user_name :this._env.live_chat_agent_name});
  }


  confirmation_event(event,sessionIndex) {
    switch(event.type) {
      case 'success':
        this.end_chat();
      break;
      case 'cancel':
        this._liveChatService.live_chat_sessions[sessionIndex].end_chat_confirmation = false;
      break;
    }
  }

  message_composer_input_focus(room_id){
    setTimeout(() => {
      if(document.getElementById('composer-' + room_id)) {
        document.getElementById('composer-' + room_id).focus();
      }
    },500)
  }

  chatbot_history_events(event){
    switch(event.type){
      case 'get_chatbot_source':
        if(event.datarec.source == 'chatbot'){
          this.container_conf.container_title = 'Web Self Service Chat Bot History';
        }else{
          this.container_conf.container_title = 'WhatsApp Chat History';
        }
        break;
      
      default:
        break;
    }
  }

  chat_disconnect_event(event) {
    this.chat_popup_event.emit(event);
  }
}