import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[inputNumber]'
})
export class InputNumberDirective {

  private number_regex: RegExp = new RegExp(/^[0-9]*$/g);
  private special_keys: Array<string> = ['Backspace', 'Tab'];

  constructor(private el: ElementRef) {
  }
  
  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.special_keys.indexOf(event.key) !== -1) {
      return;
    }
    // Get current value
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if ((next && !String(next).match(this.number_regex))) {  
      event.preventDefault();
    } else {

    }
  }
}
