import { AfterViewInit, Component,EventEmitter,Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as SurveyCreator from 'survey-creator';
import * as Survey from 'survey-angular';
import * as SurveyKo from "survey-knockout";

declare var $:any;
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-generic-surveyjs',
  templateUrl: './generic-surveyjs.component.html',
  styleUrls: ['./generic-surveyjs.component.scss']
})

export class GenericSurveyjsComponent  implements AfterViewInit, OnChanges{

  @Input() survey_mode?: string = 'creator'; // creator/read_mode/write_mode (read_mode == display)
  @Input() config_identifier?: string = 'default';
  @Input() survey_id?: string = 'surveyElement';

  // Can be make to one
  @Input() color_config?:any = {};
  @Input() surveyStrings_config?:any = {};
  @Input() surveyOptions_config?: any= {};
  @Input() curStrings_config?: any= {};
  @Input() css_classes_config?: any= {};
  @Input() showingProperties?:any = {};

  @Input() survey_questionaire?: any = [];
  @Input() survey_answers?: any = [];

  // Event emitters
  // @Output() survey_events: EventEmitter<any> = new EventEmitter<any>(); 
  @Output() survey_messages: EventEmitter<any> = new EventEmitter<any>(); 
  // @Output() survey_answers_data: EventEmitter<any> = new EventEmitter<any>(); 

  // emit_survey_events(events_json) {
  //   this.survey_events.emit(events_json)
  // }

  emit_survey_messages(message) {
    this.survey_messages.emit(message)
  }

  // emit_survey_answers(answers) {
  //   this.survey_answers_data.emit(answers)
  // }
  
  surveyCreator: SurveyCreator.SurveyCreator;
  surveyModel: Survey.Model;

	constructor(
		private _router: ActivatedRoute,
  ) {}

	ngOnInit() {
    // this.survey_strings();
    // if(this.survey_mode == 'creator') {
    //   this.survey_creator_color_theme();
    //   this.survey_creator_mode();
    // } else if (this.survey_mode == 'write_mode'  || this.survey_mode == 'read_mode') {
    //   this.display_survey_color_theme();
    //   this.survey_display_mode();
    // }

    // TODO
    // var myBlackList = ["choicesByUrl","hasOther","otherText", "name", "locale","logo", "logoWidth", "logoHeight", "logoFit" , "logoPosition"];
    // this.surveyModel.onCanShowProperty.add(function (sender, options) {
    //   options.canShow = myBlackList.indexOf(options.property.name) < 0;
    // })
    
  }

  ngAfterViewInit() {
    this.parse_survey();
  }

  parse_survey() {
    Survey.StylesManager.ThemeColors["default"] = this.color_config.defaultThemeColorsSurvey;
    Survey.StylesManager.applyTheme();
    SurveyCreator.StylesManager.ThemeColors["default"] = this.color_config.defaultThemeColorsEditor;
    SurveyCreator.StylesManager.applyTheme();
    if(this.survey_mode == 'creator') {
      this.survey_creator_strings();
      this.survey_creator_mode();
    } else if (this.survey_mode == 'write_mode'  || this.survey_mode == 'read_mode') {
      this.survey_display_strings();
      this.survey_display_mode();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.survey_questionaire && !changes.survey_questionaire.firstChange) {
      this.survey_questionaire = changes.survey_questionaire.currentValue;
      this.parse_survey();
    }
  }
  
  // Color theme for survey
  // display_survey_color_theme() {
    // Survey.StylesManager.ThemeColors["default"] = {
    //   "$main-color": this.color_config.mainColor,
    //   "$main-hover-color": this.color_config.mainHoverColor,
    //   "$text-color": this.color_config.textColor,
    //   "$body-container-background-color": this.color_config.headerColor,
    //   "$header-color": this.color_config.headerBackgroundColor,
    //   "$header-background-color": this.color_config.bodyContainerBackgroundColor
    // }
    // Survey.StylesManager.ThemeColors["default"] = defaultThemeColorsSurvey;
    // Survey.StylesManager.applyTheme();
  // }

  // survey_creator_color_theme () {
    // SurveyCreator.StylesManager.ThemeColors["default"] = {
    //   "$primary-color":  this.color_config.mainColor,
    //   "$secondary-color": this.color_config.secondaryColor,
    //   "$primary-hover-color": this.color_config.mainHoverColor,
    //   "$primary-text-color": this.color_config.textColor,
    //   "$selection-border-color": this.color_config.mainColor
    // };

    // SurveyCreator.StylesManager.ThemeColors["default"] = this.color_config.defaultThemeColorsEditor;
    // SurveyCreator.StylesManager.applyTheme();
  // }

  // Survey  strings for completion
  survey_creator_strings() {
    for (let key in this.surveyStrings_config.creator_options ) {
      SurveyKo.surveyStrings[key] = this.surveyStrings_config.creator_options[key];
    }


    // if (Object.keys(this.surveyStrings_config).length) {
    //   if (this.surveyStrings_config.completingSurvey) {
    //     Survey.surveyStrings.completingSurvey = this.surveyStrings_config.completingSurvey
    //   }
    //   if(this.surveyStrings_config.completeText) {
    //     Survey.surveyStrings.completeText = this.surveyStrings_config.completeText;
    //   }
    // }
  }

  survey_display_strings() {
    for (let key in this.surveyStrings_config.display_options ) {
      Survey.surveyStrings[key] = this.surveyStrings_config.display_options[key];;
    }
  }

  // Create survey mode
  survey_creator_mode() {
    var curStrings = SurveyCreator.editorLocalization.getLocale(""); // For language
    curStrings = Object.assign(curStrings, this.curStrings_config);
    this.surveyCreator = new SurveyCreator.SurveyCreator(this.survey_id, this.surveyOptions_config.creator_options); 
    this.surveyCreator.rightContainerActiveItem("toolbox");
    if(this.survey_questionaire != null && Object.keys(this.survey_questionaire).length) {
      this.surveyCreator.text =  JSON.stringify(this.survey_questionaire);
    }
    if(this.surveyOptions_config.creator_options.showProgressBar) {
      this.surveyCreator.survey.showProgressBar = this.surveyOptions_config.creator_options.showProgressBar;
    } else {
      this.surveyCreator.survey.showProgressBar = 'off';
    }
    if (this.showingProperties['default_properties']) {
      this.surveyCreator.onShowingProperty.add((sender, options) => {
        var propName = options.property.name;
        options.canShow = this.showingProperties.isInList("default_properties", propName) || this.showingProperties.isInList(options.obj.getType(), propName);
      });
    }

  }

  // Return the questionaire data
  get_questionare_data() {
    return this.surveyCreator.JSON
  }

  // Attempt and view mode (display)
  survey_display_mode() {
    this.surveyModel = new Survey.Model(JSON.stringify(this.survey_questionaire));
    this.display_mode_config()
    this.surveyModel.data = this.survey_answers;
    if (this.survey_mode == 'read_mode') {
      this.surveyModel.mode = 'display';
    }
    Survey.SurveyNG.render(this.survey_id, { model: this.surveyModel, css: this.css_classes_config });	
    // this.scroll_to_top();
  }


  display_mode_config() {
    if (this.surveyOptions_config.display_options) {
      for(let key in this.surveyOptions_config.display_options) {
        this.surveyModel[key] = this.surveyOptions_config.display_options[key]
      }
    }
  }

  scroll_to_top() {
    this.surveyModel.onCurrentPageChanging.add(function(sender, options) {
      $(window).scrollTop( $(".scrollup").offset().top );
    })
    this.surveyModel.onValidateQuestion.add(function(sender, options) {
      $(window).scrollTop( $(".scrollup").offset().top );
    });
  }

  // survey setters
  set_survey_mode(mode){
    this.survey_mode = mode;
    this.parse_survey();
  }

  get_answers(){
    return this.surveyModel.getAllValues() 
  }
  
  // check validation in survey
  validate_survey() {
    return this.surveyModel.hasErrors() 
  }

  is_empty() {
    const temp_model = new Survey.Model(JSON.stringify(this.get_questionare_data()));
    const state = temp_model.state 
    if(state == 'empty') {
      return true
    }
    return false 
    // loading - the survey is being loaded from JSON,
    // empty - there is nothing to display in the current survey,
    // starting - the survey's start page is displayed,
    // running - a respondent is answering survey questions right now,
    // preview - a respondent is previewing answered questions before submitting the survey (see example),
    // completed - a respondent has completed the survey and submitted the results.
  }
  
}
