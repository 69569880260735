import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message } from '../dce-views/live-chat/interface/message';
import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/dce-service/env.service';
import { CommonService } from './common.service';
import { Subject, BehaviorSubject } from 'rxjs';
import * as prettyMs from 'pretty-ms';

type lifecycle_statuses = null | 'pre-login' | 'logged-in' | 'chat-on';
export type connection_status = 'connected' | 'disconnected';
type screenMode = 'chat' | 'customer_link' | 'case_link';
type linkType = "" | 'case' | 'individual' | 'organisation';
type networkStatus = 'OFFLINE' | 'ONLINE';

interface chatLinkedData {
  case_id: string;
  case_table_id :string | number;
  customer_id: string;
  company_name: string;
  link_type: linkType
}

interface live_chat_store {
  agent_id: number,
  agent_username: string,
  lifecycle_status: lifecycle_statuses ,
  pause_codes_list : any,
  chat_outcomes_list : any,
  current_pause_code : any,
  agent_socket_session_id: string,
  is_loading: boolean,
  network_status: BehaviorSubject<networkStatus>;
}

interface typingConf {
  is_typing: boolean;
  typing_message: string;
}

interface QueueConf { 
  queue_name: string;
  request_time: string;
}

interface BtnLoader { 
  save_outcome: boolean;
}
export interface live_chat_session {
  session_id: string;
  chatbot_session_id :string | null;
  client_data: any;
  customer_data: any;
  message_data: Message[];
  notification_count: number;
  connection_status: string | connection_status;
  current_chat_outcome : string;
  customer_inactivity_timer: number;
  customer_inactivity_interval: any;
  enable_agent_end_chat: boolean;
  end_chat_confirmation: boolean;
  chat_outcome_timer: string;
  chat_outcome_interval: any;
  chat_outcome_time_completed: BehaviorSubject<boolean>;
  current_screen_mode : screenMode; 
  chat_linked_data: chatLinkedData;
  typing_conf: typingConf;
  document_to_upload: any;
  document_to_upload_change: BehaviorSubject<boolean>;
  // socket_event_data: BehaviorSubject<any>;
  // focus_input_observable: BehaviorSubject<any>;
  queue_data: QueueConf;
  btn_loaders: BtnLoader;
}

@Injectable({
  providedIn: 'root'
})

export class LiveChatService {
  live_chat_store: live_chat_store
  
  live_chat_sessions:live_chat_session[] = [
    // Used for testing purposes
    // {
    //   "session_id": "1",
    //   "chatbot_session_id":"",
    //   "client_data": { user_data : {user_name: "Disha"}},
    //   "message_data":[
    //     {
    //     "timestamp": new Date(),
    //     "message_by": "rahul",
    //     "message_type": 'text',
    //     "message": 'test',
    //     "document_data": []
    //     },
    //     {
    //       "timestamp": new Date(),
    //       "message_by": "rahul",
    //       "message_type": 'text',
    //       "message": 'Bansal',
    //       "document_data": []
    //       },
    //       {
    //         "timestamp": new Date(),
    //         "message_by": "rahul",
    //         "message_type": 'text',
    //         "message": 'new text',
    //         "document_data": []
    //         }
    //   ],
    //   "notification_count": 0,
    //   "customer_data":{user_details : {user_name: "Rahul"}},
    //   "connection_status": 'connected',
    //   'current_chat_outcome' : '',
    //   'customer_inactivity_timer': 0,
    //   'customer_inactivity_interval': null,
    //   'chat_outcome_timer': "",
    //   'chat_outcome_interval': null,
    //   'enable_agent_end_chat': false,
    //   'chat_outcome_time_completed': new BehaviorSubject(false),
    //   'current_screen_mode': 'chat',
    //   'chat_linked_data': {
    //     "case_id": "",
    //     "customer_id": "",
    //     "company_name": "",
    //     "link_type": "",
    //     "case_table_id":""
    //   },
    //   "typing_conf": {
    //     'is_typing': false,
    //     'typing_message':''
    //   },
    //   "document_to_upload": []
    // }
  ];
  selected_session_index: number = 0;
  selected_session: live_chat_session | any =  {}
  // Do not remove below line . It is used for testing purposes
  // selected_session: live_chat_session | any =  this.live_chat_sessions[0];

  socket_event_data= new BehaviorSubject(false);

  constructor(
    private _env: EnvService,
    protected http: HttpClient,
    private _commonService: CommonService,
  ) { 
    this.init_chatstore();
    // this.get_classification_data(); //naming
  }

  ngOnInit() {
    // this.get_classification_data();
  }

  urlify(text) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')
  }
  
  init_chatstore() {
    this.live_chat_store = {
      agent_id: null,
      agent_username: null,
      lifecycle_status: 'pre-login',
      pause_codes_list : [],
      chat_outcomes_list : [],
      current_pause_code : null,
      agent_socket_session_id: "",
      is_loading: false,
      network_status: new BehaviorSubject('ONLINE')
    }
  }

  async get_classification_data() {
    //cls names - live-chat-outcomes, live-chat-agent-pause-statuses
    const payload = {
      'classification_type_id': [this._env.dropdown_list.live_chat_agent_pause_statuses,this._env.dropdown_list.live_chat_outcomes] ,
      'classification_value_id': [-1,-1]
    };
    const res = await this._commonService.get_particular_classification_data(payload).toPromise()
    if (res.errCode == 0) {
      for (let i = 0; i < res.msg.length; i++) {
        if (res.msg[i].classification_type == this._env.dropdown_list.live_chat_agent_pause_statuses) {
          this.live_chat_store.pause_codes_list = res.msg[i].data;
          console.log(this.live_chat_store.pause_codes_list)
        }else if (res.msg[i].classification_type == this._env.dropdown_list.live_chat_outcomes) {
          this.live_chat_store.chat_outcomes_list = res.msg[i].data;
        }
      }
    }
  }

  set_user(data) {
    console.log(data)
    this.live_chat_store.agent_id = data.user_id
    this.live_chat_store.agent_username = data.user_name
  }

  public live_chat_broadcast_operations(room,data):void {
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == room);
    switch (data.type) {
      case 'text':
        // if(this.live_chat_store.agent_username != data.user_data.user_name) {
          this.add_message(room,data);
          if(this._env.live_chat_agent_name != data.user_data.user_name) {
            this.live_chat_sessions[sessionIndex].notification_count++;
            this.live_chat_sessions[sessionIndex].customer_inactivity_timer = 0;
            if(this.live_chat_sessions[sessionIndex].enable_agent_end_chat) {
              this.live_chat_sessions[sessionIndex].enable_agent_end_chat = false;
              this.start_pause_timer(room)
            }
            this.messgae_sound(room)
          }
        // }
      break;
      case 'document':
        // if(this.live_chat_store.agent_username != data.user_data.user_name) {
          this.add_document(room,data);
          if(this._env.live_chat_agent_name != data.user_data.user_name) {
            this.live_chat_sessions[sessionIndex].notification_count++;
            this.live_chat_sessions[sessionIndex].customer_inactivity_timer = 0;
            this.messgae_sound(room)
          }
          if(this.live_chat_sessions[sessionIndex].enable_agent_end_chat) {
            this.live_chat_sessions[sessionIndex].enable_agent_end_chat = false;
            this.start_pause_timer(room)
          }
        // }
        break;
      case 'notification':
        break;
    }
  }

  public add_message(room,data) {
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == room);
    this.live_chat_sessions[sessionIndex].message_data.push({
      "timestamp": new Date(),
      "message_by": data.user_data.user_name,
      "message_type": 'text',
      "message": data.message,
      "display_message": this.urlify(data.message),
      "document_data": []
    })
  }

  public add_document(room,data) {
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == room);
    this.live_chat_sessions[sessionIndex].message_data.push({
      "timestamp": new Date(),
      "message_by": data.user_data.user_name,
      "message_type": 'document',
      "message": '',
      "display_message":'',
      "document_data": data.document_data_list
    })
  }

  // Message sound if client is in different tab
  private messgae_sound(room) {
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == room);
    if(this.selected_session_index != sessionIndex) {
      const audio_path = '/assets/chat_sounds/message.mp3';
      let audio = new Audio(audio_path); // audio .mp3 etc to be passed
      audio.play();
    }
  }

  public typing_operation(room,data):void {
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == room);
    switch (data.type) {
      case 'start':
        this.live_chat_sessions[sessionIndex].typing_conf.is_typing = true;
        this.live_chat_sessions[sessionIndex].typing_conf.typing_message = data.user_data.user_name + ' is typing...';
      break;
      case 'end':
        this.live_chat_sessions[sessionIndex].typing_conf.is_typing = false;
        this.live_chat_sessions[sessionIndex].typing_conf.typing_message = "";
      break;
    }
  }
  
  public set_selected_session(data: live_chat_session): void {
    this.selected_session = data
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == this.selected_session.session_id);
    this.selected_session_index = sessionIndex;
  }

  public reset_notification_count() {
    this.live_chat_sessions[this.selected_session_index].notification_count = 0
  }

  public get_selected_session (): live_chat_session | {} {
    return this.selected_session;
  }

  public get_live_chat_sessions() {
    return this.live_chat_sessions
  }

  // TODO: Create an array of this.
  public manipulate_socket_event_data(data) {
    this.socket_event_data.next(data)
  }

  public update_network_status(status: networkStatus) {
    this.live_chat_store.network_status.next(status)
  }

  public end_pause_timer(session_id) {
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == session_id);
    clearInterval(this.live_chat_sessions[sessionIndex].customer_inactivity_interval);
    this.live_chat_sessions[sessionIndex].customer_inactivity_timer = 0;
  }

  public start_pause_timer(session_id) { //timer check after few minutes
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == session_id);
    this.end_pause_timer(session_id);
    let timer = 0;
    let interval = 1000;
    this.live_chat_sessions[sessionIndex].customer_inactivity_interval = setInterval(() => {
      const sessionIndex_updated = this.live_chat_sessions.findIndex(session => session.session_id == session_id);
      timer = timer + interval;
      this.live_chat_sessions[sessionIndex_updated].customer_inactivity_timer = timer/1000;
      if(this.live_chat_sessions[sessionIndex_updated].customer_inactivity_timer >= this._env.live_chat.customer_inactivity_timer) {
        this.live_chat_sessions[sessionIndex_updated].enable_agent_end_chat = true;
        this.end_pause_timer(session_id);
      }
    }, interval);
  }

  public end_chat_outcome_timer(session_id) {
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == session_id);
    clearInterval(this.live_chat_sessions[sessionIndex].chat_outcome_interval);
    // this.live_chat_sessions[sessionIndex].chat_outcome_timer = prettyMs(this._env.live_chat.chat_outcome_timer*1000);
  }

  public start_chat_outcome_timer(session_id) {
    const sessionIndex = this.live_chat_sessions.findIndex(session => session.session_id == session_id);
    this.end_chat_outcome_timer(session_id);
    let timer = 0;
    let prev_timer = 0;
    let interval = 1000;
    this.live_chat_sessions[sessionIndex].chat_outcome_timer = prettyMs((this._env.live_chat.chat_outcome_timer*1000));
    this.live_chat_sessions[sessionIndex].chat_outcome_interval = setInterval(() => {
      const sessionIndex_updated = this.live_chat_sessions.findIndex(session => session.session_id == session_id);
      timer = timer + interval;
      // console.log(timer);
      if (prev_timer > timer) {
        console.log('Error:::::::', interval, timer);
        clearInterval(this.live_chat_sessions[sessionIndex_updated].chat_outcome_interval);
      }
      prev_timer = timer;
      this.live_chat_sessions[sessionIndex_updated].chat_outcome_timer = prettyMs((this._env.live_chat.chat_outcome_timer*1000) - timer);
      if(timer/1000 >= this._env.live_chat.chat_outcome_timer) {
        this.end_chat_outcome_timer(session_id);
        this.live_chat_sessions[sessionIndex_updated].chat_outcome_time_completed.next(true);
      }
    }, interval);
  }

  // ****************** Audio *************** //
  call_connect_audio() {
    const audio_path = '/assets/chat_sounds/chat_connect.mp3';
    let audio = new Audio(audio_path); // audio .mp3 etc to be passed
    audio.play();
  }

  call_disconnect_audio() {
    const audio_path = '/assets/chat_sounds/chat_disconnect.wav';
    let audio = new Audio(audio_path); // audio .mp3 etc to be passed
    audio.play();
  }

  // ****************** ROUTES *************** //
  chat_queue_login(payload) {
    const route = `${this._env.qengine_service_url}/qengine/chatqueue/agent/login`;
    return this.http.post<any>(route, payload);
  }

  chat_queue_logout(payload) {
    const route = `${this._env.qengine_service_url}/qengine/chatqueue/agent/logout`;
    return this.http.post<any>(route, payload);
  }

  get_chat_history(payload) {
    const route = `${this._env.qengine_service_url}/chathistory/session/get`;
    return this.http.post<any>(route, payload);
  }

  end_chat(payload) {
    const route = `${this._env.qengine_service_url}/qengine/chat/end`;
    return this.http.post<any>(route, payload);
  }

  link_case_with_chat(payload) {
    const route = `${this._env.qengine_service_url}/qengine/workitem/case/link`;
    return this.http.post<any>(route, payload);
  }

  unlink_case_with_chat(payload) {
    const route = `${this._env.qengine_service_url}/qengine/workitem/case/unlink`;
    return this.http.post<any>(route, payload);
  }

  link_customer_with_chat(payload) {
    const route = `${this._env.qengine_service_url}/qengine/workitem/customer/link`;
    return this.http.post<any>(route, payload);
  }

  unlink_customer_with_chat(payload) {
    const route = `${this._env.qengine_service_url}/qengine/workitem/customer/unlink`;
    return this.http.post<any>(route, payload);
  }

}

