import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BusOrgDetailsPayloadComponent } from 'src/app/dce-shared/bus-org-details-payload/bus-org-details-payload.component';
import { AddressComponent } from 'src/app/dce-shared/address/address.component';
import { IndividualCustomersPayloadComponent } from 'src/app/dce-shared/individual-customers-payload/individual-customers-payload.component';
import { CommonService } from 'src/app/dce-service/common.service';
import { AltProjectService } from 'src/app/dce-service/alt-project.service';
declare var $:any;

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss']
})
export class CaseDetailsComponent implements OnInit {
  
  @Input() data : any = {};
  @Input() feedback?:any = [];

  @ViewChild(BusOrgDetailsPayloadComponent, {static: false}) busorgPayload: BusOrgDetailsPayloadComponent;
  @ViewChild(AddressComponent, {static: false}) addressPayload: AddressComponent;
  @ViewChild(IndividualCustomersPayloadComponent, {static: false}) indCustomerPayload: IndividualCustomersPayloadComponent;


  constructor(
    private _commonService: CommonService,
    private _altServices: AltProjectService,
  ) { }

  ngOnInit() {
  }

  open_details_modal() {
    if(this.data.party1_role_type == 'Organisation'){
      this.get_company_details();
    }
    else if(this.data.party1_role_type == 'Individual'){
      this.get_customer_details();
    }
    else{
      /**Do Nothing */
    }
  }

  get_company_details() {
    if (this.data.party1_biz_id) {
      const payload = {
        'case': 'get',
        'payload': {
          'bizorg_id': this.data.party1_biz_id
        }
      };
      this._commonService.get_update_bizorg(payload).subscribe(
        res => {
          if (res.errCode == 0) {
            this.open_modal();
            this.busorgPayload.fill_busorg_details(res.msg.biz_org_details);
            this.addressPayload.fill_data(res.msg.company_address);
            this.busorgPayload.toggle_form(false);
            this.addressPayload.toggle_form(true);
          }
        }
      );
    }
  }

  get_customer_details() {
    if (this.data.party1_ind_id) {
      const payload = {
        individual_id: this.data.party1_ind_id
      }
      this._altServices.get_individual_customer_data(payload).subscribe(res => {
        if (res.errCode == 0) {
          this.open_modal();
          this.indCustomerPayload.fill_ind_cus_details(res.data);
          this.indCustomerPayload.toggle_ind_cus_details(false);
          // this.indCustomerPayload.onSelectIdentificationType(null)
        }
      })
    }
  }

  open_modal() {
    $('#view-details-modal').modal({backdrop: 'static', keyboard: false});
  }

  close_modal() {
    $('#view-details-modal').modal('hide');
  }
}
