import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LiveChatRoutingModule } from './live-chat-routing.module';
import { FormWizardModule } from 'src/app/shared/components/form-wizard/form-wizard.module';
import { DceSharedModule } from 'src/app/dce-shared/dce-shared.module';
import { QueueSessionRenderComponent } from './queue-session-render/queue-session-render.component';
import { ChatPopupComponent } from './chat-popup/chat-popup.component';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { OngoingChatComponent } from './ongoing-chat/ongoing-chat.component';
import { OngoingChatHeaderComponent } from './ongoing-chat-header/ongoing-chat-header.component';
import { OngoingChatListComponent } from './ongoing-chat-list/ongoing-chat-list.component';
import { OngoingChatComposerComponent } from './ongoing-chat-composer/ongoing-chat-composer.component';
import { OngoingChatBubbleComponent } from './ongoing-chat-bubble/ongoing-chat-bubble.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocketComponent } from './socket/socket.component';
import { ChatbotHistoryComponent } from './chatbot-history/chatbot-history.component';
import { ChatbotChatBubbleComponent } from './chatbot-chat-bubble/chatbot-chat-bubble.component';
import { CustomerLinkComponent } from './customer-link/customer-link.component';
import { CaseLinkComponent } from './case-link/case-link.component';
import { LiveChatHistoryComponent } from './live-chat-history/live-chat-history.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HistoryCaseLinkComponent } from './history-case-link/history-case-link.component';
import { HistoryCustomerLinkComponent } from './history-customer-link/history-customer-link.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [QueueSessionRenderComponent, ChatPopupComponent, OngoingChatHeaderComponent, OngoingChatListComponent, OngoingChatBubbleComponent, OngoingChatListComponent,OngoingChatComponent,OngoingChatComposerComponent, SocketComponent, ChatbotHistoryComponent, ChatbotChatBubbleComponent, LiveChatHistoryComponent, CustomerLinkComponent, CaseLinkComponent, HistoryCaseLinkComponent, HistoryCustomerLinkComponent],
  imports: [
    CommonModule,
    LiveChatRoutingModule,
    DceSharedModule,
    FormWizardModule,
    DragAndDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [QueueSessionRenderComponent, ChatPopupComponent]
})
export class LiveChatModule { }
