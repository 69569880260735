import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
import { ChatListComposerComponent } from '../chat-list-composer/chat-list-composer.component';
declare var $: any;
interface ChatBotListEmitter {
  case: String;
  data: Object;
}
@Component({
  selector: 'app-chatbot-list',
  templateUrl: './chatbot-list.component.html',
  styleUrls: ['./chatbot-list.component.scss']
})
export class ChatbotListComponent implements OnInit {
  @ViewChild('ChatListComposer', { static: false }) chat_list_composer: ChatListComposerComponent
  @Output() chatbot_list_emitter: EventEmitter<any> = new EventEmitter<ChatBotListEmitter>();
  @Input() chat_state = "chatting"
  @Input() close_chat = false;
  @Input() input_disabled = true;
  @Input() chat_process_id: number = -1;
  @Input() demo_mode: boolean = false;
  @Input() start_chat_tour: boolean = false;
  @Input() height: string = '100svh - 58px';
  @Input() config_list = {
    "quick_access_menus": [],
    "commands": []
  }
  @Input() user_data = {
    "user_name": "user"
  }
  session_id = null;
  chat_list = [];
  local_form_data: any = {}
  api_loading = false;
  end_chat_message = 'This chat is now closed. You may restart the chat.';
  previous_message = [];
  restart_chat_process_demo_btn_loader: boolean = false

  constructor(
    private _configService: ConfigService,
  ) {
   }

  user_messages = [];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.scroll_to_bottom();
  }

  ngOnInit() {
    this.get_intent_response();
  }

  async get_intent_response(text: string = '', intent: number = null, form_answer: object = {}, type = "text", reset_flow: number = 0) {
    this.api_loading = true;
    let payload = {
      "from": "user",
      "process_id": this.chat_process_id,
      "session_guid": this.session_id,
      "type": type,
      "msg_text": text,
      "form_answer": form_answer,
      "intent": intent,
      "reset_flow": reset_flow,
      "is_demo": this.demo_mode
    }
    let res;
    if(this.demo_mode){
      res = await this._configService.get_intent_response_for_demo(payload).toPromise()
    } else {
      res = await this._configService.get_intent_response(payload).toPromise()
    }
    if (res.errCode == 0) {
      if(res.datacode == 0) { // demo session expired
        this.open_demo_session_expired_modal()
        return 
      }
      if (this.session_id == null) {
        this.session_id = res.datarec.session_guid;
      }
      if (res.datarec.end_session == true) {
        this.close_chat = true;
      }
      if(this.chat_list.length == 0 && res.data[0].type == 'notification'){
        this.restrict_user_to_move_forward();
        this.append_chat_to_chat_list([res.data[0]]);
        return;
      }
      if (res.data.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].type == 'option_label') {
            if (res.data[i + 1] && res.data[i + 1].type == 'option') {
              res.data[i].options = res.data[i + 1].options;
              res.data.splice(i + 1, 1);
            }
          } else if (res.data[i].type == 'form_label') {
            if (res.data[i + 1] && res.data[i + 1].type == 'form') {
              res.data[i].skip_form = this.check_skip_and_default_form(res.data[i + 1].form_question);
              res.data[i].form_question = res.data[i + 1].form_question;
              res.data[i].show_form = true;
              res.data.splice(i + 1, 1);
            }
          }
        }
        this.previous_message = [];
        this.previous_message.push([...res.data][res.data.length - 1]);
        if(this.close_chat){
          res.data.push(this.end_chat_response());
        }
        this.append_chat_to_chat_list(res.data);
      } else {
        this.api_loading = false;
        this.disable_restart_chat();
        
      }
    } else if (res.errCode == 101) {
      this.add_user_response(this._configService.chat_conf.bo_server_error_msg, 'notification', 'notification')
      this.api_loading = false;
      this.disable_restart_chat();
      if(reset_flow != 2){ //for not add previous message on restart chat
        this.append_chat_to_chat_list(this.previous_message);
      }
    } else {
      this.api_loading = false;
      this.disable_restart_chat();
    }
  }

  disable_restart_chat(){
    this.chatbot_list_emitter.emit({'case': 'disable_restart_chat', 'data': {}});
    
  }

  restrict_user_to_move_forward(){
    this.close_chat = true;
  }

  open_demo_session_expired_modal() {
    $('#demo-expired-modal').modal({ backdrop: 'static', keyboard: false })
  }

  append_chat_to_chat_list(new_messages) {
    for (let i = 0; i < new_messages.length; i++) {
      setTimeout(() => {
        this.chat_list.push({ ...new_messages[i] });
        if (new_messages[i].type == "form_label") {
          new_messages[i].is_form_empty = true;
          this.chat_list_composer.change_selected_form_field(new_messages[i].form_question[0])
          this.input_disabled = false;
        } else if (new_messages[i].type == "faq") {
          this.chat_list_composer.scroll_to_faq_container(this.create_faq_unique_id(i))
        } else if (new_messages[i].type == "option_label") {
          if (!this._configService.chat_conf.input_always_enabled) {
            this.input_disabled = true;
          }
        } else {
          this.input_disabled = false;
        }
        if (i == new_messages.length - 1) {
          if (this.close_chat) {
            if (!this._configService.chat_conf.input_always_enabled) {
              this.input_disabled = true;
            }
            this.chat_list_composer.reset_and_clear_form_input();
          }
          this.api_loading = false;
          this.disable_restart_chat();
        }
        if (this.chat_list_composer) {
          this.scroll_to_bottom();
          this.chat_list_composer.focus_input_using_id('message-composer');
        }
        if (i == new_messages.length - 1) {
          this.start_tour();
        }
      }, 1000 * (i + 1));
    }
  }

  start_tour(){
    this.chatbot_list_emitter.emit({'case': 'start_tour', 'data': {}});
  }

  create_faq_unique_id(index: number) {
    return { data: 'faq_accordian' + 'unique' + index }
  }

  end_chat_response() {
    let response = {
      "session_guid": this.session_id,
      "type": "notification",
      "msg_text": this.end_chat_message,
      "entities_data": {},
      "form_question": [],
      "options": [],
      "access_type": "text",
      "sent_time": new Date().toLocaleString('en-US', { timeZone: 'Africa/Johannesburg' })
    }
    return response;
  }

  check_skip_and_default_form(form_data) {
    let valid = true;
    for (let i = 0; i < form_data.length; i++) {
      if (form_data[i].default_value == "" && this.local_form_data[form_data[i].key]) {
        form_data[i].default_value = this.local_form_data[form_data[i].key]
      }
      if(!form_data[i].pre_filled){
        form_data[i].default_value = "";
      }
      if (form_data[i].validation_list && form_data[i].validation_list.length) {
        for (let j = 0; j < form_data[i].validation_list.length; j++) {
          if (form_data[i].validation_list[j].name == "required") {
            valid = false;
          }
        }
      }
      if (!this.check_skip_and_default_form(form_data[i].children)) {
        valid = false;
      }
    }
    return valid;
  }

  chat_emitter(event) {
    switch (event.case) {
      case "access_options":
        this.access_options(event);
        break;
      case "form_submit":
        this.submit_form(event, 'submit');
        break;
      case "capture_form_field_data":
        this.capture_form_field_data(event);
        break;
      case "form_skipped":
        this.submit_form(event, 'skip')
        break;
      case "user_input":
        this.user_input(event);
        break;
    }
    this.chatbot_list_emitter.emit(event)
  }

  access_options(event) {
    this.api_loading = true;
    this.chat_list[this.chat_list.length - 1].options = []
    this.chat_list[this.chat_list.length - 1].form_question = [];
    let msg_text = this.chat_list[this.chat_list.length - 1].msg_text;
    this.chat_list[this.chat_list.length - 1].msg_text = 'Loading...';
    setTimeout(() => {
      this.chat_list[this.chat_list.length - 1].msg_text = msg_text
      let data = event.data;
      let access_type = data.reset_flow ? 'quick_access_option' : 'option';
      data.msg = data.reset_flow ? '<b>You clicked: </b>' + data.name : data.name;
      this.add_user_response(data.msg, access_type);
      this.get_intent_response(data.name, data.intent, null, 'option', data.reset_flow)
    }, 1);
  }

  add_user_response(msg_text: string, access_type: string, type: string = "text", sender: string = "user") {
    let user_response = {
      "from": sender,
      "session_guid": this.session_id,
      "type": type,
      "msg_text": this.urlify(msg_text),
      "entities_data": {},
      "form_question": {},
      "options": [],
      "access_type": access_type,
      "form_icon_title": "Show Form",
      "sent_time": new Date().toLocaleString('en-US', { timeZone: 'Africa/Johannesburg' })
    }
    this.chat_list.push(user_response);
    this.scroll_to_bottom();
  }

  urlify(text) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')
  }

  submit_form(event, type) {
    let user_response = this.create_form_response(event, type);
    this.chat_list[this.chat_list.length - 1].show_form = false;
    this.add_user_response(user_response, 'form');
    if (type == 'skip') {
      this.local_form_data = { ...this.local_form_data, ...event.data };
      event.data = this.empty_form_data(event.data);
    }
    this.get_intent_response(user_response, null, event.data, 'form')
  }

  empty_form_data(data: any) {
    Object.entries(data).forEach(([key, value]) => {
      data[key] = '';
    });
    return data;
  }

  capture_form_field_data(event) {
    let form_data = this.chat_list[this.chat_list.length - 1].form_question;
    let data = event.data;
    this.update_form_data(form_data, data);
    this.check_empty_form(form_data)
    this.chat_list[this.chat_list.length - 1].form_question = [...this.chat_list[this.chat_list.length - 1].form_question]
  }

  check_empty_form(form_data) {
    this.chat_list[this.chat_list.length - 1].is_form_empty = true;
    for (let i = 0; i < form_data.length; i++) {
      if (form_data[i].default_value && form_data[i].default_value != '') {
        this.chat_list[this.chat_list.length - 1].is_form_empty = false;
        return
      }
    }
  }

  update_form_data(form_data, data) {
    for (let i = 0; i < form_data.length; i++) {
      if (form_data[i].key == data.key) {
        form_data[i] = data;
        return
      }
      if (form_data[i].children && form_data[i].children.length) {
        this.update_form_data(form_data[i].children, data);
      }
    }
  }

  create_form_response(event, type: string): string {
    let response = ''
    if (type == 'skip') {
      response = 'Form Skipped'
      return response;
    }
    let form_message = this.chat_list[this.chat_list.length - 1];
    let form_data = form_message.form_question;
    let data = event.data;
    for (let i = 0; i < form_data.length; i++) {
      if (data[form_data[i].key]) {
        form_data[i].default_value = data[form_data[i].key];
        response = response + "<b>" + (form_data[i].label ? form_data[i].label : form_message.msg_text) + "</b>:<br/>" + data[form_data[i].key];
        if (i != form_data.length - 1) {
          response = response + "<br/>";
        }
      }
    }
    if (response == "") {
      response = 'Form Skipped'
    }
    return response;
  }

  user_input(event) {
    this.chat_list[this.chat_list.length - 1].options = []
    this.chat_list[this.chat_list.length - 1].form_question = [];
    this.add_user_response(event.data, 'text');
    this.get_intent_response(event.data, null, null, 'text')
  }

  restart_chat_process_demo() { // used only in demo mode
    const payload = {
      "process_id": this.chat_process_id,
      "from": "demo_user",
      "session_guid": null,
      "type": "text",
      "msg_text": "",
      "form_answer": null,
      "intent": null,
      "reset_flow": 0
    }
    this.restart_chat_process_demo_btn_loader = true
    this._configService.chat_process_demo(payload).subscribe(res => {
      if(res.errCode == 0) {
        location.reload()
      }
      this.restart_chat_process_demo_btn_loader = false
    })
  }

  reset_and_clear_form_input(){
    if (this.chat_list_composer) {
      this.chat_list_composer.reset_and_clear_form_input();
      this.chat_list_composer.showScrollToBottom = false;
    }
  }

  change_selected_form_field() {
    if(this.chat_list_composer){
      this.chat_list_composer.change_selected_form_field(this.chat_list[this.chat_list.length -1].form_question[0])
      this.scroll_to_bottom();
    }
  }

  close_tour(){
    if(this.chat_list_composer){
      this.chat_list_composer.reset_and_clear_form_input();
      this.chat_list_composer.showScrollToBottom = false;
      this.scroll_to_bottom();
    }
    if (this.chat_list[this.chat_list.length -1].type == "form_label") {
      this.chat_list_composer.change_selected_form_field(this.chat_list[this.chat_list.length -1].form_question[0])
      this.input_disabled = false;
    }
  }

  scroll_to_bottom(){
    if(this.chat_list_composer){
      this.chat_list_composer.scroll_to_bottom();
    }
  }

  async restart_chat(){
    if(this.chat_list_composer){
      this.chat_list_composer.reset_and_clear_form_input();
      this.chat_list_composer.showScrollToBottom = false;
    }
    await this.get_intent_response('', null, {}, "text", 2);
  }

  open_command_area(){
    if(this.chat_list_composer){
      this.chat_list_composer.open_command_area();
    }
  }

}
