import { Component, Input, OnInit } from '@angular/core';
import { KeysInfoConfig } from './config';
declare var $:any;
@Component({
  selector: 'app-shortcut-keys-info',
  templateUrl: './shortcut-keys-info.component.html',
  styleUrls: ['./shortcut-keys-info.component.scss']
})
export class ShortcutKeysInfoComponent implements OnInit {
  
  shortcut_keys_info_config={};
  @Input() screen = '';
  @Input() btn_class ='btn btn-sm btn-primary'

  Object = Object;
  shortcuts_modal_is_open:boolean=false;

  constructor(
    public config:KeysInfoConfig,
  ) { }

  ngOnInit() {
    this.shortcut_keys_info_config = this.config.key_events_info;
    console.log(this.shortcut_keys_info_config)
  }

  open_shortcuts_modal() {
    $('#view-shortcuts-modal').modal({backdrop: 'static', keyboard: false});
    this.shortcuts_modal_is_open=true;
  }

  close_shortcuts_modal() {
    $('#view-shortcuts-modal').modal('hide');
    this.shortcuts_modal_is_open=false;
  }

}
