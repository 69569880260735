import { Component, Input, OnChanges, OnInit, SimpleChanges, AfterViewInit,ViewChild } from '@angular/core';
import { CampaignService } from 'src/app/dce-service/campaign.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { SharedService } from 'src/app/dce-service/shared.service';
import { colorConfig, surveyOptions, curStrings, surveyStrings, css_classes, showingProperties } from 'src/app/dce-views/generic-surveyjs/generic-surveyjs.model';
import { GenericSurveyjsComponent } from 'src/app/dce-views/generic-surveyjs/generic-surveyjs/generic-surveyjs.component';
declare var $:any;
type Case = 'org' | 'ind'
type Tab = 'campaign' | 'live-chat'

@Component({
  selector: 'app-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss']
})
export class InteractionsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(GenericSurveyjsComponent, { static: false }) surveyChild: GenericSurveyjsComponent;
  
  @Input() object_id = null;
  @Input() version_id = null;
  @Input() case: Case = 'ind';
  @Input() collapsed_view = false;
  survey_data = []
  int_conf = {
    int_linear_data: [],
    int_heirarchy: {
      campaign_heirarchy:[],
      live_chat_heirarchy:[],
    },
    parent_keys: ['campaign_table_id', 'campaign_name', 'campaign_id', 'campaign_create_datetime'],
    campaign_interactions: [],
    live_chat_interactions: []
  }

  active_tab:Tab = 'campaign';
  
  survey_questionaire:any ={};
  survey_answers: any = {};
  survey_config = {
    form_mode: 'read_mode',
    form_answers: null,
    form_json: null,
    survey_identifier: 'default',
    color_config: colorConfig['default'],
    surveyOptions: surveyOptions['default'],
    surveyStrings: {
      creator_options: {
        completingSurvey: "Your response has been saved.",
        completeText: "Complete",
        emptySurvey: "Fetching Survey Details"
      },
      display_options: {
        completingSurvey: "Your response has been saved.",
        completeText: "Complete",
        emptySurvey: "Fetching Survey Details"
      }
    },
    curStrings: curStrings['default'],
    css_classes: css_classes['default'],
    showingProperties: showingProperties['default']
  };
  constructor(
    private campaignService: CampaignService,
    private _env: EnvService,
    private _common_service: CommonService,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.object_id && changes.object_id.currentValue) {
      this.active_tab = 'campaign';
      this.detect_change();
    }
  }

  detect_change() {
    this.clear_interaction();
    if (this.case == 'ind') {
      this.get_ind_interaction();
    } else if (this.case == 'org') {
      this.get_biz_interaction();
    } else {
      // do nothing
    }
  }

  rows_toggler() {
    console.log(this.collapsed_view)
    for (let i = 0; i < this.int_conf.int_heirarchy.campaign_heirarchy.length; i++) {
      if (!this.collapsed_view) {
        $('.parent-' + i).toggleClass("open").next(".fold").toggleClass("open");
      } else {
        // $('.parent-' + i).toggleClass("open").next(".fold").toggleClass("close"); // no need to impose close class.
      }
    }
    for (let i = 0; i < this.int_conf.int_heirarchy.live_chat_heirarchy.length; i++) {
      if (!this.collapsed_view) {
        $('.parent-' + i).toggleClass("open").next(".fold").toggleClass("open");
      } else {
        // $('.parent-' + i).toggleClass("open").next(".fold").toggleClass("close"); // no need to impose close class.
      }
    }
  }

  clear_interaction() {
    this.int_conf.int_linear_data = [];
    this.int_conf.int_heirarchy.campaign_heirarchy = [];
    this.int_conf.int_heirarchy.live_chat_heirarchy = [];
  }

  get_biz_interaction() {
    const payload: any = {
      biz_org_id: this.object_id
    };
    if (this.version_id) {
      payload.version_id = this.version_id;
    }
    this.campaignService.get_biz_interaction(payload).subscribe(res => {
      if (res.errCode === 0) {
        this.int_conf.int_linear_data = res.data;
        this.get_campaign_and_live_chat_int();
        this.make_campaign_int_heirarchy();
        this.make_live_chat_int_heirarchy();
      }
    })
  }

  get_ind_interaction() {
    const payload: any = {
      individual_id: this.object_id
    };
    if (this.version_id) {
      payload.version_id = this.version_id;
    }

    this.campaignService.get_ind_interaction(payload).subscribe(res => {
      if (res.errCode === 0) {
        this.int_conf.int_linear_data = res.data;
        this.get_campaign_and_live_chat_int();
        this.make_campaign_int_heirarchy();
        this.make_live_chat_int_heirarchy();
      }
    })
  }

  toggle_rows(i) {
    $('.parent-' + i).toggleClass("open").next(".fold").toggleClass("open");
  }

  make_campaign_int_heirarchy() {
    var result = [];
    for (let i = 0; i < this.int_conf.campaign_interactions.length; i++) {
      var parent = {
        'campaign_table_id': this.int_conf.campaign_interactions[i].campaign_table_id,
        'campaign_name': this.int_conf.campaign_interactions[i].campaign_name,
        'campaign_id': this.int_conf.campaign_interactions[i].campaign_id,
        'campaign_create_datetime': this.int_conf.campaign_interactions[i].campaign_create_datetime
      }
      result.push(parent)
    }
    const ids = result.map(o => o.campaign_table_id)
    const filtered = result.filter(({ campaign_table_id }, index) => !ids.includes(campaign_table_id, index + 1))  //unique campagin ids

    //adding interactions under campaign
    for (let j = 0; j < filtered.length; j++) {
      for (let k = 0; k < this.int_conf.campaign_interactions.length; k++) {
        if (filtered[j].campaign_table_id == this.int_conf.campaign_interactions[k].campaign_table_id) {
          if (filtered[j]['children']) {
            filtered[j]['children'].push(this.int_conf.campaign_interactions[k]);
          } else {
            filtered[j]['children'] = [];
            filtered[j]['children'].push(this.int_conf.campaign_interactions[k]);
          }
        }
      }
    }

    this.int_conf.int_heirarchy.campaign_heirarchy = filtered;
    setTimeout(() => {
      this.rows_toggler();
    }, 100);
  }

  make_live_chat_int_heirarchy(){
    var result = [];
    for (let i = 0; i < this.int_conf.live_chat_interactions.length; i++) {
      var parent = {
        'interaction_int_id': this.int_conf.live_chat_interactions[i].interaction_int_id,
        'creator_user_name': this.int_conf.live_chat_interactions[i].creator_user_name,
        'interaction_create_datetime': this.int_conf.live_chat_interactions[i].interaction_create_datetime
      }
      result.push(parent)
    }
    const ids = result.map(o => o.interaction_int_id)
    const filtered = result.filter(({ interaction_int_id }, index) => !ids.includes(interaction_int_id, index + 1))  //unique campagin ids

    //adding interactions under campaign
    for (let j = 0; j < filtered.length; j++) {
      for (let k = 0; k < this.int_conf.live_chat_interactions.length; k++) {
        if (filtered[j].interaction_int_id == this.int_conf.live_chat_interactions[k].interaction_int_id) {
          if (filtered[j]['children']) {
            filtered[j]['children'].push(this.int_conf.live_chat_interactions[k]);
          } else {
            filtered[j]['children'] = [];
            filtered[j]['children'].push(this.int_conf.live_chat_interactions[k]);
          }
        }
      }
    }

    this.int_conf.int_heirarchy.live_chat_heirarchy = filtered;
    setTimeout(() => {
      this.rows_toggler();
    }, 100);
  }

  get_campaign_and_live_chat_int(){
    this.int_conf.campaign_interactions = this.int_conf.int_linear_data.filter(elem => elem.interaction_type != 'Live-Chat');
    this.int_conf.live_chat_interactions = this.int_conf.int_linear_data.filter(elem => elem.interaction_type == 'Live-Chat');
  }

  get_interaction_doc(index, int_id, id_value) {
    const payload = {
      interaction_id: int_id
    };
    this.campaignService.get_interaction_doc(payload).subscribe(res => {
      if (res.errCode == 0) {
        this.view_doc_links(res.doc_data, id_value, index);
      };
    });
  }

  view_doc_links(doc_data, id_value, index) {
    var html_links = '';
    html_links += '<ul style="padding-left: 17px;margin-bottom:6px">';
    let regex_string = '';
    for (let i = 0; i < this._env.show_file_in_browser.length; i++) {
      if (i != this._env.show_file_in_browser.length - 1) {
        regex_string += this._env.show_file_in_browser[i] + '|'
      } else {
        regex_string += this._env.show_file_in_browser[i];
      }
    }
    var regex = new RegExp("[.](" + regex_string + ")");
    for (var i = 0; i < doc_data.length; i++) {
      if (regex.test(doc_data[i]['document_link'])) {
        // html_links += "<li><a target='_blank' href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' id='" + doc_data[i]['document_link'] + "$"  + doc_data[i]['document_name'] +"'>" + "(Download)" + "</span></li>";
        html_links += "<li><a target='_blank' href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' class='fas fa-file-download' title='Click to download document' id='" + doc_data[i]['document_link'] + "$" + doc_data[i]['document_name'] + "'></span></li>";
      } else {
        // html_links += "<li><a target='_blank' download='" + doc_data[i]['document_name'] + "'" + "href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' id='" + doc_data[i]['document_link'] + "$"  + doc_data[i]['document_name'] +"'>" + "(Download)" + "</span></li>";
        html_links += "<li><a target='_blank' download='" + doc_data[i]['document_name'] + "'" + "href='" + doc_data[i]['document_link'] + "'>" + doc_data[i]['document_name'] + "</a><span style='cursor:pointer;color:#ff0000;margin-left:5px' class='fas fa-file-download' title='Click to download document' id='" + doc_data[i]['document_link'] + "$" + doc_data[i]['document_name'] + "'></span></li>";
      }
    }
    html_links += '</ul>';
    if (doc_data.length) {
      $('#' + id_value + index).html(html_links);
    } else {
      const no_doc_msg = 'No documents found';
      $('#' + id_value + index).html(no_doc_msg);
    }
    const self = this;
    for (var i = 0; i < doc_data.length; i++) {
      document.getElementById(doc_data[i]['document_link'] + '$' + doc_data[i]['document_name']).addEventListener("click", function (event) {
        const file_data = event.target['id'].split('$');
        const url = file_data[0]
        self.sharedService.spinnerVisibility(true);
        self._common_service.get_b64_doc_from_url({ url: url }).subscribe(res => {
          if (res.errCode == 0) {
            const link = document.createElement('a');
            link.setAttribute('target', '_self');
            link.setAttribute('href', res.mimetype + ';base64,' + res.data);
            link.setAttribute('download', file_data[1]);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          self.sharedService.spinnerVisibility(false);
        })
      });
    }
  }

  collapse_all_campaigns(){
    for (let i = 0; i < this.int_conf.int_heirarchy.campaign_heirarchy.length; i++) {
      $('.parent-' + i).removeClass("open").next(".fold").removeClass("open");
    }
  }

  expand_all_campaigns(){
    for (let i = 0; i < this.int_conf.int_heirarchy.campaign_heirarchy.length; i++) {
      $('.parent-' + i).addClass("open").next(".fold").addClass("open");
    }
  }

  collapse_all_live_chats(){
    for (let i = 0; i < this.int_conf.int_heirarchy.live_chat_heirarchy.length; i++) {
      $('.parent-' + i).removeClass("open").next(".fold").removeClass("open");
    }
  }

  expand_all_live_chats(){
    for (let i = 0; i < this.int_conf.int_heirarchy.live_chat_heirarchy.length; i++) {
      $('.parent-' + i).addClass("open").next(".fold").addClass("open");
    }
  }
  
  open_modal(interaction){
    $('#survey-feedback').modal({ backdrop: 'static', keyboard: false })
    this.get_campaign_feedback(interaction)
  }
  get_campaign_feedback(interaction){
    this._common_service.get_campaign_feedback({'interaction_id':interaction.id}).subscribe(res => {
      if(res.errCode == 0){
        this.survey_config.form_mode = 'read_mode';
        this.survey_questionaire = res.datarec.questionnaire;
        this.survey_answers = res.datarec.answers;
      }
    })
  }

  toggle_tab(tab_name){
    this.active_tab = tab_name;
  }
}