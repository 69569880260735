import { Component, OnInit,Input, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { DatatableSearchComponent } from '../datatable-search/datatable-search.component';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {

  @ViewChild('wg_audit' ,{static: false}) wg_audit: DatatableSearchComponent;
  @Input() emp_id?:any;
  @Input() case:any;

  search_string: FormControl = new FormControl();
  audit_logs = [];
  global_audit_logs = [];
  audit_selected = [];
  audit_logs_column = [];
  reportee_audit_logs_column = [
    { use_date_pipe: false, prop: 'creator_user', name: 'Modified by'},
    { use_date_pipe: false, prop: 'emp_child_full_name', name: 'Full Name'},
    { use_date_pipe: false, prop: 'emp_child_gender', name: 'Gender'},
    { use_date_pipe: false, prop: 'role', name: 'Role'},
    { use_date_pipe: true, prop: 'emp_heir_create_datetime', name:'Created On'},
    { use_date_pipe: true, prop: 'emp_heir_lastchange_datetime', name:'Modified Date'},
    { use_date_pipe: true, prop: 'heir_start_date', name: 'Start Date'},
    { use_date_pipe: true, prop: 'heir_end_date', name: 'End Date'},
  ]

  reporting_manager_audit_logs_column = [
    { use_date_pipe: false, prop: 'creator_user', name: 'Modified by'},
    { use_date_pipe: false, prop: 'emp_parent_full_name', name: 'Full Name'},
    { use_date_pipe: false, prop: 'emp_parent_gender', name: 'Gender'},
    { use_date_pipe: false, prop: 'role', name: 'Role'},
    { use_date_pipe: true, prop: 'emp_heir_create_datetime', name:'Created On'},
    { use_date_pipe: true, prop: 'emp_heir_lastchange_datetime', name:'Modified Date'},
    { use_date_pipe: true, prop: 'heir_start_date', name: 'Start Date'},
    { use_date_pipe: true, prop: 'heir_end_date', name: 'End Date'},
  ];

  workgroup_audit_logs_column = [
    // { prop: 'wrkgrp_type', name: 'Workgroup Type'},
    // { prop: 'name', name: 'Case/Task/Advice Name'},
    { use_date_pipe: false, prop: 'creator_user', name: 'Modified by'},
    { use_date_pipe: false, prop: 'record_full_name', name: 'Full Name'},
    { use_date_pipe: false, prop: 'record_type', name: 'Record Type'},
    { use_date_pipe: false, prop: 'role', name: 'Role'},
    { use_date_pipe: true, prop: 'user_create_time', name:'Created On'},
    { use_date_pipe: true, prop: 'user_last_change', name:'Modified Date'},
    { use_date_pipe: true, prop: 'start_date', name: 'Start Date'},
    { use_date_pipe: true, prop: 'end_date', name: 'End Date'},
  ]

  loadingIndicator_logs=false;
  table_message = {emptyMessage: 'There are no records.'};
  constructor(
    private _commonService: CommonService

  ) { }

  ngOnInit() {
    // this.datatable_search();
  }


  // datatable_search() {
  //   this.search_string.valueChanges
  //   .pipe(debounceTime(200))
  //   .subscribe(value => {
  //     this.search_in_dt(value, this.global_audit_logs, this.audit_logs_column);
  //   });
    
  // }

  search_in_dt(val, global_data, column) {
    const data = global_data;
    const columns = column.map(col => col.prop );
    const filtered_data = this._commonService.filter_data_in_datatable(val, columns, data);
    this.audit_logs = [...filtered_data];
    if(this.audit_logs.length == 0){
      if(val==''){
        this.table_message.emptyMessage="There are no records."
      }else{
        this.table_message.emptyMessage="No records matched for entered search criteria. Please try a new search"
      }
    }
  }
 

  get_employee_reportees_audit() {
    this.audit_logs = [];
    this.loadingIndicator_logs=true;
    this._commonService.get_employee_reportees_audit({"employee_id": this.emp_id}).subscribe(res => {
      if (res.errCode == 0) {
        this.audit_logs = res.msg;
        this.global_audit_logs = res.msg;
        this.audit_logs_column = this.reportee_audit_logs_column;
        this.wg_audit.reset_search();
      }
      this.loadingIndicator_logs=false;
    });
  }

  get_employee_managers_audit() {
    this.audit_logs = [];
    this.loadingIndicator_logs=true;
    this._commonService.get_employee_managers_audit({"employee_id": this.emp_id}).subscribe(res => {
      if (res.errCode == 0) {
        this.audit_logs = res.msg;
        this.global_audit_logs = res.msg;
        this.audit_logs_column = this.reporting_manager_audit_logs_column;
        this.wg_audit.reset_search();
      }
      this.loadingIndicator_logs=false;
    });
  }

  get_workgroup_audit(wg_id){
    const payload = {
      "wrkgrp_id": wg_id,
    }
    this.get_user_workgroup_audit_service_call(payload);
    this.wg_audit.reset_search();
  }

  get_user_workgroup_audit(user_id, wrkgrp_id) {
    const payload = {
      "user_id": user_id,
      "wrkgrp_id": wrkgrp_id,
    }
    this.get_user_workgroup_audit_service_call(payload);
  }

  get_user_workgroup_audit_service_call(payload) {
    this.loadingIndicator_logs=true;
    this.audit_logs=[];
    this.global_audit_logs=[];
    this._commonService.get_workgroup_role_audit(payload).subscribe(res => {
      if (res.errCode == 0) {
        this.audit_logs = res.msg;
        this.global_audit_logs = res.msg;
        this.audit_logs_column = this.workgroup_audit_logs_column;
      }
      this.loadingIndicator_logs=false;
    });
  }

  on_select_audit(event) {
    switch(event.type){
      case 'click':
      break;
      case 'dblclick':
      break;
    }
  }

  search_dt_role(event){
		switch(event.type){
		  case 'search':
		  case 'clear':
		  case 'change':
        this.search_in_dt(event.data, this.global_audit_logs, this.audit_logs_column);
		  break;
		}
	}

}
