import { Component, OnInit, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/dce-service/common.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { SharedService } from 'src/app/dce-service/shared.service';
@Component({
  selector: 'app-announcement-marquee',
  templateUrl: './announcement-marquee.component.html',
  styleUrls: ['./announcement-marquee.component.scss']
})
export class AnnouncementMarqueeComponent implements OnInit {
  announcements = [];
  marquee_div_width = 1;
  constructor(
    public _commonService: CommonService,
    private el: ElementRef,
    public navService: NavigationService,
    private shared_service: SharedService
  ) {
   }

  ngOnInit() {    
  }

  async get_today_announcement() {
    let res = await this._commonService.get_today_announcements({'service_type':'DCE'}).toPromise();
    if (res.errCode == 0) {
      if (res.data.length) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].datatext = res.data[i].datatext.replace(/> /g, '>&nbsp;')
          this.announcements.push(res.data[i].datatext);
        }
        this.toggleAnnouncement(true);
      }else{
        this.toggleAnnouncement(false);
      }
      this.set_announcement_marquee_width();
    }
  }

  toggleAnnouncement(show_announcement:boolean) {
    const state = this.navService.sidebarState;
    state.showAnnouncement = show_announcement;
  }

  ngAfterViewInit() {
    if(this.shared_service.check_dt_role_access('87__870010')){
      this.get_today_announcement();
    }
  }

  set_announcement_marquee_width(){
    if (this.announcements.length>=5){
      setTimeout(()=>{
        const marquee_div = this.el.nativeElement.querySelector('.Marquee-content');
          this.marquee_div_width = marquee_div.offsetWidth;
        },100)
      }
    }
}
