import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DynamicFormRoutingModule } from './dynamic-form-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FormCreatorComponent } from './form-creator/form-creator.component';
//import { ShowErrorsComponent } from './show-errors/show-errors.component';
import { CancelEditComponent } from './cancel-edit/cancel-edit.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {DpDatePickerModule} from 'ng2-date-picker';
import { ShowErrorsComponent } from './show-errors/show-errors.component';

@NgModule({
	declarations: [DynamicFormComponent, FormCreatorComponent,CancelEditComponent,ShowErrorsComponent],
	imports: [
		CommonModule,
		DynamicFormRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		NgMultiSelectDropDownModule,
		// AngularDateTimePickerModule,
		FontAwesomeModule,
		DpDatePickerModule
	],
	providers: [
	    DatePipe,
  	],
	exports:[
		NgMultiSelectDropDownModule,
		DynamicFormComponent,
		ShowErrorsComponent
	]
})
export class DynamicFormModule { }
