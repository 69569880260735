import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
	@Output() confirmation_modal_event: EventEmitter<any> = new EventEmitter<any>();
	@Input() is_hidden?: boolean = false;
	@Input() button_class?: string = 'btn btn-primary btn-sm mb-2 mr-2';
	@Input() modal_text_case ?= "default";
	@Input() parent_data ?: any = {};
	@Input() btn_role : string;
	@Input() btn_name = "Save";
	@Input() modal_btn_name?:string = "";
	@Input() modal_id = 0;
	@Input() btn_loading : boolean = false
	@Input() loadingText: string = "Saving"
	is_disable=false;

  constructor() { }

  ngOnInit() {
  }

  	click_event(case_){
  		this.is_disable=true;
  	// console.log(this.confirmation_case)
		let data = {
			'case': case_,
		}

		this.confirmation_modal_event.emit(data);
	}


	open_modal(id){
		this.is_disable=false;
		if (this.modal_btn_name == '') {
			this.modal_btn_name  = this.btn_name;
		}
		$('#confirmatin_modal_'+ id).modal({backdrop: 'static', keyboard: false});
	}	

	close_modal(id){
		$('#confirmatin_modal_'+ id).modal('hide');
		if(this.modal_text_case == 'send-advice'){
			$('#confirmatin_modal_'+ id).modal().on('hidden.bs.modal', function (e) {
			  $("body").addClass("modal-open");
			});
		}
	}

}
