import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { connection_status, LiveChatService } from 'src/app/dce-service/live-chat.service';
// import { EnvService } from 'src/app/dce-service/env.service';
// import { CommonService } from 'src/app/dce-services/common.service';
import { OngoingChatListComponent } from '../ongoing-chat-list/ongoing-chat-list.component';
declare var $:any
interface ongoingChatEmitter {
  type : 'document' | 'text',
  document_data_list: any;
  message: string;
}
@Component({
  selector: 'app-ongoing-chat',
  templateUrl: './ongoing-chat.component.html',
  styleUrls: ['./ongoing-chat.component.scss']
})
export class OngoingChatComponent implements OnInit {
  @ViewChild(OngoingChatListComponent,{static: false}) chat_list: OngoingChatListComponent
  @ViewChild('OngoingChatListComponent',{static: false}) chat_composer: OngoingChatListComponent
  @Input() chat_state:any;
  @Input() user_data:any;
  @Input() unique_id:any;
  @Input() live_chat_session_id:any;
  @Output() end_chat = new EventEmitter<any>();
  @Output() ongoing_chat_emitter = new EventEmitter<ongoingChatEmitter>();
  @Output() input_focused_event = new EventEmitter<any>();
  @Output() typing_indicator_event = new EventEmitter<any>();
  @Output() chat_disconnect_event = new EventEmitter<any>();
  @Input() messages:any = []
  @Input() connection_status?:connection_status = 'connected'

  feedback_message="Thank you! Have a nice day. We would like to know your experience. Please share your feedback ";
  // feedback_link = this._env.live_chat_feedback_link;
  cancel_chat_reason_code:any = [];
  selected_reason = '';
  cancel_chat_btn: boolean = false;
  network_status_value: string = '';
  left_characters_count: number;
  offline_timer:number;
  input_width:any;

  constructor(
    // private _env:EnvService,
    public _liveChatService: LiveChatService,//chat store,
    public el:ElementRef
    // private _commonService: CommonService
  ) { }

  ngOnInit() {
    this.network_status();
    // this.get_classification_data()
  }

  // get_classification_data() {
  //   const payload = {
  //     'classification_type_id': [this._env.classification_ids.cancel_chat_reason_code],
  //     'classification_value_id': [-1]
  //   };
  //   this._commonService.get_particular_classification_data(payload).subscribe(res => {
  //     if (res.errCode == 0) {
  //       for (let i = 0; i < res.msg.length; i++) {
  //         if (res.msg[i].classification_type == this._env.classification_ids.cancel_chat_reason_code) {
  //           this.cancel_chat_reason_code = res.msg[i].data;
  //         }
  //       }
  //     }
  //   })
  // }

  private network_status() {
    this._liveChatService.live_chat_store.network_status.subscribe(res => {
      this.network_status_value = res
      console.log(res)
      if (res == 'OFFLINE') {
        this.clear_live_chat_sessions()
      }
    })
  }

  // countdown(seconds) {
  //   let remainingSeconds = seconds;
  //   const intervalId = setInterval(() => {
  //     this.offline_timer = remainingSeconds
  //     remainingSeconds--;
  //     if (remainingSeconds <= 0) {
  //       clearInterval(intervalId);
  //     }
  //   }, 1000);
  // }

  clear_live_chat_sessions() {
    // this.countdown(timer);
    // setTimeout(() => {
      for (let i = 0; i < this._liveChatService.live_chat_sessions.length; i++) {
        this._liveChatService.end_pause_timer(this._liveChatService.live_chat_sessions[i].session_id)
      }
      this._liveChatService.live_chat_sessions = [];
      this._liveChatService.selected_session = {};
      this._liveChatService.live_chat_store.lifecycle_status = "pre-login";
      this.chat_disconnect_event.emit({case : 'live_chat_disconnect_modal'})
    // }, (timer+1)*1000)
  }

  get_user_input(event){
    this.ongoing_chat_emitter.emit(event);
    // console.log(event);
  }

  get_left_character_count(event){
    this.left_characters_count=event;
  }
  
  close_chat(event){
    console.log(event);
    switch(event.event) {
      case 'close_chat':
        this.end_chat_from_qengine(event);
        break;
      case 'cancel_chat':
        $('#cancel-chat-modal').modal({backdrop: 'static', keyboard: false});
      break;
    }
  }

  private end_chat_from_qengine(event: any) {
    const payload = {
      "live_chat_session_id": this.live_chat_session_id,
	    "agent_id": null // to be discussed further
    }
    // this._commonService.end_chat(payload).subscribe(res => {
    //   if(res.errCode == 0) {
    //     $('#live-chat-modal').modal({backdrop: 'static', keyboard: false});
    //     this.end_chat.emit(event);
    //   }
    // })
  }

  cancel_chat_from_qengine() {
    const payload = {
      "live_chat_session_id": this.live_chat_session_id,
	    "reason_code": this.selected_reason
    }
    this.cancel_chat_btn = true;
    // this._commonService.cancel_chat(payload).subscribe(res => {
    //   if(res.errCode == 0) {
    //     this.end_chat.emit({event: 'cancel_chat'});
    //     $('#cancel-chat-modal').modal('hide');
    //     this.cancel_chat_btn = false;
    //   }
    // })
  }

  scroll_to_bottom(){
    if(this.chat_list){
      this.chat_list.scroll_to_bottom();
    }
  }

  input_focused(event) {
    this.input_focused_event.emit(event);
  }

  typing_indicator(event){
    this.typing_indicator_event.emit(event);
  }
}
