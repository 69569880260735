import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/dce-service/common.service';

declare var $:any;

@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss']
})
export class AdvanceSearchComponent implements OnInit {

  busorg_id = -1; 
  busorg_name :any ;
  copy_busorg_id = -1;
  copy_busorg_name: any;

  @Input() search_results_column = [];
  @Input() id?:string = '';
  @Input() case:string = '';
  @Output() result_data: EventEmitter<any> = new EventEmitter<any>()

  @Output() biz_idChange: EventEmitter<any> = new EventEmitter<any>()
  @Input()
  set biz_id(val: any) {
    this.busorg_id = val;
    this.copy_busorg_id = val;
  }
  get biz_id(): any { return this.busorg_id; } 

  @Output() biz_org_nameChange: EventEmitter<any> = new EventEmitter<any>()
  @Input()
  set biz_org_name(val: any) {
    this.busorg_name = val;
    this.copy_busorg_name = val;
  }
  get biz_org_name(): any { return this.busorg_name; } 

  advanced_search_value = '';
  private advanced_search_subject: Subject<string> = new Subject();
  search_result = [];
  search_result_suggestion = [];
  selected_search_result: any = {};

  // service_call_map = {
  //   'contact': 'search_contact_person_details',
  //   'employee': 'search_employee_fm'
  // }

  moduleLoading = false;

  constructor(
    private _commonService: CommonService,
  ) { }

  ngOnInit() {
    this.advanced_search_subject.pipe(
      debounceTime(300)).subscribe((title) => {
        this.moduleLoading = true;
        const payload = {
          'query': title,
          'biz_org_id': (this.biz_id == -1 || this.biz_id == null) ? null : this.biz_id
        }
      if(this.case == 'contact') {  
        this._commonService.search_contact_person_details(payload).subscribe(res => {
          if (res.errCode == 0) {
            this.moduleLoading = false;
            this.search_result = [...res.msg];
            this.search_result_suggestion = res.suggestion;
          }
        });
      } else if (this.case == 'employee') {
        this._commonService.search_employee_fm(payload).subscribe(res => {
          if (res.errCode == 0) {
            this.moduleLoading = false;
            this.search_result = [...res.msg];
            this.search_result_suggestion = res.suggestion;
          }
        });
      } else if (this.case == 'pot_mem') {
        this._commonService.search_potential_members(payload).subscribe(res => {
          if (res.errCode == 0) {
            this.moduleLoading = false;
            this.search_result = [...res.msg];
            // this.search_result_suggestion = res.suggestion;
          }
        });
      }
      // Future need
      // setTimeout(() => {
      //   this.moduleLoading = false;
      // }, 5000);
    });
  }

  open_advanced_search_modal() {
    if (typeof(this.busorg_name) == 'object'){
      this.busorg_name = this.busorg_name.company_name;
    }
    $('#advanced-search-modal_' +this.id).modal({backdrop: 'static', keyboard: false});
    this.clear_fields_data();
    this.fill_copied_data();
    setTimeout(()=>{
      this.search_result = [...this.search_result];
    }, 500);
  }


  advanced_search(event) {
    this.selected_search_result = {};
    let title = event.target.value.trim();
    title = this.advanced_search_value;
    if((title.length > 2) && (event.keyCode != 8)) {
      this.advanced_search_subject.next(title);
    } else if(title.length <= 2 ) {
      this.search_result = [];
    }
  }

  search_result_activate(event) {
    switch (event.type) {
      case 'click':
        this.selected_search_result = event.row;
      break;
      case 'dblclick':
        this.result_data.emit({data:event.row, case: this.case});
        $('#advanced-search-modal_' +this.id).modal('hide');
        // if(this._sharedService.check_dt_role_access('28__28026')){
          //         this.get_selected_data();
          //       }
      break;
      case 'mouseenter':

      break;
    }
  }

  suggestion_click(val) {
    this.advanced_search_value = val;
    this.advanced_search_subject.next(val);
  }

  get_selected_data() {
    this.result_data.emit({data:this.selected_search_result, case:this.case});
    $('#advanced-search-modal_' +this.id).modal('hide');
    this.selected_search_result = {};
  }

  clear_bizorg() {
    this.busorg_id = -1;
    this.busorg_name = '';
    // this.biz_idChange.emit(-1);
    // this.biz_org_nameChange.emit('');
    this.clear_fields_data();
  }

  clear_fields_data() {
    this.search_result = [];
    this.selected_search_result = {};
    this.advanced_search_value = '';
  }

  fill_copied_data() {
    if (typeof(this.copy_busorg_name) == 'object'){
      if (this.busorg_name != this.copy_busorg_name) {
        this.busorg_name = this.copy_busorg_name.company_name
      } 
    } else {
      if (this.busorg_name != this.copy_busorg_name) {
        this.busorg_name = this.copy_busorg_name
      } 
    }
    if (this.busorg_id != this.copy_busorg_id) {
      this.busorg_id = this.copy_busorg_id
    } 
  }

}
