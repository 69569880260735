import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AltProjectService } from 'src/app/dce-service/alt-project.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
import { SharedService } from 'src/app/dce-service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../../dce-service/toastr-utility.service';

type searchType = 'Organisation' | 'Individual';

interface orgRecord {
  rec_type: searchType;
  biz_data: string;
  bizorg_id: string;
  category: string;
  company_name: string;
  email_address1: string;
  email_address2: string;
  id: string;
  phone_number1: string;
  phone_number2: string;
  phone_number3: string;
  registration_date: string;
  registration_number: string;
  trading_name: string;
  uifrefno : string;
}

interface indRecord {
  rec_type: searchType;
  company_role_type: string;
  company_name: string;
  registration_number: string;
  phone_number1: string;
  email_address1: string;
  individual_first_name: string;
  individual_last_name: string;
  individual_idnumber: string;
  individual_passport_number: string;
  individual_id: string;
  bizorg_id: string;
}
@Component({
  selector: 'app-customer-link',
  templateUrl: './customer-link.component.html',
  styleUrls: ['./customer-link.component.scss']
})
export class CustomerLinkComponent implements OnInit {

  @Output() customer_event: EventEmitter<any> = new EventEmitter<any>();

  search_type: searchType = 'Organisation'
  selected_organsation: orgRecord | {} = {};
  selected_individual: indRecord | {} = {};

  global_client_list = [];
  client_list = [];
  loading_text = "No new clients"
  loading = false;

  // organisation search
  name = ''
  service_call_url = this._commonService._get_all_bizorg_v3_url;
  service_call_payload = {
    columns: [],
    data_records: 100,
    page: 1,
    company_role_type: 'Organisation'
  }

  individual_name = ''
  individual_service_call_payload = {
    columns: [],
    data_records: 100,
    page: 1,
  }
  individual_completer_service_url = this._altServices.search_individual_customer_url
  search_details: any = {};
  activate_on_call = false;

  link_customer_btn_loader = false;
  constructor(
    private _commonService: CommonService,
    private _router: Router,
    private _shared: SharedService,
    private _altServices: AltProjectService,
    private _liveChatService:  LiveChatService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  onSelect_type(type) {
    if(type != this.search_type) {
      this.selected_organsation = {}
      this.selected_individual = {}
      this.individual_name = '';
      this.name = '';
    }
    this.search_type = type;
  }

  new_case_v2() {
    this.customer_event.emit({ case: 'new_case' })
  }

  close_customer_information() {
    this._shared.sip_storage.cus_info_toggle = false;
  }

  link_customer() {
    let payload = {}
    if(this.search_type == 'Organisation') {
      if(Object.keys(this.selected_organsation).length) {
        payload = this.link_organisation_customer_payload()
      } else {
        this._toastr.error('', 'Please select a customer', this._toastrUtility.basic_configuration);
        return
      }
    } else if (this.search_type == 'Individual') {
      if(Object.keys(this.selected_individual).length) {
        payload = this.link_individual_customer_payload()
      } else {
        this._toastr.error('', 'Please select a customer', this._toastrUtility.basic_configuration);
        return
      }
    }
    this.link_customer_btn_loader = true;
    this._liveChatService.link_customer_with_chat(payload).subscribe(res => {
      if(res.errCode == 0) {
        const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == this._liveChatService.selected_session.session_id);
        this._liveChatService.live_chat_sessions[sessionIndex].current_screen_mode = 'chat';
        this._liveChatService.live_chat_sessions[sessionIndex].chat_linked_data = {
          "case_id": "",
          "case_table_id": "",
          "customer_id": this.search_type == 'Organisation' ? this.selected_organsation['bizorg_id']: this.selected_individual['individual_id'],
          "company_name": this.search_type == 'Organisation' ? this.selected_organsation['company_name']: this.selected_individual['company_name'],
          "link_type": this.search_type == 'Organisation' ? "organisation": 'individual'
        }
        this._toastr.success('', 'Customer Linked successfully', this._toastrUtility.basic_configuration);
      }
      this.link_customer_btn_loader = false;
    })
  }

  link_individual_customer_payload() {
    const payload = {
      "individual_id": this.selected_individual['individual_id'],
      "bizorg_id": null,
      "live_chat_session_id": this._liveChatService.selected_session.session_id,
      "agent_id": this._liveChatService.live_chat_store.agent_id,
      "agent_username": this._liveChatService.live_chat_store.agent_username
    }
    return payload;
  }

  link_organisation_customer_payload() {
    const payload = {
      "individual_id": null,
      "bizorg_id": this.selected_organsation['bizorg_id'],
      "live_chat_session_id": this._liveChatService.selected_session.session_id,
      "agent_id": this._liveChatService.live_chat_store.agent_id,
      "agent_username": this._liveChatService.live_chat_store.agent_username
    }
    return payload;
  }


  completer_select_organisation(event) {
    console.log(event);
    switch (event.case) {
      case 'select_v2':
        if (event.data != null) {
          const temp_data: orgRecord = {
            rec_type: 'Organisation',
            biz_data: event.data.biz_data,
            bizorg_id: event.data.id,
            category: event.data.category,
            company_name: event.data.company_name,
            email_address1: event.data.email_address1,
            email_address2: event.data.email_address2,
            id: event.data.id,
            phone_number1: event.data.phone_number1,
            phone_number2: event.data.phone_number2,
            phone_number3: event.data.phone_number3,
            registration_date: event.data.registration_date,
            registration_number: event.data.registration_number,
            trading_name: event.data.trading_name,
            uifrefno: event.data.uifrefno
          }
          this.selected_organsation = temp_data
          this.name = event.data.company_name;
        }
        break;
      case 'clear':
        this.name = '';
        this.selected_organsation = {};
        break;
      default:
        break;
    }
  }

  completer_select_individual(event) {
    switch (event.case) {
      case 'select_v2':
        if (event.data != null) {
          this.activate_on_call = false;
          let registration_number = '';
          if(event.data.idtype == 'National ID') {
            registration_number = event.data.idnumber
          } else if(event.data.idtype == 'Passport') {
            registration_number = event.data.idnumber
          } else if(event.data.idtype == 'Asylum Seeker ID') {
            registration_number = event.data.asylum_seeker_no
          }
          const temp_data = {
            'rec_type': 'Individual',
            'company_role_type': 'Individual',
            'company_name': event.data.full_name,
            'registration_number': registration_number,
            'phone_number1': event.data.phone_number1,
            'email_address1': event.data.email_address1,
            'individual_first_name': event.data.first_name,
            'individual_last_name': event.data.last_name,
            'individual_idnumber': event.data.idnumber,
            'individual_passport_number': event.data.passport_number,
            'individual_id': event.data.individual_id,
            'bizorg_id': event.data.biz_org_id
          }
          this.selected_individual = temp_data;
          this.individual_name = event.data.full_name;
        }
        break;
      case 'clear':
        this.individual_name = '';
        this.selected_individual = {};
        break;
      default:
        break;
    }
  }

  cancel() {
    const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == this._liveChatService.selected_session.session_id);
    this._liveChatService.live_chat_sessions[sessionIndex].current_screen_mode = 'chat';
  }

}
