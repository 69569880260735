import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from './env.service';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { Observable, observable, of } from "rxjs";
import { ProjectService } from './project.service';
import { CommonObject } from './common-object';
import { getBusinessTimeResponse, getBusinessTimeResponseVizDate, getBusinessTimeResponseVizDateTime } from '../dce-views/procedure/procedure-interface';

declare var $: any;

@Injectable({
	providedIn: 'root'
})
export class CommonService extends ProjectService {
  
	lambda = {
		'equal': function(op1,op2){ return op1 == op2} ,
		'not_equal': function(op1,op2){ return op1 != op2} ,
		'less': function(op1,op2){ return op1 < op2} ,
		'less_or_equal': function(op1,op2){ return op1 <= op2} ,
		'greater': function(op1,op2){ return op1 > op2} ,
		'greater_or_equal': function(op1,op2){ return op1 >= op2} ,
		'in': function(op1,op2){ return $.inArray(op2,op1) !== -1} ,
		'not_in': function(op1,op2){ return $.inArray(op2,op1) === -1} ,
		'is_not_null': function(op1,op2){ return (op1 != undefined && op1 != null)} ,
		'is_null': function(op1,op2){ return (op1 == undefined || op1 == null)} ,
		'is_empty': function(op1){ return (op1 == null || op1 == '')} ,
	}
	final_string : string;
	field_map = {};
	operator_map = {
		"equal": "equal",
		"is_not_null": "is not null",             
		"is_null": "is null",             
		"not_equal": "not equal",             
		"less": "less",             
		"less_or_equal": "less or equal",             
		"greater": "greater",             
		"greater_or_equal": "greater or equal",             
		"contains": "contains",             
		"in": "in",             
		"not_contains": "doesn't contain",             
		"not_in": "not in",             
		"begins_with": "begins with",             
		"ends_with": "ends with",             
		"not_begins_with": "doesn't begin with",             
		"not_ends_with": "doesn't end with",
		"between": "between",
		"is_empty": "is empty",
		"is_not_empty": "is not empty",
		"not_between": "not between"
	};
	constructor(
		protected http: HttpClient,
		protected comObj: CommonObject,
		protected env: EnvService,
		) { 
			// tslint:disable-next-line:indent
			super(http, env);
		}
	
	
	
	private get_campaign_feedback_url = `${this.env.service_url}/campaign/feedback/get`;
	get_campaign_feedback(payload) {
		return this.http.post<any>(this.get_campaign_feedback_url, payload);
	}

	
	//telephony
	private get_all_telephony_users_service_url = `${this.env.service_url}/telephony/details/all_user`;
	get_all_telephony_users(payload) {
		return this.http.post<any>(this.get_all_telephony_users_service_url, payload);
	}

	private get_telephony_agent_list_service_url = `${this.env.service_url}/telephony/agents/list`;
	get_telephony_agent_list(payload) {
		return this.http.post<any>(this.get_telephony_agent_list_service_url, payload);
	}
	
	private get_call_history_url = `${this.env.service_url}/telephony/call/history`;
	get_call_history(payload) {
		return this.http.post<any>(this.get_call_history_url, payload);
	}
		
	private get_call_id_url = `${this.env.service_url}/telephony/agent/get/call_id`;
	get_call_id(payload) {
		return this.http.post<any>(this.get_call_id_url, payload);
	}
	//breadcrumb
		private _get_bread_crumb_service_url = `${this.env.service_url}/bread_crumb_service`;
	    bread_crumb_service(payload) {
	      return this.http.post<any>(this._get_bread_crumb_service_url, payload );
	    }

	  private _get_classification_value_data_by_name_url = `${this.env.service_url}/get_classification_value_data_by_name`;
	  get_classification_value_data_by_name(payload) {
	    return this.http.post<any>(this._get_classification_value_data_by_name_url, payload);
	  }

	  private _get_tags_data_url = `${this.env.service_url}/search_tags/get`;
		get_tags_data(payload) {
	    return this.http.post<any>(this._get_tags_data_url, payload);
		}

		private _update_tags_data_url = `${this.env.service_url}/search_tags/update`;
		update_tags_data(payload) {
	    return this.http.post<any>(this._update_tags_data_url, payload);
		}
	// Bizorg Service Calls
		private _send_activation_mail_url = `${this.env.service_url}/send_activation_mail`;
		send_activation_mail(payload) {
			return this.http.post<any>(this._send_activation_mail_url, payload);
		}
		private display_resend_confirmation_button_url = `${this.env.service_url}/display_resend_confirmation_button`;
		display_resend_confirmation_button(payload) {
			return this.http.post<any>(this.display_resend_confirmation_button_url, payload);
		}
		private _get_all_biz_org_url = `${this.env.service_url}/get_all_bizorg`;
		get_all_biz_org(payload) {
			return this.http.post<any>(this._get_all_biz_org_url, payload);
		}
		public _update_classification_extension_url = `${this.env.service_url}/update_classification_extension`;
		update_classification_extension(payload) {
			return this.http.post<any>(this._update_classification_extension_url, payload);
		}

		public _update_classification_extension_checkbox_url = `${this.env.service_url}/classification_extension/checkbox`;
		update_classification_extension_checkbox(payload) {
			return this.http.post<any>(this._update_classification_extension_checkbox_url, payload);
		}

		private _get_all_biz_orgs_with_unallocated_url = `${this.env.service_url}/get_all_biz_orgs_with_unallocated`;
		get_all_biz_orgs_with_unallocated(payload) {
			return this.http.post<any>(this._get_all_biz_orgs_with_unallocated_url, payload);
		}

		public _create_biz_org_url = `${this.env.service_url}/create_bizorg`;
		create_new_bizorg(payload) {
			return this.http.post<any>(this._create_biz_org_url, payload);
		}

		public _get_update_bizorg_url = `${this.env.service_url}/get_update_bizorg`;
		get_update_bizorg(payload) {
			return this.http.post<any>(this._get_update_bizorg_url, payload);
		}

		private _get_employee_account_bizorg_list_url = `${this.env.service_url}/get_employee_account_bizorg_list`;
		get_employee_account_bizorg_list(payload) {
			return this.http.post<any>(this._get_employee_account_bizorg_list_url, payload);
		}

		private get_employee_account_bizorg_list_single_url = `${this.env.service_url}/get_employee_account_bizorg_list_single`;
		get_employee_account_bizorg_list_single(payload) {
			return this.http.post<any>(this.get_employee_account_bizorg_list_single_url, payload);
		}

		private _update_employee_bizorg_url = `${this.env.service_url}/update_employee_bizorg`;
		update_employee_bizorg(payload) {
			return this.http.post<any>(this._update_employee_bizorg_url, payload);
		}

		private get_internal_org_employee_div_history_url = `${this.env.service_url}/get_internal_org_employee_div_history`;
		get_internal_org_employee_div_history(payload) {
			return this.http.post<any>(this.get_internal_org_employee_div_history_url, payload);
		}

		private terminate_employee_url = `${this.env.service_url}/terminate_employee`;
		terminate_employee(payload) {
			return this.http.post<any>(this.terminate_employee_url, payload);
		}

		private get_internal_employee_audit_url = `${this.env.service_url}/get_internal_employee_audit`;
		get_internal_employee_audit(payload) {
			return this.http.post<any>(this.get_internal_employee_audit_url, payload);
		}

	// Classification Service Calls
		private get_all_classification_type_url = `${this.env.service_url}/get_all_classification_type`;
		get_all_classification_type(payload) {
			return this.http.post<any>(this.get_all_classification_type_url, payload);
		}

		private get_classification_value_data_url = `${this.env.service_url}/get_classification_value_data`;
		get_classification_value_data(payload) {
			return this.http.post<any>(this.get_classification_value_data_url, payload);
		}


		private _get_all_case_status_url = `${this.env.service_url}/get_all_case_status`;
		get_all_case_status(payload) {
			return this.http.post<any>(this._get_all_case_status_url, payload);
		}
		public add_classification_value_url = `${this.env.service_url}/add_classification_value`;
		add_classification_value(payload) {
			return this.http.post<any>(this.add_classification_value_url, payload);
		}

		public check_classification_value_url = `${this.env.service_url}/classification_value/check_exist`;
		check_classification_value(payload) {
			return this.http.post<any>(this.check_classification_value_url, payload);
		}

		private remove_classification_url = `${this.env.service_url}/remove_classification`;
		remove_classification(payload) {
			return this.http.post<any>(this.remove_classification_url, payload);
		}

		private activate_classification_url = `${this.env.service_url}/activate_classification`;
		activate_classification(payload) {
			return this.http.post<any>(this.activate_classification_url, payload);
		}

		public update_classification_value_url = `${this.env.service_url}/update_classification_value`;
		update_classification_value(payload) {
			return this.http.post<any>(this.update_classification_value_url, payload);
		}

		public update_classification_order_type_url = `${this.env.service_url}/update_classification_order_type`;
		update_classification_order_type(payload) {
			return this.http.post<any>(this.update_classification_order_type_url, payload);
		}

		public _classification_url = `${this.env.service_url}/create_classification`;
		post_classification_data(payload) {
			return this.http.post<any>(this._classification_url, payload);
		}

		private _get_particular_classification_data_url = `${this.env.service_url}/get_classification_value_data_dropdown`;
		get_particular_classification_data(payload) {
			return this.http.post<any>(this._get_particular_classification_data_url, payload);
		}

		private _move_classification_value_url = `${this.env.service_url}/move_classification_value`;
		move_classification_value(payload) {
			return this.http.post<any>(this._move_classification_value_url, payload);
		}

	// Divorg Service Calls
		public _create_div_org_url = `${this.env.service_url}/add_division`;
		create_new_divorg(payload) {
			return this.http.post<any>(this._create_div_org_url, payload);
		}

		public _get_update_divorg_url = `${this.env.service_url}/get_update_division`;
		get_update_divorg(payload) {
			return this.http.post<any>(this._get_update_divorg_url, payload);
		}

		private _get_all_divisions_data_url = `${this.env.service_url}/get_all_divisions_data`;
		get_all_divisions_data(payload) {
			return this.http.post<any>(this._get_all_divisions_data_url, payload);
		}

	// Work Groups Calls
       
		private get_user_default_workgroup_service_url = `${this.env.service_url}/workflow/user/workgroups/default`;
		get_user_default_workgroup(payload) {
			return this.http.post<any>(this.get_user_default_workgroup_service_url, payload);
		}

		private _get_all_workgroups_url = `${this.env.service_url}/get_all_workgroups`;
		get_all_workgroups(payload) {
			return this.http.post<any>(this._get_all_workgroups_url, payload);
		}

		public _add_multiple_workgroup_url = `${this.env.service_url}/user/workgroup/bulk/add`;
		add_multiple_workgroup(payload) {
 			return this.http.post<any>( this._add_multiple_workgroup_url , payload);
		}

		private _get_member_workgroups_url = `${this.env.service_url}/get_user_workgroups/dispatch/member`;
		get_member_workgroups(payload) {
			return this.http.post<any>(this._get_member_workgroups_url, payload);
		}

		private get_user_workgroups_url = `${this.env.service_url}/workflow/user/workgroups`;
		get_user_workgroups(payload) {
			return this.http.post<any>(this.get_user_workgroups_url, payload);
		}

		public get_users_list_url = `${this.env.service_url}/get_all_users`;
		get_users_list(payload) {
			return this.http.post<any>(this.get_users_list_url, payload);
		}
		private get_all_users_for_filters_url = `${this.env.service_url}/get_all_users_for_filters`;
		get_all_users_for_filters(payload) {
			return this.http.post<any>(this.get_all_users_for_filters_url, payload);
		}

		private _get_workgroup_url = `${this.env.service_url}/get_workgroup_data`;
		get_workgroup_data(payload) {
			return this.http.post<any>(this._get_workgroup_url, payload);
		}
		
		public _update_workgroup_url = `${this.env.service_url}/update_workgroup`;
		update_workgroup(payload) {
			return this.http.post<any>(this._update_workgroup_url, payload);
		}

		private get_default_workgroup_url = `${this.env.service_url}/check_user_default_workgroup`;
		get_default_workgroup(payload) {
			return this.http.post<any>(this.get_default_workgroup_url, payload);
		}
		
		private _get_user_workgroups_with_id_url = `${this.env.service_url}/get_user_workgroups_with_id`;
		get_user_workgroups_with_id(payload) {
			return this.http.post<any>(this._get_user_workgroups_with_id_url, payload);
		}

		private _get_user_active_and_inactive_workgroups_url = `${this.env.service_url}/get_user_active_and_inactive_workgroups`;
		get_user_active_and_inactive_workgroups(payload) {
			return this.http.post<any>(this._get_user_active_and_inactive_workgroups_url, payload);
		}

		private _get_workgroup_audit_url = `${this.env.service_url}/get_workgroup_audit`;
		get_workgroup_audit(payload) {
			return this.http.post<any>(this._get_workgroup_audit_url, payload);
		}

		private get_employee_reportees_audit_url = `${this.env.service_url}/get_employee_reportees_audit`;
		get_employee_reportees_audit(payload) {
			return this.http.post<any>(this.get_employee_reportees_audit_url, payload);
		}

		private get_employee_managers_audit_url = `${this.env.service_url}/get_employee_managers_audit`;
		get_employee_managers_audit(payload) {
			return this.http.post<any>(this.get_employee_managers_audit_url, payload);
		}

		private get_workgroup_role_audit_url = `${this.env.service_url}/get_workgroup_role_audit`;
		get_workgroup_role_audit(payload) {
			return this.http.post<any>(this.get_workgroup_role_audit_url, payload);
		}

		private get_workgroup_mapping_url = `${this.env.service_url}/workgroup_mapping/get`;
		get_workgroup_mapping(payload) {
			return this.http.post<any>(this.get_workgroup_mapping_url, payload);
		}

		public create_workgroup_mapping_url = `${this.env.service_url}/workgroup_mapping/create`;
		create_workgroup_mapping(payload) {
			return this.http.post<any>(this.create_workgroup_mapping_url, payload);
		}

		private delete_workgroup_mapping_url = `${this.env.service_url}/workgroup_mapping/delete`;
		delete_workgroup_mapping(payload) {
			return this.http.post<any>(this.delete_workgroup_mapping_url, payload);
		}

	// Sites Service Calls
		public _create_site_url = `${this.env.service_url}/add_site`;
		create_new_site(payload) {
			return this.http.post<any>(this._create_site_url, payload);
		}

		public _get_update_site_url = `${this.env.service_url}/get_update_site`;
		get_update_site(payload) {
			return this.http.post<any>( this._get_update_site_url , payload);
		}
		
		private _get_site_contacts_url = `${this.env.service_url}/get_contact_person_data`;
		get_site_contacts(payload) {
			return this.http.post<any>( this._get_site_contacts_url , payload);
		}

		private get_site_and_site_contact_tree_url = `${this.env.service_url}/get_site_and_site_contact_tree`;
		get_site_and_site_contact_tree(payload) {
			return this.http.post<any>( this.get_site_and_site_contact_tree_url , payload);
		}

		private download_all_contacts_url = `${this.env.service_url}/moses/v1/download_all_contacts`;
		download_all_contacts(payload) {
			return this.http.post<any>( this.download_all_contacts_url , payload);
		}

		private get_details_of_unallocated_contact_url = `${this.env.service_url}/get_contact_person_data_unallocated`;
		get_details_of_unallocated_contact(payload) {
			return this.http.post<any>( this.get_details_of_unallocated_contact_url , payload);
		}

		private _add_site_contact_url = `${this.env.service_url}/add_site_contact`;
		create_site_contact(payload) {
			return this.http.post<any>( this._add_site_contact_url , payload);
		}	

		private _update_site_contact_url = `${this.env.service_url}/update_site_contact`;
		update_site_contact(payload) {
			return this.http.post<any>( this._update_site_contact_url , payload);
		}
		
		private update_contact_to_site_url = `${this.env.service_url}/update_contact_to_site`;
		update_contact_to_site(payload) {
			return this.http.post<any>( this.update_contact_to_site_url , payload);
		}	

		private _remove_site_contact_url = `${this.env.service_url}/remove_site_contact`;
		remove_site_contact(payload) {
			return this.http.post<any>( this._remove_site_contact_url , payload);
		}	

		private _remove_site_url = `${this.env.service_url}/remove_site`;
		remove_site_from_employer(payload) {
			return this.http.post<any>( this._remove_site_url , payload);
		}	

		private _get_all_sites_data_url = `${this.env.service_url}/get_all_sites_data`;
		get_all_sites_data(payload) {
			return this.http.post<any>(this._get_all_sites_data_url, payload);
		}

		private _get_all_site_contacts_url = `${this.env.service_url}/get_all_site_contacts`;
		get_all_site_contacts(payload) {
			return this.http.post<any>(this._get_all_site_contacts_url, payload);
		}

		private get_site_divs_ur = `${this.env.service_url}/get_site_divs`;
		get_site_divs(payload) {
			return this.http.post<any>(this.get_site_divs_ur, payload);
		}

		public update_site_divs_url = `${this.env.service_url}/update_site_divs`;
		update_site_divs(payload) {
			return this.http.post<any>(this.update_site_divs_url, payload);
		}

		private add_contact_to_site_url = `${this.env.service_url}/add_contact_to_site`;
		add_contact_to_site(payload) {
			return this.http.post<any>(this.add_contact_to_site_url, payload);
		}

		private get_contact_details_from_national_id_url = `${this.env.service_url}/get_contact_details_from_national_id`;
		get_contact_details_from_national_id(payload) {
			return this.http.post<any>(this.get_contact_details_from_national_id_url, payload);
		}

	// Employee Service Calls
		private _get_division_employee_url = `${this.env.service_url}/get_all_corporate_employees`;
		get_division_employee(payload) {
			return this.http.post<any>( this._get_division_employee_url , payload);
		}
		private get_employee_details_from_bizorg_divorg_url = `${this.env.service_url}/get_employee_details_from_bizorg_divorg`;
		get_employee_details_from_bizorg_divorg(payload) {
			return this.http.post<any>( this.get_employee_details_from_bizorg_divorg_url , payload);
		}


		private _search_internal_org_employee_url = `${this.env.service_url}/search_internal_org_employee`;
		search_internal_org_employee(payload) {
		// search_internal_org_employee= (): Observable<any> => {
		// 	return  Observable.create((observer) => {
		// 		var data =	[{
		// 			"emp_id": 23,
		// 			"emp_name": "Varun Dhariyal"
		// 		},{
		// 			"emp_id": 2,
		// 			"emp_name": "Var"
		// 		}];
		// 		observer.next(data)
		// 	})
			return this.http.post<any>( this._search_internal_org_employee_url , payload);
		}

		// private get_contact_details_for_employee_url = `${this.env.service_url}/get_contact_details_for_employee`;
		// get_contact_details_for_employee(payload) {
		// 	return this.http.post<any>( this.get_contact_details_for_employee_url , payload);
		// }

		private get_employee_details_from_employee_id_url = `${this.env.service_url}/get_employee_details_from_employee_id`;
		get_employee_details_from_employee_id(payload) {
			return this.http.post<any>( this.get_employee_details_from_employee_id_url , payload);
		}

		private get_employee_products_from_employee_id_url = `${this.env.service_url}/get_employee_products_from_employee_id`;
		get_employee_products_from_employee_id(payload) {
			return this.http.post<any>( this.get_employee_products_from_employee_id_url , payload);
		}

		private get_employee_details_from_national_id_url = `${this.env.service_url}/get_employee_details_from_national_id`;
		get_employee_details_from_national_id(payload) {
			return this.http.post<any>( this.get_employee_details_from_national_id_url , payload);
		}

		private get_employee_details_from_passport_number_url = `${this.env.service_url}/get_employee_details_from_passport_number`;
		get_employee_details_from_passport_number(payload) {
			return this.http.post<any>( this.get_employee_details_from_passport_number_url , payload);
		}
		
		private add_employee_url = `${this.env.service_url}/add_employee`;
		add_employee(payload) {
			return this.http.post<any>( this.add_employee_url , payload);
		}

		private get_product_house_map_url = `${this.env.service_url}/get_product_house_map`;
		get_product_house_map(payload) {
			return this.http.post<any>( this.get_product_house_map_url , payload);
		}

		private add_prod_to_biz_url = `${this.env.service_url}/add_prod_to_biz`;
		add_prod_to_biz(payload) {
			return this.http.post<any>( this.add_prod_to_biz_url , payload);
		}

		private remove_prod_from_biz_url = `${this.env.service_url}/remove_prod_from_biz`;
		remove_prod_from_biz(payload) {
			return this.http.post<any>( this.remove_prod_from_biz_url , payload);
		}

		private add_json_to_prod_biz_url = `${this.env.service_url}/add_json_to_prod_biz`;
		add_json_to_prod_biz(payload) {
			return this.http.post<any>( this.add_json_to_prod_biz_url , payload);
		}

		private get_json_from_prod_biz_url = `${this.env.service_url}/get_json_from_prod_biz`;
		get_json_from_prod_biz(payload) {
			return this.http.post<any>( this.get_json_from_prod_biz_url , payload);
		}

		private make_employee_vip_url = `${this.env.service_url}/make_employee_vip`;
		make_employee_vip(payload) {
			return this.http.post<any>( this.make_employee_vip_url , payload);
		}

		private get_employee_master_data_url = `${this.env.service_url}/get_employee_master_data`;
		get_employee_master_data(payload) {
			return this.http.post<any>( this.get_employee_master_data_url , payload);
		}		

	// Tree service calls
		private remove_division_url = `${this.env.service_url}/remove_division`;
		remove_division(payload) {
			return this.http.post<any>( this.remove_division_url , payload);
		}

	// Internal Organisation service calls
		public _create_internal_org_employee_url = `${this.env.service_url}/create_internal_org_employee`;
		create_internal_org_employee(payload) {
			return this.http.post<any>( this._create_internal_org_employee_url , payload);
		}

		private _update_internal_org_employee_url = `${this.env.service_url}/update_internal_org_employee`;
		update_internal_org_employee(payload) {
			return this.http.post<any>( this._update_internal_org_employee_url , payload);
		}

		private _get_internal_org_employee_url = `${this.env.service_url}/get_internal_org_employee`;
		get_particular_internal_org_employee(payload) {
			return this.http.post<any>( this._get_internal_org_employee_url , payload);
		}

		private _remove_internal_org_employee_from_division_url = `${this.env.service_url}/remove_internal_org_employee_from_division`;
		remove_internal_org_employee_from_division(payload) {
			return this.http.post<any>( this._remove_internal_org_employee_from_division_url , payload);
		}

		private _add_internal_org_employee_to_division_url = `${this.env.service_url}/add_internal_org_employee_to_division`;
		add_internal_org_employee_to_division(payload) {
			return this.http.post<any>( this._add_internal_org_employee_to_division_url , payload);
		}	

		private _get_internal_org_div_employees_url = `${this.env.service_url}/get_internal_org_div_employees`;
		get_internal_org_employee(payload) {
			return this.http.post<any>( this._get_internal_org_div_employees_url , payload);
		}	

		private get_terminated_employees_url = `${this.env.service_url}/get_terminated_employees`;
		get_terminated_employees(payload) {
			return this.http.post<any>( this.get_terminated_employees_url , payload);
		}

		get_unallocated_emploees_url = `${this.env.service_url}/get_internal_unallocated_employees`;
		get_unallocated_emploees(payload){
			return this.http.post<any>( this.get_unallocated_emploees_url , payload);
		}

		private _get_tree_biz_with_levels_url = `${this.env.service_url}/get_tree_biz_with_levels`;
		get_tree_biz_with_levels(payload) {
			return this.http.post<any>( this._get_tree_biz_with_levels_url , payload);
	    }
	    
	    private _get_internal_org_employee_heirarchy_rec_url = `${this.env.service_url}/get_internal_org_employee_heirarchy_rec`;
			get_internal_org_employee_heirarchy_rec(payload) {
				return this.http.post<any>( this._get_internal_org_employee_heirarchy_rec_url , payload);
		}

		private _get_internal_org_employee_reportees_url = `${this.env.service_url}/get_internal_org_employee_reportees`;
			get_internal_org_employee_reportees(payload) {
				return this.http.post<any>( this._get_internal_org_employee_reportees_url , payload);
		}
		
		private _get_internal_org_employee_managers_url = `${this.env.service_url}/get_internal_org_employee_managers`;
		get_internal_org_employee_managers(payload) {
				return this.http.post<any>( this._get_internal_org_employee_managers_url , payload);
		}
		
		private _get_internal_org_employee_certs_url = `${this.env.service_url}/get_internal_org_employee_certs`;
		get_internal_org_employee_certs(payload) {
				return this.http.post<any>( this._get_internal_org_employee_certs_url , payload);
		}
		
		private _get_internal_org_employee_contacts_url = `${this.env.service_url}/get_internal_org_employee_contacts`;
		get_internal_org_employee_contacts(payload) {
				return this.http.post<any>( this._get_internal_org_employee_contacts_url , payload);
	    }
	    
	    private _create_internal_org_employee_connection_url = `${this.env.service_url}/create_internal_org_employee_connection`;
		create_internal_org_employee_connection(payload) {
			return this.http.post<any>( this._create_internal_org_employee_connection_url , payload);
		}

		private _remove_internal_org_employee_connection_url = `${this.env.service_url}/remove_internal_org_employee_connection`;
		remove_internal_org_employee_connection(payload) {
			return this.http.post<any>( this._remove_internal_org_employee_connection_url , payload);
		}
	    
	    private _add_multiple_reportees_url = `${this.env.service_url}/add_multiple_reportees`;
		add_multiple_reportees(payload) {
			return this.http.post<any>( this._add_multiple_reportees_url , payload);
		}

	    private _remove_relocate_reportees_url = `${this.env.service_url}/remove_relocate_reportees`;
		remove_relocate_reportees(payload) {
			return this.http.post<any>( this._remove_relocate_reportees_url , payload);
		}

	    private _get_eligible_managers_multiple_employees_url = `${this.env.service_url}/get_eligible_managers_multiple_employees`;
		get_eligible_managers_multiple_employees(payload) {
			return this.http.post<any>( this._get_eligible_managers_multiple_employees_url , payload);
		}

		private remove_all_internal_org_employees_from_division_url = `${this.env.service_url}/remove_all_internal_org_employees_from_division`;
		remove_all_internal_org_employees_from_division(payload) {
			return this.http.post<any>( this.remove_all_internal_org_employees_from_division_url , payload);
		}   

		private _create_internal_org_certificate_url = `${this.env.service_url}/create_internal_org_certificate`;
		create_internal_org_certificate(payload) {
			return this.http.post<any>( this._create_internal_org_certificate_url , payload);
		}   
	
		private _update_certificate_data_url = `${this.env.service_url}/update_certificate_data`;
		update_certificate_data(payload) {
			return this.http.post<any>( this._update_certificate_data_url , payload);
		}
	
		private _update_contact_data_url = `${this.env.service_url}/add_internal_contacts`;
		update_contact_data(payload) {
			return this.http.post<any>( this._update_contact_data_url , payload);
		}
	
		private get_emp_count_for_nonleaf_url = `${this.env.service_url}/get_internal_org_div_employees_count`;
		get_emp_count_for_nonleaf(payload) {
			return this.http.post<any>( this.get_emp_count_for_nonleaf_url , payload);
		}
	
		private get_site_contact_count_url = `${this.env.service_url}/get_site_contact_count`;
		get_site_contact_count(payload) {
			return this.http.post<any>( this.get_site_contact_count_url , payload);
		}
	
		private remove_all_contacts_from_site_url = `${this.env.service_url}/remove_all_contacts_from_site`;
		remove_all_contacts_from_site(payload) {
			return this.http.post<any>( this.remove_all_contacts_from_site_url , payload);
		}


		
		public update_division_communications_url = `${this.env.service_url}/update_division_communications`;
		update_division_communications(payload) {
			return this.http.post<any>( this.update_division_communications_url , payload);
		}
		
		private get_communication_details_for_division_url = `${this.env.service_url}/get_communication_details_for_division`;
		get_communication_details_for_division(payload) {
			return this.http.post<any>( this.get_communication_details_for_division_url , payload);
		}

		private get_user_profile_picture_url = `${this.env.service_url}/user/profile/picture/get`;
		get_user_profile_picture(payload) {
			return this.http.get<any>(this.process_get_req(this.get_user_profile_picture_url, payload))
		}

		// Employer Section servcie calls
		private get_all_sections_url = `${this.env.service_url}/sections/get_all_sections`;
		get_all_sections(payload) {
			return this.http.post<any>( this.get_all_sections_url , payload);
		}

		private get_bizorg_section_url = `${this.env.service_url}/biz_sections/get_bizorg_section`;
		get_bizorg_section(payload) {
			return this.http.post<any>( this.get_bizorg_section_url , payload);
		}

		private save_sections_to_biz_org_url = `${this.env.service_url}/biz_sections/save_sections_to_biz_org`;
		save_sections_to_biz_org(payload) {
			return this.http.post<any>( this.save_sections_to_biz_org_url , payload);
		}
		
	
	// Workgroup Services
		public _create_workgroup_url = `${this.env.service_url}/create_workgroup`;
		create_workgroup(payload) {
			return this.http.post<any>( this._create_workgroup_url , payload);
		}

		private _create_ticket_url = `${this.env.service_url}/?`;
		create_ticket(payload) {
			return this.http.post<any>( this._create_ticket_url , payload);
		}   

		private _get_workgroup_tickets_url = `${this.env.service_url}/get_workgroup_case_list`;
		get_workgroup_tickets(payload) {
			return this.http.post<any>( this._get_workgroup_tickets_url , payload);
		}   
	
		private _get_workgroup_member_list_url = `${this.env.service_url}/get_workgroup_member_list`;
		get_workgroup_member_list(payload) {
			return this.http.post<any>( this._get_workgroup_member_list_url , payload);
		}   
		private _get_inactive_workgroup_member_list_url = `${this.env.service_url}/get_inactive_workgroup_members`;
		get_inactive_workgroup_member_list(payload) {
			return this.http.post<any>( this._get_inactive_workgroup_member_list_url , payload);
		}   

		public _create_workgroup_user_url = `${this.env.service_url}/create_workgroup_user`;
		create_workgroup_user(payload) {
			return this.http.post<any>( this._create_workgroup_user_url , payload);
		}   	

		public _update_workgroup_user_url = `${this.env.service_url}/update_workgroup_user`;
		update_workgroup_user(payload) {
			return this.http.post<any>( this._update_workgroup_user_url , payload);
		}  	
	
	// Case Management Services
		private get_ticket_data_url = `${this.env.service_url}/get_tickets_classification`;
		get_ticket_data(payload) {
			return this.http.post<any>( this.get_ticket_data_url , payload)
		}

		private get_feedback_url = `${this.env.service_url}/get_feedback`;
		get_feedback(payload) {
			return this.http.post<any>( this.get_feedback_url , payload)
		}

		private get_bizorg_employee_list_url = `${this.env.service_url}/get_bizorg_employee_list`;
		get_bizorg_employee_list(payload) {
			return this.http.post<any>( this.get_bizorg_employee_list_url , payload)
		}
		private get_bizorg_contracts_url = `${this.env.service_url}/get_bizorg_contracts`;
		get_bizorg_contracts(payload) {
			return this.http.post<any>( this.get_bizorg_contracts_url , payload)
		}
		
		private remove_bizorg_contract_docs_url = `${this.env.service_url}/remove_bizorg_contract_docs`;
		remove_bizorg_contract_docs(payload) {
			return this.http.post<any>( this.remove_bizorg_contract_docs_url , payload)
		}
		private get_bizorg_contract_docs_url = `${this.env.service_url}/get_bizorg_contract_docs`;
		get_bizorg_contract_docs(payload) {
			return this.http.post<any>( this.get_bizorg_contract_docs_url , payload)
		}
		private add_bizorg_contract_docs_url = `${this.env.service_url}/add_bizorg_contract_docs`;
		add_bizorg_contract_docs(payload) {
			return this.http.post<any>( this.add_bizorg_contract_docs_url , payload)
		}

		private add_bizorg_contract_url = `${this.env.service_url}/add_bizorg_contract`;
		add_bizorg_contract(payload) {
			return this.http.post<any>( this.add_bizorg_contract_url , payload)
		}

		private _check_existing_open_case_url = `${this.env.service_url}/case/open/check_existing`;
		check_existing_open_case(payload) {
			return this.http.post<any>( this._check_existing_open_case_url , payload)
		}

		public create_case_url = `${this.env.service_url}/create_case`;
		create_case(payload) {
			return this.http.post<any>( this.create_case_url , payload)
		}

		private create_child_case_url = `${this.env.service_url}/create_child_case`;
		create_child_case(payload) {
			return this.http.post<any>( this.create_child_case_url , payload)
		}

		private update_case_url = `${this.env.service_url}/update_case`;
		update_case(payload) {
			return this.http.post<any>( this.update_case_url , payload)
		}

		private add_case_notes_url = `${this.env.service_url}/add_case_notes`;
		add_case_notes(payload) {
			return this.http.post<any>( this.add_case_notes_url , payload)
		}

		private get_workflow_object_search_url = `${this.env.service_url}/workflow/object/search`
		get_workflow_object_search(payload){
			return this.http.post<any>( this.get_workflow_object_search_url , payload)
		}

		private get_closed_workflow_objects_dynamic_filter_url = `${this.env.service_url}/get_closed_workflow_objects_dynamic_filter`
		get_closed_workflow_objects_dynamic_filter(payload){
			return this.http.post<any>( this.get_closed_workflow_objects_dynamic_filter_url , payload)
		}

		private link_child_url = `${this.env.service_url}/workflow/case/heirarchy/child/link`
		link_child(payload){
			return this.http.post<any>( this.link_child_url , payload)
		}

		private link_parent_url = `${this.env.service_url}/workflow/case/heirarchy/parent/link`
		link_parent(payload){
			return this.http.post<any>( this.link_parent_url , payload)
		}

		private get_case_links_url = `${this.env.service_url}/workflow/case/heirarchy/links`
		get_case_links(payload){
			return this.http.post<any>( this.get_case_links_url , payload)
		}

		public link_task_child_url = `${this.env.service_url}/workflow/task/heirarchy/child/link`
		link_child_task(payload){
			return this.http.post<any>( this.link_task_child_url , payload)
		}

		public link_task_parent_url = `${this.env.service_url}/workflow/task/heirarchy/parent/link`
		link_parent_task(payload){
			return this.http.post<any>( this.link_task_parent_url , payload)
		}

		private get_task_links_url = `${this.env.service_url}/workflow/task/heirarchy/links`
		get_task_links(payload){
			return this.http.post<any>( this.get_task_links_url , payload)
		}

		private get_follow_up_task_url = `${this.env.service_url}/workflow/task/follow_up/links`
		get_follow_up_task(payload){
			return this.http.post<any>( this.get_follow_up_task_url , payload)
		}

		// private get_case_notes_url = `${this.env.service_url}/get_case_notes`;
		// get_case_notes(payload) {
		// 	return this.http.post<any>( this.get_case_notes_url , payload)
		// }

		private get_case_classification_dropdowns_url = `${this.env.service_url}/get_case_classification_dropdowns`;
		get_case_classification_dropdowns(payload) {
			return this.http.post<any>( this.get_case_classification_dropdowns_url , payload)
		}

		private get_employee_communication_details_url = `${this.env.service_url}/get_employee_communication_details`;
		get_employee_communication_details(payload) {
			return this.http.post<any>( this.get_employee_communication_details_url , payload)
		}

		private add_employee_communication_for_case_url = `${this.env.service_url}/add_employee_communication_for_case`;
		add_employee_communication_for_case(payload) {
			return this.http.post<any>( this.add_employee_communication_for_case_url , payload)
		}

		private update_employee_communication_details_url = `${this.env.service_url}/update_employee_communication_details`;
		update_employee_communication_details(payload) {
			return this.http.post<any>( this.update_employee_communication_details_url , payload)
		}

		private update_contact_details_using_case_url = `${this.env.service_url}/update_contact_details_using_case`;
		update_contact_details_using_case(payload) {
			return this.http.post<any>( this.update_contact_details_using_case_url , payload)
		}

		private update_contact_details_using_lead_url = `${this.env.service_url}/update_contact_details_using_lead`;
		update_contact_details_using_lead(payload) {
			return this.http.post<any>( this.update_contact_details_using_lead_url , payload)
		}

		private update_contact_person_contact_for_case_url = `${this.env.service_url}/update_contact_person_contact_for_case`;
		update_contact_person_contact_for_case(payload) {
			return this.http.post<any>( this.update_contact_person_contact_for_case_url , payload)
		}

		public trydispatchrule_url = `${this.env.service_url}/dispatchrule/trydispatchrule`;
		trydispatchrule(payload) {
			return this.http.post<any>( this.trydispatchrule_url , payload)
		}
		private dispatch_case_url = `${this.env.service_url}/dispatch_case`;
		dispatch_case(payload) {
			return this.http.post<any>( this.dispatch_case_url , payload)
		}

		private dispatch_case_by_rules_url = `${this.env.service_url}/dispatch_case_by_rules`;
		dispatch_case_by_rules(payload) {
			return this.http.post<any>( this.dispatch_case_by_rules_url , payload)
		}

		private accept_case_url = `${this.env.service_url}/accept_case`;
		accept_case(payload) {
			return this.http.post<any>( this.accept_case_url , payload)
		}

		private reject_case_url = `${this.env.service_url}/reject_case`;
		reject_case(payload) {
			return this.http.post<any>( this.reject_case_url , payload)
		}

		private yank_case_url = `${this.env.service_url}/yank_case`;
		yank_case(payload) {
			return this.http.post<any>( this.yank_case_url , payload)
		}

		private assign_case_url = `${this.env.service_url}/assign_case`;
		assign_case(payload) {
			return this.http.post<any>( this.assign_case_url , payload)
		}

		private reopen_case_url = `${this.env.service_url}/reopen_case`;
		reopen_case(payload) {
			return this.http.post<any>( this.reopen_case_url , payload)
		}

		private close_case_url = `${this.env.service_url}/close_case`;
		close_case(payload) {
			return this.http.post<any>( this.close_case_url , payload)
		}
		private history_note_add_url = `${this.env.service_url}/workflow/myactions/note_add`;
		history_note_add(payload) {
			return this.http.post<any>( this.history_note_add_url , payload)
		}
		private history_status_change_url = `${this.env.service_url}/workflow/myactions/status_change`;
		history_status_change(payload) {
			return this.http.post<any>( this.history_status_change_url , payload)
		}
		private history_workflow_log_url = `${this.env.service_url}/workflow/myactions/workflow_log`;
		history_workflow_log(payload) {
			return this.http.post<any>( this.history_workflow_log_url , payload)
		}

		// private get_case_audits_url = `${this.env.service_url}/get_case_audits`;
		// get_case_audits(payload) {
		// 	return this.http.post<any>( this.get_case_audits_url , payload)
		// }

		private get_contact_details_for_employee_url = `${this.env.service_url}/get_contact_details_for_employee`;
		get_contact_details_for_employee(payload) {
			return this.http.post<any>( this.get_contact_details_for_employee_url , payload)
		}

		private search_employee_url = `${this.env.service_url}/search_employee`;
		search_employee(payload) {
			return this.http.post<any>( this.search_employee_url , payload)
		}

		private get_employee_products_and_houses_url = `${this.env.service_url}/get_employee_products_and_houses`;
		get_employee_products_and_houses(payload) {
			return this.http.post<any>( this.get_employee_products_and_houses_url , payload)
		}

	// Move to log details component	
		// private send_case_log_email_url = `${this.env.service_url}/send_case_log_email`;
		// send_case_log_email(payload) {
		// 	return this.http.post<any>( this.send_case_log_email_url , payload)
		// }

		private get_case_history_for_employee_url = `${this.env.service_url}/dce/v1/get_case_history_for_employee`;
		get_case_history_for_employee(payload) {
			return this.http.post<any>( this.get_case_history_for_employee_url , payload)
		}

		private search_contact_person_details_url = `${this.env.service_url}/search_contact_person_details`;
		search_contact_person_details(payload) {
			return this.http.post<any>( this.search_contact_person_details_url , payload)
		}

		private get_contact_persons_from_biz_org_url = `${this.env.service_url}/get_contact_persons_from_biz_org`;
		get_contact_persons_from_biz_org(payload) {
			return this.http.post<any>( this.get_contact_persons_from_biz_org_url , payload)
		}

		private get_contact_persons_with_context_from_biz_org_url = `${this.env.service_url}/get_contact_persons_with_context_from_biz_org`;
		get_contact_persons_with_context_from_biz_org(payload) {
			return this.http.post<any>( this.get_contact_persons_with_context_from_biz_org_url , payload)
		}

		private get_contact_details_for_contact_person_url = `${this.env.service_url}/get_contact_details_for_contact_person`;
		get_contact_details_for_contact_person(payload) {
			return this.http.post<any>( this.get_contact_details_for_contact_person_url , payload)
		}

		private get_case_history_for_potential_member_url = `${this.env.service_url}/dce/v1/get_case_history_for_potential_member`;
		get_case_history_for_potential_member(payload) {
			return this.http.post<any>( this.get_case_history_for_potential_member_url , payload)
		}

		private fetch_case_notes_text_url = `${this.env.service_url}/case/agg_notes/get`;
		fetch_case_notes_text(payload) {
			return this.http.get<any>(this.process_get_req(this.fetch_case_notes_text_url, payload))
		}

    //case-tree services

		private create_mobile_classification_mapping_url = `${this.env.service_url}/create_mobile_classification_mapping`;
		create_mobile_classification_mapping(payload) {
			return this.http.post<any>( this.create_mobile_classification_mapping_url , payload)
		}
	    private get_mobile_classification_mapped_data_url = `${this.env.service_url}/get_mobile_classification_mapped_data`;
		get_mobile_classification_mapped_data(payload) {
			return this.http.post<any>( this.get_mobile_classification_mapped_data_url , payload)
		}
		private update_mobile_classification_mapped_data_url = `${this.env.service_url}/update_mobile_classification_mapped_data`;
		update_mobile_classification_mapped_data(payload) {
			return this.http.post<any>( this.update_mobile_classification_mapped_data_url , payload)
		}
		private get_mobile_dropdown_url = `${this.env.service_url}/get_mobile_classification_dropdown`;
		get_mobile_dropdown(payload) {
			return this.http.post<any>( this.get_mobile_dropdown_url , payload)
		}
	// Product Mobile service
		private get_employee_inactive_products_url = `${this.env.service_url}/get_employee_inactive_products`;
		get_employee_inactive_products(payload) {
			return this.http.post<any>( this.get_employee_inactive_products_url , payload)
		}
		private activate_inactive_products_url = `${this.env.service_url}/activate_inactive_products`;
		activate_inactive_products(payload) {
			return this.http.post<any>( this.activate_inactive_products_url , payload)
		}


	// Case Management Setup Services
		public add_priority_classification_url = `${this.env.service_url}/add_priority_classification`;
		add_priority_classification(payload) {
			return this.http.post<any>( this.add_priority_classification_url , payload)
		}
		
		
		public update_priority_classification_url = `${this.env.service_url}/update_priority_classification`;
		update_priority_classification(payload) {
			return this.http.post<any>( this.update_priority_classification_url , payload)
		}
		private get_priority_severity_time_url = `${this.env.service_url}/get_all_priority_data`;
		get_priority_severity() {
			let data;
			return this.http.post<any>( this.get_priority_severity_time_url, data)
		}
		public update_priority_severity_time_url = `${this.env.service_url}/update_priority_severity_deadline`;
		update_priority_severity(payload) {
			return this.http.post<any>( this.update_priority_severity_time_url, payload)
		}
		private get_all_email_configuration_url = `${this.env.service_url}/get_all_email_configuration`;
		get_all_email_configuration() {
			let payload;
			return this.http.post<any>( this.get_all_email_configuration_url, payload)
		}

		private get_pageinated_pollar_log_url = `${this.env.service_url}/email_configuration_error_log/paginate`;
		get_pageinated_pollar_log(payload) {
			return this.http.post<any>( this.get_pageinated_pollar_log_url, payload)
		}
		public create_email_configuration_url = `${this.env.service_url}/create_email_configuration`;
		create_email_configuration(payload) {
			return this.http.post<any>( this.create_email_configuration_url, payload)
		}
		public update_email_configuration_url = `${this.env.service_url}/update_email_configuration`;
		update_email_configuration(payload) {
			return this.http.post<any>( this.update_email_configuration_url, payload)
		}

		public test_email_configuration_url = `${this.env.service_url}/email_configuration/test`;
		test_email_configuration(payload) {
			return this.http.post<any>( this.test_email_configuration_url, payload)
		}

	// Product/Offering Services
		private _create_item_url = `${this.env.service_url}/create_item`;
		create_item(payload) {
			return this.http.post<any>( this._create_item_url, payload);
		}

		private update_item_url = `${this.env.service_url}/update_item`;
		update_item(payload) {
			return this.http.post<any>( this.update_item_url, payload);
		}

		private _get_offering_url = `${this.env.service_url}/get_all_items`;
		get_all_items(payload) {
			return this.http.post<any>( this._get_offering_url, payload);
		}

		private add_subitems_to_item_url = `${this.env.service_url}/add_sub_products`;
		add_subitems_to_item(payload) {
			return this.http.post<any>( this.add_subitems_to_item_url, payload);
		}

		private stock_history_url = `${this.env.service_url}/get_stock_transaction_history`;
		get_stock_history(payload) {
			return this.http.post<any>( this.stock_history_url, payload);
		}

		private add_stock_item_url = `${this.env.service_url}/add_item_stock`;
		add_stock(payload) {
			return this.http.post<any>( this.add_stock_item_url, payload);
		}

		private remove_stock_item_url = `${this.env.service_url}/remove_item_stock`;
		remove_stock(payload) {
			return this.http.post<any>( this.remove_stock_item_url, payload);
		}

		private get_vat_url = `${this.env.service_url}/get_vat`;
		get_vat(payload) {
			return this.http.post<any>( this.get_vat_url, payload);
		}
		
		private update_vat_url = `${this.env.service_url}/update_vat`;
		update_vat(payload) {
			return this.http.post<any>( this.update_vat_url, payload);
		}

		private get_product_house_url = `${this.env.service_url}/get_product_house`;
		get_product_house(payload) {
			return this.http.post<any>( this.get_product_house_url, payload);
		}

		private get_products_url = `${this.env.service_url}/get_products`;
		get_products(payload) {
			return this.http.post<any>( this.get_products_url, payload);
		}

		private insert_product_house_url = `${this.env.service_url}/insert_product_house`;
		insert_product_house(payload) {
			return this.http.post<any>( this.insert_product_house_url, payload);
		}

		private insert_product_url = `${this.env.service_url}/insert_product`;
		insert_product(payload) {
			return this.http.post<any>( this.insert_product_url, payload);
		}
		
		private add_product_documents_url = `${this.env.service_url}/moses/v1/product/add_product_documents`;
		add_product_documents(payload) {
			return this.http.post<any>( this.add_product_documents_url, payload);
		}

		private get_product_documents_url = `${this.env.service_url}/moses/v1/product/get_product_documents`;
		get_product_documents(payload) {
			return this.http.post<any>( this.get_product_documents_url, payload);
		}

		private remove_product_document_url = `${this.env.service_url}/moses/v1/product/remove_product_document`;
		remove_product_document(payload) {
			return this.http.post<any>( this.remove_product_document_url, payload);
		}

		private add_product_house_documents_url = `${this.env.service_url}/moses/v1/product/add_product_house_documents`;
		add_product_house_documents(payload) {
			return this.http.post<any>( this.add_product_house_documents_url, payload);
		}
		
		private get_product_house_documents_url = `${this.env.service_url}/moses/v1/product/get_product_house_documents`;
		get_product_house_documents(payload) {
			return this.http.post<any>( this.get_product_house_documents_url, payload);
		}

		private remove_product_house_document_url = `${this.env.service_url}/moses/v1/product/remove_product_house_document`;
		remove_product_house_document(payload) {
			return this.http.post<any>( this.remove_product_house_document_url, payload);
		}
		
	// RFQ Services
		private get_rfq_for_workgroup_url = `${this.env.service_url}/get_rfq_for_workgroup`;
		get_rfq_for_workgroup(payload) {
			return this.http.post<any>( this.get_rfq_for_workgroup_url, payload);
		}
		
		private create_rfq_url = `${this.env.service_url}/create_rfq`;
		create_rfq(payload) {
			return this.http.post<any>( this.create_rfq_url, payload);
		}
		
		private get_boq_data_url = `${this.env.service_url}/get_boq_data`;
		get_boq_data(payload) {
			return this.http.post<any>( this.get_boq_data_url, payload);
		}
		
		private edit_rfq_data_url = `${this.env.service_url}/edit_rfq_data`;
		edit_rfq_data(payload) {
			return this.http.post<any>( this.edit_rfq_data_url, payload);
		}
		
		private accept_rfq_url = `${this.env.service_url}/accept_rfq`;
		accept_rfq(payload) {
			return this.http.post<any>( this.accept_rfq_url, payload);
		}
		
		private reject_rfq_url = `${this.env.service_url}/reject_rfq`;
		reject_rfq(payload) {
			return this.http.post<any>( this.reject_rfq_url, payload);
		}
		
		private cancel_rfq_url = `${this.env.service_url}/cancel_rfq`;
		cancel_rfq(payload) {
			return this.http.post<any>( this.cancel_rfq_url, payload);
		}
		
		private dispatch_rfq_url = `${this.env.service_url}/dispatch_rfq`;
		dispatch_rfq(payload) {
			return this.http.post<any>( this.dispatch_rfq_url, payload);
		}
	
		private get_tree_biz_site_with_levels_url = `${this.env.service_url}/get_tree_biz_site_with_levels`;
		get_tree_biz_site_with_levels(payload) {
			return this.http.post<any>( this.get_tree_biz_site_with_levels_url, payload);
		}
	
		private get_site_contacts_user_url = `${this.env.service_url}/get_site_contacts_user`;
		get_site_contacts_user(payload) {
			return this.http.post<any>( this.get_site_contacts_user_url, payload);
		}

		private get_site_contacts_persons_url = `${this.env.service_url}/get_site_contacts_persons`;
		get_site_contacts_persons(payload) {
			return this.http.post<any>( this.get_site_contacts_persons_url, payload);
		}
	
		private yank_rfq_url = `${this.env.service_url}/yank_rfq`;
		yank_rfq(payload) {
			return this.http.post<any>( this.yank_rfq_url, payload);
		}
	
		private assign_rfq_url = `${this.env.service_url}/assign_rfq`;
		assign_rfq(payload) {
			return this.http.post<any>( this.assign_rfq_url, payload);
		}
	
	// Common Workflow Services
	   
		private get_workflow_parent_record_url = `${this.env.service_url}/get_workflow_parent_record`;
		get_workflow_parent_record(payload) {
			return this.http.post<any>(this.get_workflow_parent_record_url, payload);
		}

		private get_workflow_objects_for_wrkgrp_url = `${this.env.service_url}/get_workflow_objects_for_wrkgrp`;
		get_workflow_objects_for_wrkgrp(payload) {
			return this.http.post<any>( this.get_workflow_objects_for_wrkgrp_url, payload);
		}
		
		private get_all_workflow_objects_url = `${this.env.service_url}/get_workflow_objects_for_wrkgrp`;
		get_all_workflow_objects(payload) {
			return this.http.post<any>( this.get_all_workflow_objects_url, payload);
		}
		
		private get_all_child_workflow_objects_url = `${this.env.service_url}/dce/v1/get_all_child_workflow_objects`;
		get_all_child_workflow_objects(payload) {
			return this.http.post<any>( this.get_all_child_workflow_objects_url, payload);
		}
		
		private get_workflow_object_data_url = `${this.env.service_url}/get_workflow_object_data`;
		get_workflow_object_data(payload) {
			return this.http.post<any>( this.get_workflow_object_data_url, payload);
		}

		private get_closed_workflow_objects_url = `${this.env.service_url}/get_closed_workflow_objects`;
		get_closed_workflow_objects(payload) {
			return this.http.post<any>( this.get_closed_workflow_objects_url, payload);
		}

		private get_workflow_master_link_url = `${this.env.service_url}/businessworkflowobject/workflow/master_link`;
		get_workflow_master_link(payload) {
			return this.http.post<any>( this.get_workflow_master_link_url, payload);
		}

		private get_workflow_log_url = `${this.env.service_url}/get_workflow_log`;
		get_workflow_log(payload) {
			return this.http.post<any>( this.get_workflow_log_url , payload)
		}

		private get_notification_log_url = `${this.env.service_url}/get_notification_log`;
		get_notification_log(payload) {
			return this.http.post<any>( this.get_notification_log_url , payload)
		}


		public get_copy_notificaiton_definition_url = `${this.env.service_url}/copy_notificaiton_definition`;
		copy_notificaiton_definition(payload) {
			return this.http.post<any>( this.get_copy_notificaiton_definition_url , payload)
		}

		private test_api_url = `${this.env.service_url}/test_api`;
		test_api = (payload) => {
			return this.http.post<any>(this.test_api_url, payload);
		}
	
		private create_api_definition_url = `${this.env.service_url}/create_api_definition`;
		create_api_definition = (payload) => {
			return this.http.post<any>(this.create_api_definition_url, payload);
		}
	
		private update_api_definition_url = `${this.env.service_url}/update_api_definition`;
		update_api_definition = (payload) => {
			return this.http.post<any>(this.update_api_definition_url, payload);
		}
	
		private delete_api_definition_url = `${this.env.service_url}/delete_api_definition`;
		delete_api_definition = (payload) => {
			return this.http.post<any>(this.delete_api_definition_url, payload);
		}
	
		private get_api_definition_url = `${this.env.service_url}/get_api_definition`;
		get_api_definition = (payload) => {
			return this.http.post<any>(this.get_api_definition_url, payload);
		}
	
		private get_all_api_definitions_url = `${this.env.service_url}/get_all_api_definitions`;
		get_all_api_definitions = (payload) => {
			return this.http.post<any>(this.get_all_api_definitions_url, payload);
		}

		private update_encrypted_header_value_url = `${this.env.service_url}/notification_api_definition/update/encrypted`;
		update_encrypted_header_value = (payload) => {
			return this.http.post<any>(this.update_encrypted_header_value_url, payload);
		}

		private get_workflow_audit_url = `${this.env.service_url}/get_workflow_audit`;
		get_workflow_audit(payload) {
			return this.http.post<any>( this.get_workflow_audit_url , payload)
		}

		private get_workflow_obj_children_url = `${this.env.service_url}/dce/v1/get_workflow_obj_children`;
		get_workflow_obj_children(payload) {
			return this.http.post<any>( this.get_workflow_obj_children_url , payload)
		}

		private get_workflow_notes_url = `${this.env.service_url}/get_workflow_notes`;
		get_workflow_notes(payload) {
			return this.http.post<any>( this.get_workflow_notes_url , payload)
		}

		private get_workflow_notes_v2_url = `${this.env.service_url}/get_workflow_notes_v2`;
		get_workflow_notes_v2(payload) {
			return this.http.post<any>( this.get_workflow_notes_v2_url , payload)
		}

		private get_workflow_notes_documents_url = `${this.env.service_url}/dce/v1/get_workflow_notes_documents`;
		get_workflow_notes_documents(payload){
			return this.http.post<any>( this.get_workflow_notes_documents_url , payload)
		}

		private get_notfication_documents_url = `${this.env.service_url}/document/get`;
		get_notfication_documents(payload) {
			return this.http.post<any>( this.get_notfication_documents_url , payload)
		}

		accept(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const accept_url = `${this.env.service_url}/accept_${wf_act}`;
			return this.http.post<any>(accept_url, payload);
		}

		public get_reject_url(wf_act_type){
			const wf_act = wf_act_type.toLowerCase();
			return `${this.env.service_url}/reject_${wf_act}`;
		}

		reject(wf_act_type, payload) {
			return this.http.post<any>(this.get_reject_url(wf_act_type), payload);
		}

		public get_assign_url(wf_act_type){
			const wf_act = wf_act_type.toLowerCase();
			switch(wf_act) {
				case 'meeting':
					return `${this.env.service_url}/${wf_act}/assign`;
				default:
					return `${this.env.service_url}/assign_${wf_act}`;
			}
		}

		assign(wf_act_type, payload) {
			return this.http.post<any>(this.get_assign_url(wf_act_type), payload);
		}

		public get_yank_url(wf_act_type){
			const wf_act = wf_act_type.toLowerCase();
			return `${this.env.service_url}/yank_${wf_act}`;
		}

		yank(wf_act_type, payload) {
			return this.http.post<any>(this.get_yank_url(wf_act_type), payload);
		}

		dispatch_by_rules(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const dispatch_by_rules_url = `${this.env.service_url}/dispatch_${wf_act}_by_rules`;
			return this.http.post<any>(dispatch_by_rules_url, payload);
		}

		public get_dispatch_url(wf_act_type){
			const wf_act = wf_act_type.toLowerCase();
			return `${this.env.service_url}/dispatch_${wf_act}`;
		}

		dispatch(wf_act_type, payload) {
			return this.http.post<any>(this.get_dispatch_url(wf_act_type), payload);
		}

		public get_close_wf_obj_url(wf_act_type){
			const wf_act = wf_act_type.toLowerCase();
			return `${this.env.service_url}/close_${wf_act}`;
		}

		close(wf_act_type, payload) {
			return this.http.post<any>(this.get_close_wf_obj_url(wf_act_type), payload);
		}

		private get_workflow_aggregate_log_url = `${this.env.service_url}/case/story`;
		get_workflow_aggregate_log(payload) {
			return this.http.post<any>( this.get_workflow_aggregate_log_url, payload);
		}

	// Quotes Services
		private get_quotes_for_workgroup_url = `${this.env.service_url}/get_quotes_for_workgroup`;
		get_quotes_for_workgroup(payload) {
			return this.http.post<any>( this.get_quotes_for_workgroup_url, payload);
		}

		private get_rfq_data_url = `${this.env.service_url}/get_rfq_data`;
		get_rfq_data(payload) {
			return this.http.post<any>( this.get_rfq_data_url, payload);
		}
	
		private create_quote_url = `${this.env.service_url}/create_quote`;
		create_quote(payload) {
			return this.http.post<any>( this.create_quote_url, payload);
		}
		
		private update_quote_url = `${this.env.service_url}/update_quote`;
		update_quote(payload) {
			return this.http.post<any>( this.update_quote_url, payload);
		}
		
		private send_quote_url = `${this.env.service_url}/dispatch_quote`;
		send_quote(payload) {
			return this.http.post<any>( this.send_quote_url, payload);
		}
		
		private accept_quote_url = `${this.env.service_url}/accept_quote`;
		accept_quote(payload) {
			return this.http.post<any>( this.accept_quote_url, payload);
		}
		
		private reject_quote_url = `${this.env.service_url}/reject_quote`;
		reject_quote(payload) {
			return this.http.post<any>( this.reject_quote_url, payload);
		}
		
		private cancel_quote_url = `${this.env.service_url}/cancel_quote`;
		cancel_quote(payload) {
			return this.http.post<any>( this.cancel_quote_url, payload);
		}

		private assign_quote_url = `${this.env.service_url}/assign_quote`;
		assign_quote(payload) {
			return this.http.post<any>( this.assign_quote_url, payload);
		}

		private yank_quote_url = `${this.env.service_url}/yank_quote`;
		yank_quote(payload) {
			return this.http.post<any>( this.yank_quote_url, payload);
		}
		
	// Order Services
		private create_order_url = `${this.env.service_url}/create_order`;
		create_order(payload) {
			return this.http.post<any>( this.create_order_url, payload);
		}
		
		private cancel_order_url = `${this.env.service_url}/cancel_order`;
		cancel_order(payload) {
			return this.http.post<any>( this.cancel_order_url, payload);
		}
		
		private accept_order_url = `${this.env.service_url}/accept_order`;
		accept_order(payload) {
			return this.http.post<any>( this.accept_order_url, payload);
		}
		
		private reject_order_url = `${this.env.service_url}/reject_order`;
		reject_order(payload) {
			return this.http.post<any>( this.reject_order_url, payload);
		}
		
		private send_order_url = `${this.env.service_url}/dispatch_order`;
		send_order(payload) {
			return this.http.post<any>( this.send_order_url, payload);
		}
		
		private update_order_url = `${this.env.service_url}/update_order`;
		update_order(payload) {
			return this.http.post<any>( this.update_order_url, payload);
		}
		
		private close_order_url = `${this.env.service_url}/close_order`;
		close_order(payload) {
			return this.http.post<any>( this.close_order_url, payload);
		}

		private yank_order_url = `${this.env.service_url}/yank_order`;
		yank_order(payload) {
			return this.http.post<any>( this.yank_order_url, payload);
		}
		
		private assign_order_url = `${this.env.service_url}/assign_order`;
		assign_order(payload) {
			return this.http.post<any>( this.assign_order_url, payload);
		}
		
	// Notification Services
		private get_notification_definition_url = `${this.env.service_url}/get_notification_definition`;
		get_notification_definition(payload) {
			return this.http.post<any>( this.get_notification_definition_url, payload);
		}
		private get_workflow_action_decision_data_url = `${this.env.service_url}/get_workflow_action_decision_data`;
		get_workflow_action_decision_data(payload) {
			return this.http.post<any>( this.get_workflow_action_decision_data_url, payload);
		}
		
		private get_all_notification_definition_url = `${this.env.service_url}/get_all_notification_definition`;
		get_all_notification_definition(payload) {
			return this.http.post<any>( this.get_all_notification_definition_url, payload);
		}
		
		public create_notificaiton_definition_url = `${this.env.service_url}/create_notificaiton_definition`;
		create_notificaiton_definition(payload) {
			return this.http.post<any>( this.create_notificaiton_definition_url, payload);
		}
		
		public update_notificaiton_definition_url = `${this.env.service_url}/update_notificaiton_definition`;
		update_notificaiton_definition(payload) {
			return this.http.post<any>( this.update_notificaiton_definition_url, payload);
		}
		private get_notification_view_cols_url = `${this.env.service_url}/get_notification_view_cols`;
		get_notification_view_cols(payload){
			return this.http.post<any>( this.get_notification_view_cols_url, payload);
		}
		private get_notification_decision_data_url = `${this.env.service_url}/get_notification_decision_data`;
		get_notification_decision_data(payload) {
			return this.http.post<any>( this.get_notification_decision_data_url, payload);
		}

		public notification_create_trace_url = `${this.env.service_url}/notification/connectivity/create_trace`;
		notification_create_trace(payload) {
			return this.http.post<any>( this.notification_create_trace_url , payload)
		}

		public notification_fetch_trace_url = `${this.env.service_url}/notification/connectivity/fetch_trace`;
		notification_fetch_trace(payload) {
			return this.http.post<any>( this.notification_fetch_trace_url , payload)
		}


	// Task Services
		private get_task_group_list_url = `${this.env.service_url}/get_task_group_list`;
		get_task_group_list(payload) {
			return this.http.post<any>( this.get_task_group_list_url, payload);
		}
		private get_workflow_object_data_for_user_url = `${this.env.service_url}/get_workflow_object_data_for_user`;
		get_workflow_object_data_for_user(payload) {
			return this.http.post<any>( this.get_workflow_object_data_for_user_url, payload);
		}
		public update_task_url = `${this.env.service_url}/update_task`;
		update_task(payload){
			return this.http.post<any>( this.update_task_url, payload);
		}
		public create_task_group_url = `${this.env.service_url}/create_task_group`;
		create_task_group(payload) {
			return this.http.post<any>( this.create_task_group_url, payload);
		}
		
		public update_task_group_url = `${this.env.service_url}/update_task_group`;
		update_task_group(payload) {
			return this.http.post<any>( this.update_task_group_url, payload);
		}
		
		public instantiate_task_group_url = `${this.env.service_url}/instantiate_task_group`;
		instantiate_task_group(payload) {
			return this.http.post<any>( this.instantiate_task_group_url, payload);
		}

		private get_instances_for_task_group_url = `${this.env.service_url}/get_task_group_instances`;
		get_instances_for_task_group(payload) {
			return this.http.post<any>( this.get_instances_for_task_group_url, payload);
		}		

		private _get_task_list_for_instance_url = `${this.env.service_url}/get_task_list_for_instance`;
		get_task_list_for_instance(payload) {
			return this.http.post<any>( this._get_task_list_for_instance_url, payload);
		}		

		private _accept_task_url = `${this.env.service_url}/accept_task`;
		accept_task(payload) {
			return this.http.post<any>( this._accept_task_url, payload);
		}	

		private _dispatch_task_url = `${this.env.service_url}/dispatch_task`;
		dispatch_task(payload) {
			return this.http.post<any>( this._dispatch_task_url, payload);
		}

		private _dispatch_task_by_rules_url = `${this.env.service_url}/dispatch_task_by_rules`;
		dispatch_task_by_rules(payload) {
			return this.http.post<any>( this._dispatch_task_by_rules_url, payload);
		}

		private _reject_task_url = `${this.env.service_url}/reject_task`;
		reject_task(payload) {
			return this.http.post<any>( this._reject_task_url, payload);
		}

		private _assign_task_url = `${this.env.service_url}/assign_task`;
		assign_task(payload) {
			return this.http.post<any>( this._assign_task_url, payload);
		}

		private _yank_task_url = `${this.env.service_url}/yank_task`;
		yank_task(payload) {
			return this.http.post<any>( this._yank_task_url, payload);
		}		

		public start_task_url = `${this.env.service_url}/start_task`;
		start_task(payload) {
			return this.http.post<any>( this.start_task_url, payload);
		}		

		public complete_task_url = `${this.env.service_url}/complete_task`;
		complete_task(payload) {
			return this.http.post<any>( this.complete_task_url, payload);
		}		

		public close_complete_task_url = `${this.env.service_url}/task/close_complete_task`;
		close_complete_task(payload) {
			return this.http.post<any>( this.close_complete_task_url, payload);
		}

		private close_task_url = `${this.env.service_url}/close_task`;
		close_task(payload) {
			return this.http.post<any>( this.close_task_url, payload);
		}	
		
		private check_task_complete_url = `${this.env.service_url}/businessworkflowobject/instance/check_complete`;
		check_task_complete(payload) {
			return this.http.post<any>( this.check_task_complete_url, payload);
		}

		private force_case_close_url = `${this.env.service_url}/case/force_close`;
		force_case_close(payload) {
			return this.http.post<any>( this.force_case_close_url, payload);
		}

		private task_percentage_complete_url = `${this.env.service_url}/task_percentage_complete`;
		task_percentage_complete(payload) {
			return this.http.post<any>( this.task_percentage_complete_url, payload);
		}		

		private add_task_note_url = `${this.env.service_url}/add_task_note`;
		add_task_note(payload) {
			return this.http.post<any>( this.add_task_note_url, payload);
		}

		private update_task_status_url = `${this.env.service_url}/update_task_status`;
		update_task_status(payload) {
			return this.http.post<any>( this.update_task_status_url, payload);
		}

		private _create_task_url = `${this.env.service_url}/create_task`;
		create_task(payload) {
			return this.http.post<any>( this._create_task_url, payload);
		}		

		// private get_task_notes_url = `${this.env.service_url}/get_task_notes`;
		// get_task_notes(payload) {
		// 	return this.http.post<any>( this.get_task_notes_url , payload)
		// }

		// private get_task_audits_url = `${this.env.service_url}/get_task_audits`;
		// get_task_audits(payload) {
		// 	return this.http.post<any>( this.get_task_audits_url , payload)
		// }

		private get_gannt_chart_url = `${this.env.service_url}/get_gannt_chart`;
		get_gannt_chart(payload) {
			return this.http.post<any>( this.get_gannt_chart_url , payload)
		}
		private get_task_classification_dropdown_url = `${this.env.service_url}/get_task_classification_dropdown`;
		get_task_classification_dropdown(payload){
			return this.http.post<any>( this.get_task_classification_dropdown_url , payload)			
		}
		private get_task_classification_dropdown_list_url = `${this.env.service_url}/get_task_classification_dropdown_list`;
		get_task_classification_dropdown_list(payload){
			return this.http.post<any>( this.get_task_classification_dropdown_list_url , payload)			
		}

		public remove_task_instance_url = `${this.env.service_url}/remove_task_instance`;
		remove_task_instance(payload){
			return this.http.post<any>( this.remove_task_instance_url , payload)
		}

		private force_remove_task_instance_url = `${this.env.service_url}/force_remove_task_instance`;
		force_remove_task_instance(payload){
			return this.http.post<any>( this.force_remove_task_instance_url , payload)
		}

		private get_gannt_chart_pdf_url = `${this.env.service_url}/get_gannt_chart_pdf`;
		get_gannt_chart_pdf(payload){
			return this.http.post<any>( this.get_gannt_chart_pdf_url , payload)
		}

		private get_task_form_data_url = `${this.env.service_url}/task/get_form_data`;
		get_task_form_data(payload){
			return this.http.post<any>( this.get_task_form_data_url , payload)
		}

		private update_task_form_data_url = `${this.env.service_url}/task/update_form_data`;
		update_task_form_data(payload){
			return this.http.post<any>( this.update_task_form_data_url , payload)
		}

		private reopen_task_url = `${this.env.service_url}/task/reopen_and_dispatch`;
		reopen_task(payload) {
			return this.http.post<any>( this.reopen_task_url , payload)
		}

	// Document Service call 
		private _get_document_url = `${this.env.service_url}/get_quote_documents`;
		get_quote_document(payload) {
			return this.http.post<any>( this._get_document_url, payload);
		}		

		private _get_order_document_url = `${this.env.service_url}/get_order_documents`;
		get_order_document(payload) {
			return this.http.post<any>( this._get_order_document_url, payload);
		}		
	// Data Load Services

		private check_loading_status_url = `${this.env.service_url}/data_loads/staging/check_loading_status`;
		check_loading_status(payload) {
			return this.http.post<any>( this.check_loading_status_url , payload);
		}
		private set_loading_status_url = `${this.env.service_url}/data_loads/staging/set_loading`;
		set_loading_status(payload) {
			return this.http.post<any>( this.set_loading_status_url , payload);
		}

		public load_data_from_staging_url = `${this.env.service_url}/data_loads/staging/run/load`;
		load_data_from_staging(payload) {
			return this.http.post<any>( this.load_data_from_staging_url , payload);
		}
		
		public move_data_to_staging_url = `${this.env.service_url}/data_loads/errors/run/move_to_stage`;
		move_data_to_staging(payload) {
			return this.http.post<any>( this.move_data_to_staging_url , payload);
		}
		
		private get_data_load_action_history_stats_url = `${this.env.service_url}/data_loads/history/stats`;
		get_data_load_action_history_stats(payload) {
			return this.http.post<any>( this.get_data_load_action_history_stats_url , payload);
		}

		private get_data_load_staging_stats_url = `${this.env.service_url}/data_loads/staging/stats`;
		get_data_load_staging_stats(payload) {
			return this.http.post<any>( this.get_data_load_staging_stats_url , payload);
		}
		
		private get_all_data_load_staging_action_recs_url = `${this.env.service_url}/data_loads/staging/action/recs`;
		get_all_data_load_staging_action_recs(payload) {
			return this.http.post<any>( this.get_all_data_load_staging_action_recs_url , payload);
		}
		
		private get_data_load_staging_action_rec_url = `${this.env.service_url}/data_loads/staging/action/rec/get`;
		get_data_load_staging_action_rec(payload) {
			return this.http.post<any>( this.get_data_load_staging_action_rec_url , payload);
		}

		private get_data_load_errors_stats_url = `${this.env.service_url}/data_loads/errors/stats`;
		get_data_load_errors_stats(payload) {
			return this.http.post<any>( this.get_data_load_errors_stats_url , payload);
		}

		private get_all_data_load_errors_action_recs_url = `${this.env.service_url}/data_loads/errors/action/recs`;
		get_all_data_load_errors_action_recs(payload) {
			return this.http.post<any>( this.get_all_data_load_errors_action_recs_url , payload);
		}

		private get_data_load_errors_action_rec_url = `${this.env.service_url}/data_loads/errors/action/rec/get`;
		get_data_load_errors_action_rec(payload) {
			return this.http.post<any>( this.get_data_load_errors_action_rec_url , payload);
		}
	// Bell Icon Notification Service call
		private _mark_read_notifications_url = `${this.env.service_url}/mark_read_notifications`;
		mark_read_notifications(payload) {
			return this.http.post<any>( this._mark_read_notifications_url, payload);
		}	

		private _get_unread_push_notifications_url = `${this.env.service_url}/get_unread_push_notifications`;
		get_unread_push_notifications(payload) {
			return this.http.post<any>( this._get_unread_push_notifications_url, payload);
		}	

		private _get_all_push_notifications_url = `${this.env.service_url}/get_all_push_notifications`;
		get_all_push_notifications(payload) {
			return this.http.post<any>( this._get_all_push_notifications_url, payload);
		}	
		public enable_poll_url = `${this.env.service_url}/enable_poller`;
		enable_poll(payload) {
			return this.http.post<any>( this.enable_poll_url, payload);
		}	
		public disable_poll_url = `${this.env.service_url}/disable_poller`;
		disable_poll(payload) {
			return this.http.post<any>( this.disable_poll_url, payload);
		}		

	// Other
		private get_sidebar_tree_url = `${this.env.service_url}/get_sidebar_tree`;
		get_sidebar_tree(payload) {
			return this.http.post<any>( this.get_sidebar_tree_url, payload);
		}

		private _get_internal_user_details_refresh_url = `${this.env.service_url}/get_internal_user_details_refresh`;
		get_data_after_refresh(payload) {
			return this.http.post<any>( this._get_internal_user_details_refresh_url, payload);
		}	

		private _get_sidebar_configuration_url = `${this.env.service_url}/get_sidebar_configuration`;
		get_sidebar_configuration(payload) {
			return this.http.post<any>( this._get_sidebar_configuration_url, payload);
		}
		
		private get_sidebar_configuration_for_user_url = `${this.env.service_url}/get_sidebar_configuration_for_user`;
		get_sidebar_configuration_for_user(payload) {
			return this.http.post<any>( this.get_sidebar_configuration_for_user_url, payload);
		}

		private update_sidebar_configuration_for_user_url = `${this.env.service_url}/update_sidebar_configuration_for_user`;
		update_sidebar_configuration_for_user(payload) {
			return this.http.post<any>( this.update_sidebar_configuration_for_user_url, payload);
		}

		private get_roles_list_for_user_url = `${this.env.service_url}/get_roles_list_for_user`;
		get_roles_list_for_user(payload) {
			return this.http.post<any>( this.get_roles_list_for_user_url, payload);
		}

		private get_user_role_map_url = `${this.env.service_url}/get_user_role_map`;
		get_user_role_map(payload) {
			return this.http.post<any>( this.get_user_role_map_url, payload);
		}
		
		private change_user_password_by_admin_url = `${this.env.service_url}/change_user_password_by_admin`
		change_user_password_by_admin(payload){
			return this.http.post<any>( this.change_user_password_by_admin_url, payload);
		}
		
		private reset_password_url = `${this.env.service_url}/reset_password`
		reset_password(payload){
			return this.http.post<any>( this.reset_password_url, payload);
		}
		
		private send_forgot_password_email_url = `${this.env.service_url}/send_forgot_password_email`
		send_forgot_password_email(payload){
			return this.http.post<any>( this.send_forgot_password_email_url, payload);
		}
		
		private forgot_password_url  = `${this.env.service_url}/forgot_password`;
		forgot_password(payload){
			return this.http.post<any>( this.forgot_password_url, payload);
		}

		private check_password_availablity_url  = `${this.env.service_url}/check_password_already_used`;
		check_password_availablity(payload){
			return this.http.post<any>( this.check_password_availablity_url, payload);
		}
	
		private internal_dashboard_details_rfq_url  = `${this.env.service_url}/internal_dashboard_details_rfq`;
		internal_dashboard_details_rfq(payload){
			return this.http.post<any>( this.internal_dashboard_details_rfq_url, payload);
		}
	
		private internal_dashboard_details_quote_url  = `${this.env.service_url}/internal_dashboard_details_quote`;
		internal_dashboard_details_quote(payload){
			return this.http.post<any>( this.internal_dashboard_details_quote_url, payload);
		}
	
		private internal_dashboard_details_order_url  = `${this.env.service_url}/internal_dashboard_details_order`;
		internal_dashboard_details_order(payload){
			return this.http.post<any>( this.internal_dashboard_details_order_url, payload);
		}

		private _get_user_signature_url = `${this.env.service_url}/get_signature_from_user_id`;
		get_signature_from_user_id(payload){
			return this.http.post<any>( this._get_user_signature_url, payload);
		}

		private _get_user_signature_from_template_url = `${this.env.service_url}/get_template_signature_for_user`;
		get_template_signature_for_user(payload){
			return this.http.post<any>( this._get_user_signature_from_template_url, payload);
		}
		
		private create_user_signature_url = `${this.env.service_url}/create_user_signature`
		create_user_signature(payload){
		       return this.http.post<any>( this.create_user_signature_url, payload);
		}

	// Workflow Action Config
		private get_all_workflow_actions_url = `${this.env.service_url}/get_all_workflow_actions`;
		get_all_workflow_actions(payload){
			return this.http.post<any>( this.get_all_workflow_actions_url, payload);
		}
		private update_workflow_actions_url = `${this.env.service_url}/update_workflow_actions`;
		update_workflow_actions(payload){
			return this.http.post<any>( this.update_workflow_actions_url, payload);
		}
		private create_workflow_actions_url = `${this.env.service_url}/create_workflow_actions`;
		create_workflow_actions(payload){
			return this.http.post<any>( this.create_workflow_actions_url, payload);
		}
	// Roles
		private deactivate_user_role_url = `${this.env.service_url}/deactivate_user_role`;
		deactivate_user_role(payload) {
			return this.http.post<any>( this.deactivate_user_role_url, payload);
		}

		private add_user_role_url = `${this.env.service_url}/add_user_role`;
		add_user_role(payload) {
			return this.http.post<any>( this.add_user_role_url, payload);
		}

		private add_multiple_user_role_url = `${this.env.service_url}/add_multiple_user_role`;
		add_multiple_user_role(payload) {
			return this.http.post<any>( this.add_multiple_user_role_url, payload);
		}

		private get_all_user_role_v1_url = `${this.env.service_url}/role/users/get_all`;
		get_all_user_role_v1(payload) {
			return this.http.post<any>( this.get_all_user_role_v1_url, payload);
		}

		private get_roles_list_url = `${this.env.service_url}/get_roles_list`;
		get_roles_list(payload) {
			return this.http.post<any>( this.get_roles_list_url, payload);
		}

		public create_new_role_url = `${this.env.service_url}/create_new_role`;
		create_new_role(payload) {
			return this.http.post<any>( this.create_new_role_url, payload);
		}

		public update_sidebar_configuration_url = `${this.env.service_url}/update_sidebar_configuration`;
		update_sidebar_configuration(payload) {
			return this.http.post<any>( this.update_sidebar_configuration_url, payload);
		}

		private get_component_configuration_url = `${this.env.service_url}/get_component_configuration`;
		get_component_configuration(payload) {
			return this.http.post<any>( this.get_component_configuration_url, payload);
		}

		public update_component_configuration_url = `${this.env.service_url}/update_component_configuration`;
		update_component_configuration(payload) {
			return this.http.post<any>( this.update_component_configuration_url, payload);
		}

		private get_all_user_roles_url = `${this.env.service_url}/get_all_user_roles`;
		get_all_user_roles(payload) {
			return this.http.post<any>( this.get_all_user_roles_url, payload);
		}

		private get_user_role_history_url = `${this.env.service_url}/user/role/audits`;
		get_user_role_history(payload) {
			return this.http.post<any>( this.get_user_role_history_url, payload);
		}

		private update_user_roles_url = `${this.env.service_url}/update_user_roles`;
		update_user_roles(payload) {
			return this.http.post<any>( this.update_user_roles_url, payload);
		}
		private get_contact_contact_details_url = `${this.env.service_url}/get_contact_contact_details`;
		get_contact_contact_details(payload){
			return this.http.post<any>( this.get_contact_contact_details_url, payload);
		}
	//Dispatch create_dispatch_definition
		public create_dispatch_definition_url = `${this.env.service_url}/create_dispatch_definition`;
		create_dispatch_definition(payload){
			return this.http.post<any>( this.create_dispatch_definition_url, payload);
		}
		private get_all_dispatch_definition_url = `${this.env.service_url}/get_all_dispatch_definition`
		get_all_dispatch_definition(payload){
			return this.http.post<any>( this.get_all_dispatch_definition_url, payload);
		}
		private get_dispatch_definition_url = `${this.env.service_url}/get_dispatch_definition`
		get_dispatch_definition(payload){
			return this.http.post<any>( this.get_dispatch_definition_url, payload);
		}
		public update_dispatch_definition_url = `${this.env.service_url}/update_dispatch_definition`
		update_dispatch_definition(payload){
			return this.http.post<any>( this.update_dispatch_definition_url, payload);
		}
		private get_dispatch_decision_data_url = `${this.env.service_url}/get_dispatch_decision_data`
		get_dispatch_decision_data(payload){
			return this.http.post<any>( this.get_dispatch_decision_data_url, payload);
		}

	// Potential Member Service call
		private create_potential_member_for_case_url = `${this.env.service_url}/create_potential_member_for_case`
		create_potential_member_for_case(payload){
			return this.http.post<any>( this.create_potential_member_for_case_url, payload);
		}

		private get_potential_member_contacts_for_case_url = `${this.env.service_url}/get_potential_member_contacts_for_case`
		get_potential_member_contacts_for_case(payload){
			return this.http.post<any>( this.get_potential_member_contacts_for_case_url, payload);
		}

		public search_potential_members_url = `${this.env.service_url}/search_potential_members`
		search_potential_members(payload){
			return this.http.post<any>( this.search_potential_members_url, payload);
		}

		private get_potential_member_from_national_id_url = `${this.env.service_url}/get_potential_member_from_national_id`
		get_potential_member_from_national_id(payload){
			return this.http.post<any>( this.get_potential_member_from_national_id_url, payload);
		}

		private get_potential_member_from_passport_number_url = `${this.env.service_url}/get_potential_member_from_passport_number`
		get_potential_member_from_passport_number(payload){
			return this.http.post<any>( this.get_potential_member_from_passport_number_url, payload);
		}

		private convert_potential_customer_to_customer_url = `${this.env.service_url}/convert_potential_customer_to_customer`
		convert_potential_customer_to_customer(payload){
			return this.http.post<any>( this.convert_potential_customer_to_customer_url, payload);
		}

	// Portfolio Service calls
		private get_all_portfolio_url = `${this.env.service_url}/portfolio/get_all_portfolio`
		get_all_portfolio(payload){
			return this.http.post<any>( this.get_all_portfolio_url, payload);
		}

		private get_all_clients_from_portfolio_url = `${this.env.service_url}/portfolio/get_all_clients_from_portfolio`
		get_all_clients_from_portfolio(payload){
			return this.http.post<any>( this.get_all_clients_from_portfolio_url, payload);
		}

		private add_portfolio_url = `${this.env.service_url}/portfolio/add_portfolio`
		add_portfolio(payload){
			return this.http.post<any>( this.add_portfolio_url, payload);
		}

		private add_client_to_portfolio_url = `${this.env.service_url}/portfolio/add_client_to_portfolio`
		add_client_to_portfolio(payload){
			return this.http.post<any>( this.add_client_to_portfolio_url, payload);
		}

		private remove_client_from_portfolio_url = `${this.env.service_url}/portfolio/remove_client_from_portfolio`
		remove_client_from_portfolio(payload){
			return this.http.post<any>( this.remove_client_from_portfolio_url, payload);
		}

		private get_unallocated_clients_portfolio_url = `${this.env.service_url}/portfolio/get_unallocated_clients_portfolio`
		get_unallocated_clients_portfolio(payload){
			return this.http.post<any>( this.get_unallocated_clients_portfolio_url, payload);
		}

		private remove_all_clients_from_portfolio_url = `${this.env.service_url}/portfolio/remove_all_clients_from_portfolio`
		remove_all_clients_from_portfolio(payload){
			return this.http.post<any>( this.remove_all_clients_from_portfolio_url, payload);
		}

		private remove_portfolio_url = `${this.env.service_url}/portfolio/remove_portfolio`
		remove_portfolio(payload){
			return this.http.post<any>( this.remove_portfolio_url, payload);
		}

		private update_portfolio_url = `${this.env.service_url}/portfolio/update_portfolio`
		update_portfolio(payload){
			return this.http.post<any>( this.update_portfolio_url, payload);
		}

		private get_employee_portfolio_url = `${this.env.service_url}/portfolio/get_employee_portfolio`
		get_employee_portfolio(payload){
			return this.http.post<any>( this.get_employee_portfolio_url, payload);
		}

		private add_employee_portfolio_url = `${this.env.service_url}/portfolio/add_employee_portfolio`
		add_employee_portfolio(payload){
			return this.http.post<any>( this.add_employee_portfolio_url, payload);
		}

		private remove_employee_portfolio_url = `${this.env.service_url}/portfolio/remove_employee_portfolio`
		remove_employee_portfolio(payload){
			return this.http.post<any>( this.remove_employee_portfolio_url, payload);
		}

		private get_portfolio_employees_url = `${this.env.service_url}/portfolio/get_portfolio_employees`
		get_portfolio_employees(payload){
			return this.http.post<any>( this.get_portfolio_employees_url, payload);
		}

		private get_reporting_heirarchy_graph_url = `${this.env.service_url}/get_reporting_heirarchy_graph`
		get_reporting_heirarchy_graph(payload){
			return this.http.post<any>( this.get_reporting_heirarchy_graph_url, payload);
		}
	
	// GEMS FNA service calls
		private get_schemes_for_gems_fna_url = `${this.env.service_url}/moses/v1/get_schemes_for_gems_fna`
		get_schemes_for_gems_fna(payload){
			return this.http.post<any>( this.get_schemes_for_gems_fna_url, payload);
		}


	// Lead Services
		private create_lead_url = `${this.env.service_url}/create_lead`
		create_lead(payload){
			return this.http.post<any>( this.create_lead_url, payload);
		}

		private update_lead_url = `${this.env.service_url}/update_lead`
		update_lead(payload){
			return this.http.post<any>( this.update_lead_url, payload);
		}

		private create_lead_task_url = `${this.env.service_url}/create_lead_task`
		create_lead_task(payload){
			return this.http.post<any>( this.create_lead_task_url, payload);
		}

		private get_lead_tasks_url = `${this.env.service_url}/dce/v1/get_lead_tasks`
		get_lead_tasks(payload){
			return this.http.post<any>( this.get_lead_tasks_url, payload);
		}
		private change_lead_level_url = `${this.env.service_url}/change_lead_level`
		change_lead_level(payload){
			return this.http.post<any>( this.change_lead_level_url, payload);
		}

		private check_lead_tasks_complete_url = `${this.env.service_url}/check_lead_tasks_complete`
		check_lead_tasks_complete(payload){
			return this.http.post<any>( this.check_lead_tasks_complete_url, payload);
		}

		private get_lead_classification_tree_url = `${this.env.service_url}/get_lead_classification_tree`;
		get_lead_classification_tree(payload) {
			return this.http.post<any>( this.get_lead_classification_tree_url , payload)
		}

		private get_lead_level_change_log_url = `${this.env.service_url}/get_lead_level_change_log`;
		get_lead_level_change_log(payload) {
			return this.http.post<any>( this.get_lead_level_change_log_url , payload)
		}

		private get_b64_doc_from_url_url = `${this.env.service_url}/get_b64_doc_from_url`;
		get_b64_doc_from_url(payload) {
			return this.http.post<any>( this.get_b64_doc_from_url_url , payload)
		}

// Signature Services
		public _create_signature_template_url = `${this.env.service_url}/create_signature_template`;
		create_signature_template(payload) {
			return this.http.post<any>( this._create_signature_template_url , payload);
		}

		private _update_signature_template_url = `${this.env.service_url}/update_signature_template`;
		update_signature_template(payload) {
			return this.http.post<any>( this._update_signature_template_url , payload);
		}

		private _get_all_signature_templates_url = `${this.env.service_url}/get_all_signature_templates`;
		get_all_signature_templates(payload) {
			return this.http.post<any>( this._get_all_signature_templates_url , payload);
		}

		private _activate_signature_template_url = `${this.env.service_url}/activate_signature_template`;
		activate_signature_template(payload) {
			return this.http.post<any>( this._activate_signature_template_url , payload);
		}

		private _delete_signature_template_url = `${this.env.service_url}/delete_signature_template`;
		delete_signature_template(payload) {
			return this.http.post<any>( this._delete_signature_template_url , payload);
		}
		private _get_allowed_columns_for_template_url = `${this.env.service_url}/get_allowed_columns_for_template`;
		get_allowed_columns_for_template(payload) {
			return this.http.post<any>( this._get_allowed_columns_for_template_url , payload);
		}


		public _get_all_bizorg_v2_url = `${this.env.service_url}/get_first_100_biz`;
		get_all_bizorg_v2(payload) {
			return this.http.post<any>(this._get_all_bizorg_v2_url, payload);
		}
		
		public _get_all_bizorg_v3_url = `${this.env.service_url}/search_bizorg`;
		get_all_bizorg_v3(payload) {
			return this.http.post<any>(this._get_all_bizorg_v3_url, payload);
		}

		public check_reg_num_exist_url = `${this.env.service_url}/biz_org/check_reg_num_exist`;
		check_reg_num_exist(payload) {
			return this.http.post<any>(this.check_reg_num_exist_url, payload);
		}

		public get_filtered_config_url = `${this.env.service_url}/email_configuration_error_log/get_all`;
		get_filtered_config(payload) {
			return this.http.post<any>(this.get_filtered_config_url, payload);
		}

		public get_config_log_url = `${this.env.service_url}/email_configuration_error_log/get`;
		get_config_log(payload) {
			return this.http.post<any>(this.get_config_log_url, payload);
		}
		public set_status_config_url = `${this.env.service_url}/email_configuration_error_log/set_status`;
		set_status_config(payload) {
			return this.http.post<any>(this.set_status_config_url, payload);
		}

		// paginated search
		public get_case_paginated_results_for_user_url = `${this.env.service_url}/workflow/get_paginated_results/user`;
		get_case_paginated_results_for_user(payload) {
			return this.http.post<any>(this.get_case_paginated_results_for_user_url, payload);
		}

		public get_case_paginated_results_for_workgroup_url = `${this.env.service_url}/workflow/get_paginated_results/workgroup`;
		get_case_paginated_results_for_workgroup(payload) {
			return this.http.post<any>(this.get_case_paginated_results_for_workgroup_url, payload);
		}
		
		// get clients based on phone number
		public get_clients_based_on_phone_no_url = `${this.env.service_url}/bizorg/search/phone_number`;
		get_clients_based_on_phone_no(payload) {
			return this.http.post<any>(this.get_clients_based_on_phone_no_url, payload);
		}
		
		// HOLIDAY CALENDER SERVICES
		private get_all_holidays_url = `${this.env.service_url}/holiday/get_all_holidays`;
		get_all_holidays(payload) {
			return this.http.post<any>(this.get_all_holidays_url, payload);
		}

		public create_holidays_in_date_range_url = `${this.env.service_url}/holiday/create_holidays_in_date_range`;
		create_holidays_in_date_range(payload) {
			return this.http.post<any>(this.create_holidays_in_date_range_url, payload);
		}

		private update_holiday_url = `${this.env.service_url}/holiday/update_holiday`;
		update_holiday(payload) {
			return this.http.post<any>(this.update_holiday_url, payload);
		}

		public create_single_holiday_url = `${this.env.service_url}/holiday/create_single_holiday`;
		create_single_holiday(payload) {
			return this.http.post<any>(this.create_single_holiday_url, payload);
		}

		// Email Content
		public get_all_email_content_url = `${this.env.service_url}/email_content/get_all`;
		get_all_email_content(payload) {
			return this.http.post<any>(this.get_all_email_content_url, payload);
		}

		public create_email_content_url = `${this.env.service_url}/email_content/add`;
		create_email_content(payload) {
			return this.http.post<any>(this.create_email_content_url, payload);
		}
		
		public update_email_content_url = `${this.env.service_url}/email_content/update`;
		update_email_content(payload) {
			return this.http.post<any>(this.update_email_content_url, payload);
		}
		
		public get_email_content_url = `${this.env.service_url}/email_content/get`;
		get_email_content(payload) {
			return this.http.post<any>(this.get_email_content_url, payload);
		}

		public remove_email_content_url = `${this.env.service_url}/email_content/remove`;
		remove_email_content(payload) {
			return this.http.post<any>(this.remove_email_content_url, payload);
		}

		private get_biz_id_based_on_individual_url = `${this.env.service_url}/individuals/biz_org/get`;
		get_biz_id_based_on_individual_id(payload) {
			return this.http.post<any>(this.get_biz_id_based_on_individual_url, payload);
		}
		
	// Workgroup Rules 
		private get_workgroup_rule_url = `${this.env.service_url}/workgroup/config/rules/get`;
		get_workgroup_rule(payload) {
			return this.http.post<any>(this.get_workgroup_rule_url, payload);
		}

		public update_workgroup_rule_url = `${this.env.service_url}/workgroup/config/rules/update`;
		update_workgroup_rule(payload) {
			return this.http.post<any>(this.update_workgroup_rule_url, payload);
		}
		public get_workgroup_builderule_url = `${this.env.service_url}/workgroup/config/rules/decisions`;
		get_workgroup_builderule(payload) {
			return this.http.post<any>(this.get_workgroup_builderule_url, payload);
		}

		public check_workgroup_exit_rule_url = `${this.env.service_url}/workgroup/config/rules/exit/check_object`;
		check_workgroup_exit_rule(payload) {
			return this.http.post<any>(this.check_workgroup_exit_rule_url, payload);
		}

		// Taks data approval
		private send_task_for_approval_url = `${this.env.service_url}/workflow/task/approval/send`;
		send_task_for_approval(payload) {
			return this.http.post<any>(this.send_task_for_approval_url, payload);
		}

		private check_task_approval_url = `${this.env.service_url}/task/check_approval`;
		check_task_approval(payload) {
			return this.http.post<any>(this.check_task_approval_url, payload);
		}

		private approve_task_from_approval_url = `${this.env.service_url}/workflow/task/approval/approve`;
		approve_task_from_approval(payload) {
			return this.http.post<any>(this.approve_task_from_approval_url, payload);
		}

		private reject_task_from_approval_url = `${this.env.service_url}/workflow/task/approval/reject`;
		reject_task_from_approval(payload) {
			return this.http.post<any>(this.reject_task_from_approval_url, payload);
		}

		public approve_all_task_from_approval_url = `${this.env.service_url}/workflow/task/approval/approve_all`;
		approve_all_task_from_approval(payload) {
			return this.http.post<any>(this.approve_all_task_from_approval_url, payload);
		}

		public reject_all_task_from_approval_url = `${this.env.service_url}/workflow/task/approval/reject_all`;
		reject_all_task_from_approval(payload) {
			return this.http.post<any>(this.reject_all_task_from_approval_url, payload);
		}

		private get_change_request_task_list_url = `${this.env.service_url}/workflow/approvals/user`;
		get_change_request_task_list(payload) {
			return this.http.post<any>(this.get_change_request_task_list_url, payload);
		}

		private get_change_request_history_url = `${this.env.service_url}/workflow/task/approval/history/get`;
		get_change_request_history(payload) {
			return this.http.post<any>(this.get_change_request_history_url, payload);
		}

	//  Task Classification Map Services
		
		public add_task_classification_manager_map_url = `${this.env.service_url}/workflow_attr_rules/task/mapping/approval/add`;
		add_task_classification_manager_map(payload) {
			return this.http.post<any>(this.add_task_classification_manager_map_url, payload);
		}		

		private remove_task_classification_manager_map_url = `${this.env.service_url}/workflow_attr_rules/remove`;
		remove_task_classification_manager_map(payload) {
			return this.http.post<any>(this.remove_task_classification_manager_map_url, payload);
		}		

		public update_task_classification_manager_map_url = `${this.env.service_url}/workflow_attr_rules/task/mapping/approval/update`;
		update_task_classification_manager_map(payload) {
			return this.http.post<any>(this.update_task_classification_manager_map_url, payload);
		}
				
		private get_all_task_classification_manager_map_url = `${this.env.service_url}/workflow_attr_rules/task/mapping/approval/get_all`;
		get_all_task_classification_manager_map(payload) {
			return this.http.post<any>(this.get_all_task_classification_manager_map_url, payload);
		}		

		private get_reporting_manager_include_classification_manager_url = `${this.env.service_url}/workflow/approval/reportee/get`;
		get_reporting_manager_include_classification_manager(payload) {
			return this.http.post<any>(this.get_reporting_manager_include_classification_manager_url, payload);
		}

		private get_subtask_templates_list_url = `${this.env.service_url}/workflow/task/schedulelist/get`;
		get_subtask_templates_list(payload) {
			return this.http.post<any>(this.get_subtask_templates_list_url, payload);
		}

		public create_subtask_url = `${this.env.service_url}/workflow/task/schedulelist/create`;
		create_subtask(payload) {
			return this.http.post<any>(this.create_subtask_url, payload);
		}

		private get_task_children_url = `${this.env.service_url}/workflow/task/children`;
		get_task_children(payload) {
			return this.http.post<any>(this.get_task_children_url, payload);
		}

		private update_subtask_url = `${this.env.service_url}/workflow/task/schedulelist/update`;
		update_subtask(payload) {
			return this.http.post<any>(this.update_subtask_url, payload);
		}

		private remove_subtask_url = `${this.env.service_url}/workflow/task/schedulelist/remove`;
		remove_subtask(payload) {
			return this.http.post<any>(this.remove_subtask_url, payload);
		}

		private instantiate_subtask_url = `${this.env.service_url}/workflow/task/schedulelist/instantiate`;
		instantiate_subtask(payload) {
			return this.http.post<any>(this.instantiate_subtask_url, payload);
		}

		private get_all_recurring_task_url = `${this.env.service_url}/task/schedule_list/get_all`;
		get_all_recurring(payload) {
			return this.http.post<any>( this.get_all_recurring_task_url, payload);
		}

		private get_recurring_task_url = `${this.env.service_url}/task/schedule_list/get`;
		get_recurring(payload) {
			return this.http.post<any>( this.get_recurring_task_url, payload);
		}

		private get_sub_task_gantt_chart_url = `${this.env.service_url}/task/gannt_chart/get`;
		get_sub_task_gantt_chart(payload) {
			return this.http.post<any>( this.get_sub_task_gantt_chart_url, payload)
		}

		// Task classificatio to task form mapping services
		public map_task_classification_to_task_form_url = `${this.env.service_url}/workflow_attr_rules/task/mapping/task_form/add`;
		map_task_classification_to_task_form(payload) {
			return this.http.post<any>(this.map_task_classification_to_task_form_url, payload);
		}

		public update_map_task_classification_to_task_form_url = `${this.env.service_url}/workflow_attr_rules/task/mapping/task_form/update`;
		update_map_task_classification_to_task_form(payload) {
			return this.http.post<any>(this.update_map_task_classification_to_task_form_url, payload);
		}

		private get_all_task_classification_taskform_map_url = `${this.env.service_url}/workflow_attr_rules/task/mapping/task_form/get_all`;
		get_all_task_classification_taskform_map(payload) {
			return this.http.post<any>(this.get_all_task_classification_taskform_map_url, payload);
		}	

		// Case classification to Email mapping services
		public add_case_classification_to_email_url = `${this.env.service_url}/workflow_attr_rules/case/mapping/email/add`;
		add_case_classification_to_email(payload) {
			return this.http.post<any>(this.add_case_classification_to_email_url, payload);
		}

		public update_case_classification_to_email_url = `${this.env.service_url}/workflow_attr_rules/case/mapping/email/update`;
		update_case_classification_to_email(payload) {
			return this.http.post<any>(this.update_case_classification_to_email_url, payload);
		}

		private get_all_case_classification_to_email_url = `${this.env.service_url}/workflow_attr_rules/case/mapping/email/get_all`;
		get_all_case_classification_to_email(payload) {
			return this.http.post<any>(this.get_all_case_classification_to_email_url, payload);
		}		

		public undelivered_email_data_url = `${this.env.service_url}/email/delivery/errors`;
		undelivered_email_data(payload) {
			return this.http.post<any>(this.undelivered_email_data_url, payload);
		}	

		private get_all_def_wrkgrp_for_user_url = `${this.env.service_url}/workgroup/user/defaults`;
		get_all_def_wrkgrp_for_user(payload) {
			return this.http.post<any>(this.get_all_def_wrkgrp_for_user_url, payload);
		}		

		private check_membership_wrkgrp_for_user_url = `${this.env.service_url}/workgroup/user/check_membership`;
		check_membership_wrkgrp_for_user(payload) {
			return this.http.post<any>(this.check_membership_wrkgrp_for_user_url, payload);
		}		

		public add_def_wg_to_user_url = `${this.env.service_url}/workgroup/user/default/add`;
		add_def_wg_to_user(payload) {
			return this.http.post<any>(this.add_def_wg_to_user_url, payload);
		}		

		public add_multiple_user_to_wrkgrp_url = `${this.env.service_url}/workgroup/user/add_bulk`;
		add_multiple_user_to_wrkgrp(payload) {
			return this.http.post<any>(this.add_multiple_user_to_wrkgrp_url, payload);
		}
		
		private log_error_url = `${this.env.service_url}/log/error`;
		log_error(payload) {
			return this.http.post<any>(this.log_error_url, payload);
		}		

		private opencase = `${this.env.service_url}/cases/previous/bizorg/open`;
		get_open_cases(payload) {
			return this.http.post<any>(this.opencase, payload);
		}		

		private get_workgroup_case_status_url = `${this.env.service_url}/workflow/user/workgroups/object_status`;
		get_workgroup_case_status(payload) {
			return this.http.post<any>(this.get_workgroup_case_status_url, payload);
		}

		private get_paginated_sms_errors_url = `${this.env.service_url}/exposed/sms/delivery/errors`;
		get_paginated_sms_errors(payload) {
			return this.http.post<any>(this.get_paginated_sms_errors_url, payload);
		}

		private get_record_process_data_url = `${this.env.service_url}/email/delivery/error/data`;
		get_record_process_data(payload) {
			return this.http.post<any>( this.get_record_process_data_url, payload)
		}

		//Announcements
		public get_all_announcements_url = `${this.env.service_url}/wss/announcement/data/get_all`;
		get_all_announcements(payload) {
			return this.http.post<any>(this.get_all_announcements_url, payload);
		}

		public save_announcement_url = `${this.env.service_url}/wss/announcement/create `;
		save_announcement(payload) {
			return this.http.post<any>(this.save_announcement_url, payload);
		}

		public update_announcement_url = `${this.env.service_url}/wss/announcement/update `;
		update_announcement(payload) {
			return this.http.post<any>(this.update_announcement_url, payload);
		}

		public deactivate_announcement_url = `${this.env.service_url}/wss/announcement/deactivate `;
		deactivate_announcement(payload) {
			return this.http.post<any>(this.deactivate_announcement_url, payload);
		}

		private _get_today_announcements_url = `${this.env.service_url}/announcements/get_all/today`;
		get_today_announcements(payload) {
			return this.http.post<any>(this._get_today_announcements_url, payload);
		}
	//Communication Registry

		//type CRU

		private get_communication_types_url = `${this.env.service_url}/communication/registry/types/get_all`;
		get_communication_types() {
			return this.http.get<any>( this.get_communication_types_url)
		}
		private add_comm_type_url = `${this.env.service_url}/communication/registry/types/create`;
		add_comm_type(payload) {
			return this.http.post<any>( this.add_comm_type_url , payload)
		}

		private update_comm_type_url = `${this.env.service_url}/communication/registry/types/update`;
		update_comm_type(payload) {
			return this.http.post<any>( this.update_comm_type_url , payload)
		}

		//value CRUD

		private get_registry_data_url = `${this.env.service_url}/communication/registry/data/get_all`;
		get_registry_data(payload) {
			return this.http.post<any>(this.get_registry_data_url, payload)
		}

		private add_comm_value_url = `${this.env.service_url}/communication/registry/data/create`;
		add_comm_value(payload) {
			return this.http.post<any>( this.add_comm_value_url , payload)
		}
		
		private delete_comm_val_url = `${this.env.service_url}/communication/registry/data/setstatus`;
		delete_comm_val(payload) {
			return this.http.post<any>( this.delete_comm_val_url , payload)
		}

		private update_comm_val_url = `${this.env.service_url}/communication/registry/data/update`;
		update_comm_val(payload) {
			return this.http.post<any>( this.update_comm_val_url , payload)
		}

	    //Email Poller Search Filter

		private get_email_data_url = `${this.env.email_service_url}/confs/emails/get_all`;
	    get_email_data(payload) {
	    	return this.http.post<any>( this.get_email_data_url, payload)
	    }

		private get_email_search_data_url = `${this.env.email_service_url}/data/get_all`;
	    get_email_search_data(payload) {
	    	return this.http.post<any>( this.get_email_search_data_url, payload)
	    }

		//Dispatch Rule Graph
		private get_dispatchrule_viz_url = `${this.env.service_url}/dispatchrule/viz/get`;
	    get_dispatchrule_viz(payload) {
	    	return this.http.post<any>( this.get_dispatchrule_viz_url, payload)
	    }
		private set_dispatch_rule_status_deleted_url = `${this.env.service_url}/dispatch_definition/set_status`;
	    set_dispatch_rule_status_deleted(payload) {
	    	return this.http.post<any>( this.set_dispatch_rule_status_deleted_url, payload)
	    }

		//Live Chat 

		//chat queue
		private add_chat_queue_url = `${this.env.service_url}/meta/chatqueue/add`;
	    add_chat_queue(payload) {
	    	return this.http.post<any>( this.add_chat_queue_url, payload)
	    }

		private update_chat_queue_url = `${this.env.service_url}/meta/chatqueue/update`;
	    update_chat_queue(payload) {
	    	return this.http.post<any>( this.update_chat_queue_url, payload)
	    }

		private remove_chat_queue_url = `${this.env.service_url}/meta/chatqueue/remove`;
	    remove_chat_queue(payload) {
	    	return this.http.post<any>( this.remove_chat_queue_url, payload)
	    }

		private get_chat_queue_url = `${this.env.service_url}/meta/chatqueue/get`;
	    get_chat_queue(payload) {
	    	return this.http.get<any>(this.process_get_req(this.get_chat_queue_url, payload))
	    }

		private get_all_chat_queue_url = `${this.env.service_url}/meta/chatqueue/get_all`;
	    get_all_chat_queue() {
	    	return this.http.get<any>( this.get_all_chat_queue_url)
	    }

		//chat queue user
		private add_chat_queue_user_url = `${this.env.service_url}/meta/chatqueue/user/add`;
	    add_chat_queue_user(payload) {
	    	return this.http.post<any>( this.add_chat_queue_user_url, payload)
	    }

		private remove_chat_queue_user_url = `${this.env.service_url}/meta/chatqueue/user/remove`;
	    remove_chat_queue_user(payload) {
	    	return this.http.post<any>( this.remove_chat_queue_user_url, payload)
	    }

		private get_all_chat_queue_users_url = `${this.env.service_url}/meta/chatqueue/user/get_all`;
	    get_all_chat_queue_users(payload) {
	    	return this.http.get<any>( this.process_get_req(this.get_all_chat_queue_users_url, payload))
	    }

		private bulk_add_user_url = `${this.env.service_url}/meta/chatqueue/user/bulk_add`;
	    bulk_add_user(payload) {
	    	return this.http.post<any>( this.bulk_add_user_url, payload)
	    }
		
		//timings
		private add_chat_queue_timings_url = `${this.env.service_url}/meta/chatqueue/timings/add`;
	    add_chat_queue_timings(payload) {
	    	return this.http.post<any>( this.add_chat_queue_timings_url, payload)
	    }

		private update_chat_queue_timings_url = `${this.env.service_url}/meta/chatqueue/timings/update`;
	    update_chat_queue_timings(payload) {
	    	return this.http.post<any>( this.update_chat_queue_timings_url, payload)
	    }

		private remove_chat_queue_timings_url = `${this.env.service_url}/meta/chatqueue/timings/remove`;
	    remove_chat_queue_timings(payload) {
	    	return this.http.post<any>( this.remove_chat_queue_timings_url, payload)
	    }

		private get_all_chat_queue_timings_url = `${this.env.service_url}/meta/chatqueue/timings/get_all`;
	    get_all_chat_queue_timings(payload) {
	    	return this.http.get<any>(this.process_get_req(this.get_all_chat_queue_timings_url, payload))
	    }

		//Mapping Queue classification
		private map_classification_url = `${this.env.service_url}/classification_extension/entity/map`;
	    map_classification(payload) {
	    	return this.http.post<any>( this.map_classification_url, payload)
	    }

		//Chatbot History
		private get_chatbot_history_url = `${this.env.service_url}/chatbot/history/get`;
	    get_chatbot_history(payload) {
	    	return this.http.get<any>(this.process_get_req(this.get_chatbot_history_url, payload))
	    }

		//livechat history
		private get_livechat_filter_url = `${this.env.service_url}/livechat/history/filter`;
	    get_livechat_filter(payload) {
	    	return this.http.post<any>( this.get_livechat_filter_url, payload)
	    }

		private set_livechat_status_url = `${this.env.service_url}/livechat/chatattr`;
	    set_livechat_status(payload) {
	    	return this.http.post<any>( this.set_livechat_status_url, payload)
	    }

		private get_case_details_url = `${this.env.service_url}/get_case_using_case_id`;
	    get_case_details(payload) {
			return this.http.post<any>( this.get_case_details_url, payload)
	    }

		private link_customer_url = `${this.env.service_url}/livechat/customer/link`;
	    link_customer(payload) {
			return this.http.post<any>( this.link_customer_url, payload)
	    }

	//Notification Grpah

		private get_notification_viz_url = `${this.env.service_url}/notification_rule/viz/get`;
	    get_notification_viz(payload) {
	    	return this.http.post<any>( this.get_notification_viz_url, payload)
	    }
		private set_notification_status_deleted_url = `${this.env.service_url}/notification_definition/set_status`;
	    set_notification_status_deleted(payload) {
	    	return this.http.post<any>( this.set_notification_status_deleted_url, payload)
	    }

		//Reload Chat Queues
		private reload_chat_queues_url = `${this.env.service_url}/meta/chatqueue/reload`;
	    reload_chat_queues(payload) {
	    	return this.http.post<any>( this.reload_chat_queues_url, payload)
	    }

		private get_chatqueue_status_url = `${this.env.service_url}/chatqueue/status`;
		get_chatqueue_status(payload) {
	    	return this.http.get<any>( this.process_get_req(this.get_chatqueue_status_url, payload));
	    }
		
		//Procedure api's
		private get_business_time_url = `${this.env.service_url}/businesstime/params/get`;
		get_business_time(payload) {
			return this.http.get<getBusinessTimeResponse>(this.process_get_req(this.get_business_time_url, payload));
	    }
		private get_businesstime_viz_date_url = `${this.env.service_url}/businesstime/value/date/run`;
	    get_businesstime_viz_date(payload) {
			return this.http.post<getBusinessTimeResponseVizDate>( this.get_businesstime_viz_date_url, payload)
	    }
		private get_businesstime_viz_datetime_url = `${this.env.service_url}/businesstime/value/datetime/run`;
	    get_businesstime_viz_datetime(payload) {
			return this.http.post<getBusinessTimeResponseVizDateTime>( this.get_businesstime_viz_datetime_url, payload)
	    }

		private user_telephone_get_all_url = `${this.env.service_url}/user/telephone/get_all`;
		user_telephone_get_all(payload){
			return this.http.post<any>(this.user_telephone_get_all_url, payload);
		}

		private remove_user_role_url = `${this.env.service_url}/remove_user_role`;
		remove_user_role(payload){
			return this.http.post<any>(this.remove_user_role_url, payload);
		}

		private user_telephone_details_update_url = `${this.env.service_url}/user/telephone/details/update`;
		user_telephone_details_update(payload){
			return this.http.post<any>(this.user_telephone_details_update_url, payload);
		}

		private user_telephone_details_get_url = `${this.env.service_url}/user/telephone/details/get`;
		user_telephone_details_get(payload){
			return this.http.post<any>(this.user_telephone_details_get_url, payload);
		}

		private get_bizorg_audit_url = `${this.env.service_url}/get/bizorg/audit`
		get_bizorg_audit(payload){
			return this.http.get<any>(this.process_get_req(this.get_bizorg_audit_url, payload));
		}

		private get_individual_audit_url = `${this.env.service_url}/get/individual/audit`
		get_individual_audit(payload){
			return this.http.get<any>(this.process_get_req(this.get_individual_audit_url, payload));
		}

		private get_all_task_users_url = `${this.env.service_url}/task/user/get_all`
		get_all_task_users(payload){
			return this.http.post<any>(this.get_all_task_users_url, payload);
		}

		private get_follow_up_template_url = `${this.env.service_url}/workflow/followup/get_all`
		get_follow_up_template(payload){
			return this.http.get<any>(this.process_get_req(this.get_follow_up_template_url, payload));
		}
		
		private create_new_follow_up_template_url=`${this.env.service_url}/workflow/followup/add`
		create_new_follow_up_template(payload: any) {
			return this.http.post<any>(this.create_new_follow_up_template_url, payload)
		}
		
		public update_follow_up_template_url= `${this.env.service_url}/workflow/followup/update`
		update_follow_up_template(payload:any){
			return this.http.post<any>(this.update_follow_up_template_url, payload)

		}

		private get_carry_on_tasks_url = `${this.env.service_url}/workflow/task/meta_info/get`
		public get_carry_on_tasks(payload) {
			return this.http.get<any>(this.process_get_req(this.get_carry_on_tasks_url, payload));
		}
		
		private get_workflow_followup_decision_data_url = `${this.env.service_url}/workflow/followup/decision/data`
		public get_workflow_followup_decision_data(payload){
			return this.http.get<any>(this.process_get_req(this.get_workflow_followup_decision_data_url, payload));
		}

		private get_workflow_status_change_log_url = `${this.env.service_url}/workflow/get_status_change`
		public get_workflow_status_change_log(payload){
			return this.http.post<any>(this.get_workflow_status_change_log_url, payload)
		}

		
		private create_da_task_url =`${this.env.service_url}/task/da/create`
		public create_da_task(payload){
			return this.http.post<any>(this.create_da_task_url, payload)
		}
		
		private get_task_created_by_user_url =`${this.env.service_url}/workflow/get_paginated_results/creator`
		public get_task_created_by_user(payload){
			return this.http.post<any>(this.get_task_created_by_user_url, payload)
		}

		private get_workflow_status_count_url = `${this.env.service_url}/workflow/status/count`
		public get_workflow_status_count(payload){
			 return this.http.post<any>(this.get_workflow_status_count_url,payload);
		}

		private get_intent_response_url = `http://localhost:5031/get_intent_response`
		public get_intent_response(payload){
			 return this.http.post<any>(this.get_intent_response_url,payload);
		}
		

	// Common Functions

		process_get_req(route, payload) {
			if(Object.keys(payload).length) {
				return `${route}?${this.object_to_query_param(payload)}`;
			}
			return `${route}`
		}
		object_to_query_param(obj) {
			let query_param = "";
			for (let key in obj) {
			query_param += key + "=" + obj[key] + '&'
			}
			return query_param.slice(0, -1)
		}

		async remove_particular_division( bus_id, div_id) {
			const payload = {
				'div_org_id': div_id,
				'biz_org_id': bus_id
			};
			const check_division = await this.remove_division(payload).toPromise();
			if (check_division.errCode == 0) {
				return true;
			} else {
				return false;
			}

		}

		//replace_empty_values
		replace_empty_values(res, keys, replace_with) {

			for(let i=0;i<res.length;i++){
				for(var j=0; j<keys.length; j++){
					if(res[i][keys[j]] == '' || res[i][keys[j]] == null){
						res[i][keys[j]] = replace_with;
					}
				}
			}

			return res;

		}

		filter_data_in_datatable(val, column_list, data) {

			let data_list = [];
			if (val) {
				val = val.trim().toLowerCase();
			} else {
				return data_list = [...data];
			}

			if (!column_list.length) {
				return;
			}

			const rows = data.filter(function (d) {
				for (let i = 0; i <= column_list.length; i++) {
					const column = column_list[i];
					if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
						return true;
					}
				}
			});
			data_list = rows;
			return data_list;
		}

		date_formatter(event){
			let new_date = '';
			for ( var key in event) {
			  if (key == 'day') {
				new_date+= event[key];
			  } else  if (key == 'month' || key == 'year'){
				new_date+= event[key]+ '-';
			  }
			}
			return new_date;
		}

		date_validation(start_date, end_date) {
			const sdate = new Date(start_date);
			const edate = new Date(end_date);
			return (sdate.getTime() < edate.getTime()) ? true : false;
		}

		date_validation_eq(start_date, end_date) {
			const sdate = new Date(start_date);
			const edate = new Date(end_date);
			return (sdate.getTime() <= edate.getTime()) ? true : false;
		}


		qry_builder_decision_to_backend_old(decision_data, data_type_map){
			var condition_map = {
				"AND": 'and',
				"OR": 'or',
				"NOT": 'not'
			};

			var operator_map = {
				"equal": '==',
				"not_equal": '!=',
				"less": '<',
				"less_or_equal": '<=',
				"greater": '>',
				"greater_or_equal": '>=',
				"is_null":"is_null",
				"is_not_null":"is_not_null",
				'in':'in',
				'not_in':'not_in',
				// 'between':'between',
				// 'not_between':'not_between',
				// 'begins_with':'begins_with',
				// 'not_begins_with':'not_begins_with',
				'contains':'contains',
				'not_contains':'not_contains',
				// 'ends_with':'ends_with',
				// 'not_ends_with':'not_ends_with',
				// 'is_empty':'is_empty',
				// 'is_not_empty':'is_not_empty',
			};

			// console.log(decision_data);
			var ret_val = {};
			var temp_list = [];
			var condition = decision_data['condition'];
			var rules = decision_data['rules'];
			var ret = {};
			var final_list = [];
			var d_type = 'str';

			for(var i=0; i<rules.length; i++){
				if(rules[i]['condition'] != undefined){
					ret = this.qry_builder_decision_to_backend_old(rules[i], data_type_map);
				} else {
					d_type = "str";
					if(data_type_map[rules[i]['id']]){
						d_type = data_type_map[rules[i]['id']];
					}
					temp_list.push({
						"operator": operator_map[rules[i]['operator']],
						"type": "str",	//	not required
						"data_type": data_type_map[rules[i]['id']],
						"op1": rules[i]['id'],
						"op2": rules[i]['value']
					});
				}
			}
			if(!$.isEmptyObject(ret)){
				final_list = temp_list.concat(ret);
			} else {
				final_list = temp_list;
			}

			ret_val[condition_map[condition]] = final_list;
			// console.log(condition);
			// console.log(final_list);
			// console.log(ret_val);
			// console.log("-------");
			// debugger
			return ret_val;
		}

		qry_builder_decision_to_backend(decision_data, data_type_map){
			var condition_map = {
				"AND": 'and',
				"OR": 'or',
				"NOT": 'not'
			};

			var operator_map = {
				"equal": '==',
				"not_equal": '!=',
				"less": '<',
				"less_or_equal": '<=',
				"greater": '>',
				"greater_or_equal": '>=',
				"is_null":"is_null",
				"is_not_null":"is_not_null",
				'in':'in',
				'not_in':'not_in',
				'between':'between',
				'not_between':'not_between',
				'begins_with':'begins_with',
				'not_begins_with':'not_begins_with',
				'contains':'contains',
				'not_contains':'not_contains',
				'ends_with':'ends_with',
				'not_ends_with':'not_ends_with',
				'is_empty':'is_empty',
				'is_not_empty':'is_not_empty',
			};

			var ret_val = {};
			var obj = {};

			if(decision_data.condition != undefined){
				ret_val[condition_map[decision_data.condition]] = []
				for(var i=0; i<decision_data.rules.length; i++){
					obj = this.qry_builder_decision_to_backend(decision_data.rules[i], data_type_map)
					ret_val[condition_map[decision_data.condition]].push(obj)
				}
				return ret_val;

			} else {
				return {
					"operator": operator_map[decision_data['operator']],
					"type": "str",	//	not required
					"data_type": data_type_map[decision_data['id']],
					"op1": decision_data['id'],
					"op2": decision_data['value']
				}
			}
		}
		
		//Quote To RFQ Workgroup Relation
		private add_rfq_to_quote_workgroup_url = `${this.env.service_url}/add_rfq_to_quote_workgroup`;
		add_rfq_to_quote_workgroup(payload) {
			return this.http.post<any>( this.add_rfq_to_quote_workgroup_url, payload);
		}
		private get_rfq_quote_map_url = `${this.env.service_url}/get_rfq_quote_map`;
		get_rfq_quote_map() {
			return this.http.post<any>( this.get_rfq_quote_map_url,{});
		}
		process_rule(decision_data,object_data,user_id){
			// console.log(this.lambda['equal'](1,2));
			let ret_val = true;
			let temp_list = [];
			let condition = decision_data['condition'];
			let rules = decision_data['rules'];

			for(let i=0; i<rules.length; i++){
				if(rules[i]['condition'] != undefined){
					temp_list.push(this.process_rule(rules[i],object_data,user_id));
				} else {
					if(rules[i]['id'] == "DCE_SPECIAL_KEY"){
						temp_list.push(this.process_dce_special_key(rules[i],user_id,object_data))
					}else{
						temp_list.push(this.lambda[rules[i]['operator']](object_data[rules[i]['id']],rules[i]['value']));
		          	}	
				}
			}
			for (let index = 0; index < temp_list.length; index++) {
				if(condition == "AND"){
					if(!temp_list[index]){
						ret_val =  false
						break;
					}else{
						ret_val =  true
					}
				}
				if(condition == "OR"){
					if(temp_list[index]){
						ret_val =  true
						break;
					}else{
						ret_val =  false
					}
				}				
			}
			return ret_val;
		}	
		process_dce_special_key(rule,user_id,object_data){
			if(rule['value']){
				let op = rule['value'].split('$$')
				if(op[1] == 'user_id'){
					return this.lambda[rule['operator']](object_data[op[0]],user_id);
				} else if (op[1] =='today')  {
					const today_string = this.formatDate(new Date());
					return this.lambda[rule['operator']](object_data[op[0]],today_string);
				}
			}
			return false;
		}
		onSortDT(event,data){
			const rows = [...data];
			const sort = event.sorts[0];
			rows.sort((a, b) => {
			  return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
			});
			return rows
		}

		sortByKey(array, key, order) {
			return array.sort(function(a, b) {
				var x = a[key]; var y = b[key];
				// if(x<y) {
				// 	return order == 'asc'? -1: 1
				// } else if (x>y) {
				// 	return order == 'asc'? 1: -1
				// } else {
				// 	return 0;
				// }
				if (order == 'asc') {
					return ((x < y) ? -1 : ((x > y) ? 1 : 0));
				} else if (order == 'desc') {
					return ((x < y) ? 1 : ((x > y) ? -1 : 0));
				}
			});
	}

		sortByKeyCaseInsensitive(array, key, order) {
			return array.sort(function(a, b) {
				var x = a[key].toLowerCase(); var y = b[key].toLowerCase();
				if (order == 'asc') {
					return ((x < y) ? -1 : ((x > y) ? 1 : 0));
				} else if (order == 'desc') {
					return ((x < y) ? 1 : ((x > y) ? -1 : 0));
				}
			});
	}

		// Pass data in array e.g ['select', 'input', 'textarea'];
		add_title_attribute(tag_list) {
			const html_tags = {
				'input': function(){
						$("input").each(function(){
							$(this).attr("title", $(this).val());
						});
				},
				'textarea': function() { 
						$("textarea").each(function(){
							$(this).attr("title", $(this).val());
						});
				},
				'select': function(){	
						$("select").each(function(){
							$(this).attr("title", $(this).find(":selected").text());
						});
				}
			}
			for(let i = 0;i< tag_list.length; i++) {
				setTimeout(() => {
					html_tags[tag_list[i]]();
				}, 200);
			}

		}
		onChanged($event){
			setTimeout(function(){
			  if(!$('.autocomplete-container').hasClass('active')){
			    $('.suggestions-container').hide()
			    $('.not-found').hide()
			  }else{
			    $('.suggestions-container').show()
			    $('.not-found').show()
			  }
			},10);
		}

		changeSortSettings(col,sort_type,_case) {
			localStorage.setItem(_case, JSON.stringify([{prop: col, dir: sort_type}]));
		}

		getSortSettings(_case) {
			if (localStorage.getItem(_case)) {
				return JSON.parse(localStorage.getItem(_case));
			}
		}

		formatDate(date) {
			var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();
	
			if (month.length < 2) 
					month = '0' + month;
			if (day.length < 2) 
					day = '0' + day;
	
			return [year, month, day].join('-');
		}
		process_selected_advice(selected_plan){
			let show_payload= {
				'basic' : [
					{'label':'Member National Id','value':selected_plan.national_id},
					{'label':'Member Full Name','value':selected_plan.mem_full_name},
					{'label':'Cell phone','value':selected_plan.comm_phone},
					{'label':'Email Address','value':selected_plan.comm_email},
					// {'label':'Advice Id','value':selected_plan.advice_id},
				],
				'client_objectives':[],
				'need_filters':[],
			}

			// Clien Objective
			if(selected_plan.client_objectives){

				if(selected_plan.client_objectives.deductibles['1']){
					show_payload['client_objectives'].push({'label':'Deductibles/ Co-Payments','value':'Pre-determined list of procedures'})
				}
				if(selected_plan.client_objectives.special_needs['0']){
					show_payload['client_objectives'].push({'label':'Special Needs','value':'Pregnancy'})
				}
				if(selected_plan.client_objectives.additional_needs != ''){
					show_payload['client_objectives'].push({'label':'Additional Needs','value':selected_plan.client_objectives.additional_needs})
				}
				let optD = ''
				for (let i in this.comObj.optical_dentistry) {
					if(selected_plan.client_objectives.optical_dentistry[i]){
						optD += this.comObj.optical_dentistry[i]+", "
					}
				}
				if(optD != ''){
					show_payload['client_objectives'].push({'label':'Optical/ Dentistry','value':optD})
				}
				let undW = ''
				for (let i in this.comObj._underwriting) {
					if(selected_plan.client_objectives.underwriting[i]){
						undW += this.comObj._underwriting[i]+", "
					}
				}
				if(undW != ''){
					show_payload['client_objectives'].push({'label':'Under Writings','value':undW})
				}
				let dTd = ''
				for (let i in this.comObj.day_to_day) {
					if(selected_plan.client_objectives.day_to_day[i]){
						dTd += this.comObj.day_to_day[i]+", "
					}
				}
				if(dTd != ''){
					show_payload['client_objectives'].push({'label':'Day-to-Day Cover','value':dTd})
				}
			}

			// Need Filters
			if(selected_plan.need_filters){
				if(selected_plan.need_filters.no_of_spouses){
					show_payload['need_filters'].push({'label':'No. of Spouses','value':selected_plan.need_filters.no_of_spouses})
				}
				if(selected_plan.need_filters.no_of_children){
					show_payload['need_filters'].push({'label':'No. of Children','value':selected_plan.need_filters.no_of_children})
				}
				if(selected_plan.need_filters.hospital){
					show_payload['need_filters'].push({'label':'Hospital','value':this.comObj.hospital[selected_plan.need_filters.hospital]})
				}
				if(selected_plan.need_filters.no_of_adult_dependants){
					show_payload['need_filters'].push({'label':'No. of Adult Dependants','value':selected_plan.need_filters.no_of_adult_dependants})
				}
				if(selected_plan.need_filters.no_of_dependents){
					show_payload['need_filters'].push({'label':'No. of Dependants','value':selected_plan.need_filters.no_of_dependents})
				}
				if(selected_plan.need_filters.day_to_day_cover){
					show_payload['need_filters'].push({'label':'Day-to-Day Cover','value':this.comObj.day_to_day_cover[selected_plan.need_filters.day_to_day_cover]})
				}
				if(selected_plan.need_filters.chronic_conditions){
					show_payload['need_filters'].push({'label':'Chronic Condition','value':selected_plan.need_filters.chronic_conditions?'Yes':'No'})
				}
				if(selected_plan.need_filters.coverage){
					show_payload['need_filters'].push({'label':'Coverage','value':this.comObj.coverage[selected_plan.need_filters.coverage]})
				}
				if(selected_plan.need_filters.day_to_day_extent){
					show_payload['need_filters'].push({'label':'Day-to-Day extent','value':this.comObj.day_to_day_extent[selected_plan.need_filters.day_to_day_extent]})
				}
			}
			return show_payload


		}

		jump_to_error() {
			setTimeout(() => {
				let invalidFields = [].slice.call(document.getElementsByClassName('app-error-class'));
				// console.log(invalidFields);
				$('html, body').animate({
					scrollTop: $(invalidFields[0]).offset().top - 130
				}, 500);
			}, 100);
		}

		select_row(dt_object_list,value_to_compare,key_to_compare){
		    if(dt_object_list.length > 0){
		      for (var i = dt_object_list.length - 1; i >= 0; i--) {
		        if(dt_object_list[i][key_to_compare] == value_to_compare){
		          return [dt_object_list[i]]
		        }
		      }
		    }
		    return []
			}

			autocomplete_value_exist(data_list,search_keyword, formNameValue) {
				if (typeof(formNameValue) == 'object'){
					for (let i =0 ;i< data_list.length;i++) {
						if (data_list[i][search_keyword] == formNameValue[search_keyword]) {
							return {status: true,value: formNameValue};
						}
					}
				} else if (typeof(formNameValue) == 'string') {
					for (let i =0 ;i< data_list.length;i++) {
						if (data_list[i][search_keyword] == formNameValue) {
							return {status: true,value: formNameValue};
						}
					}
				}
				return {status: false,value: null};
			}

	download_files(dataurl, target, filename) {
		var a = document.createElement("a");
		a.target = target;
		a.href = dataurl;
		a.setAttribute("download", filename);
		a.click();
	}

	date_to_string_for_month(event) {
		const y = event.getFullYear(), m = event.getMonth();
		const firstdate = new Date(y, m, 1);
		const lastdate = new Date(y, m + 1, 0);
		const firstdate_string = firstdate.getFullYear() + '-' + (firstdate.getMonth() + 1) + '-' +firstdate.getDate();
		const lastdate_string = lastdate.getFullYear() + '-' + (lastdate.getMonth() + 1) + '-' +lastdate.getDate();
		return [firstdate_string, lastdate_string];
	}

	date_to_string_for_week(date) {
		var first = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
		var last = first + 6; // last day is the first day + 6
		var firstday = new Date(date.setDate(first));
		var lastday = new Date(date.setDate(last));
		const firstdate_string = firstday.getFullYear() + '-' + (firstday.getMonth() + 1) + '-' +firstday.getDate();
		const lastdate_string = lastday.getFullYear() + '-' + (lastday.getMonth() + 1) + '-' +lastday.getDate();
		return [firstdate_string, lastdate_string];
	  }
	
	  date_to_string_for_day(date) {
		const firstdate_string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +date.getDate();
		const lastdate_string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +date.getDate();
		return [firstdate_string, lastdate_string];
	  }
	datatable_empty_msg(datatable_records, val, datatable_msg){
      if(datatable_records.length == 0){
        if(val == ''){
          datatable_msg.emptyMessage="There are no records."
        }else{
          datatable_msg.emptyMessage="No records matched for entered search criteria. Please try a new search."
        }	
      }
    }
	
	phone_number_pattern() {
		return {
			'Z': {
			  pattern: new RegExp('\(0)')
			},
			'N': {
			  pattern: new RegExp('\(6|7|8)')
			},
			'0': {
			  pattern: new RegExp('\\d')
			}
		};
	}

	jsonToString(jsondata, field_map, operator_map) {
		let list = [];
		let values_of_value = []
		let value_for_in_operator = ''
		for (let key in jsondata["rules"]) {
			let obj = jsondata["rules"][key];
	
		  if (obj.hasOwnProperty('condition')) {
			list.push("("+ this.jsonToString(obj, field_map, operator_map) +")");
		  }
		  else {
			if (obj["operator"] === "in") {
				obj["value"] = obj["value"].toString()
				let temp_list = obj["value"].split("|");
				temp_list = "".concat(temp_list);

				value_for_in_operator = temp_list
				temp_list = "(" + temp_list + ")";
				obj["value"] = temp_list;
			}
			else if(obj["operator"] == "is_not_null" || obj["operator"]== "is_null" || obj["operator"] == "is_not_empty" || obj["operator"] == "is_empty"){
				obj["value"] = ""
			}
			else if(obj["operator"] == "not_between" || obj["operator"] == "between"){
				values_of_value = obj["value"]
				obj["value"] = "["+ obj["value"] +"]"
			}
			
			list.push(field_map[obj["field"]] + " " + operator_map[obj["operator"]] + " " + obj["value"]);

			if(obj["operator"] == "not_between" || obj["operator"] == "between"){
				obj["value"] = values_of_value
			}
			else if(obj["operator"] === "in"){
				obj["value"] = value_for_in_operator
			}
			
		  }
		}
		let condition = " " + jsondata["condition"] + " ";
		this.final_string =  list.join(condition);
		//For Indentation
		this.final_string = this.final_string.split('(').join('\n(')

		return this.final_string
	  }
	

	mapper(data_array, key_key, label_key){
		var newObj = {}
		for (var i = 0; i < data_array.length; i++){
			Object.assign(newObj, {[data_array[i][key_key]] : data_array[i][label_key]});
		}
		return newObj;
	}
	
	get_recursive_data(data_list_rules,json_data){
		let temp_data=false
		if(data_list_rules){
			for(let i=0;i<data_list_rules.length;i++){
				if(data_list_rules[i].rules){
					if(this.get_recursive_data(data_list_rules[i].rules,json_data )){
						return true
					}
				}else {
					if(json_data.user_value== "" && json_data.user_field== ""){
						temp_data=true
						break;
					}
					else if(json_data.user_field!= "" && json_data.user_value== "" && data_list_rules[i].field != null && data_list_rules[i].field==json_data.user_field){
						temp_data=true
						break;
					}
					else if(json_data.user_value!= "" && json_data.user_field== "" && data_list_rules[i].value != null && data_list_rules[i].value.toLowerCase()==json_data.user_value.trim().toLowerCase()){
						temp_data=true
						break;
					}
					else if(json_data.user_value!= "" && json_data.user_field!= "" && data_list_rules[i].value != null && data_list_rules[i].value.toLowerCase()==json_data.user_value.trim().toLowerCase()  && data_list_rules[i].field != null && data_list_rules[i].field==json_data.user_field){
						temp_data=true
						break;
					}
					else{
						temp_data=false
					} 
				}
			}
		}else{
			if(json_data.user_value== "" && json_data.user_field== ""){
				temp_data=true
			}else {
				temp_data=false
			}
			
		}
		return temp_data
	}

	filter_data_in_table(data_list, json_data, rule_type){
		const temp_list=[]
		const new_data_list=data_list.filter(data=>data.object_type==json_data.user_rule_type)
		for(let i=0;i<new_data_list.length;i++){
			switch(rule_type) {
				case 'action_rule':
				  	if(this.get_recursive_data(new_data_list[i].rules_json.rules,json_data)==true){
						temp_list.push(new_data_list[i])
					}
				  	break;
				case 'dispatch_rule':
				  	if(this.get_recursive_data(new_data_list[i].dispatch_rules_frontend.rules,json_data)==true){
						temp_list.push(new_data_list[i])
					}
				  	break;
				case 'notification_rule':
					if(this.get_recursive_data(new_data_list[i].notification_rules_front_end.rules,json_data)==true){
						temp_list.push(new_data_list[i])
					}
					break;
			}
		}
		return temp_list
	}

	get_initials(name: string): string {
		const nameParts = name.trim().split(' ');
	
		if (nameParts.length === 1) {
		  // If only the first name is provided, return the capitalized first letter
		  return nameParts[0].charAt(0).toUpperCase();
		} else if (nameParts.length >= 2) {
		  // If first and last name (or middle name) are provided, return the first letters of the first and last name
		  const firstInitial = nameParts[0].charAt(0).toUpperCase();
		  const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
		  return firstInitial + lastInitial;
		}
	
		return '';
	  }
}
