import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable } from 'rxjs';
import { tap, delay, takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/dce-service/shared.service';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { isNumeric } from "rxjs/util/isNumeric"

declare var $: any;

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit, AfterViewInit {
  @Output() dialer_events: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('removeNumBtn', { static: false }) removeNumBtn: ElementRef;
  @ViewChild('sipNumInput', { static: false }) sipNumInput: ElementRef;

  dialer_config = {
    dialed_num: '',
    sip_uri: null,
  }
  mouseDown$: Observable<any>;
  mouseUp$: Observable<any>;

  constructor(
    public _shared: SharedService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.mouseDown$ = fromEvent(this.removeNumBtn.nativeElement, 'mousedown').pipe(tap());
    this.mouseUp$ = fromEvent(this.removeNumBtn.nativeElement, 'mouseup').pipe(tap());
  }


  trim_num() {
    this.dialer_config.dialed_num = this.dialer_config.dialed_num.replace(/\s+/g, '');
  }

  append_num(val) {
    this.dialer_config.dialed_num = this.dialer_config.dialed_num + val;
    this.trim_num();
    // console.log(this.dialer_config.dialed_num, this.dialer_config.dialed_num);
  }

  erase_num() {
    this.dialer_config.dialed_num = '';
  }

  remove_last_pos() {
    let val = JSON.parse(JSON.stringify(this.dialer_config.dialed_num));
    const num = parseInt(val);
    if (num) {
      const final_num = Math.floor(num / 10).toString(); // will remove last digit of num
      this.dialer_config.dialed_num = final_num == '0' ? '' : final_num;
    } else {
      this.dialer_config.dialed_num = val.slice(0, -1); // will remove last character of str.
    }
  }

  on_remove_num() {
    let is_hold = false;
    // passed delay time and condtion as operator.
    // hold 1 second
    this.mouseDown$.pipe(
      delay(1000),
      takeUntil(this.mouseUp$)).subscribe(res => {
        // console.log('long press remove: hold');
        is_hold = true;
        this.erase_num();
      });
    if (!is_hold) {
      this.remove_last_pos();
    }
  }

  place_call() {
    console.log('calling num:', this.dialer_config.dialed_num);
    if (this._shared.sip_storage.telephony_keys && this._shared.sip_storage.telephony_keys.outbound_call_prefix) {
      if (this.validate_num()) {
        const outbound_num_with_prefix = this._shared.sip_storage.telephony_keys.outbound_call_prefix + this.dialer_config.dialed_num;
        this.dialer_events.emit({ type: 'sip_general', case: 'place_call', data: { number: outbound_num_with_prefix } });
      }
    } else {
      this._toastr.warning('', 'Outbound Call Prefix does not exist!', this._toastrUtility.basic_configuration);
    }
  }

  transfer_call() {
    console.log('tranfer num is:', this.dialer_config.dialed_num);
    if (this.validate_num()) {
      this.dialer_events.emit({ type: 'sip_general', case: 'transfer_call', data: { number: this.dialer_config.sip_uri } });
    }
  }

  toggle_dialer() {
    this.dialer_events.emit({ type: 'sip_general', case: 'toggle_dialer', data: null });
  }

  validate_num() {
    this.extract_sip_uri_from_dial_ext();
    if (!this.is_num_valid()) {
      this._toastr.warning('', 'Please enter a valid phone number/extension', this._toastrUtility.basic_configuration);
      return false;
    }
    return true;
    // if (this.dialer_config.sip_uri == null) {
    //   this._toastr.warning('', 'Extension does not exist!', this._toastrUtility.basic_configuration);
    //   return false;
    // }
  }

  is_num_valid() {
    const isValid = (this.dialer_config.dialed_num != null && isNumeric(this.dialer_config.dialed_num) && this.dialer_config.dialed_num.toString().length == 10);
    return isValid;
  }

  extract_sip_uri_from_dial_ext() {
    for (let i = 0; i < this._shared.sip_storage.all_telephone_user.length; i++) {
      const index: any = this._shared.sip_storage.all_telephone_user[i];
      if (this.dialer_config.dialed_num == index.sip_ext) {
        this.dialer_config.sip_uri = index.sip_url;
        break;
      }
      this.dialer_config.sip_uri = null;
    }
  }

}
