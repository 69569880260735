import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EnvService } from 'src/app/dce-service/env.service';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  constructor(private _env: EnvService) {}

  transform(value: any, ...args: any[]): any {
    var datePipe = new DatePipe("en-US");
    var format = this._env.date_format && this._env.date_format != ''? this._env.date_format: 'dd MMM yyyy HH:mm';
    var formatted_value = datePipe.transform(value, format)
    return formatted_value;
  }

}
