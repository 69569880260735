import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
// import { colorConfig, curStrings, showingProperties, surveyOptions, surveyStrings } from '../../../generic-surveyjs/generic-surveyjs.model';
@Component({
  selector: 'app-knowledge-content-default',
  templateUrl: './knowledge-content-default.component.html',
  styleUrls: ['./knowledge-content-default.component.scss']
})
export class KnowledgeContentDefaultComponent implements OnInit, OnChanges {

  @Input() data : any = {};
  modules_readonly = {toolbar: false };


  survey_config = {
    survery_mode: 'write_mode', //creator/read_mode
    survey_questionaire: null,
    survey_answers: null,
    survey_identifier: 'default',
    // color_config: colorConfig['default'],
    // surveyOptions: surveyOptions['default'],
    // surveyStrings: surveyStrings['default'],
    // curStrings: curStrings['default'],
    // showingProperties: showingProperties['default']
  }

  constructor(
    private elRef: ElementRef
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    const audio_player = this.elRef.nativeElement.querySelector('audio');
    const video_player = this.elRef.nativeElement.querySelector('video')
    if(audio_player && changes.data.currentValue.content_type == 'audio'){
      audio_player.load();
    }
    if(video_player && changes.data.currentValue.content_type == 'video'){
      video_player.load();
    }
    if(changes.data && changes.data.currentValue.content_type == 'call-script'){
      this.survey_config.survey_questionaire = changes.data.currentValue.body_json;
    }
    if(changes.data && changes.data.currentValue.content_type == 'link'){
      this.convert_link(changes.data.currentValue.body_text);
    }
  }

  convert_link(data){
    return `<a href=${data} target=_blank>${data}</a>`
  }

  show_doc_data(document_link) {
    window.open(document_link, '_blank');
  }

}
