import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisConfigService {

  constructor() { }

  options = {};

  config_json = {
    'view': {
      options: this.flowchart_view_options(),
      event_list: ['doubleClick'],
      is_manipulation: false
    },
    'edit': {
      options: this.flowchart_view_options(),
      event_list: ['doubleClick','hold'],
      is_manipulation: true
    }
  }

  flowchart_view_options() {

    this.options = {
      interaction: {
        zoomView: true,
        dragView: true,
        dragNodes: true
      },
      nodes: {
        shape: 'circle',
        color: {
          highlight: {
            border: '#262626',
            background: '#d1d0d3'
          }
        },
      },
      layout: {
        hierarchical: {
          enabled: true,
          levelSeparation: 400,
          nodeSpacing: 150,
          treeSpacing: 100,
          direction: "LR", // UD, DU, LR, RL
          sortMethod: "directed", // hubsize, directed
         },
      },
      edges: {
        arrows: {
          to: { enabled: true }
        },
        color: {
          color: '#333333',
          inherit: false
        },
        smooth: {
          enabled: false
        }
      },
      physics: true,
      locales: {},
      manipulation: false
    };

    return this.options;

  }

  flowchart_edit_options() { //  VIS with Updates

    this.options = {
      interaction: {
        zoomView: true,
        // dragView: false
      },
      nodes: {
        shape: 'circle',
        color: {
          highlight: {
            border: '#262626',
            background: '#d1d0d3'
          }
        },
      },
      edges: {
        arrows: {
          to: { enabled: true }
        },
        color: {
          color: '#333333',
          inherit: false
        },
        smooth: {
          enabled: false
        }
      },
      layout: {
        hierarchical: {
          enabled: true,
          levelSeparation: 400,
          nodeSpacing: 150,
          treeSpacing: 100,
          direction: "LR", // UD, DU, LR, RL
          sortMethod: "directed", // hubsize, directed
         },
      },
      physics: {
        barnesHut: {
          gravitationalConstant: -3250,
          springLength: 100
        }
      },
      // locales: {},
      manipulation: {}
    };
    return this.options;
  }

  flowchart_hierarchy_options() {

  this.options = {
    interaction: {
      zoomView: true,
      dragView: true,
      dragNodes: true
    },
    layout: {
      hierarchical: {
        direction: "UD",
        sortMethod: "directed",
        levelSeparation: 200,
        nodeSpacing: 200
      }
    },
    nodes: {
      shape: 'ellipse',
      color: {
        highlight: {
          border: '#262626',
          background: '#d1d0d3'
        }
      },
    },
    edges: {
      arrows: {
        to: { enabled: true }
      },
      color: {
        color: '#333333',
        inherit: false
      },
      smooth: {
        enabled: false
      }
    },
    physics: {
      enabled: false
    },
    locales: {},
    manipulation: false
  };

  return this.options;
  }
}
