import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';

import { httpInterceptorProviders } from './dce-service/index';
// import { CaseManagementSetupComponent } from './dce-views/case-management-setup/case-management-setup.component';
// import { ClassificationMapComponent } from './dce-views/case-management-setup/classification-map/classification-map.component';
// import { TimeMapComponent } from './dce-views/case-management-setup/time-map/time-map.component';
// import { CaseEmailComponent } from './dce-views/case-management-setup/case-email/case-email.component';
// import { AngularSplitModule } from 'angular-split';
import { Resolver } from './resolver';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

@NgModule({
  declarations: [
    AppComponent,
    // CaseManagementSetupComponent,
    // ClassificationMapComponent,
    // TimeMapComponent,
    // CaseEmailComponent
    // ClassificationComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    // AngularSplitModule.forRoot(),
    SnotifyModule,
    AppRoutingModule
  ],
  providers: [
    Resolver, 
    httpInterceptorProviders, 
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// For testing