import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
import { SocketService } from 'src/app/dce-service/socket.service';
import * as prettyMs from 'pretty-ms';
import { SideContainerComponent } from 'src/app/dce-shared/side-container/side-container.component';
import { EnvService } from 'src/app/dce-service/env.service';
import { ToastrService } from 'ngx-toastr';
import { ChatLinkingService } from 'src/app/dce-service/chat-linking.service';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { Router } from '@angular/router';
import { ChatbotListComponent } from '../../chatbot-list-composer-v1/chatbot-list/chatbot-list.component';
declare var $: any
@Component({
  selector: 'app-digital-assitant-bot',
  templateUrl: './digital-assitant-bot.component.html',
  styleUrls: ['./digital-assitant-bot.component.scss']
})
export class DigitalAssitantBotComponent implements OnInit {

  @ViewChild(SideContainerComponent, { static: false }) sideContainer: SideContainerComponent;
  @ViewChild('ChatBotList', { static: false }) chatbotList: ChatbotListComponent
  @ViewChild('dragDiv', { static: false }) dragDiv: ElementRef;
  @Input() visibility = false;
  @Input() workflow_status_count =null;
  @Output() visibility_emitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() task_count_refresh: EventEmitter<any> = new EventEmitter<any>();
  
  inputValue = ''
  da_input_btn_loader = false;
  show_chat_data = true;

  user_data = {
    user_name: this._env.live_chat_agent_name
  }
  chat_list = [
    {
      "from":"bot",
      "message":"Hi, how may i assist you ?",
      "time":this.get_formatted_local_time()
    },   
  ];
  chat_process_id = this._env.chatbot_conf.chat_process_id
  chat_list_height = '60vh';
  
  constructor(
    // public _liveChatService: LiveChatService,//chat store
    private socket: SocketService,
    private _env: EnvService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    public _chatLinkingService: ChatLinkingService,
    private _commonService: CommonService,
    private _router : Router,
    private _socketService: SocketService,
  ) { }

  ngOnInit() {
    $('#drag').draggable({
      handle: '#chat-popup-handler',
      containment: "window", 
      scroll: false
    });

    $("#drag").resizable({
      handles: "n, s, w, ne, nw, sw, se",
      minHeight: 450,
      minWidth: 400,
      // maxHeight: $(window).height() * 0.7,
      // maxWidth: $(window).width() * 0.7,
      ghost:false,
      // containment: "#chat-container",
      resize: (event, ui) => {
        // Get the new height and width
        const newHeight = ui.size.height;
  
        // You can also do something with the height here
        this.onResize(newHeight);
      }
    });
    this.socket_subscribe();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.visibility) {
      this.scroll_to_bottom();
      this.inputValue = '';
      this.show_chat_data= true;
    }
  }

  scroll_to_bottom(){
    if(this.chatbotList){
      this.chatbotList.scroll_to_bottom();
    }
  }
  ngAfterViewInit(): void {
  }

  onResize(height){
    this.chat_list_height = height.toString() + "px - 58px";
    this.scroll_to_bottom();
  }


  toggle_chat() {
    if (this.show_chat_data) {
      $("#drag").resizable("disable");
      $("#drag").css({ right: '10px', top: 'calc(100vh - 90px)', left: ''});
  
    } else {
      $("#drag").resizable("enable");
      $("#drag").css({ top:'15vh',left:'10vw', right: ''});
    }
    this.show_chat_data = !this.show_chat_data;
  }
  
  da_bot_visibility(){
        this.visibility_emitter.emit({data:false});
  }

  handle_search_input() {
    this.create_da_task(this.inputValue);
  }

  create_da_task(search_text) {
    this.da_input_btn_loader = true;

    this.chat_list.push(
       {
         "from":"user",
         "message":this.inputValue,
         "time":this.get_formatted_local_time()
       }
    )

    let payload = { "query": search_text };
    this._commonService.create_da_task(payload).subscribe(res => {
      if (res.errCode == 0) {
        this.inputValue = '';
        this._toastr.success('', 'Task created sucessfully', this._toastrUtility.basic_configuration);
      }
      this.chat_list.push(
        {
          "from":"bot",
          "message":res.msg,
          "time":this.get_formatted_local_time(),
        }
      )
      this.da_input_btn_loader = false;
    });
  }

  navigateTo(_case){
    this._router.navigate([`/task/app-digital-task/${_case}`]);
  }
  
  get_formatted_local_time(): string {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true // true for 12-hour format with AM/PM
    };
    return now.toLocaleTimeString(undefined, options);
  }
  
  socket_subscribe() {
    this._socketService.get_da_data_out_all_events().subscribe(res => {
      console.log(res);
      switch (res.event_type) {
        case 'join_room':
          console.log("joining room")
          break;
        case 'leave_room':
          break;
        case 'wf_obj_actions':
          console.log(res);
          this.task_count_refresh.emit({"data":"true"});
          break;
        case 'disconnect':
          console.log("disconnect")
      }


    })
  }


  chat_emitter(event){

  }





}
