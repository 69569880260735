import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
type input_type = 'input' | 'textarea'
@Component({
  selector: "app-clipboard-key-parser",
  templateUrl: "./clipboard-key-parser.component.html",
  styleUrls: ["./clipboard-key-parser.component.scss"],
})
export class ClipboardKeyParserComponent implements OnInit {
  @Input() separator: string = "";
  @Input() key_list = [];
  @Input() placeholder: string = "Paste here the information to process";
  @Input() input_type:input_type = 'textarea';
  @Output() clipboard_events: EventEmitter<any> = new EventEmitter<any>();

  clipboard_input: string = "";

  constructor() {}

  ngOnInit() {}

  clipboard_text_parser() {
    const extracted_values_list = this.clipboard_input.split(this.separator).map(item=>item.trim());
    if(this.key_list.length){
      if (extracted_values_list.length === this.key_list.length) {
        const key_dict = {};
        extracted_values_list.map((value, index) => {
          key_dict[this.key_list[index]] = value.trim();
          return key_dict;
        });
        this.emit_succcess_text_parser(key_dict);
        this.clear_clipboard();
      } else {
        this.emit_err_text_parser();
        this.clear_clipboard();
      }
    }else{
      this.emit_succcess_text_parser(extracted_values_list);
      this.clear_clipboard();
    }
  }

  emit_succcess_text_parser(key_dict) {
    this.clipboard_events.emit({
      type: "parsing_text_success",
      datarec: key_dict,
    });
  }

  emit_err_text_parser() {
    this.clipboard_events.emit({
      type: "parsing_text_err",
      datarec: {},
    });
  }

  clear_clipboard(){
    setTimeout(() => {
      this.clipboard_input = '';
    }, 1); // gives time for template binding render
  }

}
