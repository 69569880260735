import { Injectable } from '@angular/core';
import { LiveChatService } from './live-chat.service';

type roleType = '' | 'Individual' | 'Organisation' | 'poller_biz'
interface linkObject {
  case_id: string; // for case details
  case_table_id: number; // for case details
  case_title: string; // for case details
  case_channel: string; // for case details
  case_status: string; // for case details
  classification_l1: string; // for case details
  classification_l2: string; // for case details
  classification_l3: string; // for case details
  classification_l4: string; // for case details
  creator_full_name: string; // for case details
  owner_full_name: string; // for case details
  case_createtime: string; // for case details
  case_lastchange_time: string; // for case details
  role_type: roleType; // for party 1 only

  org_biz_id: string; // for party1 organisation
  org_company_name: string; // for party1 organisation
  org_registration_number: string; // for party1 organisation
  org_trading_name: string; // for party1 organisation
  org_uifrefno_biz: string; // for party1 organisation
  org_email_address1: string; // for party1 organisation
  org_phone_number1: string; // for party1 organisation
  org_ministerial_status: string; // for party1 organisation

  ind_full_name: string;  // for party1 individual
  ind_id: string;  // for party1 individual
  ind_idtype: string;  // for party1 individual
  ind_dob: string;  // for party1 individual
  ind_idnumber: string;  // for party1 individual
  ind_passport_number: string;  // for party1 individual
  ind_uifrefno: string;  // for party1 individual
  ind_email_address1: string;  // for party1 individual
  ind_phone_number1: string;  // for party1 individual
  ind_ministerial_status: string;  // for party1 individual
  ind_asylum_seeker_no: string;    // for party1 individual
}

@Injectable({
  providedIn: 'root'
})
export class ChatLinkingService {

  private selected_object: linkObject = this.empty_selected_object();

  constructor(
    private _liveChatService: LiveChatService
  ) { }

  set_and_parse_case_data(data: any) {
    let parsed_data: linkObject;
    if(data == null) {
      parsed_data = this.empty_selected_object();
      const sessionIndex = this._liveChatService.live_chat_sessions.findIndex(session => session.session_id == this._liveChatService.selected_session.session_id);
      if(sessionIndex != -1) {
        this._liveChatService.live_chat_sessions[sessionIndex].current_screen_mode = 'chat';
      }
    } else {
      parsed_data = {
        case_table_id: data.case_table_id,
        case_id : data.case_id,
        case_title: data.case_title,
        case_channel:data.case_channel,
        case_status:data.case_status,
        classification_l1:data.classification_l1,
        classification_l2:data.classification_l2,
        classification_l3:data.classification_l3,
        classification_l4:data.classification_l4,
        creator_full_name: data.creator_full_name,
        owner_full_name: data.owner_full_name,
        case_createtime: data.case_createtime,
        case_lastchange_time: data.case_lastchange_time,
        role_type: data.party1_role_type,

        org_biz_id: data.party1_biz_id,
        org_company_name: data.party1_company_name,
        org_registration_number: data.party1_registration_number,
        org_trading_name: data.party1_trading_name,
        org_uifrefno_biz: data.party1_uifrefno_biz,
        org_email_address1: data.party1_email_address1_biz,
        org_phone_number1: data.party1_phone_number1_biz,
        org_ministerial_status: data.party1_ministerial_status_biz,
        
        ind_full_name: data.party1_full_name,
        ind_id: data.party1_ind_id,
        ind_idtype: data.party1_idtype,
        ind_dob: data.party1_ind_dob,
        ind_idnumber: data.party1_idnumber,
        ind_passport_number: data.party1_passport_num,
        ind_uifrefno: data.party1_uifrefno_ind,
        ind_email_address1: data.party1_email_address1_ind,
        ind_phone_number1: data.party1_phone_number1_ind,
        ind_ministerial_status: data.party1_ministerial_status_ind,
        ind_asylum_seeker_no: data.party1_asylum_seeker_no_ind,
      }
    }
    this.set_selected_object(parsed_data);
  }

  private set_selected_object(data: linkObject) {
    this.selected_object = data
  }

  public get get_selected_object(): linkObject {
    return this.selected_object
  }

  private empty_selected_object (): linkObject {
    return {
      case_table_id: null,
      case_id : '',
      case_title: '',
      case_channel: '',
      case_status: '',
      classification_l1: '',
      classification_l2: '',
      classification_l3: '',
      classification_l4: '',
      creator_full_name: '',
      owner_full_name: '',
      case_createtime: '',
      case_lastchange_time: '',
      role_type: '',
      org_biz_id: '',
      org_company_name: '',
      org_registration_number: '',
      org_trading_name: '',
      org_uifrefno_biz: '',
      org_email_address1: '',
      org_phone_number1: '',
      org_ministerial_status: '',
      ind_full_name: '',
      ind_id: '',
      ind_idtype: '',
      ind_dob: '',
      ind_idnumber: '',
      ind_passport_number: '',
      ind_uifrefno: '',
      ind_email_address1: '',
      ind_phone_number1: '',
      ind_ministerial_status: '',
      ind_asylum_seeker_no: '',
    }
  }
}
