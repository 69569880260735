import { Component, AfterContentInit, ContentChildren, QueryList, EventEmitter, Output, Input } from '@angular/core';
import { WizardStepComponent } from '../wizard-step/wizard-step.component';
declare var $:any;

@Component({
  selector: 'form-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class WizardComponent implements AfterContentInit {

  @ContentChildren(WizardStepComponent)
  wizardSteps: QueryList<WizardStepComponent>;

  private _steps: Array<WizardStepComponent> = [];
  private _isCompleted = false;

  @Input() alignItemHorizontal?:boolean = false;
  @Input() showNavBtn?:boolean = false;

  navBtnRequired = false;
  @Output()
  onStepChanged: EventEmitter<WizardStepComponent> = new EventEmitter<WizardStepComponent>();

  constructor() { }

  ngAfterContentInit() {
    this.wizardSteps.forEach(step => this._steps.push(step));
    this.steps[0].isActive = true;
    this.onResize(null)
   }

   onResize(event) {
    setTimeout(() => {
      const nav_item_list_width = document.getElementById('nav-item-list').scrollWidth;
      const nav_item_all_width = document.getElementById('nav-item-all').scrollWidth;
      if(nav_item_list_width > nav_item_all_width) {
        this.navBtnRequired = true;
      } else {
        this.navBtnRequired = false;
      }
    },100)
  }

  get steps(): Array<WizardStepComponent> {
    return this._steps.filter(step => !step.hidden);
  }

  get isCompleted(): boolean {
    return this._isCompleted;
  }

  get activeStep(): WizardStepComponent {
    return this.steps.find(step => step.isActive);
  }

  set activeStep(step: WizardStepComponent) {
    if (step !== this.activeStep && !step.isDisabled) {
      this.activeStep.isActive = false;
      step.isActive = true;
      this.onStepChanged.emit(step);
    }
  }

  public get activeStepIndex(): number {
    return this.steps.indexOf(this.activeStep);
  }

  get hasNextStep(): boolean {
    return this.activeStepIndex < this.steps.length - 1;
  }

  get hasPrevStep(): boolean {
    return this.activeStepIndex > 0;
  }

  public goToStep(step: WizardStepComponent): void {
    if (!this.isCompleted) {
      this.activeStep = step;
    }
  }

  public next(): void {
    if (this.hasNextStep) {
      const nextStep: WizardStepComponent = this.steps[this.activeStepIndex + 1];
      this.activeStep.onNext.emit();
      nextStep.isDisabled = false;
      this.activeStep = nextStep;
    }
  }

  public previous(): void {
    if (this.hasPrevStep) {
      const prevStep: WizardStepComponent = this.steps[this.activeStepIndex - 1];
      this.activeStep.onPrev.emit();
      prevStep.isDisabled = false;
      this.activeStep = prevStep;
    }
  }

  public complete(): void {
    this.activeStep.onComplete.emit();
    this._isCompleted = true;
  }

  scroll(direction) {
    const nav_item_list_width = document.getElementById('nav-item-list').scrollWidth;
    const nav_item_all_width = document.getElementById('nav-item-all').scrollWidth;

    // const sc = Math.floor(nav_item_list_width/nav_item_all_width);

    if(direction == 'left') {
      const w = nav_item_all_width -48;// 48 is extra margin
      // document.getElementById('nav-item-list').scrollLeft -= w;
      // if(this.scroll_count >= sc) {

        $('#nav-item-list').animate({
          scrollLeft: "-=" + w + "px"
        }, "slow", function() {

        });
      // }
    } else if(direction == 'right') {
      const w = nav_item_all_width - 48; // 48 is extra margin
      // document.getElementById('nav-item-list').scrollLeft += w;

      // if(this.scroll_count < sc) {
        // this.scroll_count++;
        $('#nav-item-list').animate({
            scrollLeft: "+=" + w + "px"
          }, "slow", function() {            
        });
      // }
    }

  }
  scroll_to_start() {
    $('#nav-item-list').animate({
        scrollLeft: "0px"
      }, "slow", function() {
    });
  }
}
