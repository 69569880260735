import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../../dce-service/toastr-utility.service';
@Component({
  selector: 'app-ongoing-chat-bubble',
  templateUrl: './ongoing-chat-bubble.component.html',
  styleUrls: ['./ongoing-chat-bubble.component.scss']
})
export class OngoingChatBubbleComponent implements OnInit {
  @Input() message:any="message"
  @Input() user_data:any;

  is_show:boolean = false;

  message_type = {
    "TEXT": "text",
    'DOCUMENT':"document",
    'NOTIFICATION':"notification",
    'FOOTER':"footer"
  }
  constructor(
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
  ) { }

  ngOnInit() {
  }

  copy_text(copy_content:string){
    let copyText = document.createElement('input');
    copyText.value = copy_content;
    console.log(copyText.value)
    document.body.appendChild(copyText);
    copyText.select();
    document.execCommand('copy');
    this._toastr.success('', 'Copied Successfully', this._toastrUtility.basic_configuration);
    document.body.removeChild(copyText);
  }

}
