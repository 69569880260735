import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenericButtonService {

  constructor(
    private http: HttpClient
  ) { }

  get_service_data(service_url, payload) {
    return this.http.post<any>(service_url, payload)
  }
}
