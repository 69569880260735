import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wf-aggregate-logs',
  templateUrl: './wf-aggregate-logs.component.html',
  styleUrls: ['./wf-aggregate-logs.component.scss']
})
export class WfAggregateLogsComponent implements OnInit {

  @Input() dataList = [];

  constructor() { }

  ngOnInit() {
  }

}
