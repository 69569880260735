import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/dce-service/common.service';
declare var $: any;

@Component({
  selector: 'app-linked-tasks',
  templateUrl: './linked-tasks.component.html',
  styleUrls: ['./linked-tasks.component.scss']
})
export class LinkedTasksComponent implements OnInit {
  
  @Output() task_data_refresh_event: EventEmitter<any> = new EventEmitter<any>();
  @Input() task_table_id = '';
  @Input() task_id?:string = '';
  @Input() mode = '';
  task_data:any = {};
  task_relationship = '';
  linked_tasks = [];
  selected = [];
  child_columns: any = [
    { prop: 'task_name', name: 'Task Name'},
    { prop: 'owner_full_name' , name:'Task Owner'},
    { prop: 'task_gen_id' , name:'Task ID'},
    { prop: 'task_status', name: 'Status'},
    { prop: 'tg_instance_name', name: 'Task Group Instance'},
    { prop: 'task_type_label', name: 'Task Type'},
    { prop: 'task_planned_start_date', name: 'Planned Start Date'},
    { prop: 'task_planned_end_date', name: 'Expected End Date'}
  ];
  parent_columns: any = [
    { prop: 'task_gen_id' , name:'Task ID'},
    { prop: 'owner_full_name' , name:'Task Owner'},
    { prop: 'task_name', name: 'Task Name'},
    { prop: 'task_status', name: 'Status'},
    { prop: 'tg_instance_name', name: 'Task Group Instance'},
    { prop: 'task_type_label', name: 'Task Type'},
    { prop: 'task_planned_start_date', name: 'Planned Start Date'},
    { prop: 'task_planned_end_date', name: 'Expected End Date'}
  ];
  followup_columns: any = [
    { prop: 'type' , name:'Type'},
    { prop: 'task_gen_id' , name:'Task ID'},
    { prop: 'owner_full_name' , name:'Task Owner'},
    { prop: 'task_name', name: 'Task Name'},
    { prop: 'task_status', name: 'Status'},
    { prop: 'tg_instance_name', name: 'Task Group Instance'},
    { prop: 'task_type_label', name: 'Task Type'},
    { prop: 'task_planned_start_date', name: 'Planned Start Date'},
    { prop: 'task_planned_end_date', name: 'Expected End Date'}
  ];
  columns :any = []
  loadingIndicator = false;
  task_id_input ='';
  constructor(
    public commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.task_table_id.currentValue != null) {
      this.task_id_input ='';
    }
  }

  search_task(){
    const payload = {
      'filter_payload' : {
        'task_gen_id' : {
          'operator': "==",
          'data': this.task_id_input.trim(),
          'type': "str"
        }
      },
      'obj_type':"task_view",
    }
    this.commonService.get_closed_workflow_objects_dynamic_filter(payload).subscribe(res=>{
      if (res.errCode == 0) {
        if(res.msg.length){
          // selected_task.task_task_type == 'Meeting-Task' || selected_task.task_task_type == 'Normal Instance' || selected_task.task_task_type == 'Ad-Hoc'
          this.task_data = res.msg[0];
          if(this.task_data.task_table_id != this.task_table_id){
            if(this.task_data.task_task_type == 'Meeting-Task' || this.task_data.task_task_type == 'Normal Instance' || this.task_data.task_task_type == 'Ad-Hoc') {
              this.open_modal();
            } else {
              this._toastr.error('', 'You can only link Meeting Task, Normal Instance Task and Ad Hoc Task.', this._toastrUtility.basic_configuration);
            }
          } else {
            this.clear_task_input();
            this._toastr.error('', 'Cannot Link same Task to itself.', this._toastrUtility.basic_configuration);
          }
        } else {
          this._toastr.error('', 'Please Enter a valid Task ID', this._toastrUtility.basic_configuration);
        }
      }
    })
  }

  close_modal(){
    $('#task_modal').modal('hide');
    this.clear_task_input();
  }

  open_modal() {
    $('#task_modal').modal({backdrop: 'static', keyboard: false});
  }

  link_child_task(){
    const payload = {
      "parent_id": this.task_table_id,
      "child_id": this.task_data.task_table_id
    }
    this.commonService.link_child_task(payload).subscribe(res=>{
      if (res.errCode == 0) {
        this._toastr.success('', 'Task ID ' +  this.task_data.task_gen_id + ' linked with Child Task ' + this.task_id, this._toastrUtility.basic_configuration);
        this.task_data = {};
        this.close_modal()
        this.task_data_refresh_event.emit({"case": "child", "data": this.task_data});
        this.get_link_task();
      }
    })
  }

  link_parent_task(){
    const payload = {
      "parent_id": this.task_data.task_table_id,
      "child_id": this.task_table_id
    }
    this.commonService.link_parent_task(payload).subscribe(res=>{
      if (res.errCode == 0) {
        this._toastr.success('', 'Task ID ' +  this.task_data.task_gen_id + ' linked with Parent Task ID ' + this.task_id, this._toastrUtility.basic_configuration);
        this.close_modal()
        this.task_data_refresh_event.emit({"case": "parent", "data": this.task_data});
        this.task_data = {};
        this.get_link_task();
      }
    })
  }

  get_link_task(){
    this.task_relationship = '';
    this.linked_tasks = [];
    const payload = {
      "task_id": this.task_table_id
    }
    this.commonService.get_task_links(payload).subscribe(res=>{
      if (res.errCode == 0) {
        if(res.parent.length == 0 && res.children.length == 0){
          this.task_relationship = ''
        }else if(res.parent.length != 0 && res.children.length == 0){
          this.task_relationship = 'parent';
          this.linked_tasks = res.parent;
          this.columns = this.parent_columns;
        }else if(res.parent.length == 0 && res.children.length != 0){
          this.task_relationship = 'child'
          this.linked_tasks = res.children;
          this.columns = this.child_columns;
        }
      }
    })
  }

  get_follow_up_task(){
    // this.task_relationship = '';
    this.linked_tasks = [];
    const payload = {
      "task_id": this.task_table_id
    }
    this.commonService.get_follow_up_task(payload).subscribe(res=>{
      if (res.errCode == 0) {
        const linked_task_data = []
        for(let i =0;i< res.follow_ups.length;i++){
          let temp_obj = res.follow_ups[i]
          temp_obj['type'] = 'Follow Up Task'
          linked_task_data.push(temp_obj)
        }
        for(let i =0;i< res.predecessors.length;i++){
          let temp_obj = res.predecessors[i]
          temp_obj['type'] = 'Predecessor Task'
          linked_task_data.push(temp_obj)
        }
        this.linked_tasks = linked_task_data;
        this.columns = this.followup_columns;
      }
    })
  }

  link_task(){
    if(this.task_relationship == 'parent'){
      this.link_parent_task();
    }else if(this.task_relationship == 'child'){
      this.link_child_task();
    }else{
      // Do Nothing
    }
  }

  on_activate(event){
    // console.log(event)
  }

  onSelect_task_type(type){
    this.linked_tasks = [];
    this.task_relationship = type;
    this.clear_task_input();
  }

  clear_task_input(){
    this.task_id_input = '';
  }

}
