import { Component, OnInit, OnDestroy, HostListener,ViewChild, EventEmitter, Output } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../../../../dce-service/shared.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { EnvService } from 'src/app/dce-service/env.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faExchangeAlt, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router, Route, NavigationEnd, NavigationStart } from '@angular/router';
import { SideContainerComponent } from 'src/app/dce-shared/side-container/side-container.component';
import { SipRenderComponent } from 'src/app/dce-views/jssip/sip-render/sip-render.component';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';
import { QueueSessionRenderComponent } from 'src/app/dce-views/live-chat/queue-session-render/queue-session-render.component';
import { FontawesomeService } from 'src/app/dce-views/dynamic-form/fontawesome.service';
import { LiveChatService } from 'src/app/dce-service/live-chat.service';
import { Md5 } from 'md5-typescript';
import { SocketService } from 'src/app/dce-service/socket.service';


declare var $:any;

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit, OnDestroy {

  @ViewChild(SideContainerComponent ,{static: false}) sideContainer: SideContainerComponent;
  @ViewChild(SipRenderComponent ,{static: false}) sipComp: SipRenderComponent;
  @ViewChild(QueueSessionRenderComponent ,{static: false}) queueSessionRender: QueueSessionRenderComponent;
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.signout();
  }

    notifications: any = [];
    user_name = '';
    user_email = '';
    user_full_name = '';
    recent_notifications = [];
    unread_notification = 0
    interval_obj: any;
    profiles = [];
    current_profile = {
      role_id : -1,
      role_name: ''
    };
    visible_bool = false;
    selected_role_id: any;
    admin_password:string = ''
    da_bot_visible= false;
    workflow_status_count ={};
    constructor(
      private navService: NavigationService,
      public searchService: SearchService,
      private auth: AuthService,
      public _shared: SharedService,
      private _commonService: CommonService,
      private _env: EnvService,
      private sanitizer:DomSanitizer,
      private library: FaIconLibrary,
      private _router: Router,
      private _sharedService: SharedService,
      private _toastr: ToastrService,
      private _toastrUtility: ToastrUtilityService,
      public fa:FontawesomeService,
      public _liveChat: LiveChatService,
      private _socket:SocketService
      // private deviceService: DeviceDetectorService
    ) {
      library.addIcons(faExchangeAlt, faTimes, faUser );
      // this.notifications = [
      //   {
      //     icon: 'i-Speach-Bubble-6',
      //     title: 'New message',
      //     badge: '3',
      //     text: 'James: Hey! are you busy?',
      //     time: new Date(),
      //     status: 'primary',
      //     link: '/chat'
      //   },
      //   {
      //     icon: 'i-Receipt-3',
      //     title: 'New order received',
      //     badge: '$4036',
      //     text: '1 Headphone, 3 iPhone x',
      //     time: new Date('11/11/2018'),
      //     status: 'success',
      //     link: '/tables/full'
      //   },
      //   {
      //     icon: 'i-Empty-Box',
      //     title: 'Product out of stock',
      //     text: 'Headphone E67, R98, XL90, Q77',
      //     time: new Date('11/10/2018'),
      //     status: 'danger',
      //     link: '/tables/list'
      //   },
      //   {
      //     icon: 'i-Data-Power',
      //     title: 'Server up!',
      //     text: 'Server rebooted successfully',
      //     time: new Date('11/08/2018'),
      //     status: 'success',
      //     link: '/dashboard/v2'
      //   },
      //   {
      //     icon: 'i-Data-Block',
      //     title: 'Server down!',
      //     badge: 'Resolved',
      //     text: 'Region 1: Server crashed!',
      //     time: new Date('11/06/2018'),
      //     status: 'danger',
      //     link: '/dashboard/v3'
      //   }
      // ];
    }
  
    ngOnInit() {
      const user_details = this._shared.getValue('user_details');
      this.user_name = user_details.first_name + ' ' + user_details.last_name;
      this.user_full_name = user_details.full_name;
      console.log(this.user_full_name)
      this.user_email = user_details.user_name;
      this.get_notification();
      this.get_notification_interval();
      this.get_profile_roles();
      this.check_telephony();
      this.login_confirmation_livechat();
    }

    ngOnDestroy() {
      clearInterval(this.interval_obj);
      this.signout();
    }

    login_confirmation_livechat() {
      if (this._shared.CONNECTOR_CONTROLS.LIVE_CHAT_SWITCH && this._sharedService.check_dt_role_access('87__87009')) {
        this.open_livechat_login_modal();
      }else {

      }
    }

    check_telephony() {
      if (this._shared.CONNECTOR_CONTROLS.TELEPHONY_SWITCH && this._sharedService.check_dt_role_access('87__87001')) { // check telephony-role attached

        if (this._shared.sip_storage.uri == null || this._shared.sip_storage.config_data == null) {
          this._toastr.error('', 'Telephony Details not found. Failed to start Telephony Interface.', this._toastrUtility.basic_configuration);
          return;
        }

        if (this._shared.sip_storage.telephony_keys == null) {
          this._toastr.error('', 'Telephony Details not linked to Default Workgroup. Failed to start Telephony Interface.', this._toastrUtility.basic_configuration);
          return;
        }

        if (this._shared.sip_storage.config_data.show_sip_login_modal) {
          this.open_sip_login_modal();
        }

      } else {
        // do nothing
      }
    }

    open_force_mic_modal() {
      $('#mic-access').modal({ backdrop: 'static', keyboard: false });
    }

    close_force_mic_modal() {
      $('#mic-access').modal('hide');
    }

    open_sip_login_modal() {
      $('#sip-login').modal({ backdrop: 'static', keyboard: false });
    }
  
    close_sip_login_modal() {
      $('#sip-login').modal('hide');
    }

    open_sip_logout_modal() {
      $('#sip-logout').modal({ backdrop: 'static', keyboard: false });
    }

    close_sip_logout_modal() {
      $('#sip-logout').modal('hide');
    }

    open_repeat_caller_modal() {
      $('#repeat-caller').modal({ backdrop: 'static', keyboard: false });
    }

    close_repeat_caller_modal() {
      $('#repeat-caller').modal('hide');
    }

    open_live_chat_logout_modal() {
      $('#logout_live_chat').modal({backdrop: 'static', keyboard: false});
    }

    close_live_chat_logout_modal() {
      $('#logout_live_chat').modal('hide'); 
    }

    open_live_chat_disconnect_modal() {
      $('#network-disconnect-modal').modal({backdrop: 'static', keyboard: false});
    }

    close_live_chat_disconnect_modal() {
      $('#network-disconnect-modal').modal('hide'); 
    }

    chat_queue_logout() {
      this.queueSessionRender.chat_queue_logout();
      this.close_live_chat_logout_modal();
    }

    open_livechat_login_modal() {
      $('#livechat-login').modal({ backdrop: 'static', keyboard: false });
    }

    chat_queue_login() {
      this.queueSessionRender.socket_ini_connection();
      this.close_livechat_login_modal();
    }

    close_livechat_login_modal() {
      $('#livechat-login').modal('hide');
    }

    login_sip() {
      if (this._shared.sip_storage.safe_logout) {
        setTimeout(() => {
          this.sipComp.login();
        }, 1000);
      } else {
        this.sipComp.login();
      }
      this.close_sip_login_modal();
    }

    logout_sip(){
      this._shared.sip_storage.safe_logout = true;
      this.sipComp.logout();
      this.close_sip_logout_modal();
    }

    get_open_side_container() {
      this.visible_bool = true;
      this.sideContainer.openContainer();
    }
  
  
    get_notification(){
      this.unread_notification = 0;
      this.recent_notifications = [];
      this._commonService.get_unread_push_notifications({'climit':10}).subscribe(res => {
        if (res.errCode == 0 ) {
          this.notifications = res.msg;
          this.unread_notification = res.unread_count
          for (let index = 0; index < this.notifications.length; index++) {
            const element = this.notifications[index];
            element.message = this.sanitizer.bypassSecurityTrustHtml(element.message)
            this.recent_notifications.push(element);
          }
        }
      });
    }
    get_notification_interval() {
      this.interval_obj = setInterval(()=>{
        this.get_notification();
      },this._env.notification_interval)
    }

    mark_as_read_notfication(id,read_all) {
      const payload = {
        'notification_id_list': [id],
        'read_all':read_all
      };
      this._commonService.mark_read_notifications(payload).subscribe(res => {
        if (res.errCode == 0) {
          for (let i = 0; i < this.recent_notifications.length; i++) {
            if (this.recent_notifications[i].id == id || read_all) {
              this.recent_notifications[i].status = "Read";
              if(!read_all){
                this.recent_notifications.splice(i,1)
                this.unread_notification -= 1;
                if(this.recent_notifications.length < 5){
                  this.get_notification();
                }
                break;
              }
            }
          }
          if(read_all){
            this.unread_notification = 0;
          }
        }
      })
    }

    get_workflow_status_count(){
      const payload ={
        "task_type": "DA",
        "obj_type": "task_view"
      }
      
      this._commonService.get_workflow_status_count(payload).subscribe(res =>{
         if(res.errCode == 0){
             this.workflow_status_count=res.datarec;
         }
      })
    }

    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      if (!state.sidenavOpen && !state.childnavOpen) {
          state.sidenavOpen = true;
      }
    }
  
    signout() {
      console.log("signout")
      let tkn = this._shared.getToken()
      this._socket.da_socket_session_close();
      // this.logout();
      // if (tkn != undefined) {
      // }
      if (localStorage.getItem('token') != undefined) {
        this.logout();
      }
    }

    async logout() {
      // if (this.sipComp != undefined) {
      //   await this.sipComp.logout();
      // } // no need for explicit sip logout; as we dont allow user to logout of dce unless sip is logged-in
      const payload = {}; // will be set in future based on functionality during logging out.
      this.auth.logout(payload).subscribe(res => {
        if (res.errCode === 0) {
          this._shared.removeValue('token');
          // do nothing for now
          this.auth.signout();
          this.navService.sidebarState.showAnnouncement = false;
        }
      });
    }

    get_profile_roles() {
      this.profiles = this._shared.getValue('user_roles');
      const current_role = this._shared.getValue('current_role');
      console.log(this._shared.getValue('current_role'));
      console.log(this._shared.getValue('current_role'));
      this.current_profile.role_id = current_role.role_id;
      this.current_profile.role_name = current_role.role_name;
    }

    switch_role(role_id){
      const payload = {
        'role_id': role_id, 
        'password': Md5.init(Md5.init(this.admin_password))
      }
      if(role_id != null &&  this.admin_password != "") {
        this._commonService.get_data_after_refresh(payload).subscribe(
          res => {
            if(res.errCode == undefined && Object.keys(res).length > 0) {
              // window.location.reload();
              this._shared.setValue('token', res.token); // This will be used only when use_local_storage is false in env.
              localStorage.setItem("token", res.token);
              this.reiniate_roles_sidebar({'role_id': role_id})
              this._toastr.success('', 'Successfully switched to role - ' + res.current_role.role_name, this._toastrUtility.basic_configuration);
              this.close_switch_profile_modal();
            }
        });
      }else {
        if(role_id == undefined) {
          this._toastr.warning('', 'Please select a role to switch.', this._toastrUtility.basic_configuration);
        }else {
          this._toastr.warning('', 'Please enter admin password.', this._toastrUtility.basic_configuration);
        }
      }
    }

    async reiniate_roles_sidebar(payload) {
      // this.sidebar_loaded = false;
      const get_user_rec = await this._shared.promise_fn(payload); // api to switch tenant / switch role payload differ.
      if (get_user_rec.errCode === 0) {
        await this._shared.get_sidebar_tree();
        this.get_profile_roles();
        this.route_dashboard_skip_local();
      }
      return get_user_rec;
    }

    route_dashboard_skip_local() {
      // route to dashboard v2, skip location and reroute to home dashbaord
      this._router.navigateByUrl("/dashboard/v2", { skipLocationChange: true })
        .then(() => this._router.navigate(["./dashboard/v1"]));
    }
    
    side_div_events(event) {
      switch(event.type){
        case 'close':
          this.visible_bool = false;
        break;
      }
    }

    // customer-info methods
    cus_info_toggler() {
      this._shared.sip_storage.cus_info_toggle = !this._shared.sip_storage.cus_info_toggle;
    }

    sip_parent_events(e) {
      const literal = {
        init: () => {
          // this.sip_wrapper.sip_user_config = this.uri_config;
        },
        mic_access: () => {
          if (!e.data) {
            this.close_sip_login_modal();
            this.open_force_mic_modal();
          }
        },
        sip_logout_modal: () => {
          this.open_sip_logout_modal();
        },
        repeat_caller_modal: () => {
          this.open_repeat_caller_modal();
        }
      }
      if (literal[e.case]) { //fail-safe
        return literal[e.case](this);
      }
    }

    live_chat_parent_events(e) {
      console.log(e);
      const literal = {
        live_chat_logout_modal: () => {
          this.open_live_chat_logout_modal();
        },
        live_chat_disconnect_modal: () => {
          this.open_live_chat_disconnect_modal();
        },
      }
      if (literal[e.case]) { //fail-safe
        return literal[e.case](this);
      }
    }

    chat_popup_event(event) {
      console.log(event)
      switch (event.case) {
        case 'live_chat_disconnect_modal':
          this.open_live_chat_disconnect_modal();
        break;
      }
    }

    open_switch_profile_modal(){
      $('#switchProfileModal').modal({backdrop: 'static', keyboard: false});
      this.admin_password = "";
    }

    close_switch_profile_modal() {
      this.selected_role_id = 0
      $('#switchProfileModal').modal('hide');
      //
    }

    da_bot_visibility(){
       if(!this.da_bot_visible){
        console.log("yess");
        this.get_workflow_status_count();
       }
      
        this.da_bot_visible = ! this.da_bot_visible;
    }
    task_count_refresh(){
      this.get_workflow_status_count();
    }
    hide_da_bot_on_cross(){
      this.da_bot_visible = false;                        // function called when cross button of da bot clicked
    }
}
