import { Injectable } from '@angular/core';
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class FontawesomeService {
	icon_map = {
		'faCog'  : fas.faCog,
		'faBars' : fas.faBars,
		'faSignOutAlt' : fas.faSignOutAlt,
		'faUser' : fas.faUser,
		'faHome' : fas.faHome,
		'faHeartbeat' : fas.faHeartbeat,
		'faCaretUp' : fas.faCaretUp,
		'faCaretDown' : fas.faCaretDown,
		'faBell' : fas.faBell,
		'farBell' : far.faBell,
		'faSpinner' : fas.faSpinner,
		'faExclamationTriangle' : fas.faExclamationTriangle,
		'faTimes' : fas.faTimes,
		'faEye' : fas.faEye,
		'faEyeSlash' : fas.faEyeSlash,
		'faTicketAlt' : fas.faTicketAlt,
		'faList' : fas.faList,
		'faLock' : fas.faLock,
		'faCartPlus' : fas.faCartPlus,
		'faChartLine' : fas.faChartLine,
		'faFileAlt': fas.faFileAlt,
		'faPhone':fas.faPhone,
		'faSuitcase':fas.faSuitcase,
		'faEnvelope':far.faEnvelope,
		'faDownload' : fas.faDownload,
		'faDirections' : fas.faDirections,
		'faClipboardList' : fas.faClipboardList,
		'faFileInvoice' : fas.faFileInvoice,
		'faTimesCircle' : fas.faTimesCircle,
		'faQuestionCircle' : fas.faQuestionCircle,
		'faFolderOpen' : fas.faFolderOpen,
		'faFolderPlus' : fas.faFolderPlus,
		'faArrowCircleLeft' : fas.faArrowCircleLeft,
		'faLink' : fas.faLink,
		'faFax' : fas.faFax,
		'faCheck' : fas.faCheck,
		'faChat': fas.faComments,
		'faSend': fas.faPaperPlane,
		'faCross': fas.faTimesCircle,
		'faAngleRight': fas.faAngleRight,
		'faAngleDown': fas.faAngleDown,
		'faReply': fas.faReply,
		'faExternalLink': fas.faExternalLinkAlt,
		'faCaretRight' : fas.faCaretRight,
		'faCaretLeft' : fas.faCaretLeft,
		'faStepForward' : fas.faStepForward,
		'faFilePdf': fas.faFilePdf
	}
	constructor() { }
}
